import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take,concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-org-add',
  templateUrl: './org-add.component.html',
  styleUrls: ['./org-add.component.css']
})
export class OrgAddComponent implements OnInit {

  mainOrg: any;
  searchText: any;
  table:any;
  sortOrderAscOrDesc = true;
  msgListGet = false;
  orgInfo: any = {};
  itemsSubscribted: any = [];
  userInfo: any;
  users: any = [];
  companyModel: any;
  epost: any;
  firstname: any;
  checkBoxItems = new Array();
  subscriptionValues: any;
  items2: any = [];
  checkBoxItems2: any = [];

  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.dataService.getSubscriptionValues();
    this.subscriptionValues=this.dataService.subscriptionValuesListener().subscribe((subscriptionValues)=>{
    this.subscriptionValues=subscriptionValues;
    for(let i=0;i<this.subscriptionValues.subscriptions.length;i++){
      this.items2.push(this.subscriptionValues.subscriptions[i].name);
      this.checkBoxItems.push(this.subscriptionValues.subscriptions[i].field+':0:'+this.subscriptionValues.subscriptions[i].name);
      this.checkBoxItems2.push(this.subscriptionValues.subscriptions[i].field);
      }
    });

    this.msgService.sendMessage(null);
  }

  goBack() {
    this.routing.navigate(['/controlpanel/organisations']);
  }

  addItem() {
    var qwertObject=({
      'orginfoId':this.orgInfo.Id,
      'orginfoName':this.orgInfo.Name,
      'orginfoOrg_nr':this.orgInfo.Org_nr,
      'orginfoAddress_1':this.orgInfo.Address_1,
      'orginfoAddress_2':this.orgInfo.Address_2,
      'orginfoVisitAddress':this.orgInfo.visitAddress,
      'orginfoZip':this.orgInfo.Zip,
      'orginfoCity':this.orgInfo.City,
      'orginfoCountry':this.orgInfo.Country,
      'orginfoWww':this.orgInfo.Www,
      'orginfoEmail':this.orgInfo.Email,
      'orginfoPhone':this.orgInfo.Phone,
      'orginfoContact_person':this.orgInfo.Contact_person,
      'userId':this.authService.getOrgid()
    });
    this.dataService.addUser(qwertObject, this.checkBoxItems);
  }
}
