import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "../../../auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take, concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-safetyroundissue-edit-five',
  templateUrl: './safetyroundissue-edit-five.component.html',
  styleUrls: ['./safetyroundissue-edit-five.component.css']
})
export class SafetyroundissueEditFiveComponent implements OnInit {

  safetyissueid: any;
  orgId: any;
  localOrgId: any;
  safetyRoundValue: any;
  fileNames: any = [];
  file: any;
  filetypeinfo: any;
  subscriptions: any;
  is_admin: any;
  is_super_user: any;

  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router) { }

  ngOnInit(): void {
    this.orgId = this.authService.getOrgid();
    this.localOrgId = this.authService.getLocalOrgid();
    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    this.subscriptions=this.dataService.getSubscriptions(this.localOrgId);
    this.dataService.subscriptionsListener()
    .subscribe((subscriptions) => {
      this.subscriptions=subscriptions[0];
    });

    this.route.params.pipe(take(1)).subscribe(params => {
      this.safetyissueid = params['id'];

      this.dataService.getRoundById(this.safetyissueid);
      this.dataService
        .roundModelTableListener()
        .pipe(take(1))
        .subscribe((roundItem: any) => {
          this.safetyRoundValue = roundItem.roundvalue;
      });
    });

    this.msgService.sendMessage(null);
  }

  goBack() {
    this.routing.navigate(['safetyrounditem/' + this.safetyRoundValue.Id + '/safetyroundissueitem/' + this.safetyissueid]);
  }

  updateItem() {
    let user = this.authService.getUserId();
    this.dataService.updateround(this.safetyRoundValue, user);
  }
}
