<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div *ngIf="safetyRoundValue" class="ui card">
                <div class="computer only ui grid secondary pointing menu" style="padding:20px;width:100%">
                    <a class="item" data-target="#" (click)="changeTab(true)" [class.active]="showTab1==true">{{'safetyrounditem' | translate}}</a>
                    <a *ngIf="is_admin||is_super_user" class="item" data-target="#" (click)="changeTab(false)" [class.active]="showTab2==true">{{'riskevaluation' | translate}}</a>
                    <span class="back-edit-text" (click)="goBack()"><button class="ui white button"><button class="ui white button">{{"back" | translate}}</button></button></span>
                </div>
                <div class="mobile only ui grid secondary pointing menu" style="padding:20px;width:100%">
                    <a class="item" data-target="#" (click)="changeTab(true)" [class.active]="showTab1==true">{{'safetyrounditem' | translate}}</a>
                    <a *ngIf="is_admin||is_super_user" class="item" data-target="#" (click)="changeTab(false)" [class.active]="showTab2==true">{{'riskevaluation' | translate}}</a>
                    <i class="ui icon left arrow" style="cursor:pointer;margin-top:15px" (click)="goBack()"></i>
                </div>
                <div *ngIf="showTab1==true" style="padding:10px">
                    <form class="ui form" #postForm="ngForm">
                        <div class="field">
                            <label>{{'place'|translate}}</label>
                            <input id="place" name="place" [(ngModel)]="safetyRoundValue.roundLocation"/>
                        </div>
                        <div class="field">
                            <label>{{'shortageorcomment'|translate}}</label>
                            <input id="roundRisk" name="roundRisk" [(ngModel)]="safetyRoundValue.roundRisk"/>
                        </div>
                        <div class="field">
                            <label>{{'proposedmeasures'|translate}}</label>
                            <textarea id="measures" name="measures" rows="3" [(ngModel)]="safetyRoundValue.roundMeasure"></textarea><br><br>
                        </div>
                        <div class="field">
                            <label>{{'responsible'|translate}}</label>
                            <select [(ngModel)]="safetyRoundValue.roundResponsible2" name="roundResponsible2" required>
                                <option disabled selected hidden>{{safetyRoundValue.roundResponsible2}}</option>   
                                <option *ngFor="let userInfoitem of userInfo;let i=index" [ngValue]="userInfoitem.username">{{userInfoitem.username}}</option>
                            </select>
                        </div>
                        <div class="field">
                            <label>{{'date'| translate }}</label>
                            <input type="date" name="roundDoneDate" [value]="safetyRoundValue.roundDoneDate" [(ngModel)]="safetyRoundValue.roundDoneDate" placeholder="safetyRoundValue.roundDoneDate"/>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" id="roundDone" name="roundDone" [value]="roundDone" [(ngModel)]="roundDone">
                                <label>{{'done'|translate}}</label>
                            </div><br>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" id="roundActive" name="roundActive" [value]="roundActive" [(ngModel)]="roundActive">
                                <label>{{'active'|translate}}</label>
                            </div><br>
                        </div>
                        <div class="field">
                            <input class="ui button" type="file" (change)="imageUpload($event)" placeholder="Upload file" accept="application/msword,
                                application/vnd.ms-excel,
                                application/vnd.ms-powerpoint,
                                text/plain,
                                application/pdf,
                                image/*,
                                video/*"/>
                            
                            <div [hidden]="!uploadFileDone" class="ui segment">
                                <div class="ui active loader"></div>
                                <p></p>
                            </div><br><br><br>
                            
                            <div class="ui grid item" *ngFor="let file of fileNames; let i=index" style="width:100%;margin-left:20px;padding:0px">
                                <div class="row" *ngIf="filetypeinfo&&filetypeinfo[i]" style="margin:0px;padding:0px">
                                    <div class="eight wide column" style="margin:0px;padding:0px">
                                        <i class="ui icon file alternate outline"></i>{{filetypeinfo[i].thisFileName}}
                                    </div>
                                    <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                        <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                    </div>
                                </div>
                            </div><br>
                        </div>
                        <div class="field">
                            <div class="ui modal">
                                <div class="ui range" id="smooth"></div>
                            </div>
                        </div>
                        <div class="field">
                            <button [hidden]="uploadFileDone" style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                        </div>
                    </form>
                </div>
                <div *ngIf="showTab2==true" style="padding:10px">
                    <div class="ui grid row" style="margin-right:4%">
                        <div class="fifteen wide column"></div>
                        <div class="one wide column">
                            <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1">
                                <div class="{{riskvaluesModal(safetyRoundValue.roundSeverity, safetyRoundValue.roundPossibility, safetyRoundValue.roundFrequency,'panel')}}">
                                    {{riskvaluesModalNumber(safetyRoundValue.roundSeverity, safetyRoundValue.roundPossibility, safetyRoundValue.roundFrequency)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <label for="riskSeverity" style="margin-left: 10px" class="col-sm-12 col-form-label">{{'Severity'|translate}}<br/>{{severityValues(safetyRoundValue.roundSeverity)}}</label>
                    <input type="range" style="margin-left: 20px; width: 95%"
                            class="form-control"
                            id="riskSeverity"
                            min="1"
                            max="10"
                            step="1"
                            list="ticks"
                            name="riskSeverity"
                            [value]="safetyRoundValue.roundSeverity"
                            [(ngModel)]="safetyRoundValue.roundSeverity" />
                    <br>
                    <div class="col-sm-12" style="margin-left: 10px; width: 95%">
                        <textarea id="riskSeverityComment"
                                name="riskSeverityComment"
                                style="width: 100%;"
                                [value]="safetyRoundValue.roundSeverityComment"
                                [(ngModel)]="safetyRoundValue.roundSeverityComment">
                        </textarea>
                    </div>
                    <hr/>
                    <label for="riskFrequency" class="col-sm-12 col-form-label" style="margin-left: 10px;">{{'Frequency'|translate}}<br/>{{frequencyValues(safetyRoundValue.roundFrequency)}}</label>
                    <input type="range" style="margin-left: 20px; width: 95%" class="form-control" id="riskFrequency" name="riskFrequency" min="1" max="10" step="1"
                        list="ticks" [value]="safetyRoundValue.roundFrequency" [(ngModel)]="safetyRoundValue.roundFrequency" />
                    <br>
                    <div class="col-sm-12" style="margin-left: 10px; width: 95%">
                        <textarea id="riskFrequencyComment"
                                name="riskFrequencyComment"
                                style="width: 100%;"
                                [value]="safetyRoundValue.roundFrequencyComment"
                                [(ngModel)]="safetyRoundValue.roundFrequencyComment"></textarea>
                    </div>
                    <hr />
                    <label for="riskPossibility" style="margin-left: 10px;" class="col-sm-12 col-form-label">{{'Possibility'|translate}}<br />{{possibilityValues(safetyRoundValue.roundPossibility)}}</label>
                    <input type="range" class="form-control" style="margin-left: 20px; width: 95%" id="riskPossibility" name="riskPossibility" min="1" max="10"
                        step="1" list="ticks" [value]="safetyRoundValue.roundPossibility" [(ngModel)]="safetyRoundValue.roundPossibility" />
                    <br>
                    <div class="col-sm-12" style="margin-left: 10px; width: 95%">
                        <textarea id="riskPossibilityComment"
                                name="riskPossibilityComment"
                                style="width: 100%;"
                                [value]="safetyRoundValue.roundPossibilityComment"
                                [(ngModel)]="safetyRoundValue.roundPossibilityComment"></textarea>
                    </div>
                    <label style="margin-left: 10px;" for="roundTotalComment" class="col-sm-12 col-form-label">{{'commenthazard'|translate}}:</label>
                    <div class="col-sm-12">
                        <textarea id="roundTotalComment" name="roundTotalComment" style="margin-left: 10px; width: 95%;" [value]="safetyRoundValue.roundTotalComment"
                            [(ngModel)]="safetyRoundValue.roundTotalComment">
                        </textarea>
                        <div style="margin-left: 10px;">            
                            <button style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
