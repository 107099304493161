import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFService } from "src/app/shared/services/pdf.service";
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { take, concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-send-email-workplace',
  templateUrl: './modal-send-email-workplace.component.html',
  styleUrls: ['./modal-send-email-workplace.component.css']
})
export class ModalSendEmailWorkplaceComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() workplaceitem:any;
  @Input() workplaceValues:any;

  mailReceiver: any;
  mailSubject: any;
  mailMessage: any;
  mailCheckbox: boolean;
  emailisValid: any = true;

  constructor(public activeModal: NgbActiveModal,
    private authService: AuthService, 
    private msgService: MessageService,
    private router: Router,
    private dataService: DataService,
    private pdfService: PDFService,
    private uploadService: FileUploadService) { }

  ngOnInit(): void {
    this.mailReceiver = "";
    this.mailSubject = "";
    this.mailMessage = "";
  }

  mailModalSend() {
    var path = "main";
    var mailReceiver = this.mailReceiver;
    if (!mailReceiver) {
      this.emailisValid = false;
      return;
    } else if (!mailReceiver.match("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")) {
      return;
    }

    var mailMessage = this.mailMessage;
    var mailSubject = this.mailSubject+"- Arbetsplats";
    var mailRond = "";
    let attachmentfile="";

    // I remove the file a while before I upload another
    // as it is asynchronous events
    var file:any = this.pdfService.createPDF('workplace',this.workplaceitem, this.workplaceitem.Id, this.workplaceValues, true);
    attachmentfile="arbetsplats";

    this.uploadService.upload(file, 'tempfileRN5251.pdf');
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {

      if (filename == null)
      {
        attachmentfile="";
      }

      mailMessage+="<hr/><b><u>Arbetsplats:</u><br/>Ansvarig:</b> "
      this.workplaceitem.Supervisor+"<br/>"
      "<b>Deltagare:</b> "
      this.workplaceitem.Participants+"<br/>"
      "<b>Datum:</b> "
      this.workplaceitem.doneDate+"<br/>"
      "<b>Avdelning:</b> "
      this.workplaceitem.Department+"<br/>"
      "<b>Rubrik:</b> "
      this.workplaceitem.Title+"<br/>"
      "<b>Beskrivning:</b> "
      this.workplaceitem.Text+"<br/><br/>";
      
      mailMessage+="<u>Rondpunkter</u><ul>åtgärd - ansvarig - datum";
      for(var n=0;n<this.workplaceValues.length;n++){
        mailMessage += "<li>"
          +this.workplaceValues[n].roundMeasure
          +' - '
          +this.workplaceValues[n].roundResponsible2
          +' - '
          +this.workplaceValues[n].roundDoneDate
          +'</li>';
      }
      mailMessage+="</ul>";

      if (mailMessage == "") {
        mailMessage = "Meddelande";
      }
      // A very simple way to get a delay
      // (to give the pdf time to upload)
      for(var n=0;n<20000;n++){
        n++;
      }

      this.dataService.sendmail(
        mailReceiver,
        mailMessage,
        mailSubject,
        mailRond,
        attachmentfile,
        path
      );

      this.activeModal.close(ModalSendEmailWorkplaceComponent);
    });
  }
}
