import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/shared/services/data.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  message: string;
  token: boolean;
  usermodel: any;
  localOrgId: any;
  loginForm: any;
  private authListenerSubs: Subscription;
  constructor(private authService:AuthService,private activatedRoute: ActivatedRoute, 
              private formBuilder:FormBuilder, private dataService: DataService,
              private msgService: MessageService) { }

  ngOnInit() {
      this.loginForm = this.formBuilder.group({
        username: [''],
        password: [''],
      });

      this.activatedRoute.queryParams.subscribe(params => {
        const access_token = params['access_token'];

        if(access_token&&access_token!=''){
          // this.token=true;
        this.authService.loginToken(access_token);
        this.authListenerSubs = this.authService
          .getAuthServiceListener()
          .subscribe(isAuthenticated => {
          //this.isAuthenticated = isAuthenticated;
          if(isAuthenticated==false){
            this.message="Felaktig token, skriv in användarnamn och lösenord"
          }else{
            this.message=""
          }
        });
      }else{
        this.token=false;
      }
    });


  }

  login(){
    if (this.loginForm.invalid) {
      this.message="Fyll i lösenord och användarnamn"
      return;
    }

    this.authService.login(this.loginForm.value.username, this.loginForm.value.password);
    this.authListenerSubs = this.authService
      .getAuthServiceListener()
      .subscribe(isAuthenticated => {
        //this.isAuthenticated = isAuthenticated;
        if(isAuthenticated==false){
          this.message="Felaktigt användarnamn eller lösenord försök igen"
        }else{
          this.message=""
        }
    });
    this.loginForm.reset();
  }
}
