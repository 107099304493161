import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { Orgmodel } from '../../shared/model_view/orgmodel';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { SafetyRoundModel, riskValueModel } from "src/app/shared/model_view/safetymodel";
import { take } from "rxjs/operators";
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-incident-add',
  templateUrl: './incident-add.component.html',
  styleUrls: ['./incident-add.component.css']
})
export class IncidentAddComponent implements OnInit {

  selectIncidentType = "";
  selectIncidentCategory = "";
  eventList: any;
  orgId: any;
  categoryList: any;
  incidentReporter: any;
  incidentDate: any ="";
  todayDate: any;
  depname: any;
  orgmodel: any;
  usermodel: any;
  localOrgId: any;
  incidentPlace: any;
  incidentTitle: any;
  incidentDescription: any;
  incidentCause: any;
  selectedFile: any = [];
  files: any = [];
  item: any = [];
  filenames: any = [];
  file: any;
  randomNumber: any;
  IncidentValues: any;
  IncidentRoundValues: any = new Array();
  userInfo: any;
  userId: any;
  oldid: any;
  itemArray: any;
  doNotShowSubDepartment: boolean;
  uploadFileDone: boolean = false;
  depnameId: any;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $("#incidentResponsibleChose").dropdown();
    $("#depnameChose").dropdown();
    $("#incidenttypechose").dropdown();
    $("#injurytypechose").dropdown();
  }

  ngOnInit(): void {
    this.todayDate = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.incidentDate = this.todayDate;

    this.orgId = this.authService.getOrgid();
    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();

    this.dataService.getEventList(this.orgId);
    this.dataService.getEventListListener().subscribe((eventList:[]) => {
      this.eventList = eventList;
    });

    this.dataService.getCategoryList(this.orgId);
    this.dataService.getCategoryListListener().subscribe((categoryList:[]) => {
      this.categoryList = categoryList;
    });

    if (this.localOrgId) {
      this.dataService.getUserInfo(this.localOrgId);
      this.dataService
        .getUserModelListener()
        .subscribe((usermodel: any) => {
          if (usermodel[0].name) {
            this.usermodel = usermodel[0].name;
            this.msgService.sendMessage(this.usermodel);
          }
      });
    }

    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService
      .orgModelListener()
      .pipe(take(1))
      .subscribe((orgmodel: Orgmodel) => {
        this.orgmodel = orgmodel;
        if (this.doNotShowSubDepartment == true) {
          this.itemArray = null;
        } else {
          this.itemArray = this.displayTreeItem(this.localOrgId);
        }

        if (this.orgmodel) {
          var count=this.orgmodel.length;
          for (var n = 0; n < count; n++) {
            if (this.orgmodel[n].Name == this.usermodel) {
              this.depname = this.orgmodel[n].Name;
            }
          }
        }
    });

    this.dataService.getUsers(this.authService.getOrgid(), this.userId);
    this.dataService.usersListener().subscribe((users) => {
      this.userInfo = users;
    });
  }

  setRoundResponsible(userInfo:any) {
    this.incidentReporter = userInfo.username;
  }

  displayTreeItem(id:any) {
    if (this.orgmodel) {
      if (this.oldid != id) {
        this.oldid = id;
        var count = Object.keys(this.orgmodel).length;
        this.itemArray = new Array();
        for (let nthItem = 0; nthItem < count; nthItem++) {
          if (
            this.orgmodel[nthItem].Parent == id ||
            (this.itemArray &&
              this.itemArray.indexOf(+this.orgmodel[nthItem].Parent) > -1)
          ) {
            this.itemArray.push(this.orgmodel[nthItem].Id);
          }

          if (this.orgmodel) {
            let count=this.orgmodel.length;
            for (let n = 0; n < count; n++) {
              if (this.orgmodel[n].Name == this.usermodel) {
                this.depname = this.orgmodel[n].Name;
                this.depnameId = this.orgmodel[n].Id;
              }
            }
          }
        }
      } else {
        this.oldid = null;
        this.itemArray = [];
      }
      return this.itemArray;
    }
  }

  imageUpload(event:any) {
    this.uploadFileDone = true,
    this.uploadService.upload(event.target.files[0], "");
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {
      if (filename == null)
      {
        this.uploadFileDone = false;
        Swal.fire("ERROR!", this.translate.instant('FILE_TO_BIG'));
      }
      else
      {
        this.files.push(filename);
        setTimeout(() => {
          this.uploadFileDone = false;

          this.selectedFile.push(event.target.files[0]);
    
          if (this.files) {
            this.filenames.push(event.target.files[0].name);
          } else {
            this.filenames = event.target.files[0].name;
          }
          this.item.push(1);
        }, 1000);
      }
    });
  }

  removeImage(file: any, index: any) {
    this.selectedFile.splice(index, 1);
    this.filenames.splice(index, 1);
  }

  goBack() {
    this.routing.navigate(['/incident']);
  }

  setIncidentType(type:any) {
    this.selectIncidentType = type.name;
  }

  setCategoryType(type:any) {
    this.selectIncidentCategory = type.name;
  }

  setDepartment(department: any) {
    this.depname = department.Name + "|" + department.Id;
    this.depnameId = null;
  }

  add_incident(form: NgForm){
    //form.reset();
  }

  saveItem() {

    if (!this.incidentReporter ||!this.incidentPlace ||!this.incidentTitle || this.selectIncidentType == this.translate.instant('choose')) {
      if (!this.incidentReporter) {
        Swal.fire(this.translate.instant("INFORMATION_MISSING"), this.translate.instant("INFORMATION_MISSING_RESPONSIBLE"), "error");
      }

      if (!this.incidentPlace) {
        Swal.fire(this.translate.instant("INFORMATION_MISSING"), this.translate.instant("INFORMATION_MISSING_PLACE"), "error");
      }

      if (!this.incidentTitle) {
        Swal.fire(this.translate.instant("INFORMATION_MISSING"), this.translate.instant("INFORMATION_MISSING_HEADING"), "error");
      }

      if (this.selectIncidentType == this.translate.instant('choose')) {
        Swal.fire(this.translate.instant("INFORMATION_MISSING"), this.translate.instant("INFORMATION_MISSING_INCIDENT_TYPE"), "error");
      }
      return;
    }

    this.file = "";
    for (var i = 0; i < this.selectedFile.length; i++) {
      if (this.selectedFile[i]) {
        if (i != 0 || (i == 0 && this.file != "")) {
          this.file += ",";
        }
        this.file += this.files[i];
      }
    }

    Math.random;
    this.randomNumber = "R-" + Math.floor(Math.random() * 100);

    this.IncidentValues = [];

    if (this.depnameId != null)
    {
      this.depname = this.depname + "|" +  this.depnameId;
    }

    if (this.incidentDate == "")
    {
      this.incidentDate = new Date().toISOString().slice(0, 10);
    }

    this.IncidentValues.push({
      Id: this.randomNumber,
      Date: this.incidentDate,
      Reporter: this.incidentReporter,
      Department: this.depname,
      Place: this.incidentPlace,
      Title: this.incidentTitle,
      Description: this.incidentDescription,
      Cause: this.incidentCause,
      Files: this.file,
      typeofaction:this.selectIncidentType,
      category: this.selectIncidentCategory
    });

    let user = +this.authService.getUserId();
    let localOrg = +this.authService.getLocalOrgid();
    let org = +this.authService.getOrgid();
    this.dataService.addIncident(
      this.IncidentValues,
      this.IncidentRoundValues,
      user,
      this.localOrgId,
      org
    );
  }
}
