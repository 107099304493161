import { Component, OnInit } from "@angular/core";
import { DatePipe } from '@angular/common';
import { Subscription, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { PDFService } from "src/app/shared/services/pdf.service";
import { FileUploader } from "ng2-file-upload";
import { MessageService } from "src/app/shared/services/message.service";
import { SafetyRoundModel, riskValueModel } from "src/app/shared/model_view/safetymodel";
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { Orgmodel } from 'src/app/shared/model_view/orgmodel';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';
import { TranslateService } from '@ngx-translate/core';
import { DataTableSetup } from 'src/app/shared/services/dataTableSetup';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.css']
})
export class IncidentComponent implements OnInit {

  dtOptions: any = {};
  searchText: any;
  table:any;
  doNotShowSubDepartment: any = false;
  sortOrderAscOrDesc:any = true;
  private orgId: any;
  orgmodel: any;
  itemArray: any;
  oldid: any = 0;
  localOrgId: any;
  headerid: any = null;
  spinner: boolean = true;
  is_admin: any;
  is_super_user: any;
  incidentValues_table: any = [];
  incidentValues_table2: any = [];
  userId: any;
  currentUser: any;

  constructor(private msgService: MessageService,
    private authService: AuthService,
    private dataService: DataService,
    private pdfService: PDFService,
    private router: ActivatedRoute,
    private routing: Router,
    private datePipe: DatePipe,
    private dataTableSetup: DataTableSetup,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.doNotShowSubDepartment = this.authService.getShowOrHideSubdepartment();
    this.orgId = this.authService.getOrgid();
    this.localOrgId = this.authService.getLocalOrgid();
    this.headerid = this.router.snapshot.queryParamMap.get("id");
    this.dtOptions =this.dataTableSetup.dataTableInit('incident');
    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
    this.userId = this.authService.getUserId();
    this.headerid = this.router.snapshot.queryParamMap.get('id');
    this.currentUser = this.authService.getUsername();

    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService
      .orgModelListener()
      .pipe(take(1))
      .subscribe((orgmodel: Orgmodel) => {
        this.orgmodel = orgmodel;
        if (this.doNotShowSubDepartment == true) {
          this.itemArray = null;
        } else {
          this.itemArray = this.displayTreeItem(this.localOrgId);
        }
        this.dataService.getIncidentValues(this.headerid, this.itemArray, this.userId);
        this.dataService.incidentListener().pipe(take(1)).subscribe({
          next: incidentValues_table => {
            // On Next
            this.incidentValues_table = incidentValues_table;
            var tableindexvalues = new Array();
            // För att inte få med dubletter
            var tlength = this.incidentValues_table.length;
            for (var tableindex = 0; tableindex < tlength; tableindex++) {
              if (
                tableindexvalues.indexOf(
                  this.incidentValues_table[tableindex].Id
                ) == -1
              ) {
                tableindexvalues.push(
                  this.incidentValues_table[tableindex].Id
                );

                if (this.is_admin == 0 && this.is_super_user == 0)
                {
                  if ((!this.incidentValues_table[tableindex].Reporter.indexOf(this.currentUser)) || (!this.incidentValues_table[tableindex].roundResponsible2.indexOf(this.currentUser)))
                  {
                    this.incidentValues_table2.push(
                      this.incidentValues_table[tableindex]
                    );
                  }
                }
                else
                {
                  this.incidentValues_table2.push(
                    this.incidentValues_table[tableindex]
                  );
                  }
              }
            }
          },
          error: error => {
            if (this.headerid && !isNaN(+this.headerid)) {
              this.spinner = false;
            }
            this.spinner = false;
          }
        })
    });

    this.msgService.sendMessage(null);
  }

  showRoundInfo(id:any){ 
    var numberOfRounds=0;
    var numberOfDoneRounds=0;

    for(var n=0;n<this.incidentValues_table.length;n++){

      if(this.incidentValues_table[n].roundId!=null&&this.incidentValues_table[n].incidentId==id){
        numberOfRounds++;
        if(this.incidentValues_table[n].roundDone=="Ja"){
          numberOfDoneRounds++;
        }
      }
    }
    if(numberOfRounds>0&&numberOfDoneRounds<numberOfRounds){
      return ({"A":numberOfDoneRounds+" / "+numberOfRounds,"B":true});
    }
    else if(numberOfRounds==0) {
      return null;
    }else{
      return ({"A":numberOfDoneRounds+" / "+numberOfRounds,"B":false});
    }
  }

  addItem() {
    this.routing.navigate(['/addincident']);
  }

  filterTableValue(){
    var thisvalue=$("#searchText").val();
    if(thisvalue.toString().length>0||thisvalue.toString()==''){
      this.table = $('#incidenttable').DataTable({retrieve: true});
      this.table.search(thisvalue.toString()).draw();
    }
  }

  changeShowSubdepartment() {
    this.doNotShowSubDepartment = !this.doNotShowSubDepartment;
    this.authService.setShowOrHideSubdepartment(this.doNotShowSubDepartment);
    this.routing.routeReuseStrategy.shouldReuseRoute = () => false;
    this.routing.onSameUrlNavigation = 'reload';
    this.routing.navigate(['/incident']);
  }

  displayTreeItem(id:any) {
    if (this.orgmodel) {
      if (this.oldid != id) {
        this.oldid = id;
        var count = Object.keys(this.orgmodel).length;
        this.itemArray = new Array();
        for (let nthItem = 0; nthItem < count; nthItem++) {
          if (
            this.orgmodel[nthItem].Parent == id ||
            (this.itemArray &&
              this.itemArray.indexOf(+this.orgmodel[nthItem].Parent) > -1)
          ) {
            this.itemArray.push(this.orgmodel[nthItem].Id);
          }
        }
      } else {
        this.oldid = null;
        this.itemArray = [];
      }
      return this.itemArray;
    }
  }

  viewItem(id: any) {
    this.routing.navigate(['/incidentitem', id]);
  }

  sort(column:any, lower:any) {
    // The logic is in this pipe see under shared/pipes.
    let sortPipe = new SortPipe();
    if (this.sortOrderAscOrDesc == true) {
      this.incidentValues_table2 = sortPipe.transform(
        this.incidentValues_table2,
        true,
        column,
        lower
      );
      this.sortOrderAscOrDesc = false;
    } else if (this.sortOrderAscOrDesc == false) {
      this.incidentValues_table2 = sortPipe.transform(
        this.incidentValues_table2,
        false,
        column,
        lower
      );
      this.sortOrderAscOrDesc = true;
    }
  }

  wordbreak(text:any) {
    var text = text.split(" ");
    var words = "";
    var length = 0;
    for (var n = 0; n < 10; n++) {
      if (text[n]) {
        length = Number(length + text[n].length);
      }
      if (length > 20) {
        break;
      } else if (!text[n]) {
        break;
      } else {
        if (text[n]) {
          words += text[n] + " ";
        }
      }
    }
    return words;
  }
}
