import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { Managementsystemmodel } from 'src/app/shared/model_view/managementsystemmodel';
import { Subscription } from 'rxjs';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import Swal from 'sweetalert2';
import { take } from "rxjs/operators";

@Component({
  selector: 'app-modal-add-file',
  templateUrl: './modal-add-file.component.html',
  styleUrls: ['./modal-add-file.component.css']
})
export class ModalAddFileComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() dep:any;
  @Input() department_id:any;
  @Input() organisation_id:any;

  filedescription:string;
  managementsystemFile:any;
  managementsystemFileName:string;
  filetoupload:boolean=false;
  fileUploadMessage:string;
  managementSystemModel:any = [];
  placement:number=0;
  private managementSystemModelSub: Subscription;

  constructor(public activeModal: NgbActiveModal,
    private authService: AuthService, 
    private msgService: MessageService,
    private router: Router,
    private dataService: DataService,
    private uploadService: FileUploadService) { }

  ngOnInit(): void {
    this.dataService.getFilesManagement(this.dep, this.department_id);
    this.managementSystemModelSub = this.dataService.getManagementsystemListener()
    .subscribe((managementsystemmodel:Managementsystemmodel[]) => {
      if (managementsystemmodel != null)
      {
        this.managementSystemModel  = managementsystemmodel;
      }
    });
  }

  fileUpload(event:any){
    let extension = event.target.files[0].name.substr(event.target.files[0].name.lastIndexOf('.')+1);
    let name = uuidv4() + '.' + extension;
    this.managementsystemFile=event.target.files[0];
    this.managementsystemFileName=name;
    this.filetoupload=true;
    this.fileUploadMessage='Fil vald, tryck spara';
  }
  upload(){
    if(this.filetoupload==false){
      this.fileUploadMessage='Finns ingen fil att ladda upp';
      return;
    }

    if(!this.filedescription||this.filedescription==""){
      this.filedescription="";
    }

    if ((this.managementSystemModel != null) && (this.managementSystemModel != undefined))
    {
      for(var n=0;n<this.managementSystemModel.length;n++){
        if(this.managementSystemModel[n].placement>this.placement){
          this.placement=this.managementSystemModel[n].placement;
        }
      }
    }

    this.placement=this.placement+1;
    this.uploadService.upload(this.managementsystemFile,this.managementsystemFileName);
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {
      if (filename == null)
      {
        Swal.fire("Error", "Error");
      }
      else
      {
        this.managementsystemFileName = filename;
        this.dataService.addFileToManagementTable(
          this.managementsystemFileName,
          this.filedescription,
          this.placement,
          this.dep,
          this.organisation_id,
          this.department_id);

        this.managementSystemModel.push({
          id:0,
          filename:this.managementsystemFileName,
          filedescription:this.filedescription,
          placement:this.placement
        });
        this.fileUploadMessage='Fil uppladdad';
        this.managementsystemFile=null;
        this.managementsystemFileName="";
        this.filetoupload=false;
      }
      this.activeModal.close(ModalAddFileComponent);
    });
  }
}
