<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <div class="header-text">
                            <h1>{{'user'|translate}}</h1>
                        </div>
                    </div>
                    <div class="computer only three wide right aligned column" style="padding: 20px;cursor:pointer" (click)="goBack()">
                        <button class="ui white button">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <div class="ui top attached tabular menu" (active)="0">
                    <a class="item" [class.active] = "activeTab==0" index="0" data-tab="zero" (click)="setActiveTab(0)" >{{'COMPANY_INFO' | translate}}</a>
                    <a class="item" [class.active] = "activeTab==1" index="1" data-tab="one" (click)="setActiveTab(1)" >{{'SUBSCRIPTIONS' | translate}}</a>
                    <a class="item" [class.active] = "activeTab==2" index="2" data-tab="two" (click)="setActiveTab(2)">{{'USERS' | translate}}</a>
                </div>
                <div class="sixteen wide column">
                    <div class="ui bottom attached tab segment" [class.active] = "activeTab==0" data-tab="zero">
                        <form class="ui form" #postForm="ngForm">
                            <div class="field">
                                <label>{{'name'| translate }}</label>
                                <input name="Name" [(ngModel)]="orgInfo.Name" placeholder="{{orgInfo.Name||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'organisationnumber'| translate }}</label>
                                <input name="Org_nr" [(ngModel)]="orgInfo.Org_nr" placeholder="{{orgInfo.Org_nr||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'adress1'| translate }}</label>
                                <input name="Address_1" [(ngModel)]="orgInfo.Address_1" placeholder="{{orgInfo.Address_1||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'adress2'| translate }}</label>
                                <input name="Address_2" [(ngModel)]="orgInfo.Address_2" placeholder="{{orgInfo.Address_2||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'visitadress'| translate }}</label>
                                <input name="visitAddress" [(ngModel)]="orgInfo.visitAddress" placeholder="{{orgInfo.visitAddress||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'postalcode'| translate }}</label>
                                <input name="Zip" [(ngModel)]="orgInfo.Zip" placeholder="{{orgInfo.Zip||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'city'| translate }}</label>
                                <input name="City" [(ngModel)]="orgInfo.City" placeholder="{{orgInfo.City||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'country'| translate }}</label>
                                <input name="Country" [(ngModel)]="orgInfo.Country" placeholder="{{orgInfo.Country||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'homepage'| translate }}</label>
                                <input name="Www" [(ngModel)]="orgInfo.Www" placeholder="{{orgInfo.Www||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'email'| translate }}</label>
                                <input name="Email" [(ngModel)]="orgInfo.Email" placeholder="{{orgInfo.Email||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'phone'| translate }}</label>
                                <input name="Phone" [(ngModel)]="orgInfo.Phone" placeholder="{{orgInfo.Phone||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'contactperson'| translate }}</label>
                                <input name="Contact_person" [(ngModel)]="orgInfo.Contact_person" placeholder="{{orgInfo.Contact_person||''}}"/>
                            </div>

                            <button style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                        </form>
                    </div>
                    <div class="ui bottom attached tab segment" [class.active] = "activeTab==1" data-tab="one">
                        <div>
                            <label class="col-8" style="font-weight:bold">{{'selectall'|translate}}</label>
                            <input class="col-1" type="checkbox" [checked]="checkedAll==true" (click)="checkAll()" /><br />
                            <div *ngFor="let x of itemsSubscribted;let j=index">
                              <div *ngIf="checkBoxItems2.includes(x.split(':')[0])">
                                <label class="col-8">{{thisname(x.split(':')[0])}}</label>
                                <input class="col-1" type="checkbox" (change)="changed($event)"
                                  [checked]="x.split(':')[1]==1&&!selectAll2||selectAll2" [name]="x.split(':')[0]" />
                              </div>
                              <div *ngIf="j>9&&j<20">
                                <label class="col-8">{{x.split(':')[0].substring(x.split(':')[0].indexOf('_')+1)}}</label>
                                <input class="col-1" type="checkbox" (change)="changed($event)"
                                  [checked]="x.split(':')[1]==1&&!selectAll2||selectAll2" [name]="x.split(':')[0]" />
                              </div>
                              <div *ngIf="j>19&&j<30">
                                <label class="col-8">{{x.split(':')[0].substring(x.split(':')[0].indexOf('_')+1)}}</label>
                                <input class="col-1" type="checkbox" (change)="changed($event)"
                                  [checked]="x.split(':')[1]==1&&!selectAll2||selectAll2" [name]="x.split(':')[0]" />
                              </div>
                            </div>
                        </div>
                        <button style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                    </div>
                    <div class="ui bottom attached tab segment" [class.active] = "activeTab==2" data-tab="two">
                        <div>
                            <button class="ui right floated positive button" *ngIf="is_admin||is_super_user" style="margin-top:0px;margin-bottom:20px" (click)="addUser()"><i class="ui icon add"></i>{{'ADD_USER' | translate}}</button>
                            <div class="input-group" style="margin-bottom:20px">
                                <div class="input-group-prepend" >
                                    <span class="input-group-text" id="basic-text1"><i class="fa fa-search" aria-hidden="true"></i></span>
                                </div>
                                <input class="form-control my-0 py-1" type="text" name="searchText" placeholder="{{'searchall'|translate}}" aria-label="Sök alla..." [(ngModel)]="searchText">
                            </div>
                            <table class="ui selectable table">
                                <thead>
                                    <tr>
                                        <th>{{'name'|translate}}</th>
                                        <th>{{'email'|translate}}</th>
                                        <th>{{'eligibility'|translate}}</th>
                                        <th>{{'CREATED_AT'|translate}}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of users | filter : 'name' : searchText;let i=index" style="cursor:pointer">
                                        <td (click)="editUser(user.id)" >{{user.username}}</td>
                                        <td (click)="editUser(user.id)" >{{user.email}}</td>
                                        <td (click)="editUser(user.id)" class="thirdcolumn" *ngIf="user.is_super_user=='1'">{{'superuser'|translate}}</td>
                                        <td (click)="editUser(user.id)" class="fourthcolumn" *ngIf="user.is_admin=='1'">{{'admin'|translate}}</td>
                                        <td (click)="editUser(user.id)" class="fifthcolumn" *ngIf="user.is_admin!='1'&&user.is_super_user!='1'">{{'user'|translate}}</td>
                                        <td (click)="editUser(user.id)" >{{user.created | date:"yyyy-MM-dd:HH:MM"}}</td>
                                        <td><i class="ui icon red trash" (click)="removeUser(user.id)"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
