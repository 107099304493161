import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

const BackendUrl = environment.BackendUrl;

@Injectable({
  providedIn: 'root'
})

export class FileUploadService {
  
  getFileDataUpdated = new Subject<any>();
  readFileDataUpdated = new Subject<any>();

  constructor(private http: HttpClient,
              private sanitizer: DomSanitizer) { }

  // For the subscription model array, allowed to do stuff
  public addToFilesTable(id:any,name:string) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post(BackendUrl + "/addToFilesTable/", {id,name}, {headers: headers}).subscribe((values) => {
      });
    }
  }

  upload(file: File, filename:any) {

    const formData: FormData = new FormData();

    formData.append('file', file);

    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.post<{filename: any}>(BackendUrl + "/file/uploadfile", formData, {headers: headers} )
          .pipe(take(1)).subscribe({next: response => {
            if (!response.filename)
            {
              this.getFileDataUpdated.next(null);
            }
            else
            {
              this.addToFilesTable(response.filename, file.name);
              this.getFileDataUpdated.next(response.filename);
            }
          },
          error: error => {
            this.getFileDataUpdated.next(null);
          }
      });
    }
  }

  getFileAsTrustedResourceUrl(file:any) {

    let extension = file.ContentType;

    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/readfile/' + file.File, {headers: headers, responseType: "blob"})
        .subscribe((response) => {

          let contentType = "application/pdf";
          if (extension == 'image')
          {
            contentType = "image/png";
          }
          else if (extension == 'wordprocessor')
          {
            contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          }
          else if (extension == 'presentation')
          {
            contentType = "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          }
          let file = new Blob([response], { type: contentType });
          let fileURL = URL.createObjectURL(file);
          this.readFileDataUpdated.next(fileURL);
        });
      }
  };

  getFileIdAsTrustedResourceUrl(file:any) {

    var index = file.indexOf(".");

    let extension = file.substring(index+1, [file.length])

    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      this.http.get(BackendUrl + '/readfile/' + file, {headers: headers, responseType: "blob"}).pipe(take(1)).subscribe({next: response => {

        let contentType = "application/pdf";
        switch(extension){
            case 'pdf': {
              contentType = "application/pdf";
              break;
            }
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'jfif': {
              contentType = "image/png";
              break;
            }
            case 'docx': {
              contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
              break;
            }
            case 'docm':{
              contentType = "application/vnd.ms-word.document.macroEnabled.12";
              break;
            }
            case 'pptx':  {
              contentType = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
              break;
            }
            case 'xlsx': {
              contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              break;
            }
            case 'txt': {
              contentType = "plain/txt";
              break;
            }
          }
        
          let file = new Blob([response], { type: contentType });
          let fileURL = URL.createObjectURL(file);
          this.readFileDataUpdated.next(fileURL);
        },
        error: error => {
          this.readFileDataUpdated.next(null);
        }
      });
    }
  }

  public getFileDataListener() {
    return this.getFileDataUpdated.asObservable();
  } 

  public readFileDataListener() {
    return this.readFileDataUpdated.asObservable();
  } 
}
