import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'samtec';

  toggle: boolean=true;

  isAuthenticated: boolean = false;

  constructor( private authService: AuthService, translate: TranslateService ){
    translate.addLangs(['en', 'sv']);
    translate.setDefaultLang('sv');
    let usedLanguage = localStorage.getItem('usedLanguage');
    if (usedLanguage == null) {
      translate.use('sv');
      localStorage.setItem('usedLanguage', 'sv');
    } else {
      translate.use(usedLanguage);
    }
  }

  ngAfterViewInit(): void {
    $('#samtecapp .ui.sidebar').sidebar({
      context:$('#samtecapp #samtecapp2'),
      dimPage: false,
      closable: false,
      delaySetup: true
    }).sidebar('show');
  }

  ngOnInit() {
    this.isAuthenticated = this.authService.getIsAuth();
  }

  toggleNavBar() {
    if(this.toggle==false){
      $('.ui.sidebar').sidebar({
        context:$('#samtecapp #samtecapp2'),
        dimPage: false,
        closable: false,
        delaySetup: true
      }).sidebar('show');
    }else{
      $('.ui.sidebar').sidebar({
        context:$('#samtecapp #samtecapp2'),
        dimPage: false,
        closable: false,
        delaySetup: true
      }).sidebar('hide');
    }
    this.toggle = !this.toggle;
  }
}
