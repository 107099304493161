<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <div class="header-text">
                            <h1>{{'user'|translate}}</h1>
                        </div>
                    </div>
                    <div class="computer only three wide right aligned column" style="padding: 40px;cursor:pointer" (click)="goBack()">
                        <button class="ui white button">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <form class="ui form" #postForm="ngForm" name="userForm" *ngIf="usermodel">
                    <div class="field">
                        <label>{{'username'| translate }}</label>
                        <input [(ngModel)]="usermodel.username" name="username" placeholder="{{usermodel.username||''}}" />
                    </div>
                    <div class="field">
                        <label>{{'email'| translate }}</label>
                        <input [(ngModel)]="usermodel.email" name="email" placeholder="{{usermodel.email||''}}" />
                    </div>
                    <div class="field">
                        <label>{{'FIRSTNAME'| translate }}</label>
                        <input [(ngModel)]="usermodel.first_name" name="first_name" placeholder="{{usermodel.first_name||''}}" />
                    </div>
                    <div class="field">
                        <label>{{'LASTNAME'| translate }}</label>
                        <input [(ngModel)]="usermodel.last_name" name="last_name" placeholder="{{usermodel.last_name||''}}" />
                    </div>
                    <div class="field">
                        <label>{{'phone'| translate }}</label>
                        <input [(ngModel)]="usermodel.phone" name="phone" placeholder="{{usermodel.phone||''}}"/>
                    </div>
                    <div class="field">
                        <label for="userlevel">{{'userlevel'|translate}}:</label>
                        <select [(ngModel)]="userlevel" name="userlevel">
                          <option *ngIf="is_super_user" value="1" [selected]="userlevel=='1'">{{'superuser'|translate}}</option>
                          <option value="2" [selected]="userlevel=='2'">{{'admin'|translate}}</option>
                          <option value="0" [selected]="userlevel!='2'&&userlevel!='1'">{{'user'|translate}}</option>
                        </select>
                    </div>
                    <div class="field">
                        <label for="department">{{'department'|translate}}:</label>
                        <select [(ngModel)]="depname" name="depname">
                          <option disabled selected hidden>{{usermodel.department}}</option>   
                          <option *ngFor="let orgmodelitem of orgmodel" (selected)="depname" value="{{orgmodelitem.Name||''}}">
                            {{orgmodelitem.Name}}</option>
                        </select>
                    </div>
                    <div class="field">
                        <label>{{'password'| translate }}</label>
                        <input type="password" name="password" [(ngModel)]="usermodel.password" placeholder="{{usermodel.password||''}}" />
                    </div>
                    <div class="field">
                        <label>{{'statictoken'| translate }}</label>
                        <input [(ngModel)]="static_token_text" name="static_token" placeholder="{{static_token_text||''}}" />
                        <button class="ui primary button" (click)="generateToken()" style="margin-top:10px">{{'GENERATE_TOKEN'|translate}}</button>
                    </div>

                    <button style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                </form>
            </div>
        </div>
    </div>
</div>
