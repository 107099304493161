import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, pipe } from 'rxjs';
import { DataService } from 'src/app/shared/services/data.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { Usermodel } from 'src/app/shared/model_view/usermodel';
import { take } from 'rxjs/operators';
import { Orgmodel } from 'src/app/shared/model_view/orgmodel';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';
import { DataTableSetup } from 'src/app/shared/services/dataTableSetup';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.css']
})
export class OrganisationsComponent implements OnInit {

  dtOptions: any = {};
  mainOrg: any;
  searchText: any;
  table:any;
  sortOrderAscOrDesc = true;
  msgListGet = false;
  orgInfo: any;
  itemsSubscribted: any = [];
  userInfo: any;
  users: any = [];
  is_admin: any;
  is_super_user: any;
  companyModel: any;

  constructor(
    private dataService: DataService,
    private msgService: MessageService,
    private authService: AuthService,
    private router: Router,
    private dataTableSetup: DataTableSetup,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    this.dataService.getMainOrg();
    this.dataService.getmainorgListener().subscribe((mainOrg) => {
      if (mainOrg == null)
      {
        this.authService.logout();
      }
      this.mainOrg = mainOrg;
    });

    this.msgService.sendMessage(null);
  }

  choseOrganisation(id:any){
    this.router.navigate(['controlpanel/organisations/' + id]);
  }

  removeOrganisation(id:any){
    var tmpString = this.translate.instant('DO_YOU_WANT_TO_REMOVE')
    var deleteQuestion = confirm(tmpString);
    if (deleteQuestion == true) {
      this.dataService.deleteOrganisation(id);
    } 
  }

  addItem() {
    this.router.navigate(['controlpanel/addorganisation']);
  }
}
