<div class="ui container footer">
  <div class="ui center aligned grid">
    <div class="column" style="color:white" (click)="showmodalbox()">
      &copy; Samtec AB / Phone: 035-10 81 89 / E-mail: support@samtec.se<br>
    </div>
  </div>
</div>

<!-- Footer -->
<div class="modal" id="showMailModalbox" [style.display]="showFooterModalbox ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Skicka meddelande till support</h4>
        <button type="button" class="close" data-dismiss="modal"  (click)="footerModalClose()">&times;</button>
      </div>
<div class="modal-body">
  <div class="col-sm-12">
    <label for="mailFrom" class="col-sm-12 col-form-label">Från:</label>
    <input type="text"
            required
          class="form-control"
          id="mailRondFooter"
          name="mailRondFooter"
          [value]="mailRondFooter"
          [(ngModel)]="mailRondFooter">
  </div>
  <div class="form-group row">
    <label for="mailFooterSubject" class="col-sm-12 col-form-label">{{'subject'|translate}}:</label>
    <div class="col-sm-12">
      <input type="text"
        required
        class="form-control"
        id="mailSubjectFooter"
        name="mailSubjectFooter"
        [value]="mailSubjectFooter"
        [(ngModel)]="mailSubjectFooter"
        placeholder="{{'subject'|translate}}">
    </div>
  </div>
  <div class="form-group row">
    <label for="mailMessage" class="col-sm-12 col-form-label">{{'message'|translate}}:</label>
    <div class="col-sm-12">
      <textarea
        class="form-control"
        id="mailMessageFooter"
        name="mailMessageFooter"
        [value]="mailMessageFooter"
        [(ngModel)]="mailMessageFooter"
        placeholder="{{'message'|translate}}">
      </textarea>
    </div>
  </div>
  </div>
  <!-- Modal footer -->
  <div class="modal-footer">
    <div class="col-sm-12"
          align="center"><i data-dismiss="modal"
                            class="fa fa-close fa-2x" (click)="footerModalClose()"></i>
      <i  data-dismiss="modal" (click)="footerModalSend()" class="fa fa-floppy-o fa-2x"></i></div>
  </div>
</div>
</div>
</div>
