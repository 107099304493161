import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFService } from "src/app/shared/services/pdf.service";
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { take, concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-send-email-incident',
  templateUrl: './modal-send-email-incident.component.html',
  styleUrls: ['./modal-send-email-incident.component.css']
})
export class ModalSendEmailIncidentComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() incidentitem:any;
  @Input() incidentValues:any;

  mailReceiver: any;
  mailSubject: any;
  mailMessage: any;
  mailCheckbox: boolean;
  emailisValid: any = true;

  constructor(public activeModal: NgbActiveModal,
    private authService: AuthService, 
    private msgService: MessageService,
    private router: Router,
    private dataService: DataService,
    private pdfService: PDFService,
    private uploadService: FileUploadService) { }

  ngOnInit(): void {
    this.mailReceiver = "";
    this.mailSubject = "";
    this.mailMessage = "";
  }

  mailModalSend() {
    var path = "main";
    var mailReceiver = this.mailReceiver;
    if (!mailReceiver) {
      this.emailisValid = false;
      return;
    } else if (!mailReceiver.match("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")) {
      return;
    }

    var mailMessage = this.mailMessage;
    var mailSubject = this.mailSubject+"- Incidenter";
    var mailRond = "";
    var attachmentfile="";

      // I remove the file a while before I upload another
      // as it is asynchronous events
      let file:any = this.pdfService.createPDF('incident',this.incidentitem, this.incidentitem.Id, this.incidentValues, true);
      var attachmentfile="incident";

      this.uploadService.upload(file, 'tempfileRN5251.pdf');
      this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {

        if (filename == null)
        {
          attachmentfile="";
        }

        mailMessage+="<hr/><b><u>Incident:</u><br/><br/>"
        +"<b>Typ av incident:</b> "
        +this.incidentitem.typeofaction+"<br/>"
        +"<b>Typ skada:</b> "
        +this.incidentitem.category+"<br/>"
        +"<b>Ansvarig:</b> "
        +this.incidentitem.Reporter+"<br/>"
        +"<b>Datum:</b> "
        +this.incidentitem.Date+"<br/>"
        +"<b>Avdelning:</b> "
        +this.incidentitem.Department+"<br/>"
        +"<b>Plats:</b> "
        +this.incidentitem.Place+"<br/>"
        +"<b>Rubrik:</b> "
        +this.incidentitem.Title+"<br/>"
        +"<b>Beskrivning:</b> "
        +this.incidentitem.Description+"<br/>"
        +"<b>Orsak:</b> "
        +this.incidentitem.Cause+"<br/><br/>";
        
        mailMessage+="<u>Rondpunkter</u><ul>åtgärd - ansvarig - datum";
        for(var n=0;n<this.incidentValues.length;n++){
          mailMessage += "<li>"
            +this.incidentValues[n].roundMeasure
            +' - '
            +this.incidentValues[n].roundResponsible2
            +' - '
            +this.incidentValues[n].roundDoneDate
            +'</li>';
        }
        mailMessage+="</ul>";

      var typeOfAction="";
      if (mailSubject == "") {
        mailSubject = "Ämne";
      }
      
      if (mailMessage == "") {
        mailMessage = "Meddelande";
      }
      // A very simple way to get a delay
      // (to give the pdf time to upload)
      for(var n=0;n<10000;n++){
        n++;
      }
      this.dataService.sendmail(
        mailReceiver,
        mailMessage,
        mailSubject,
        mailRond,
        attachmentfile,
        path
      );

      this.activeModal.close(ModalSendEmailIncidentComponent);
    });
  }
}
