<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <div class="header-text">
                            <h1>{{'company'|translate}}</h1>
                        </div>
                    </div>
                    <div class="computer only three wide right aligned column" style="padding: 40px;cursor:pointer" (click)="goBack()">
                        <button class="ui white button">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <form class="ui form" #postForm="ngForm">
                    <div class="field">
                        <label>{{'name'| translate }}</label>
                        <input name="Name" [(ngModel)]="orgInfo.Name" required/>
                    </div>
                    <div class="field">
                        <label>{{'organisationnumber'| translate }}</label>
                        <input name="Org_nr" [(ngModel)]="orgInfo.Org_nr" placeholder="{{orgInfo.Org_nr||''}}"/>
                    </div>
                    <div class="field">
                        <label>{{'adress1'| translate }}</label>
                        <input name="Address_1" [(ngModel)]="orgInfo.Address_1" placeholder="{{orgInfo.Address_1||''}}"/>
                    </div>
                    <div class="field">
                        <label>{{'adress2'| translate }}</label>
                        <input name="Address_2" [(ngModel)]="orgInfo.Address_2" placeholder="{{orgInfo.Address_2||''}}"/>
                    </div>
                    <div class="field">
                        <label>{{'visitadress'| translate }}</label>
                        <input name="visitAddress" [(ngModel)]="orgInfo.visitAddress" placeholder="{{orgInfo.visitAddress||''}}"/>
                    </div>
                    <div class="field">
                        <label>{{'postalcode'| translate }}</label>
                        <input name="Zip" [(ngModel)]="orgInfo.Zip" placeholder="{{orgInfo.Zip||''}}"/>
                    </div>
                    <div class="field">
                        <label>{{'city'| translate }}</label>
                        <input name="City" [(ngModel)]="orgInfo.City" placeholder="{{orgInfo.City||''}}"/>
                    </div>
                    <div class="field">
                        <label>{{'country'| translate }}</label>
                        <input name="Country" [(ngModel)]="orgInfo.Country" placeholder="{{orgInfo.Country||''}}"/>
                    </div>
                    <div class="field">
                        <label>{{'homepage'| translate }}</label>
                        <input name="Www" [(ngModel)]="orgInfo.Www" placeholder="{{orgInfo.Www||''}}"/>
                    </div>
                    <div class="field">
                        <label>{{'email'| translate }}</label>
                        <input name="Email" [(ngModel)]="epost" />
                    </div>
                    <div class="field">
                        <label>{{'phone'| translate }}</label>
                        <input name="Phone" [(ngModel)]="orgInfo.Phone" placeholder="{{orgInfo.Phone||''}}"/>
                    </div>
                    <div class="field">
                        <label>{{'contactperson'| translate }}</label>
                        <input name="Contact_person" [(ngModel)]="orgInfo.Contact_person" placeholder="{{orgInfo.Contact_person||''}}"/>
                    </div>

                    <button style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="addItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                </form>
            </div>
        </div>
    </div>
</div>
