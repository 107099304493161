<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <h1>{{'addnews'|translate}}</h1>
                    </div>
                    <div class="computer only three wide right aligned column" style="padding: 20px;cursor:pointer" (click)="goBack()">
                        <button class="ui white button">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                
                <div class="ui grid row">
                    <div class="sixteen wide column">
                        <form class="ui form" #postForm="ngForm">
                            <div class="field">
                                <label>{{'title'| translate }}</label>
                                <input name="title" [(ngModel)]="information.Title" placeholder="{{information.Title||''}}"/>
                            </div>
                            <div class="field">
                                <label>{{'text'| translate }}</label>
                                <textarea name="text" [(ngModel)]="information.Text" placeholder="{{information.Title||''}}"></textarea>
                            </div>
                            <div class="field">
                                <div class="ui checkbox">
                                    <input type="checkbox" (change)="checkbox()">
                                    <label>{{'show'|translate}}</label>
                                </div>
                            </div>
                            
                            <button style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="informationEdit()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
