import { Injectable } from '@angular/core';
import * as _ from "lodash";

@Injectable({
    providedIn: 'root'
})

export class SortService {
  constructor() { }

  // Sorts the departments, first by level than by name alphabetically
  sortOrgModel(orgmodelDep:any) {
    orgmodelDep=_.orderBy(orgmodelDep, ['Level', 'Name'], ['asc', 'asc']);
    let n = 1;
    // Sort the items on level 1 alphabetically first
    _.forEach(orgmodelDep, function(value, key) {
      if(value.Level==1){
        value.Place=n;
        n=n+1;
      }
    });

    // Now level 2
    let found = false;
    for(var orgmodelLevel=2;orgmodelLevel<10;orgmodelLevel++){
      _.forEach(orgmodelDep, function(value, index, key) {
        found = false;
        if(value.Level==orgmodelLevel) {
          _.forEach(orgmodelDep, function(value2, index2, key2) {
            if(found==false&&value2.Level==(orgmodelLevel-1)&&value2.Id==value.Parent){
              found = true;
              let j=1;
              while (orgmodelDep[index2+j]!=undefined&&orgmodelDep[index2+j].Parent==value.Parent){
                if(orgmodelDep[index2+j].Level==orgmodelLevel){
                  j++;
                }
              }
              if((index2+j)<orgmodelDep.length){
                orgmodelDep.splice(index2+j, 0, value);
                orgmodelDep.splice(index+1, 1);
              }
            }
          })
        }
      });
    }
    return orgmodelDep;
  }

  // A recursive functions to get all children of a node
  getItemParents(point:any, level:any, object:any){
    let pointArray = [];
    pointArray.push(point);

    function pointIterator(point:any){
      _.forEach(object, function(value, index, key) {
        if(value.Parent==point&&+value.Level>=+level&&value.Parent!=value.Id){
          pointArray.push(value.Id);
          pointIterator(value.Id);
        }
      });
    }
    pointIterator(point);
    return pointArray;
  }
}
