import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-edit-department',
  templateUrl: './modal-edit-department.component.html',
  styleUrls: ['./modal-edit-department.component.css']
})
export class ModalEditDepartmentComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() AvdName:any;

  constructor(public activeModal: NgbActiveModal,
    private authService: AuthService, 
    private msgService: MessageService,
    private router: Router,
    private dataService: DataService) { }

  ngOnInit(): void {
  }

  saveDepartment() {
    this.passEntry.emit(this.AvdName);
    this.activeModal.close(ModalEditDepartmentComponent);
  }
}
