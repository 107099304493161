import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { Orgmodel } from '../../shared/model_view/orgmodel';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { SafetyRoundModel, riskValueModel } from "src/app/shared/model_view/safetymodel";
import { take } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-safetyround-edit',
  templateUrl: './safetyround-edit.component.html',
  styleUrls: ['./safetyround-edit.component.css']
})
export class SafetyroundEditComponent implements OnInit {

  safetyRoundResponsible: string;
  userInfo: any;
  safetyRoundDate: string;
  localOrgId: any;
  userId: any;
  orgId: string;
  depname: any;
  orgmodel: any;
  error: any;
  doNotShowSubDepartment: boolean;
  itemArray: any;
  oldid: any;
  usermodel: any;
  filesExisting: any;
  safetyRoundParticipants: string;
  safetyRoundHeader: string;
  safetyRoundDescription: string;
  selectedFile: any = [];
  files: any = [];
  item:any = [];
  is_admin: any;
  is_super_user: any;
  filenames: any = [];
  file: any;
  safetyRoundValues: SafetyRoundModel[] = new Array();
  randomNumber: any;
  selectSafetyroundCategory: any;
  roundValues: any = new Array();
  safetyitem: any;
  safetyid: any;
  subscriptions: any;
  filetypeinfo: any;
  fileNames: any = [];
  uploadFileDone: boolean = false;

  constructor(private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $("#safetyRoundResponsibleChose").dropdown();
    $("#depnameEditChose").dropdown();
  }

  ngOnInit(): void {

    this.route.params.pipe(take(1)).subscribe(params => {
      this.safetyid = params['id'];
    });

    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.orgId = this.authService.getOrgid();
    this.safetyRoundResponsible = "";
    this.safetyRoundParticipants = "";
    this.safetyRoundHeader = "";
    this.safetyRoundDescription = "";

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    if (this.localOrgId) {
      this.dataService.getUserInfo(this.localOrgId);
      this.dataService
        .getUserModelListener()
        .subscribe((usermodel: any) => {
          if (usermodel[0].name) {
            this.usermodel = usermodel[0].name;
            this.msgService.sendMessage(this.usermodel);
          }
      });
    }

    this.dataService.getUsers(this.authService.getOrgid(), this.userId);
    this.dataService.usersListener().subscribe((users) => {
      this.userInfo = users;
    });

    var file = "";
    this.dataService.getSafetyItembyId(this.safetyid);
    this.dataService
      .safetyItemModelUpdatedEditListener()
      .pipe(take(1))
      .subscribe((safety_item: any) => {
        this.safetyitem = safety_item.safetyitemvalue[0];
        for (var f = 0;f < this.safetyitem.File.split(",").length;f++) {
          if (this.safetyitem.File != "") {
            file += "|" + this.safetyitem.File.split(",")[f];
          }
        }
        this.getImage(file,'');

        this.safetyRoundResponsible=this.safetyitem.Supervisor;
        this.safetyRoundDate = this.safetyitem.doneDate;
        this.depname = this.safetyitem.Department;
        this.safetyRoundParticipants = this.safetyitem.Participants;
        this.safetyRoundHeader = this.safetyitem.Title;
        this.safetyRoundDescription = this.safetyitem.Text;
    });

    this.dataService.getSafetyRoundValuesbyId(this.safetyid);
    this.dataService
      .safetyRoundModelUpdatedEditListener()
      .pipe(take(1))
      .subscribe((safety_round: any) => {
        this.safetyRoundValues = safety_round.safetyroundvalue;
    });

    this.subscriptions = this.dataService.getSubscriptions(
      this.authService.getLocalOrgid()
    );

    this.dataService.subscriptionsListener().subscribe((subscriptions) => {
      this.subscriptions = subscriptions[0];
    });

    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService
      .orgModelListener()
      .pipe(take(1))
      .subscribe((orgmodel: Orgmodel) => {
        this.orgmodel = orgmodel;
        if (this.doNotShowSubDepartment == true) {
          this.itemArray = null;
        } else {
          this.itemArray = this.displayTreeItem(this.localOrgId);
        }
    });
  }

  displayTreeItem(id:any) {
    if (this.orgmodel) {
      if (this.oldid != id) {
        this.oldid = id;
        var count = Object.keys(this.orgmodel).length;
        this.itemArray = new Array();
        for (let nthItem = 0; nthItem < count; nthItem++) {
          if (
            this.orgmodel[nthItem].Parent == id ||
            (this.itemArray &&
              this.itemArray.indexOf(+this.orgmodel[nthItem].Parent) > -1)
          ) {
            this.itemArray.push(this.orgmodel[nthItem].Id);
          }
        }
      } else {
        this.oldid = null;
        this.itemArray = [];
      }
      return this.itemArray;
    }
  }

  goBack() {
    this.routing.navigate(['safetyrounditem/', this.safetyid]);
  }

  updateItem() {

    if (!this.safetyRoundResponsible ||!this.safetyRoundParticipants ||!this.safetyRoundHeader) {
      if (!this.safetyRoundResponsible) {
        this.error.push("Fyll i ansvarig");
      }
      if (!this.safetyRoundParticipants) {
        this.error.push("Fyll i deltagare");
      }
      if (!this.safetyRoundHeader) {
        this.error.push("Fyll i rubrik");
      }
      return;
    }

    if(!this.filesExisting){this.filesExisting=0;}
    this.file = this.safetyitem.File;
    for (var i = 0; i < this.selectedFile.length; i++) {
      if (this.selectedFile[i]) {
        if (i != 0 || (i == 0 && this.file != "")) {
          this.file += ",";
        }
        this.file += this.files[i + this.filesExisting];
      }
    }

    this.safetyitem.Supervisor = this.safetyRoundResponsible;
    this.safetyitem.Participants = this.safetyRoundParticipants;
    this.safetyitem.Text = this.safetyRoundDescription;
    this.safetyitem.Title = this.safetyRoundHeader;
    this.safetyitem.doneDate = this.safetyRoundDate.substr(0, 10);
    this.safetyitem.File = this.file;
    this.safetyitem.Department = this.depname;
    this.safetyitem.category=this.selectSafetyroundCategory;
    this.dataService.updateSafetyRound(this.safetyitem);
  }

  deleteItem() {
    var tmpString = this.translate.instant('DO_YOU_WANT_TO_REMOVE')
    var deleteQuestion = confirm(tmpString);
    if (deleteQuestion == true) {
      this.dataService.deleteSafetyRound(this.safetyitem.Id);
    } 
  }

  // A function for the filetype
  getImage(file:any,entrance:any) {
    this.dataService
      .fileInfo(environment.catalogue, file)
      .subscribe((response) => {
        this.dataService.fileInfoUpdated.next(response);
      });
    this.dataService
      .fileInfoListener()
      .pipe(take(1))
      .subscribe((response) => {

      if (typeof response != "undefined") {
          this.filetypeinfo=response;
          for (var i = 0; i < this.filetypeinfo.length; i++)
          {
            this.fileNames.push(this.filetypeinfo[i].File);
          }
      }
    });
  }

  removeImage(file: any, index: any) {
    this.file = "";

    this.filetypeinfo.splice(index, 1);
    this.fileNames.splice(index, 1);

    for (var i = 0; i < this.fileNames.length; i++)
    {
      if (i != 0 || (i == 0 && this.file != "")) {
        this.file += ",";
      }
      this.file += this.fileNames[i];
    }

    this.safetyitem.File = this.file;
  }

  setRoundResponsible(userInfo:any) {
    this.safetyRoundResponsible = userInfo.username;
  }

  setDepartment(department: any) {
    this.depname = department.Name;
  }

  imageUpload(event:any) {
    this.uploadFileDone = true,
    this.uploadService.upload(event.target.files[0], "");
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {
      if (filename == null)
      {
        Swal.fire("Error", "Error");
      }
      else
      {
        this.files.push(filename);
        setTimeout(() => {
          this.uploadFileDone = false;

          this.selectedFile.push(event.target.files[0]);
    
          if (this.files) {
            this.filenames.push(event.target.files[0].name);
          } else {
            this.filenames = event.target.files[0].name;
          }
          this.item.push(1);
        }, 1000);
      }
    });
  }
}
