import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { Orgmodel } from '../../shared/model_view/orgmodel';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { SafetyRoundModel, riskValueModel } from "src/app/shared/model_view/safetymodel";
import { take } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-workplace-add',
  templateUrl: './workplace-add.component.html',
  styleUrls: ['./workplace-add.component.css']
})
export class WorkplaceAddComponent implements OnInit {

  workplaceRoundResponsible: string;
  userInfo: any;
  userId: any;
  workplaceRoundDate: string;
  depname: string = "";
  orgmodel: any;
  localOrgId: any;
  orgId: any;
  usermodel: any;
  workplaceRoundParticipants: string;
  workplaceRoundHeader: string;
  workplaceRoundDescription: string;
  selectedFile: any = [];
  files: any = [];
  item:any = [];
  filenames: any = [];
  file: any;
  randomNumber: any;
  workplaceRoundValues: any;
  roundValues: any = new Array();
  itemArray: any;
  doNotShowSubDepartment: any;
  oldid: any;
  uploadFileDone: boolean = false;
  is_admin: any;
  is_super_user: any;
  depnameId: any;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private translate: TranslateService,
    private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $("#workplaceRoundResponsibleChose").dropdown();
    $("#depnameChose").dropdown();
  }

  ngOnInit(): void {
    this.workplaceRoundDate = new Date().toISOString().slice(0, 10);
    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.orgId = this.authService.getOrgid();
    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    if (this.localOrgId) {
      this.dataService.getUserInfo(this.localOrgId);
      this.dataService
        .getUserModelListener()
        .subscribe((usermodel: any) => {
          if (usermodel[0].name) {
            this.usermodel = usermodel[0].name;
            this.msgService.sendMessage(this.usermodel);
          }
      });
    }

    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService
      .orgModelListener()
      .pipe(take(1))
      .subscribe((orgmodel: Orgmodel) => {
        this.orgmodel = orgmodel;
        if (this.doNotShowSubDepartment == true) {
          this.itemArray = null;
        } else {
          this.itemArray = this.displayTreeItem(this.localOrgId);
        }
    });

    this.dataService.getUsers(this.authService.getOrgid(), this.userId);
    this.dataService.usersListener().subscribe((users) => {
      this.userInfo = users;
      this.workplaceRoundResponsible = this.userInfo[0].username;
    });
  }

  add_workplace(form: NgForm){
    //form.reset();
  }

  displayTreeItem(id:any) {
    if (this.orgmodel) {
      if (this.oldid != id) {
        this.oldid = id;
        var count = Object.keys(this.orgmodel).length;
        this.itemArray = new Array();
        for (let nthItem = 0; nthItem < count; nthItem++) {
          if (
            this.orgmodel[nthItem].Parent == id ||
            (this.itemArray &&
              this.itemArray.indexOf(+this.orgmodel[nthItem].Parent) > -1)
          ) {
            this.itemArray.push(this.orgmodel[nthItem].Id);
          }

          if (this.orgmodel) {
            let count=this.orgmodel.length;
            for (let n = 0; n < count; n++) {
              if (this.orgmodel[n].Name == this.usermodel) {
                this.depname = this.orgmodel[n].Name;
                this.depnameId = this.orgmodel[n].Id;
              }
            }
          }
        }
      } else {
        this.oldid = null;
        this.itemArray = [];
      }
      return this.itemArray;
    }
  }

  removeImage(file: any, index: any) {
    this.selectedFile.splice(index, 1);
    this.filenames.splice(index, 1);
  }

  imageUpload(event:any) {
    this.uploadFileDone = true,
    this.uploadService.upload(event.target.files[0], "");
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {
      if (filename == null)
      {
        Swal.fire("Error", "Error");
      }
      else
      {
        this.files.push(filename);
        setTimeout(() => {
          this.uploadFileDone = false;

          this.selectedFile.push(event.target.files[0]);
    
          if (this.files) {
            this.filenames.push(event.target.files[0].name);
          } else {
            this.filenames = event.target.files[0].name;
          }
          this.item.push(1);
        }, 1000);
      }
    });
  }

  goBack() {
    this.routing.navigate(['/workplace']);
  }

  saveItem() {

    if (!this.workplaceRoundResponsible || !this.workplaceRoundParticipants || !this.workplaceRoundHeader) {
      if (!this.workplaceRoundResponsible) {
        Swal.fire(this.translate.instant("INFORMATION_MISSING"), this.translate.instant("INFORMATION_MISSING_RESPONSIBLE"), "error");
      }

      if (!this.workplaceRoundParticipants) {
        Swal.fire(this.translate.instant("INFORMATION_MISSING"), this.translate.instant("INFORMATION_MISSING_PARTICIPANTS"), "error");
      }

      if (!this.workplaceRoundHeader) {
        Swal.fire(this.translate.instant("INFORMATION_MISSING"), this.translate.instant("INFORMATION_MISSING_HEADING"), "error");
      }
      return;
    }

    this.file = "";
    for (var i = 0; i < this.selectedFile.length; i++) {
      if (this.selectedFile[i]) {
        if (i != 0 || (i == 0 && this.file != "")) {
          this.file += ",";
        }
        this.file += this.files[i];
      }
    }

    for (let i = 0; i < this.orgmodel.length; i++)
    {
      if (this.orgmodel[i].Name == this.depname)
      {
        this.depname = this.orgmodel[i].Name + "|" + this.orgmodel[i].Id; 
      }
    }

    Math.random;
    this.randomNumber = "R-" + Math.floor(Math.random() * 100);

    this.workplaceRoundValues = [];

    this.workplaceRoundValues.push({
      Id: this.randomNumber,
      doneDate: this.workplaceRoundDate,
      Supervisor: this.workplaceRoundResponsible,
      Participants: this.workplaceRoundParticipants,
      Department: this.depname,
      Title: this.workplaceRoundHeader,
      Text: this.workplaceRoundDescription,
      File: this.file,
      Danger: 4,
    });

    let user = +this.authService.getUserId();
    let localOrg = +this.authService.getLocalOrgid();
    let org = +this.authService.getOrgid();
    this.dataService.addWorkplace(
      this.workplaceRoundValues,
      this.roundValues,
      user,
      this.localOrgId,
      org
    );
  }
}
