import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  newInformation: any;
  showValue: any;
  is_admin: any;
  is_super_user: any;
  information: any;
  searchText: any;

  constructor(private authService: AuthService,
    private router:Router,
    private dataService: DataService,
    private msgService: MessageService,) { }

  ngOnInit(): void {
    this.newInformation = false;

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    this.dataService.getInformation('all');
    this.dataService
      .getInformationListener()
      .subscribe((information: any) => {
        this.information = information;
    });

    this.msgService.sendMessage(null);
  }

  checkbox(){
    if(!this.showValue){this.showValue=true;}
    else{
      this.showValue=!this.showValue;
    }
  }

  goBack() {
    this.router.navigate(['/controlpanel']);
  }

  showButtonNewInformation(){
    this.router.navigate(['/controlpanel/addnews']);
  }

  editNews(id: any){
    this.router.navigate(['/controlpanel/editnews/'+id]);
  }

  removeNews(id: any) {
    this.dataService.deleteNews(id);
  }
}
