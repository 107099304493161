<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div *ngIf="incidentRoundValue" class="ui card">
                <div class="computer only ui grid secondary pointing menu" style="padding:20px;width:100%">
                    <a class="item" data-target="#" (click)="changeTab(true)" [class.active]="showTab1==true">{{'riskevaluationandmeasures' | translate}}</a>
                    <a *ngIf="is_admin||is_super_user" class="item" data-target="#" (click)="changeTab(false)" [class.active]="showTab2==true">{{'riskevaluation' | translate}}</a>
                    <span class="back-edit-text" (click)="goBack()"><button class="ui white button">{{"back" | translate}}</button></span>
                </div>
                <div class="mobile only ui grid secondary pointing menu" style="padding:20px;width:100%">
                    <a class="item" data-target="#" style="font-size:12px" (click)="changeTab(true)" [class.active]="showTab1==true">{{'riskevaluationandmeasures' | translate}}</a>
                    <a *ngIf="is_admin||is_super_user" style="font-size:12px" class="item" data-target="#" (click)="changeTab(false)" [class.active]="showTab2==true">{{'riskevaluation' | translate}}</a>
                    <i class="ui icon left arrow" style="cursor:pointer;margin-top:10px" (click)="goBack()"></i>
                </div>
                <div *ngIf="showTab1==true" style="padding:10px">
                    <form class="ui form" #postForm="ngForm">
                        <div class="field">
                            <label>{{'sourcesofriskandrisks'|translate}}</label>
                            <input id="sourcesofriskandrisks" name="sourcesofriskandrisks" [(ngModel)]="incidentRoundValue.roundRisk"/>
                        </div>
                        <div class="field">
                            <label>{{'measures'|translate}}</label>
                            <textarea id="measures" name="measures" rows="3" [(ngModel)]="incidentRoundValue.roundMeasure"></textarea><br><br>
                        </div>
                        <div class="field">
                            <label>{{'responsible'|translate}}</label>
                            <select [(ngModel)]="incidentRoundValue.roundResponsible2" name="roundResponsible2" required>
                                <option disabled selected hidden>{{incidentRoundValue.roundResponsible2}}</option>   
                                <option *ngFor="let userInfoitem of userInfo;let i=index" [ngValue]="userInfoitem.username">{{userInfoitem.username}}</option>
                            </select>
                        </div>
                        <div class="field">
                            <label>{{'date'| translate }}</label>
                            <input type="date" name="roundDoneDate" [value]="incidentRoundValue.roundDoneDate" [(ngModel)]="incidentRoundValue.roundDoneDate" placeholder="incidentRoundValue.roundDoneDate"/>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" id="roundDone" name="roundDone" [value]="roundDone" [(ngModel)]="roundDone">
                                <label>{{'done'|translate}}</label>
                            </div><br>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" id="roundActive" name="roundActive" [value]="roundActive" [(ngModel)]="roundActive">
                                <label>{{'active'|translate}}</label>
                            </div><br>
                        </div>
                        <div class="field">
                            <input class="ui button" type="file" (change)="imageUpload($event)" placeholder="Upload file" accept="application/msword,
                                application/vnd.ms-excel,
                                application/vnd.ms-powerpoint,
                                text/plain,
                                application/pdf,
                                image/*,
                                video/*"/> 
                            
                            <div [hidden]="!uploadFileDone" class="ui segment">
                                <div class="ui active loader"></div>
                                <p></p>
                            </div><br><br><br>
                            
                            <div class="ui grid item" *ngFor="let file of fileNames; let i=index" style="width:100%;margin-left:20px;padding:0px">
                                <div class="row" *ngIf="filetypeinfo&&filetypeinfo[i]" style="margin:0px;padding:0px">
                                    <div class="eight wide column" style="margin:0px;padding:0px">
                                        <i class="ui icon file alternate outline"></i>{{filetypeinfo[i].thisFileName}}
                                    </div>
                                    <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                        <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="ui grid item" *ngFor="let file of selectedFile; let i=index" style="width:100%;margin-left:20px;padding:0px">
                                <div class="row" style="margin:0px;padding:0px">
                                    <div class="eight wide column" style="margin:0px;padding:0px">
                                        <i class="ui icon file alternate outline"></i>{{file.name}}
                                    </div>
                                    <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                        <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                    </div>
                                </div>
                            </div><br>
                        </div>
                        <div class="field">
                            <button [hidden]="uploadFileDone" style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                        </div>
                    </form>
                </div>
                <div *ngIf="showTab2==true" style="padding:10px">
                    <div class="ui grid row" style="margin-right:4%">
                        <div class="fifteen wide column"></div>
                        <div class="one wide column">
                            <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1">
                                <div class="{{riskvaluesModal(incidentRoundValue.incidentSeverity, incidentRoundValue.incidentPossibility, incidentRoundValue.incidentFrequency,'panel')}}">
                                    {{riskvaluesModalNumber(incidentRoundValue.incidentSeverity, incidentRoundValue.incidentPossibility, incidentRoundValue.incidentFrequency)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <label for="incidentSeverity" style="margin-left: 10px" class="col-sm-12 col-form-label">{{'Severity'|translate}}<br/>{{severityValues(incidentRoundValue.incidentSeverity)}}</label>
                    <input type="range" style="margin-left: 20px; width: 95%"
                            class="form-control"
                            id="incidentSeverity"
                            min="1"
                            max="10"
                            step="1"
                            list="ticks"
                            name="incidentSeverity"
                            [value]="incidentRoundValue.incidentSeverity"
                            [(ngModel)]="incidentRoundValue.incidentSeverity" />
                    <br>
                    <div class="col-sm-12" style="margin-left: 10px; width: 95%">
                        <textarea id="incidentSeverityComment"
                                name="incidentSeverityComment"
                                style="width: 100%;"
                                [value]="incidentRoundValue.incidentSeverityComment"
                                [(ngModel)]="incidentRoundValue.incidentSeverityComment">
                        </textarea>
                    </div>
                    <hr/>
                    <label for="incidentFrequency" class="col-sm-12 col-form-label" style="margin-left: 10px;">{{'Frequency'|translate}}<br/>{{frequencyValues(incidentRoundValue.incidentFrequency)}}</label>
                    <input type="range" style="margin-left: 20px; width: 95%" class="form-control" id="riskFrequency" name="riskFrequency" min="1" max="10" step="1"
                        list="ticks" [value]="incidentRoundValue.incidentFrequency" [(ngModel)]="incidentRoundValue.incidentFrequency" />
                    <br>
                    <div class="col-sm-12" style="margin-left: 10px; width: 95%">
                        <textarea id="incidentFrequencyComment"
                                name="incidentFrequencyComment"
                                style="width: 100%;"
                                [value]="incidentRoundValue.incidentFrequencyComment"
                                [(ngModel)]="incidentRoundValue.incidentFrequencyComment"></textarea>
                    </div>
                    <hr />
                    <label for="incidentPossibility" style="margin-left: 10px;" class="col-sm-12 col-form-label">{{'Possibility'|translate}}<br />{{possibilityValues(incidentRoundValue.incidentPossibility)}}</label>
                    <input type="range" class="form-control" style="margin-left: 20px; width: 95%" id="incidentPossibility" name="incidentPossibility" min="1" max="10"
                        step="1" list="ticks" [value]="incidentRoundValue.incidentPossibility" [(ngModel)]="incidentRoundValue.incidentPossibility" />
                    <br>
                    <div class="col-sm-12" style="margin-left: 10px; width: 95%">
                        <textarea id="incidentPossibilityComment"
                                name="incidentPossibilityComment"
                                style="width: 100%;"
                                [value]="incidentRoundValue.incidentPossibilityComment"
                                [(ngModel)]="incidentRoundValue.incidentPossibilityComment"></textarea>
                    </div>
                    <label style="margin-left: 10px;" for="incidentRiskComment" class="col-sm-12 col-form-label">{{'commenthazard'|translate}}:</label>
                    <div class="col-sm-12">
                        <textarea id="incidentRiskComment" name="incidentRiskComment" style="margin-left: 10px; width: 95%;" [value]="incidentRoundValue.incidentRiskComment"
                            [(ngModel)]="incidentRoundValue.incidentRiskComment">
                        </textarea>
                        <div style="margin-left: 10px;">            
                            <button style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
