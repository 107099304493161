import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../shared/services/data.service';
import { MessageService } from '../shared/services/message.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Usermodel } from '../shared/model_view/usermodel';
import { take } from 'rxjs/operators';
import { Orgmodel } from '../shared/model_view/orgmodel';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';
import { DataTableSetup } from 'src/app/shared/services/dataTableSetup';
declare var $: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  dtOptions: any = {};
  private userModelSub: Subscription;
  private informationSub: Subscription;
  public isCollapsed0 = false;
  public isCollapsed1 = true;
  public isCollapsed2 = true;
  usermodel: Usermodel;
  subscription:any;
  msgListGet = false;
  name: string;
  public userName: string;
  private userId: string;
  private sub: any;
  private handlingsplansub: Subscription;
  actionplan_table: any = new Array();
  searchText: any;
  information: any = null;
  lasmer=false;
  lasalla=false;
  private orgId: string;
  orgmodel: any;
  localOrgId:any;
  userInfo:any;
  users:Array<any>=new Array;
  userInfo2:any;
  users2:Array<any>=new Array;
  first_name:string;
  last_name:string;
  spinner:boolean=true;
  subscriptions:any;
  userData:any;
  getStatisticalData:any;
  thisid:any;
  oldid:any;
  itemArray:any=[];
  subscriptionMessage:any;
  sortOrderAscOrDesc = true;
  table:any;
  showAllNews=false;
  newsItemsToShow=3;

  constructor(
    private dataService: DataService,
    private msgService: MessageService,
    private authService: AuthService,
    private router: Router,
    private dataTableSetup: DataTableSetup,
  ) {
    this.userName = this.authService.getUsername();
    this.userId = this.authService.getUserId();
  }


  ngOnInit() {
    this.dtOptions =this.dataTableSetup.dataTableInit('dashboard');
    this.authService.setShowOrHideSubdepartment(false);
    this.dataService.getActionPlan("dash");
    // this.spinner=true;
    this.spinner=true;
    this.handlingsplansub = this.dataService
      .actionplanListener()
      .pipe(take(1))
      .subscribe((actionplan_table: any) => {
        this.actionplan_table = actionplan_table;
        this.msgListGet = true;
        // För att inte få med dubletter
      });

    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    if(this.localOrgId){
      this.dataService.getUserInfo(this.localOrgId);
      this.userModelSub = this.dataService
        .getUserModelListener()
        .subscribe((usermodel: Usermodel) => {
          //this.usermodel = usermodel[0].name;
          this.msgService.sendMessage(this.usermodel);
        });
    }

    this.dataService.getInformation('active');
    this.informationSub = this.dataService
      .getInformationListener()
      .subscribe((information: []) => {
        this.information = information;
        setTimeout(() => {
          $('.ui.accordion').accordion();
        }, 200);
      });

    this.orgId = this.authService.getOrgid();
    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService.orgModelListener()
    .pipe(take(1))
      .subscribe((orgmodel: Orgmodel) => {
        this.orgmodel = orgmodel;
        this.itemArray=this.displayTreeItem(+this.localOrgId);
        this.userData=this.dataService.getStatisticalData(this.itemArray);
        this.dataService.getStatisticalDataListener().pipe(take(1))
          .subscribe((getStatisticalData) => {
            this.getStatisticalData=getStatisticalData;
          });
        }
      );

    this.userInfo2=this.dataService.getUserById(+this.userId);
    this.dataService.usersListener2()
      .subscribe((users2) => {
        this.users2=users2;
        this.first_name=this.users2[0].first_name;
        this.last_name=this.users2[0].last_name;
      });

      this.subscriptions=this.dataService.getSubscriptions(this.authService.getLocalOrgid());
      this.dataService.subscriptionsListener()
      .subscribe((subscriptions) => {
        this.subscriptions=subscriptions[0];
      });

      this.subscription = this.msgService.accessMessage().subscribe(
        (msg: any) => {
          if (msg&&msg.id) {
            this.itemArray=this.displayTreeItem(msg.id);
            this.userData=this.dataService.getStatisticalData(this.itemArray);
            this.dataService.getStatisticalDataListener().pipe(take(1))
            .subscribe((getStatisticalData) => {
            this.getStatisticalData=getStatisticalData;
          });
        }
      }
    );

    this.addMessage(0);
    this.spinner=false;
  }

  visit(id:any, type:any, roundid:any) {
    if (type == "skyddsrond") {
      this.addMessage(2);
      this.router.navigate(["/safetyround"], { queryParams: { id: id,roundid: roundid}});
    }
    if (type == "incident") {
      this.addMessage(3);
      this.router.navigate(["/incident"], { queryParams: { id: id,roundid: roundid}});
    }
    if (type == "arbetsplats") {
      this.addMessage(6);
      this.router.navigate(["/workplace"], { queryParams: { id: id,roundid: roundid}});
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }


  lasmera(){
    this.lasmer=!this.lasmer;
  }

  lasalla2(){
    this.lasalla=!this.lasalla;
  }

  displayTreeItem(thisid:any) {
    this.oldid = thisid;
    var count = Object.keys(this.orgmodel).length;
    this.itemArray = new Array();
    for (let nthItem = 0; nthItem < count; nthItem++) {
      if (
        this.orgmodel[nthItem].Parent == thisid ||
        (this.itemArray &&
          this.itemArray.indexOf(+this.orgmodel[nthItem].Parent) > -1)
      ) {
        this.itemArray.push(this.orgmodel[nthItem].Id);
      }
    }
    this.itemArray.push(thisid);
    this.oldid = null;
    return this.itemArray;
  }

  navigateToModule(module:any){
    if(module=='safetyrounds'){
      this.addMessage(2);
      this.router.navigate(['/safetyround']);
    }
    if(module=='incidents'){
      this.addMessage(3);
      this.router.navigate(['/incident']);
    }
  }

  addMessage(id: number) {
    this.msgService.changeHeaderBackground(id);
  }

  riskvalues(item:any) {
    if (!item.incidentSeverity || !item.incidentPossibility || !item.incidentFrequency) {
      return "fa fa-info-circle";
    }
    if(item.incidentSeverity==1 || item.incidentSeverity==2 || item.incidentSeverity==3 || item.incidentSeverity==4 || item.incidentSeverity==5){
      if(item.incidentFrequency==1 || item.incidentFrequency==2 || item.incidentFrequency==3 || item.incidentFrequency==4 || item.incidentFrequency==5){
        return "numberSquareGreenSmall";
      }
    }
    if(item.incidentSeverity==6 || item.incidentSeverity==7 || item.incidentSeverity==8 || item.incidentSeverity==9 || item.incidentSeverity==10){
      if(item.incidentFrequency==6 || item.incidentFrequency==7 || item.incidentFrequency==8 || item.incidentFrequency==9 || item.incidentFrequency==10){
        return "numberSquareRedSmall";
      }
    }
    return "numberSquareYellowSmall";
  }

  riskvaluesNumber(item:any) {
    if (!item.incidentSeverity || !item.incidentPossibility || !item.incidentFrequency) {
      // return "fa fa-info-circle";
      return "";
    }
    if(item.incidentSeverity==1 || item.incidentSeverity==2 || item.incidentSeverity==3 || item.incidentSeverity==4 || item.incidentSeverity==5){
      if(item.incidentFrequency==1 || item.incidentFrequency==2 || item.incidentFrequency==3 || item.incidentFrequency==4 || item.incidentFrequency==5){
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 1;
        }else{
          return 2;
        }
      }else{
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 2;
        }else{
          return 3;
        }
      }
    }else{
      if(item.incidentFrequency==1 || item.incidentFrequency==2 || item.incidentFrequency==3 || item.incidentFrequency==4 || item.incidentFrequency==5){
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 3;
        }else{
          return 4;
        }
      }else{
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 4;
        }else{
          return 5;
        }
      }
    }
  }

  riskvaluesModal(sev:any, poss:any, freq:any ,entrance:any) {
    if (!sev || !poss || !freq) {
      if(entrance=='panel'){
        return "";
      }else{
        return "numberSquareGrey";
      }
    }

    if(sev==1 || sev==2 || sev==3 || sev==4 || sev==5){
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        return "numberSquareGreen";
      }
    }
    if(sev==6 || sev==7 || sev==8 || sev==9 || sev==10){
      if(freq==6 || freq==7 || freq==8 || freq==9 || freq==10){
        return "numberSquareRed";
      }
    }
    return "numberSquareYellow";
    /*if(sev==1 || sev==2 || sev==3){
      return "numberSquareGreen";
    }
    else if(sev==4 || sev==5 || sev==6){
      if((poss==1 || poss==2 || poss==3 || poss==4)&&(freq==1 || freq==2 || freq==3 || freq==4)){
        return "numberSquareGreen";
      }else{
        return "numberSquareYellow";
      }
    }
    else if(sev==7 || sev==8 || sev==9 || sev==10){
      if((poss==1 || poss==2 || poss==3 || poss==4)&&(freq==1 || freq==2 || freq==3|| freq==4)){
        return "numberSquareYellow";
      }else{
        return "numberSquareRed";
      }
    }*/
  }

  riskvaluesModalNumber(sev:any, poss:any, freq:any) {
    if (!sev || !poss || !freq) {
      return "";
    }

    if(sev==1 || sev==2 || sev==3){
      return 1;
    }
    else if(sev==4 || sev==5 || sev==6){
      if((poss==1 || poss==2 || poss==3 || poss==4)&&(freq==1 || freq==2 || freq==3 || freq==4)){
        return 1;
      }else{
        return 2;
      }
    }
    else if(sev==7 || sev==8 || sev==9 || sev==10){
      if((poss==1 || poss==2 || poss==3 || poss==4)&&(freq==1 || freq==2 || freq==3|| freq==4)){
        return 2;
      }else{
        return 3;
      }
    }
    return "";
  }

  sort(column:any, lower:any) {
    // The logic is in this pipe see under shared/pipes.
    let sortPipe = new SortPipe();
    if (this.sortOrderAscOrDesc == true) {
      this.actionplan_table = sortPipe.transform(
        this.actionplan_table,
        false,
        column,
        lower
      );
      this.sortOrderAscOrDesc = false;
    } else if (this.sortOrderAscOrDesc == false) {
      this.actionplan_table = sortPipe.transform(
        this.actionplan_table,
        true,
        column,
        lower
      );
      this.sortOrderAscOrDesc = true;
    }
  }

  filterTableValue2(){
    var thisvalue=$("#searchText").val();
    if(thisvalue.toString().length>0||thisvalue.toString()==''){
      this.table = $('#thistable').DataTable({retrieve: true});
      this.table.search(thisvalue.toString()).draw();
    }
  }

  filterTableValue(){
    var thisvalue=$("#selectbox option:selected").val();
    switch(thisvalue){
      case '1': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('').draw();
        break;
      }
      case '2': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('Pågående').draw();
        break;
      }
      case '3': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('Klar för uppföljning').draw();
        break;
      }
      case '4': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('Åtgärd effektiv').draw();
        break;
      }
      default:{
        // Nothing happens
      }
    }
  }
}
