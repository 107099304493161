import { Component, OnInit } from "@angular/core";
import { DatePipe } from '@angular/common';
import { Subscription, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { PDFService } from "src/app/shared/services/pdf.service";
import { FileUploader } from "ng2-file-upload";
import { MessageService } from "src/app/shared/services/message.service";
import { SafetyRoundModel, riskValueModel } from "src/app/shared/model_view/safetymodel";
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { Orgmodel } from 'src/app/shared/model_view/orgmodel';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';
import { TranslateService } from '@ngx-translate/core';
import { DataTableSetup } from 'src/app/shared/services/dataTableSetup';
import { orderBy } from 'lodash-es';
declare var $: any;

const BackendUrl = environment.BackendUrl;
const URL = BackendUrl + "/file";

@Component({
  selector: "app-safetyround",
  templateUrl: "./safetyround.component.html",
  styleUrls: ["./safetyround.component.css"],
})
export class SafetyRoundComponent implements OnInit {
  dtOptions: any = {};
  searchText: any;
  table:any;
  doNotShowSubDepartment: any = false;
  sortOrderAscOrDesc:any = true;
  safetyRoundValues_table2: any = new Array();
  safetyRoundValues_table: any = new Array();
  private orgId: any;
  orgmodel: any;
  itemArray: any;
  oldid: any = 0;
  localOrgId: any;
  headerid: any = null;
  private safetyRoundValueSub: Subscription;
  spinner: boolean = true;
  is_admin: any;
  is_super_user: any;
  currentUser: any;

  constructor(
    private msgService: MessageService,
    private authService: AuthService,
    private dataService: DataService,
    private pdfService: PDFService,
    private router: ActivatedRoute,
    private routing: Router,
    private datePipe: DatePipe,
    private dataTableSetup: DataTableSetup,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.doNotShowSubDepartment = this.authService.getShowOrHideSubdepartment();
    this.orgId = this.authService.getOrgid();
    this.localOrgId = this.authService.getLocalOrgid();
    this.headerid = this.router.snapshot.queryParamMap.get("id");
    this.dtOptions =this.dataTableSetup.dataTableInit('safetyround');
    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
    this.currentUser = this.authService.getUsername();

    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService
      .orgModelListener()
      .pipe(take(1))
      .subscribe((orgmodel: Orgmodel) => {
        this.orgmodel = orgmodel;
        if (this.doNotShowSubDepartment == true) {
          this.itemArray = null;
        } else {
          this.itemArray = this.displayTreeItem(this.localOrgId);
        }
        this.getSafetyRoundValues(this.headerid, this.itemArray);
    });

    this.msgService.sendMessage(null);
  }

  displayTreeItem(id:any) {
    if (this.orgmodel) {
      if (this.oldid != id) {
        this.oldid = id;
        var count = Object.keys(this.orgmodel).length;
        this.itemArray = new Array();
        for (let nthItem = 0; nthItem < count; nthItem++) {
          if (
            this.orgmodel[nthItem].Parent == id ||
            (this.itemArray &&
              this.itemArray.indexOf(+this.orgmodel[nthItem].Parent) > -1)
          ) {
            this.itemArray.push(this.orgmodel[nthItem].Id);
          }
        }
      } else {
        this.oldid = null;
        this.itemArray = [];
      }
      return this.itemArray;
    }
  }

  getSafetyRoundValues(thisid: number, numbers2:any) {
    if (thisid != null && !isNaN(thisid) && thisid != 0) {
      this.dataService.getSafetyRoundValues(null, numbers2, this.headerid);
    } else {
      this.dataService.getSafetyRoundValues(null, numbers2, this.headerid);
    }
    this.dataService.safetyRoundListener().pipe(take(1)).subscribe({
      next: safetyRoundValues_table => {
        // On Next
        if (safetyRoundValues_table.safetyroundvalues)
        {
          this.safetyRoundValues_table = safetyRoundValues_table.safetyroundvalues;
          var tableindexvalues = new Array();
          // För att inte få med dubletter
          var tlength = this.safetyRoundValues_table.length;
          for (var tableindex = 0; tableindex < tlength; tableindex++) {
            if (
              tableindexvalues.indexOf(
                this.safetyRoundValues_table[tableindex].Id
              ) == -1
            ) {
              tableindexvalues.push(
                this.safetyRoundValues_table[tableindex].Id
              );
              if (this.is_admin == 0 && this.is_super_user == 0)
              {
                if ((!this.safetyRoundValues_table[tableindex].Supervisor.indexOf(this.currentUser)) || (!this.safetyRoundValues_table[tableindex].roundResponsible2.indexOf(this.currentUser)))
                {
                  this.safetyRoundValues_table2.push(
                    this.safetyRoundValues_table[tableindex]
                  );
                }
              }
              else
              {
                this.safetyRoundValues_table2.push(
                  this.safetyRoundValues_table[tableindex]
                );
              }
            }
          }
        }
      },
      error: error => {
        if (this.headerid && !isNaN(+this.headerid)) {
          this.spinner = false;
          this.editItem(this.headerid.toString());
        }
        this.spinner = false;
      }
    })
  }

  filterTableValue(){
    var thisvalue=$("#searchText").val();
    if(thisvalue.toString().length>0||thisvalue.toString()==''){
      this.table = $('#safetytable').DataTable({retrieve: true});
      this.table.search(thisvalue.toString()).draw();
    }
  }

  changeShowSubdepartment() {
    this.doNotShowSubDepartment = !this.doNotShowSubDepartment;
    this.authService.setShowOrHideSubdepartment(this.doNotShowSubDepartment);
    this.routing.routeReuseStrategy.shouldReuseRoute = () => false;
    this.routing.onSameUrlNavigation = 'reload';
    this.routing.navigate(['/safetyround']);
  }

  addItem() {
    this.routing.navigate(['/addsafetyround']);
  }

  editItem(id: any) {
    this.routing.navigate(['/addsafetyround', id]);
  }

  viewItem(id: any) {
    this.routing.navigate(['/safetyrounditem', id]);
  }

  showRoundInfo(id:any){ 
    var numberOfRounds=0;
    var numberOfDoneRounds=0;

    for(var n=0;n<this.safetyRoundValues_table.length;n++){

      if(this.safetyRoundValues_table[n].roundId!=null&&this.safetyRoundValues_table[n].Id==id){
        numberOfRounds++;
        if(this.safetyRoundValues_table[n].roundDone=="Ja"){
          numberOfDoneRounds++;
        }
      }
    }
    if(numberOfRounds>0&&numberOfDoneRounds<numberOfRounds){
      return ({"A":numberOfDoneRounds+" / "+numberOfRounds,"B":true});
    }
    else if(numberOfRounds==0) {
      return null;
    }else{
      return ({"A":numberOfDoneRounds+" / "+numberOfRounds,"B":false});
    }
  }

  sort(column:any, lower:any) {
    // The logic is in this pipe see under shared/pipes.
    let sortPipe = new SortPipe();
    if (this.sortOrderAscOrDesc == true) {
      this.safetyRoundValues_table2 = sortPipe.transform(
        this.safetyRoundValues_table2,
        true,
        column,
        lower
      );
      this.sortOrderAscOrDesc = false;
    } else if (this.sortOrderAscOrDesc == false) {
      this.safetyRoundValues_table2 = sortPipe.transform(
        this.safetyRoundValues_table2,
        false,
        column,
        lower
      );
      this.sortOrderAscOrDesc = true;
    }
  }

  wordbreak(text:any) {
    var text = text.split(" ");
    var words = "";
    var length = 0;
    for (var n = 0; n < 10; n++) {
      if (text[n]) {
        length = Number(length + text[n].length);
      }
      if (length > 20) {
        break;
      } else if (!text[n]) {
        break;
      } else {
        if (text[n]) {
          words += text[n] + " ";
        }
      }
    }
    return words;
  }
} 

