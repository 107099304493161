import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Usermodel } from '../model_view/usermodel';

@Injectable({
    providedIn: 'root'
})

export class MessageService {
    private subject = new Subject();
    private headerColor=new Subject();
    constructor( private dataService:DataService) { }

    sendMessage(usermodel:any) {
        // it is used to publish data
         this.subject.next(usermodel);

    }

    sendMessageId(id:string) {
        // it is used to publish data
        var msg = {"id":id}
         this.subject.next(msg);
    }

    changeHeaderBackground(id:number){
        if(isNaN(id)||!(id>=0&&id<10)){
          return;
        }
        var msg = {"module":id}
           this.headerColor.next(msg);
    }

    sendMessageSpinner(showSpinner:boolean) {
      // it is used to publish data
       this.subject.next(showSpinner);
    }

    headerColorChanged() {
        // asObservable helps us to prevent the
        // leaks of Observable from outside of the subject
        return this.headerColor.asObservable();
    }

    accessMessage() {
        // asObservable helps us to prevent the
        // leaks of Observable from outside of the subject
        return this.subject.asObservable();
    }

}
