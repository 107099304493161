<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card" *ngIf="incidentitem">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <div class="header-text">
                            <h1>{{'incident'|translate}}</h1>
                        </div>
                    </div>
                    <div class="computer only three wide right aligned column" style="padding: 40px;cursor:pointer" (click)="goBack()">
                        <button class="ui white button">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <form class="ui form" #postForm="ngForm">
                    <div class="field">
                        <label>{{'typeofincident'|translate}}</label>
                        <select [(ngModel)]="incidentitem.typeofaction" name="incidentitem.typeofaction" (change)="setIncidentType(incidentitem.typeofaction)" required>
                            <option disabled selected hidden>{{incidentitem.typeofaction}}</option>   
                            <option *ngFor="let eventListItem of eventList;let i=index" [ngValue]="eventListItem">{{eventListItem.name}}</option>
                        </select><br>

                        <label>{{'typeofinjury'|translate}}</label>
                        <select [(ngModel)]="incidentitem.category" name="incidentitem.category" (change)="setCategoryType(incidentitem.category)" required>
                            <option disabled selected hidden>{{incidentitem.category}}</option>   
                            <option *ngFor="let categoryListItem of categoryList;let i=index" [ngValue]="categoryListItem">{{categoryListItem.name}}</option>
                        </select><br>

                        <label>{{'responsible'|translate}}</label>
                        <select [(ngModel)]="incidentitem.Reporter" name="incidentitem.Reporter" (change)="setRoundResponsible(incidentitem.Reporter)" required>
                            <option disabled selected hidden>{{incidentitem.Reporter}}</option>   
                            <option *ngFor="let userInfoitem of userInfo;let i=index" [ngValue]="userInfoitem">{{userInfoitem.username}}</option>
                        </select><br>

                        <label>{{'incidentdate'| translate }}</label>
                        <input type="date" name="incidentDate" [value]="incidentDate" [(ngModel)]="incidentitem.Date" /><br><br>
                        
                        <label>{{'department'|translate}}</label>
                        <select [(ngModel)]="incidentitem.Department" name="incidentitem.Department" (change)="setDepartment(incidentitem.Department)" required>
                            <option disabled selected hidden>{{incidentitem.Department}}</option>   
                            <option *ngFor="let orgmodelitem of orgmodel;let i=index" [ngValue]="orgmodelitem">{{orgmodelitem.Name}}</option>
                        </select><br>

                        <label>{{ 'incidentplace' | translate }}</label>
                        <input id="incidentPlace" name="incidentPlace" [(ngModel)]="incidentitem.Place"><br><br>

                        <label>{{ 'header' | translate }}</label>
                        <input id="incidentTitle" name="incidentTitle" [(ngModel)]="incidentitem.Title"><br><br>

                        <label>{{ 'description' | translate }}</label>
                        <textarea id="incidentDescription" name="incidentDescription" rows="3" [(ngModel)]="incidentitem.Description"></textarea><br><br>

                        <label>{{ 'Cause' | translate }}</label>
                        <input id="incidentCause" name="incidentCause" [(ngModel)]="incidentitem.Cause"><br><br>

                        <input class="ui button" type="file" (change)="imageUpload($event)" placeholder="Upload file" accept="application/msword,
                            application/vnd.ms-excel,
                            application/vnd.ms-powerpoint,
                            text/plain,
                            application/pdf,
                            image/*,
                            video/*"/> 
                        
                        <div [hidden]="!uploadFileDone" class="ui segment">
                            <div class="ui active loader"></div>
                            <p></p>
                        </div><br><br><br>
                        
                        <div class="ui grid item" *ngFor="let file of fileNames; let i=index" style="width:100%;margin-left:20px;padding:0px">
                            <div class="row" *ngIf="filetypeinfo&&filetypeinfo[i]" style="margin:0px;padding:0px">
                                <div class="eight wide column" style="margin:0px;padding:0px">
                                    <i class="ui icon file alternate outline"></i>{{filetypeinfo[i].thisFileName}}
                                </div>
                                <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                    <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="ui grid item" *ngFor="let file of selectedFile; let i=index" style="width:100%;margin-left:20px;padding:0px">
                            <div class="row" style="margin:0px;padding:0px">
                                <div class="eight wide column" style="margin:0px;padding:0px">
                                    <i class="ui icon file alternate outline"></i>{{file.name}}
                                </div>
                                <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                    <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                </div>
                            </div>
                        </div><br>
                        <hr>
                        <button *ngIf="is_admin || is_super_user" style="margin:10px;margin-top:15px; margin-bottom:15px" class="ui negative button" (click)="deleteItem()" type="remove" id="deleteItem"><i class="ui trash icon"></i>{{ 'remove' | translate }}</button>
                        <button [hidden]="uploadFileDone" style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                    </div>
                  </form>
            </div>
        </div>
    </div>
</div>
