<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="sixteen wide column header-text">
                        {{'organisations'|translate}}
                        <button class="ui right floated positive button" *ngIf="is_admin||is_super_user" style="margin-left:20px;margin-bottom:0" (click)="addItem()"><i class="ui icon add"></i>{{'ADD_COMPANY' | translate}}</button>
                    </div>
                </div>
                <div class="ui grid row">
                    <div class="table-responsive" style="margin:20px;cursor:pointer">
                        <div class="sixteen wide column">
                            <div class="input-group" style="margin-bottom:20px">
                                <div class="input-group-prepend" >
                                    <span class="input-group-text" id="basic-text1"><i class="fa fa-search" aria-hidden="true"></i></span>
                                </div>
                                <input class="form-control my-0 py-1" type="text" name="searchText" placeholder="{{'searchall'|translate}}" aria-label="Sök alla..." [(ngModel)]="searchText">
                            </div>
                            <table class="ui selectable table">
                                <thead>
                                    <tr>
                                        <th>{{'name'|translate}}</th>
                                        <th>{{'CREATED_AT'|translate}}</th>
                                        <th>{{'UPDATED_AT'|translate}}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let mainOrgItem of mainOrg | filter : 'name' : searchText;let i=index">
                                        <td class="firstcolumn" (click)="choseOrganisation(mainOrgItem.id)">{{mainOrgItem.name}}</td>
                                        <td (click)="choseOrganisation(mainOrgItem.id)">{{mainOrgItem.created_at | date:"yyyy-MM-dd:HH:MM"}}</td>
                                        <td (click)="choseOrganisation(mainOrgItem.id)">{{mainOrgItem.updated_at | date:"yyyy-MM-dd:HH:MM"}}</td>
                                        <td><i class="ui icon red trash" (click)="removeOrganisation(mainOrgItem.id)"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
