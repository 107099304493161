import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.css']
})
export class EditNewsComponent implements OnInit {

  newInformation: any;
  showValue: any = false;
  is_admin: any;
  is_super_user: any;
  information: any;
  newsid: any;

  constructor(private authService: AuthService,
    private router:Router,
    private dataService: DataService,
    private msgService: MessageService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.pipe(take(1)).subscribe(params => {
      this.newsid = params['id'];

      this.dataService.getInformationId(this.newsid);
      this.dataService
        .getInformationListener()
        .subscribe((information: any) => {
          this.information = information[0];

          if(this.information.Show == 1){
            this.showValue = true;
          }
          else{
            this.showValue = false;
          }
      });
    });

    this.newInformation = false;

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    this.msgService.sendMessage(null);
  }

  informationEdit(){
    this.dataService.updateInformation(this.information);
  }

  goBack() {
    this.router.navigate(['/controlpanel/news']);
  }

  checkbox(){
    if(!this.showValue){
      this.showValue = true;
      this.information.Show = 1;
    }
    else{
      this.showValue=!this.showValue;
      this.information.Show = 0;
    }
  }
}
