import { Component, OnInit } from '@angular/core';
import { DataService } from "src/app/shared/services/data.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  showFooterModalbox:boolean=false;
  mailSubjectFooter:any;
  mailMessageFooter:any;
  mailRondFooter:any;
  todayDate:any;

  constructor(private dataService: DataService,
    private datePipe: DatePipe,) {

   }

  ngOnInit() {
    this.todayDate = this.datePipe.transform(new Date(), "yyyy");
  }

  showmodalbox(){
    this.showFooterModalbox=true;
  }

  footerModalClose(){
    this.showFooterModalbox=false;
  }

  footerModalSend(){
    var mailReceiver="support@samtec.se";
    var mailRondFooter = this.mailRondFooter;
    var mailMessageFooter = "Skickat från: "+this.mailRondFooter+"<hr/>this.mailMessageFooter";
    var attachmentfile="";

    var mailSubjectFooter = this.mailSubjectFooter;

    var path='support';
    if(mailSubjectFooter==""){mailSubjectFooter="Ämne";}
    if(mailMessageFooter==""){mailMessageFooter="Meddelande";}

    this.dataService.sendmail(
      mailReceiver,
      mailMessageFooter,
      mailSubjectFooter,
      mailRondFooter,
      attachmentfile,
      path);
      this.showFooterModalbox=false;
    this.mailSubjectFooter="";
    this.mailMessageFooter="";
  }

}
