import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "../../../auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take, concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { truncate } from 'fs';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-safetyroundissue-add',
  templateUrl: './safetyroundissue-add.component.html',
  styleUrls: ['./safetyroundissue-add.component.css']
})
export class SafetyroundissueAddComponent implements OnInit {

  UploadUrl = environment.UploadUrl;

  safetyid: any;
  orgId: any;
  localOrgId: any;
  fileNames: any = [];
  file: any;
  filetypeinfo: any;
  subscriptions: any;
  is_admin: any;
  is_super_user: any;
  userInfo: any;
  selectedFile: any = [];
  files: any = [];
  filenames: any = [];
  item: any = [];
  userId: any;
  roundLocation: any;
  roundDone: any = false;
  roundActive: any = false;
  safetyRoundValues: any = [];
  filesExisting: any;
  uploadFileDone: boolean = false;
  showTab1: boolean = true;
  showTab2: boolean = false;
  safetyRoundValue =  {
    Id: 0,
    Files:"",
    roundLocation: "",
    roundRisk: "",
    roundMeasure: "",
    roundResponsible2: "",
    roundDoneDate: new Date().toISOString().slice(0, 10),
    roundDone: "Nej",
    roundActive: "Nej",
    roundSeverity: 0,
    roundPossibility: 0,
    roundFrequency: 0,
    roundTotalComment: "",
    roundPossibilityComment: "",
    roundFrequencyComment: "",
    roundSeverityComment: ""
  }

  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private translate: TranslateService,
    private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $("#roundResponsibleChose").dropdown();
  }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.safetyid = Number(params['id']);
    });

    this.orgId = this.authService.getOrgid();
    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
    this.filesExisting = 0;

    this.subscriptions=this.dataService.getSubscriptions(this.localOrgId);
    this.dataService.subscriptionsListener()
    .subscribe((subscriptions) => {
      this.subscriptions=subscriptions[0];
    });

    this.dataService.getUsers(this.authService.getOrgid(), this.userId);
    this.dataService.usersListener().subscribe((users) => {
      this.userInfo = users;
    });

    this.msgService.sendMessage(null);
  }

  changeTab(flik:boolean){
    if (flik == true) {
      this.showTab1 = true;
      this.showTab2 = false;
    } else {
      this.showTab1 = false;
      this.showTab2 = true;
    }
  }

  removeImage(file: any, index: any) {
    this.selectedFile.splice(index, 1);
    this.filenames.splice(index, 1);
  }

  imageUpload(event:any) {
    this.uploadFileDone = true,
    this.uploadService.upload(event.target.files[0], "");
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {
      if (filename == null)
      {
        Swal.fire("Error", "Error");
      }
      else
      {
        this.files.push(filename);
        setTimeout(() => {
          this.uploadFileDone = false;

          this.selectedFile.push(event.target.files[0]);
    
          if (this.files) {
            this.filenames.push(event.target.files[0].name);
          } else {
            this.filenames = event.target.files[0].name;
          }
          this.item.push(1);
        }, 1000);
      }
    });
  }

  setRoundResponsible(userInfo:any) {
    this.safetyRoundValue.roundResponsible2 = userInfo.username;
  }

  goBack() {
    this.routing.navigate(['safetyrounditem/' + this.safetyid]);
  }

  addItem() {
    let user = this.authService.getUserId();

    if (this.roundDone == true)
    {
      this.safetyRoundValue.roundDone = "Ja";
    }

    if (this.roundActive == true)
    {
      this.safetyRoundValue.roundActive = "Ja";
    }

    if(!this.filesExisting){this.filesExisting=0;}
    this.file = this.safetyRoundValue.Files;
    if (this.file == null)
    {
      this.file = "";
    }

    for (var i = 0; i < this.selectedFile.length; i++) {
      if (this.selectedFile[i]) {
        if (i != 0 || (i == 0 && this.file != "")) {
          this.file += ",";
        }
        this.file += this.files[i + this.filesExisting];
      }
    }

    if (this.selectedFile.length > 0)
    {
      this.safetyRoundValue.Files = this.file;
    }

    this.safetyRoundValue.Id = this.safetyid;
    this.safetyRoundValues.push(this.safetyRoundValue)
    this.dataService.addRound(this.safetyRoundValues, user);
  }

  severityValues(sev:any) {
    if (sev == 1 || sev == 2 || sev == 3 || sev == 4 || sev == 5) {
      // return "lätt";
      return  this.translate.instant('Severity_low');
    }else{
      // return "svår";
      return  this.translate.instant('Severity_high');
    }
  }

  frequencyValues(freq:any) {
    if (freq == 1 || freq == 2 || freq == 3 || freq == 4 || freq == 5) {
      // return "sällan till mindre ofta och/eller kort exponeringstid";
      return  this.translate.instant('Frequency_low');
    }else{
      // return "ofta till kontinuerlig och/eller lång exponeringstid";
      return  this.translate.instant('Frequency_high');
    }
  }

  possibilityValues(poss:any) {
    if (poss == 1 || poss == 2 || poss == 3 || poss == 4 || poss == 5) {
      return  this.translate.instant('Possibility_low');
      //return "möjligt under vissa omständigheter";
    }else{
      return  this.translate.instant('Possibility_high');
      //return "knappast möjligt";
    }
  }

  riskvaluesModal(sev:any, poss:any, freq:any ,entrance:any) {
    if (!sev || !poss || !freq) {
      if(entrance=='panel'){
        return "";
      }else{
        return "numberSquareGrey";
      }
    }
    if(sev==1 || sev==2 || sev==3 || sev==4 || sev==5){
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        return "numberSquareGreen";
      }
    }
    if(sev==6 || sev==7 || sev==8 || sev==9 || sev==10){
      if(freq==6 || freq==7 || freq==8 || freq==9 || freq==10){
        return "numberSquareRed";
      }
    }
    return "numberSquareYellow";
  }

  riskvaluesModalNumber(sev:any, poss:any, freq:any) {
    if (!sev || !poss || !freq) {
      return "";
    }
    if(sev==1 || sev==2 || sev==3 || sev==4 || sev==5){
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 1;
        }else{
          return 2;
        }
      }else{
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 2;
        }else{
          return 3;
        }
      }
    }else{
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 3;
        }else{
          return 4;
        }
      }else{
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 4;
        }else{
          return 5;
        }
      }
    }
  }
}
