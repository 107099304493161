<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div *ngIf="incidentRoundValue" class="ui card">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <div class="header-text">
                            <h1>{{'five_why'|translate}}</h1>
                        </div>
                    </div>
                    <div class="computer only three wide right aligned column" style="padding: 40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <button class="ui white button">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <form class="ui form" #postForm="ngForm">
                    <div class="field">
                        <label>{{'why'|translate}}</label>
                        <input id="rootCauseAnalysisWhy1" name="rootCauseAnalysisWhy1" [(ngModel)]="incidentRoundValue.rootCauseAnalysisWhy1"/>
                    </div>
                    <div class="field">
                        <label>{{'why'|translate}}</label>
                        <input id="rootCauseAnalysisWhy2" name="rootCauseAnalysisWhy2" [(ngModel)]="incidentRoundValue.rootCauseAnalysisWhy2"/>
                    </div>
                    <div class="field">
                        <label>{{'why'|translate}}</label>
                        <input id="rootCauseAnalysisWhy3" name="rootCauseAnalysisWhy3" [(ngModel)]="incidentRoundValue.rootCauseAnalysisWhy3"/>
                    </div>
                    <div class="field">
                        <label>{{'why'|translate}}</label>
                        <input id="rootCauseAnalysisWhy4" name="rootCauseAnalysisWhy4" [(ngModel)]="incidentRoundValue.rootCauseAnalysisWhy4"/>
                    </div>
                    <div class="field">
                        <label>{{'why'|translate}}</label>
                        <input id="rootCauseAnalysisWhy5" name="rootCauseAnalysisWhy5" [(ngModel)]="incidentRoundValue.rootCauseAnalysisWhy5"/>
                    </div>
                    <div class="field">
                        <label>{{'solution'|translate}}</label>
                        <input id="rootCauseAnalysisSolution" name="rootCauseAnalysisSolution" [(ngModel)]="incidentRoundValue.rootCauseAnalysisSolution"/>
                    </div>
                    <div class="field">
                        <button style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                    </div>
                  </form>
            </div>
        </div>
    </div>
</div>
