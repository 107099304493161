import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from '../auth/auth.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from '../shared/services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { countries } from 'country-flag-icons'
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() add:{};
  showHeaderModalbox:boolean=false;
  mailHeaderSubject:any;
  mailHeaderMessage:any;
  mailHeaderRond:any;
  mailHeaderFrom:any;
  token:any;
  activeHeader:number=0;
  isAuthenticated: boolean;
  private authListenerSubs: Subscription;
  userName:string;
  subscriptions:any = [];
  subscriptionValues:any;
  is_admin: boolean;
  is_super_user: boolean;
  subscription:any;
  subscriptionHeader:any;
  defaultLanguage: any = "sv";

  constructor(private authService: AuthService,
              private router:Router,
              private dataService: DataService,
              private msgService: MessageService,
              private translate: TranslateService) { }

  ngAfterViewInit(): void {
    $('#menumobile').dropdown();
    $('#select').dropdown();
    $('#selectChemical').dropdown();
  }

  ngOnInit() {
    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
    this.subscriptions=this.dataService.getSubscriptions(this.authService.getLocalOrgid());
    this.defaultLanguage = localStorage.getItem('usedLanguage');
    this.dataService.subscriptionsListener()
    .subscribe((subscriptions) => {
      this.subscriptions=subscriptions[0];
    });

    this.dataService.getToken();
    this.dataService.tokenUpdateListener()
    .subscribe((token) => {
      this.token=token;
    });

    this.isAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthServiceListener()
      .subscribe(isAuthenticated => {
        this.isAuthenticated = isAuthenticated;
      });

      this.mailHeaderRond = this.authService.getUsername();
      this.mailHeaderFrom = "support@samtec.se";

      this.subscriptionHeader = this.msgService.headerColorChanged().subscribe(
        (msg: any) => {
          if (msg.module) {
            this.activeHeader=msg.module;
          }
        }
      );
  }

  changeLanguage(lang: any) {
    if (lang == "sv")
    {
      this.defaultLanguage = "en";
      this.translate.use('en');
      localStorage.setItem('usedLanguage', 'en');
      //this.translate.setDefaultLang('us');
    }
    else
    {
      this.defaultLanguage = "sv";
      this.translate.use('sv');
      localStorage.setItem('usedLanguage', 'sv');
      //this.translate.setDefaultLang('sv');
    }
  }

  headerModalOpen(){
    this.showHeaderModalbox=true;
  }

  headerModalClose(){
    this.showHeaderModalbox=false;
  }

  headerModalSend(){
    var mailHeaderReceiver="support@samtec.se";
    var mailHeaderRond = this.mailHeaderRond;
    var attachmentfile="";
    var mailHeaderMessage = "Skickat från: "+this.mailHeaderRond+"<hr/>"+this.mailHeaderFrom+"<hr/>"+this.mailHeaderMessage;
    var mailHeaderSubject = this.mailHeaderSubject;
    var path='';
    if(mailHeaderSubject==""){mailHeaderSubject="Ämne";}
    if(mailHeaderMessage==""){mailHeaderMessage="Meddelande";}

    this.dataService.sendmail(
          mailHeaderReceiver,
          mailHeaderMessage,
          mailHeaderSubject,
          mailHeaderRond,
          attachmentfile,
          path);
      this.showHeaderModalbox=false;
      this.mailHeaderSubject="";
      this.mailHeaderMessage="";
  }

  logout() {
    this.authService.logout();
  }

  navigateHome(){
    this.activeHeader=0;
    this.router.navigate(['/']);
  }

  navigateProduct(){
    this.activeHeader=1;
    this.router.navigate(['/managementsystem']);
  }

  navigateSafetyRound(){
    this.activeHeader=2;
    this.redirectTo('/safetyround');
  }

  navigateIncident(){
    this.activeHeader=3;
    this.redirectTo('/incident');
  }

  navigateControlPanel(){
    this.activeHeader=5;
    this.redirectTo('/controlpanel');
  }

  navigateWorkPlace(){
    this.activeHeader=6;
    this.redirectTo('/workplace');
  }

  navigateTools1(){
    this.activeHeader=4;
    // this.router.navigate(['/tools1']);
    this.redirectTo('/actionplan');
  }

  navigateHelp(){
    this.activeHeader=0;
    this.router.navigate(['/settings/help']);
  }

  navigateSettings(){
    this.activeHeader=0;
    this.router.navigate(['/settings']);
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }

  // En enkel funktion för att ladda om sida.
  redirectTo(url:any){
   this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
   this.router.navigate([url]));
  }
}

