
<div class="ui stackable grid">
    <div class="row">
        <div class="ten wide column">
            <div class="ui card">
                <div class="header-text">
                    {{'HANDLING_PLAN_FOR'|translate}} {{first_name}} {{last_name}}
                </div>
                <div class="ui search" style="margin:20px">
                    <div class="ui icon input">
                      <input class="prompt" type="text" type="text" name="searchText" id="searchText" placeholder="{{'searchall'|translate}}" [(ngModel)]="searchText" (keyup)="filterTableValue2()">
                      <i class="search icon"></i>
                    </div>
                    <div class="results"></div>
                    <div class="ui icon input" style="margin-left:20px">
                        <select class="browser-default custom-select" (change)="filterTableValue()" id="selectbox" placeholder="status">
                            <option value="1">{{'all'|translate}}</option>
                            <option value="2">{{'ongoing'|translate}}</option>
                            <option value="3">{{'ready_for_follow_up'|translate}}</option>
                            <option value="4">{{'active'|translate}}</option>
                        </select>
                    </div>
                </div>
                <table datatable [dtOptions]="dtOptions" class='ui stackable table' style="padding:20px" id='thistable' *ngIf="actionplan_table&&actionplan_table.length!=0">
                    <thead>
                        <tr>
                            <th (click)="sort('roundDoneDate','')">{{'date'|translate}}</th>
                            <th (click)="sort('roundMeasure','')">{{'TASK'|translate}}</th>
                            <th>{{'status'|translate}}</th>
                            <th>{{'ACTION_TYPE' | translate}}</th>
                            <th>{{'hazard'|translate}}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="visitrow" *ngFor="let actionplan_table_item of actionplan_table | filter : 'dashboard' : searchText;let i=index">
                            <td>{{actionplan_table_item.roundDoneDate}}</td>
                            <td>{{actionplan_table_item.roundMeasure}}</td>
                            <td *ngIf="actionplan_table_item.roundDone=='Nej'">{{'ongoing'|translate}}</td>
                            <td *ngIf="actionplan_table_item.roundDone=='Ja'&&actionplan_table_item.roundActive=='Nej'">{{'ready_for_follow_up'|translate}}</td>
                            <td *ngIf="actionplan_table_item.roundDone=='Ja'&&actionplan_table_item.roundActive=='Ja'">{{'active'|translate}}</td>
                            <td *ngIf="!actionplan_table_item.roundDone">-</td>
                            <td class="visitcolumn">{{actionplan_table_item.incidentroundtype}}</td>
                            <td>
                                <div *ngIf="!riskvaluesNumber(actionplan_table_item)" class="ui label lightgrey">-</div>
                                <div *ngIf="riskvaluesNumber(actionplan_table_item)&&riskvaluesNumber(actionplan_table_item)==1" class="ui label green">{{riskvaluesNumber(actionplan_table_item)}}</div>
                                <div *ngIf="riskvaluesNumber(actionplan_table_item)&&riskvaluesNumber(actionplan_table_item)==2" class="ui label green">{{riskvaluesNumber(actionplan_table_item)}}</div>
                                <div *ngIf="riskvaluesNumber(actionplan_table_item)&&riskvaluesNumber(actionplan_table_item)==3" class="ui label yellow">{{riskvaluesNumber(actionplan_table_item)}}</div>
                            </td>
                            <td>
                                <div class="ui positive button" (click)="visit(actionplan_table_item.incidentId,actionplan_table_item.incidentroundtype,actionplan_table_item.roundId)" style="border-radius: 29px;">{{'MANAGE' | translate}}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="six wide column">
            <div class="ui grid rectangle-green" *ngIf="getStatisticalData">
                <div class="row">
                    <div class="two wide column big-letter">
                        {{getStatisticalData.numberOfSafetyroundsLocal}}
                    </div>
                    <div class="fourteen wide column big-text">
                        {{"safetyroundsthisyear" | translate}}
                    </div>
                </div>
            </div>
            <div class="ui grid rectangle-green" *ngIf="getStatisticalData">
                <div class="row">
                    <div class="two wide column big-letter">
                        {{getStatisticalData.numberOfIncidents}}
                    </div>
                    <div class="fourteen wide column big-text">
                        {{"incidentsthisyear" | translate}}
                    </div>
                </div>
            </div>
            <div class="ui grid rectangle-orange">
                <div class="row">
                    <div class="two wide column">
                        <i style="color: white;margin-left:20px" class="ui icon large circle info" aria-hidden="true"></i>
                    </div>
                    <div class="fourteen wide column">
                        <div *ngIf="information" style="color:white">
                            {{information[0].Text}} <br/><small>{{information[0].Date| date:"yyyy-MM-dd"}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
