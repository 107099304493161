<div class="ui stackable grid">
    <div class="row">
        <div class="eleven wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <div class="header-text">
                            <h1>{{'workplace'|translate}}</h1>
                        </div>
                    </div>
                    <div class="computer only three wide right aligned column" style="padding: 40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <button class="ui white button">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <div class="ui grid row">
                    <div class="twelve wide column">
                        <h3>{{'sourcesofriskandrisks' | translate}}</h3>
                    </div>
                    <div class="computer only four wide left aligned column">
                        <button class="ui positive button" *ngIf="(is_admin||is_super_user)" (click)="addworkplaceitem()"><i class="ui icon plus"></i>{{"add_point"|translate}}</button>
                    </div>
                    <div class="mobile only one wide left aligned column">
                        <i class="ui icon large green plus" *ngIf="(is_admin||is_super_user)" (click)="addworkplaceitem()"></i>
                    </div>
                </div>
                <div *ngIf="workplaceitem&&workplaceRoundValues_table&&workplaceRoundValues_table.length == 0">
                    <p style="margin-left:20px;font-style:italic">{{"no_point_in_list"|translate}}</p>
                </div>
                <div *ngIf="workplaceitem&&workplaceRoundValues_table&&workplaceRoundValues_table.length > 0" class="second-line" style="margin:20px">
                    <div class="ui divided items" *ngFor="let rondVarde of workplaceRoundValues_table;let i=index;let first=first;" style="margin:20px; width:700px">
                        <div class="item">
                            <div class="content">
                                <i class="icon green large edit" (click)="editIssue(rondVarde.roundId)" style="cursor:pointer"></i>
                                <a *ngIf="rondVarde.roundResponsible2!=null&&rondVarde.roundResponsible2!=''" class="header" (click)="selectIssue(rondVarde.roundId)">{{rondVarde.roundDoneDate | date:"yyyy-MM-dd"}} ({{rondVarde.roundResponsible2}})</a>
                                <a *ngIf="rondVarde.roundResponsible2==''||rondVarde.roundResponsible2==''" class="header" (click)="selectIssue(rondVarde.roundId)">{{rondVarde.roundDoneDate | date:"yyyy-MM-dd"}}</a>
                                <div class="meta">
                                    <span class="cinema">{{rondVarde.roundMeasure}}</span>
                                </div>
                                <div class="description">
                                    <p>{{rondVarde.roundRisk}}</p>
                                </div>
                                <div class="extra">
                                    <div *ngIf="rondVarde.roundDone=='Nej'&&rondVarde.roundActive=='Nej'" class="ui label"><i class="ui icon clock outline"></i>{{'notstarted'|translate}}</div>
                                    <div *ngIf="rondVarde.roundDone=='Nej'&&rondVarde.roundActive=='Ja'" class="ui label"><i class="ui icon clock outline"></i>{{'ongoing'|translate}}</div>
                                    <div *ngIf="rondVarde.roundDone=='Ja'&&rondVarde.roundActive=='Nej'" class="ui label green"><i class="ui icon check"></i>{{'finished'|translate}} {{rondVarde.roundDoneRealDate | date:"yyyy-MM-dd"}}</div>
                                    <div *ngIf="rondVarde.roundDone=='Ja'&&rondVarde.roundActive=='Ja'" class="ui label green"><i class="ui icon check"></i>{{'finished'|translate}} {{rondVarde.roundDoneRealDate | date:"yyyy-MM-dd"}}</div>
                                    <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1&&riskvaluesNumber(rondVarde)==1" class="ui label green">{{riskvaluesNumber(rondVarde)}}</div>
                                    <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1&&riskvaluesNumber(rondVarde)==2" class="ui label green">{{riskvaluesNumber(rondVarde)}}</div>
                                    <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1&&riskvaluesNumber(rondVarde)==3" class="ui label orange">{{riskvaluesNumber(rondVarde)}}</div>
                                    <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1&&riskvaluesNumber(rondVarde)==4" class="ui label red">{{riskvaluesNumber(rondVarde)}}</div>
                                    <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1&&riskvaluesNumber(rondVarde)==5" class="ui label red">{{riskvaluesNumber(rondVarde)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="five wide column">
            <div class="rectangle-green">
                <div class="ui grid row">
                    <div class="twelve wide column">
                        <div *ngIf="workplaceitem" class="green-header">
                            {{ 'title' | translate }}
                        </div>
                        <div *ngIf="workplaceitem" class="green-text">
                            {{workplaceitem.Title}}
                        </div>
                    </div>
                    <div class="one wide right aligned column" style="margin-top: 20px; cursor:pointer; color:white" (click)="editItem()">
                        <i class="ui large icon edit"></i>
                    </div>
                    <div class="one wide right aligned column" style="margin-top: 20px; cursor:pointer; color:white">
                        <div class="ui dropdown" id='selectWorkplaceItem'>
                            <i class="ui icon large ellipsis vertical" style="margin-bottom:10px" ></i>
                            <div class="menu">
                                <div (click)="sendmail()" class="item"><a data-target="#"><i class="ui icon mail"></i> {{"workplace" | translate}}</a></div>
                                <div (click)="download()" class="item"><a data-target="#"><i class="ui icon download"></i> {{"workplace" | translate}}</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="workplaceitem" class="green-header">
                    {{ 'description' | translate }}
                </div>
                <div *ngIf="workplaceitem" class="green-text">
                    {{workplaceitem.Text}}
                </div>
                <div *ngIf="workplaceitem" class="green-header">
                    {{'responsible'|translate}}
                </div>
                <div *ngIf="workplaceitem" class="green-text">
                    {{workplaceitem.Supervisor}}
                </div>
                <div *ngIf="workplaceitem" class="green-header">
                    {{'date'| translate }}
                </div>
                <div *ngIf="workplaceitem" class="green-text">
                    {{workplaceitem.doneDate}}
                </div>
                <div *ngIf="workplaceitem" class="green-header">
                    {{'department'| translate }}
                </div>
                <div *ngIf="workplaceitem" class="green-text">
                    {{workplaceitem.Department}}
                </div>
                <div *ngIf="workplaceitem" class="green-header">
                    {{ 'participants' | translate }}
                </div>
                <div *ngIf="workplaceitem" class="green-text">
                    {{workplaceitem.Participants}}
                </div>
                <div *ngIf="workplaceitem&&workplaceitem.Danger" class="green-header">
                    {{ 'risk' | translate }}
                </div>
                <div *ngIf="workplaceitem&&workplaceitem.Danger" class="green-text">
                    {{workplaceitem.Danger}}
                </div>
                <div *ngIf="workplaceitem" class="green-header">
                    {{'ATTACHED_FILES'|translate}}
                </div>
                <div *ngFor="let fileName of fileNames;let i=index;" class="green-text">
                    <a (click)="openDocumentInNewWindow(filetypeinfo[i])" *ngIf="fileName" style="color:white;cursor:pointer">
                        <i title="{{splitimageName(fileName,'')}}" class="ui icon pdf file" style="font-size:14px;color:white;"></i><small>{{splitimageName(fileName,'')}}</small>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
