<div class="modal-header">
    <h4 class="modal-title">{{'chooseorganisation'|translate}}</h4>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text" id="basic-text1"><i class="fa fa-search" aria-hidden="true"></i></span>
            </div>
            <input class="form-control my-0 py-1" type="text" name="searchText" placeholder="{{'searchall'|translate}}" aria-label="Sök alla..." [(ngModel)]="searchText">
        </div>
        <table class="table table-hover">
            <tr>
                <th>{{'name'|translate}}</th>
            </tr>
            <tr *ngFor="let mainOrgItem of mainOrg | filter : 'name' : searchText;let i=index" (click)="changethisorganisation(mainOrgItem.id)" style="cursor:pointer">
                <td class="firstcolumn">{{mainOrgItem.name}}</td>
            </tr>
        </table>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer">
        <button type="button" class="ui button" (click)="activeModal.close('Close click')" id="modalSelectOrganisationButton">{{"CLOSE" | translate}}</button>
    </div>
</div>