import { Injectable } from "@angular/core";
import { Subject, Observable, of} from "rxjs";
import { take,delay } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AuthService } from "../../auth/auth.service";
import { Usermodel } from "./../../shared/model_view/usermodel";
import { Orgmodel } from "src/app/shared/model_view/orgmodel";
import { SafetyRoundModel, IncidentModel, WorkplaceRoundModel } from "../model_view/safetymodel";
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Managementsystemmodel } from '../model_view/managementsystemmodel';

const BackendUrl = environment.BackendUrl;

@Injectable({ providedIn: "root" })
export class DataService {
  private users: any;
  private users2: any;
  private usermodel: Usermodel;
  private userModelUpdated = new Subject<Usermodel>();
  private tokenUpdate = new Subject<string>();

  private orginfo: any;
  private orgmodel: Orgmodel;
  private orgmodeltmp: Orgmodel;
  private orgmodeltmp2: Orgmodel;
  private orgModelUpdated = new Subject<Orgmodel>();
  private orgModelUpdatedControlPanel = new Subject<Orgmodel>();

  private safetyRoundModelUpdated_table = new Subject<any>();
  private safetyRoundModelUpdated_edit = new Subject<any>();
  private safetyItemModelUpdated_edit = new Subject<any>();

  private roundModel_table = new Subject<any>();

  private workplaceRoundModelUpdated_table = new Subject<WorkplaceRoundModel[]>();
  private workplaceRoundModelUpdated_edit = new Subject<WorkplaceRoundModel>();
  workplaceroundvalues_table: WorkplaceRoundModel[];
  workplaceRoundValues_edit: WorkplaceRoundModel;
  workplaceItemModelUpdated_edit = new Subject<any>();
  workplaceIdModelUpdated_edit = new Subject<any>();
  roundWorkplaceModel_table = new Subject<any>();

  private incidentModelUpdated_table = new Subject<IncidentModel[]>();
  incidentItemModelUpdated_edit  = new Subject<any>();
  incidentRoundModelUpdated_edit  = new Subject<any>();
  incidentValues_table: IncidentModel[];
  private usersUpdated = new Subject<any>();
  private usersUpdated2 = new Subject<any>();
  private orgInfoUpdated = new Subject<any>();
  private actionPlanUpdated_table = new Subject<any>();
  actionplan_table: any[];
  rootOrganisations: any[];
  organisationsUpdated = new Subject<any>();
  managementSystemModel: Managementsystemmodel[];
  managementSystemModelUpdated = new Subject<Managementsystemmodel[]>();
  informationModelUpdated = new Subject<any>();
  mainorg: any;
  mainorgUpdated = new Subject<any>();
  eventList:any = [];
  eventListUpdated = new Subject<any>();
  categoryList:any  = [];
  categoryListUpdated = new Subject<any>();
  subscriptionsUpdated = new Subject<any>();
  subscriptionValues:any;
  subscriptionValuesUpdated = new Subject<any>();
  ContentType:string;
  fileInfoUpdated= new Subject<string>();
  fileInfoUpdated2:Observable<string>;
  getStatisticalDataValues:any;
  getStatisticalDataUpdated= new Subject<any>();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {}

  // ---- Navigation ----
  // Used by navigation to get the right root organisation
  // of a clicked organisation (get)
  public getMainOrg() {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get<any>(BackendUrl + "/getMainOrg/", {headers: headers}).pipe(take(1)).subscribe({next: mainorg => {
        this.mainorg = mainorg.org;
        this.mainorgUpdated.next(this.mainorg);
      },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.mainorgUpdated.next(null);
        }
      });
    }
    else
    {
      this.mainorgUpdated.next(null);
    }
  }

  // Login
  // For a new user, not used
  public getRootOrganisationsValues() {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http.get<any>(BackendUrl + "/rootorganisations/", {headers: headers}).subscribe({next: values => {
          this.rootOrganisations = values.rootOrganisations;
          this.organisationsUpdated.next(this.rootOrganisations);
        },
        error: error => {
          if (error.status === 401)
          {
            this.authService.logout();
          }
          this.organisationsUpdated.next(null);
        }
      });
    }
    else
    {
      this.organisationsUpdated.next(null);
    }
  }

    // ---- Header ----
  // Used by header and navigation but mainly header
  public getToken() {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      var userId = this.authService.getUserId();
      return this.http
        .get<{ token:any }>(
          BackendUrl + "/getToken/" + userId + "", {headers: headers}
        )
        .subscribe((token) => {
          this.tokenUpdate.next(token.token);
        });
    }
    else
    {
      return null;
    }
  }

  // ---- Dashboard ----
  // Explicity for the dashboard
  // because I need both models of user
  public getUserById(i: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; users: any }>(
          BackendUrl + "/getUserById/" + i, {headers: headers}
        )
        .subscribe((usermodelData) => {
          this.users2 = usermodelData.users;
          this.usersUpdated2.next(this.users2);
        });
      }
  }

  // Information table for information under dashboard
  public getInformation(path:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
        var httpParams = new HttpParams().set("path",path);
      this.http
        .get<{ information:any }>(BackendUrl + "/getinformation/", {
          headers: headers,
          params: httpParams,
          responseType: "json"})
        .subscribe((values) => {
          this.informationModelUpdated.next(values.information);
        });
      }
  }

  public getInformationId(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ information: any; }>(
          BackendUrl + "/getInformationId/" + id, {headers: headers}
        )
        .subscribe((values) => {
          this.informationModelUpdated.next(values.information);
        });
      }
  }

  public updateInformation(informationObject:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ informationObject:any }>(BackendUrl + "/updateinformation/", {informationObject}, {headers: headers})
        .subscribe((values) => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigate(["controlpanel/news"], { queryParams: { id: -5 } })
            );
        });
      }
  }

  public addInformation(informationObject:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ informationObject:any }>(BackendUrl + "/addinformation/", {informationObject}, {headers: headers})
        .subscribe((values) => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigate(["controlpanel/news"], { queryParams: { id: -5 } })
            );
        });
      }
  }

  public getStatisticalData(itemArray:any){
    var url = "/getStatisticalData/";
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
        var httpParams = new HttpParams()
          .set("itemArray",itemArray);
        this.http
          .get<{ message: string; getStatisticalData:any }>(BackendUrl + url, {
            headers: headers,
            params: httpParams,
            responseType: "json"
          })
          .subscribe((values) => {
            this.getStatisticalDataValues = values;
            this.getStatisticalDataUpdated.next(this.getStatisticalDataValues);
          });
        }
    }

  // Dashboard and first option under tools
  // Entrance = dash is dashboard
  public getActionPlan(entrance:any) {
    let userId = this.authService.getUserId();
    var userName = this.authService.getUsername();
    let orgid = this.authService.getOrgid();
    let localOrgid = this.authService.getLocalOrgid();
    if(entrance=='dash'){
      var httpParams = new HttpParams()
        .set("orgid", orgid)
        .set("localOrgid", localOrgid)
        .set("userId", userId)
        .set("userName", userName);
      var url = "/getActionPlanDash/";
    }else{
      var httpParams = new HttpParams()
      .set("orgid", orgid)
      .set("localOrgid", localOrgid)
      .set("userId", userId);
      var url = "/getActionPlan/";
    }

    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; actionplan: any }>(BackendUrl + url, {
          headers: headers,
          params: httpParams,
          responseType: "json",
        })
        .subscribe((values) => {
          this.actionplan_table = values.actionplan;
          this.actionPlanUpdated_table.next(this.actionplan_table);
        });
      }
  }

  // ---- management-system ----
  public addFileToManagementTable(name:any, description:any, placement:any, department:any, user:any, orgId:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ name:any; description:any; placement:any; department:any }>(
          BackendUrl + "/addFileToManagementTable/",
          {
            name: name,
            description: description,
            placement: placement,
            department: department,
            user: user,
            orgId: orgId
          }, {headers: headers}
        )
        .subscribe();
        }
  }

  public getFilesManagement(department:any, orgId:any) {
    var user = this.authService.getOrgid();
    let httpParams = new HttpParams().set("id", department).set("user", user).set("orgId", orgId);
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ managementSystemModel: Managementsystemmodel[] }>(
          BackendUrl + "/getmanagementfiles/",
          {
            headers: headers,
            params: httpParams,
            responseType: "json",
          }
        )
        .subscribe((values) => {
          this.managementSystemModel = values.managementSystemModel;
          this.managementSystemModelUpdated.next(this.managementSystemModel);
        });
      }
  }

  public removeRowManagementTable(id: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete<{ message: string }>(
          BackendUrl + "/removeRowManagementTable/" + id + "", {headers: headers}
        )
        .subscribe();
    }
  }

  //Control - Panel
  public addUser(user:any, usersubscr:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ user:any; usersubscr:any }>(
          BackendUrl + "/addUser/",
          {
            user: user,
            usersubscr: usersubscr,
          }, {headers: headers}
        )
        .subscribe((values) => {
          this.authService.setOrgid(values.usersubscr);
          this.authService.setLocalOrgid(values.usersubscr);
          this.getOrganisationSchema(values.usersubscr);
          this.orgModelListener()
            .pipe(take(1))
            .subscribe((orgmodel: Orgmodel) => {
              this.orgmodel = orgmodel;
              this.router
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() => this.router.navigate(["controlpanel/organisations"]));
            });
        });
      }
  }

  public getOrganisationSchemaControlPanel(orgID:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      return this.http
        .get<{ message: string; orgmodel: any }>(
          BackendUrl + "/orgSchema/" + orgID + "", {headers: headers}
        )
        .subscribe((orgModelData) => {
          this.orgmodel = orgModelData.orgmodel;
          this.orgModelUpdatedControlPanel.next(this.orgmodel);
        });
    }
    else
    {
      return null;
    }
  }

  public insertUserInfo(userModel:any, number:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ userModel:any }>(
          BackendUrl + "/insertUserInfo/",{userModel: userModel}, {headers: headers})
        .subscribe(() => {
          if (number == 0)
          {
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() =>
                  this.router.navigate(["controlpanel/organisations"], { queryParams: { id: -3 } })
              );
          }
          else
          {
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() =>
                  this.router.navigate(["settings"])
              );
          }
        });
      }
  }

  public getOrgInfo(orgID:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      return this.http
        .get<{ message: string; orginfo: any }>(
          BackendUrl + "/orgInfo/" + orgID + "", {headers: headers}
        )
        .subscribe((orgInfoData) => {
          this.orginfo = orgInfoData.orginfo;
          this.orgInfoUpdated.next(this.orginfo);
        });
    }
    else
    {
      return null;
    }
  }

  // Subscription table in control-panel
  public getSubscriptionValues() {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ subscriptionvalues:any }>(BackendUrl + "/getSubscriptionValues/", {headers: headers})
        .subscribe((subscriptionvalues) => {
          this.subscriptionValues = subscriptionvalues;
          this.subscriptionValuesUpdated.next(this.subscriptionValues);
        });
      }
  }

  public insertDepartment(departmentModel:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<any>(BackendUrl + "/insertDepartment/",
          {departmentModel: departmentModel}, {headers: headers})
        .subscribe((values) => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(["/settings"])
          );
        });
      }
  }

  public updateDepartment(departmentModel:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ message: string }>(
          BackendUrl + "/updatedepartment/",{departmentModel}, {headers: headers})
        .subscribe(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigate(["settings"])
            );
        });
      }
  }

  public updateUser(user:any, usersubscr:any, userId:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ user:any; usersubscr:any; userid:any }>(
          BackendUrl + "/updateUser/" + userId,
          {
            user: user,
            usersubscr: usersubscr,
            userId: userId,
          }, {headers: headers}
        )
        .subscribe((values) => {
          this.getOrganisationSchema(values.usersubscr);
          this.orgModelListener()
            .pipe(take(1))
            .subscribe((orgmodel: Orgmodel) => {
              this.orgmodel = orgmodel;
              this.router
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() =>
                  this.router.navigate(["controlpanel/organisations"])
                );
            });
        });
      }
  }

  public updateUserInfo(userModel:any, id:any, orgId: any, number: any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ userModel:any }>(
          BackendUrl + "/updateUserInfo/" + id,{userModel: userModel,}, {headers: headers})
        .subscribe(() => {
          if (number == 0)
          {
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() =>
                this.router.navigate(["controlpanel/organisations/"+orgId])
              );
          }
          else
          {
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() =>
                this.router.navigate(["settings"])
              );
          }
        });
      }
  }

  public removeDepartment(id: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete<{ message: string }>(BackendUrl + "/removeDepartment/" + id + "", {headers: headers})
        .subscribe(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigate(["settings"])
            );
        });
      }
  }

  public deleteSettingsUser(id: number, orgId: any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete<{ message: string }>(BackendUrl + "/deleteUser/" + id + "", {headers: headers})
        .subscribe(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigate(["settings"])
            );
        });
      }
  }

  public deleteUser(id: number, orgId: any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete<{ message: string }>(BackendUrl + "/deleteUser/" + id + "", {headers: headers})
        .subscribe(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigate(["controlpanel/organisations/" + orgId])
            );
        });
      }
  }

  public deleteNews(id: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete<{ message: string }>(BackendUrl + "/deleteNews/" + id + "", {headers: headers})
        .subscribe(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigate(["controlpanel/news"])
            );
        });
      }
  }

  public updateDepartments(departments:any, page:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ departmentModel:any }>(
          BackendUrl + "/updateDepartments/",{departments}, {headers: headers})
          .pipe(delay(500)) // The delay is there to make sure the request is done
          .subscribe(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })

            .then(() =>
            {
              if(page=="setting"){
                  this.router.navigate(["settings"], { queryParams: { id: 2 } })
              } else {
                this.router.navigate(["control-panel"], { queryParams: { id: -4 } })
              }
            }
            );
        });
      }
  }

  public deleteOrganisation(id: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete<{ message: string }>(BackendUrl + "/deleteOrganisation/" + id + "", {headers: headers})
        .subscribe(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigate(["controlpanel/organisations"])
            );
        });
      }
  }

   // ---- workplace ----
   public addWorkplace(workplacerounds: any, rounds: any, user: number, localOrgId: number, orgId: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ message: string; id: number }>(BackendUrl + "/addWorkplace", {
          workplacerounds,
          rounds,
          user,
          localOrgId,
          orgId,
        }, {headers: headers})
        .subscribe((values) => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(["/workplace"], {
              queryParams: { id: values.id },
            })
          );
        });
      }
  }

  // This is for workplace
  public addWorkplaceRound(round: any, user: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ message: string }>(BackendUrl + "/addWorkplaceRound", {
          round,
          user,
        }, {headers: headers})
        .subscribe((values) => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(['workplaceitem/' + round[0].Id])
          );
        });
      }
  }

  public getWorkplaceValues(type:any, numbers:any, workplaceroundId:any) {
    // Probably possible to write better but this should work
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

        if (type == "all") {
          this.http
            .get<{ message: string; workplaceroundvalues: any }>(BackendUrl + "/getWorkplace/", {headers: headers})
            .subscribe((values) => {
              this.workplaceroundvalues_table = values.workplaceroundvalues;
              this.workplaceRoundModelUpdated_table.next(this.workplaceroundvalues_table);
            });
        } else {
          let orgid = this.authService.getOrgid();
          let localOrgid = this.authService.getLocalOrgid();
          let httpParams = new HttpParams()
            .set("orgid", orgid)
            .set("localOrgid", localOrgid)
            .set("numbers", numbers)
            .set("workplaceroundId", workplaceroundId);
          this.http
            .get<{ message: string; workplaceroundvalues: any }>(
              BackendUrl + "/getWorkplace/",
              {
                headers: headers,
                params: httpParams,
                responseType: "json",
              }
            )
            .subscribe((values) => {
              this.workplaceroundvalues_table = values.workplaceroundvalues;
              this.workplaceRoundModelUpdated_table.next(this.workplaceroundvalues_table);
            });
        }
    }
  }

  public getWorkplaceItembyId(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; workplacevalues: any }>(
          BackendUrl + "/getWorkplaceItem/" + id, {headers: headers}
        )
        .subscribe((values) => {
          this.workplaceItemModelUpdated_edit.next(values);
        });
      }
  }

  // Not used ?
  public getWorkplaceValuesbyId(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; workplaceroundvalues: any }>(
          BackendUrl + "/getWorkplace/" + id, {headers: headers}
        )
        .subscribe((values) => {
          this.workplaceRoundValues_edit = values.workplaceroundvalues;
          this.workplaceIdModelUpdated_edit.next(this.workplaceRoundValues_edit);
        });
      }
  }

  public getWorkplaceRoundById(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; safetyroundvalues: any }>(
          BackendUrl + "/getWorkplaceRound/" + id, {headers: headers}
        )
        .subscribe((values) => {
          this.roundWorkplaceModel_table.next(values);
        });
      }
  }

  public updateWorkplace(workplaceRoundValues_edit:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ workplaceRoundValues_edit:any }>(
          BackendUrl + "/updateWorkplace/" + workplaceRoundValues_edit.Id,{workplaceround: workplaceRoundValues_edit}, {headers: headers})
        .subscribe(() => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(["/workplaceitem/" + workplaceRoundValues_edit.Id]))
        });
      }
  }

  public updateWorkplaceFile(workplaceRoundValues_edit:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ workplaceRoundValues_edit:any }>(
          BackendUrl + "/updateWorkplace/" + workplaceRoundValues_edit.Id,{workplaceround: workplaceRoundValues_edit}, {headers: headers})
        .subscribe();
        }
  }

  // for workplace
  public updateWorkplaceRound(workplaceRoundValues_edit:any, user: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ workplaceRoundValues_edit:any }>(
          BackendUrl + "/updateWorkplaceRound/" + workplaceRoundValues_edit.roundId,
          {
            round: workplaceRoundValues_edit,
            user,
          }, {headers: headers}
        )
        .subscribe(() => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(['workplaceitem/' + workplaceRoundValues_edit.Id + '/workplaceissueitem/' + workplaceRoundValues_edit.roundId])
        );
      });
    }
  }

  // for workplace
  public updateWorkplaceRoundFile(workplaceRoundValues_edit:any, user: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ workplaceRoundValues_edit:any }>(
          BackendUrl + "/updateWorkplaceRound/" + workplaceRoundValues_edit.roundId,
          {
            round: workplaceRoundValues_edit,
            user,
          }, {headers: headers}
        )
        .subscribe();
        }
  }

  public deleteWorkplace(id: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete(BackendUrl + "/deleteWorkplace/" + id + "", {headers: headers})
        .subscribe(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate(["workplace"]));
        });
      }
  }

  // For workplace
  public deleteWorkplaceRound(id: number, workplaceroundid: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete<{ message: string }>(BackendUrl + "/deleteWorkplaceRound/" + id + "", {headers: headers})
        .subscribe(() => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(["/workplaceitem/" + workplaceroundid])
          );
        });
      }
  }

  // ---- safetyrounds ----
  public addSafetyRounds(safetyrounds: any, rounds: any, user: number, localOrgId: number, orgId: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ message: string; id: number }>(BackendUrl + "/addSafetyRounds", {
          safetyrounds,
          rounds,
          user,
          localOrgId,
          orgId,
        }, {headers: headers})
        .subscribe((values) => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(["/safetyround"], {
              queryParams: { id: values.id },
            })
          );
        });
      }
  }

  // This is for SafetyRounds
  public addRound(round: any, user: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ message: string }>(BackendUrl + "/addRound", {
          round,
          user,
        }, {headers: headers})
        .subscribe((values) => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(['safetyrounditem/' + round[0].Id])
          );
        });
      }
  }

  public getSafetyRoundValues(type:any, numbers:any, safetyroundId:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});

      // Probably possible to write better but this should work
      if (type == "all") {
        this.http
          .get<{ message: string; safetyroundvalues: any }>(
            BackendUrl + "/getSafetyRound/", {headers: headers}
          )
          .subscribe((values) => {
            this.safetyRoundModelUpdated_table.next(values);
          });
      } else {
        let orgid = this.authService.getOrgid();
        let localOrgid = this.authService.getLocalOrgid();
        let httpParams = new HttpParams()
          .set("orgid", orgid)
          .set("localOrgid", localOrgid)
          .set("numbers", numbers)
          .set("safetyroundId", safetyroundId);
        this.http
          .get<{ message: string; safetyroundvalues: any }>(
            BackendUrl + "/getSafetyRound/",
            {
              headers: headers,
              params: httpParams,
              responseType: "json",
            }
          )
          .subscribe((values) => {
            this.safetyRoundModelUpdated_table.next(values);
          });
      }
    }
  }

  public getRoundById(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; safetyroundvalues: any }>(
          BackendUrl + "/getRound/" + id, {headers: headers}
        )
        .subscribe((values) => {
          this.roundModel_table.next(values);
        });
      }
  }

  public getSafetyRoundValuesbyId(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; safetyroundvalues: any }>(
          BackendUrl + "/getSafetyRound/" + id, {headers: headers}
        )
        .subscribe((values) => {
          this.safetyRoundModelUpdated_edit.next(values);
        });
      }
  }

  public getSafetyItembyId(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; safetyroundvalues: any }>(
          BackendUrl + "/getSafetyItem/" + id, {headers: headers}
        )
        .subscribe((values) => {
          this.safetyItemModelUpdated_edit.next(values);
        });
      }
  }

  public updateSafetyRound(safetyRoundValues_edit:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ safetyRoundValues_edit:any }>(
          BackendUrl + "/updateSafetyRound/" + safetyRoundValues_edit.Id,{safetyround: safetyRoundValues_edit}, {headers: headers})
        .subscribe(() => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(['safetyrounditem/' + safetyRoundValues_edit.Id])
          );
        });
      }
  }

  public updateSafetyRoundFile(safetyRoundValues_edit:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ safetyRoundValues_edit:any }>(
          BackendUrl + "/updateSafetyRound/" + safetyRoundValues_edit.Id,{safetyround: safetyRoundValues_edit}, {headers: headers})
        .subscribe(() => {});
        }
  }

  // for safetyround
  public updateround(safetyRoundValues_edit:any, user: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ safetyRoundValues_edit:any }>(
          BackendUrl + "/updateRound/" + safetyRoundValues_edit.roundId,
          {
            round: safetyRoundValues_edit,
            user,
          }, {headers: headers}
        )
        .subscribe(() => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(['safetyrounditem/' + safetyRoundValues_edit.Id + '/safetyroundissueitem/' + safetyRoundValues_edit.roundId])
          );
        });
      }
  }

  // for safetyround
  public updateroundFile(safetyRoundValues_edit:any, user: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ safetyRoundValues_edit:any }>(
          BackendUrl + "/updateRound/" + safetyRoundValues_edit.roundId,
          {
            round: safetyRoundValues_edit,
            user,
          }, {headers: headers}
        )
        .subscribe();
        }
  }

  public deleteSafetyRound(id: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete(BackendUrl + "/deleteSafetyRound/" + id + "", {headers: headers})
        .subscribe(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate(["safetyround"]));
        });
      }
  }

  // For safetyround
  public deleteRound(id: number, safetyroundid: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete<{ message: string }>(BackendUrl + "/deleteRound/" + id + "", {headers: headers})
        .subscribe(() => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(["/safetyround"], {
              queryParams: { id: safetyroundid },
            })
          );
        });
      }
  }

  // Module riskevaluation, used by safetyrounds
  public addRiskValues(id:any, riskValues:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ message: string }>(BackendUrl + "/addRiskValues", { riskValues }, {headers: headers})
        .subscribe((values) => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigate(["/safetyround"], { queryParams: { id: id } })
            );
        });
      }
  }

  // Categories, used by safetyrounds
  public getCategoryList(orgID:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      return this.http
        .get<{ categoryList:any }>(BackendUrl + "/getCategoryList/" + orgID + "", {headers: headers})
        .subscribe((values) => {
          this.categoryList = values.categoryList;
          this.categoryListUpdated.next(this.categoryList);
        });
      }
      else
      {
        return null;
      }
  }

  // ---- Incidents ----
  public addIncident(
    incident: any,
    rounds: any,
    user: number,
    localOrgId:any,
    orgId:any
  ) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ message: string; id: number }>(BackendUrl + "/addIncident", {
          incident,
          rounds,
          user,
          localOrgId,
          orgId,
        }, {headers: headers})
        .subscribe((values) => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(["/incident"], {
              queryParams: { id: values.id },
            })
          );
        });
      }
  }

  public getIncidentRoundValuesbyId(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; safetyroundvalues: any }>(
          BackendUrl + "/getIncidentRounds/" + id, {headers: headers}
        )
        .subscribe((values) => {
          this.incidentRoundModelUpdated_edit.next(values);
        });
      }
  }

  public getIncidentRoundValuebyId(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; safetyroundvalues: any }>(
          BackendUrl + "/getIncidentRound/" + id, {headers: headers}
        )
        .subscribe((values) => {
          this.incidentRoundModelUpdated_edit.next(values);
        });
      }
  }

  public addIncidentRound(incidentRound: any, user: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ message: string }>(BackendUrl + "/addIncidentRound", {
          incidentRound,
          user,
        }, {headers: headers})
        .subscribe((values) => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(['incidentitem/' + incidentRound[0].incidentId], { queryParams: { id: incidentRound[0].incidentId } })
          );
        });
      }
  }

  public getIncidentValues(type:any, numbers:any, thisuser:any) {
    if (thisuser != "") {
      var user = thisuser;
    } else {
      var user = null;
    }
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      if (type == "all") {
        this.http
          .get<{ message: string; incidentRoundValues: IncidentModel[] }>(
            BackendUrl + "/getIncident/", {headers: headers}
          )
          .subscribe((values) => {
            this.incidentValues_table = values.incidentRoundValues;
            this.incidentModelUpdated_table.next(this.incidentValues_table);
          });
      } else {
        let orgid = this.authService.getOrgid();
        let localOrgid = this.authService.getLocalOrgid();
        let httpParams = new HttpParams()
          .set("orgid", orgid)
          .set("localOrgid", localOrgid)
          .set("numbers", numbers)
          .set("user", user);
        this.http
          .get<{ message: string; incidentRoundValues: any }>(
            BackendUrl + "/getIncident/",
            {
              headers: headers,
              params: httpParams,
              responseType: "json",
            }
          )
          .subscribe((values) => {
            this.incidentValues_table = values.incidentRoundValues;
            this.incidentModelUpdated_table.next(this.incidentValues_table);
          });
      }
    }
  }

  public updateIncident(incidentValues_edit:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ incidentValues_edit:any }>(
          BackendUrl + "/updateIncident/" + incidentValues_edit.Id,{incident: incidentValues_edit}, {headers: headers})
          .subscribe((values) => {
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() =>

                this.router.navigate(["/incidentitem/"+incidentValues_edit.Id])
              );
          });
        //.subscribe();
    }
  }

  // When I delete a file I dont want to change screen
  public updateIncidentFile(incidentValues_edit:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ incidentValues_edit:any }>(
          BackendUrl + "/updateIncident/" + incidentValues_edit.Id,{incident: incidentValues_edit}, {headers: headers})
          .subscribe((values) => {});
        //.subscribe();
        }
  }

  public updateIncidentRoundFile(incident:any, user:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ incident:any }>(
          BackendUrl + "/updateIncidentRoundFile/" + incident.roundId,
          {
            round: incident,
            user,
          }, {headers: headers}
        )
        .subscribe();
    }
  }

  public updateIncidentRound(incident:any, user:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .put<{ incident:any }>(
          BackendUrl + "/updateIncidentRound/" + incident.roundId,
          {
            round: incident,
            user,
          }, {headers: headers}
        )
        .subscribe((values) => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>

              this.router.navigate(['incidentitem/' + incident.incidentId + '/incidentissueitem/' + incident.roundId], { queryParams: { id: incident.roundId } })
            );
        });
      }
  }

  public getIncidentItembyId(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; safetyroundvalues: any }>(
          BackendUrl + "/getIncidentItem/" + id, {headers: headers}
        )
        .subscribe((values) => {
          this.incidentItemModelUpdated_edit.next(values);
        });
      }
  }

  public deleteIncident(id: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete(BackendUrl + "/deleteIncident/" + id + "", {headers: headers})
        .subscribe(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate(["incident"]));
        });
      }
  }

  public deleteIncidentRound(id: number, incidentroundid: number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .delete<{ message: string }>(
          BackendUrl + "/deleteIncidentRound/" + id + "", {headers: headers}
        )
        .subscribe(() => {
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
            this.router.navigate(["/incident"], {
              queryParams: { id: incidentroundid },
            })
          );
        });
      }
  }

  // Module riskevaluation, used by incidents
  public addIncidentRiskValues(id:any, riskValues:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .post<{ message: string }>(BackendUrl + "/addIncidentRiskValues", {
          riskValues,
        }, {headers: headers})
        .subscribe((values) => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigate(["/incidentitem/"+id])
            );
        });
      }
  }

  // Events, used by incidents
  // For the model
  public getEventList(orgID:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      return this.http
        .get<{ eventList:any }>(BackendUrl + "/getEventList/" + orgID + "", {headers: headers})
        .subscribe((values) => {
          this.eventList = values.eventList;
          this.eventListUpdated.next(this.eventList);
        });
      }
      else
      {
        return null;
      }
  }

  // ---- Settings ----
  // Categories under safetyround
  public changeCategory(action:any, item:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      if (action == "add") {
        this.http
          .post<{}>(BackendUrl + "/addCategory/", {
            name: item,
            uid: this.authService.getOrgid(),
          }, {headers: headers})
          .subscribe(() => {
          });
      }
      if (action == "change") {
        this.http
          .put<{}>(BackendUrl + "/changeCategory/", { item }, {headers: headers})
          .subscribe(() => {
          });
      }
      if (action == "delete") {
        this.http
          .delete(BackendUrl + "/deleteCategory/" + item.id + "", {headers: headers})
          .subscribe(() => {
          });
      }
    }
  }

  // Events under incidents
  public changeEvent(action:any, item:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      if (action == "add") {
        this.http
          .post<{}>(BackendUrl + "/addEvent/", {
            name: item,
            uid: this.authService.getOrgid(),
          }, {headers: headers})
          .subscribe(() => {
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() =>
                this.router.navigate(["/settings"], {
                  queryParams: { id: 3 },
                })
              );
          });
      }
      if (action == "change") {
        this.http
          .put<{}>(BackendUrl + "/changeEvent/", { item }, {headers: headers})
          .subscribe(() => {
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() =>
                this.router.navigate(["/settings"], {
                  queryParams: { id: 3 },
                })
              );
          });
      }
      if (action == "delete") {
        this.http
          .delete(BackendUrl + "/deleteEvent/" + item.id + "", {headers: headers})
          .subscribe(() => {
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() =>
                this.router.navigate(["/settings"], {
                  queryParams: { id: 3 },
                })
              );
          });
      }
    }
  }

  // ---- Shared by most components ----
  // used to get data that is needed for all
  // This is for the organisation model array
  public getOrganisationSchema(orgID:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      return this.http
        .get<{ message: string; orgmodel: Orgmodel }>(
          BackendUrl + "/orgSchema/" + orgID + "", {headers: headers}
        )
        .subscribe((orgModelData) => {
          this.orgmodel = orgModelData.orgmodel;
          this.orgModelUpdated.next(this.orgmodel);
          this.orgModelUpdated.next(this.orgmodel);
        });
      }
      else
      {
        return null;
      }
  }

  // This is for the user model array
  public getUserInfo(userId:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      return this.http
        .get<{ message: string; usermodel: any }>(
          BackendUrl + "/userInfo/" + userId + "", {headers: headers}
        )
        .subscribe((usermodelData) => {
          this.usermodel = usermodelData.usermodel;
          this.userModelUpdated.next(this.usermodel);
        });
      }
      else
      {
        return null;
      }
  }

  // Get one user model
  public getUsers(i: number, j:number) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ message: string; users: any }>(BackendUrl + "/getUser/" + i +'/'+j, {headers: headers})
        .subscribe((usermodelData) => {
          this.users = usermodelData.users;
          this.usersUpdated.next(this.users);
        });
      }
  }

  // For the subscription model array, allowed to do stuff
  public getSubscriptions(id:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get<{ subscriptions:any }>(BackendUrl + "/getSubscriptions/" + id, {headers: headers})
        .subscribe((values) => {
          this.subscriptionsUpdated.next(values.subscriptions);
        });
      }
  }

  public getFileNames(concanatedFilenames:any){
    let httpParams = new HttpParams()
    .set("concanatedFilenames",concanatedFilenames);

    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      this.http
        .get(BackendUrl + "/getfilenames/", {
          headers: headers,
          params: httpParams,
          responseType: "json",
        })
        .subscribe((values) => {
          // this.filenamevalues = values;
          // this.filenamevaluesUpdated.next(this.filenamevalues);
        });
      }
  }

  fileInfo(folder:any,filename:any):Observable<any> {
    let token = localStorage.getItem('token');
    let httpHeaders = new HttpHeaders().set("Accept", "application/json");

    if (token != null)
    {
      httpHeaders = new HttpHeaders({'Accept':'application/json','Authorization':token});
    }

    let httpParams = new HttpParams().set("folder",folder).set("filename",filename);
    return this.http
      .get(BackendUrl + "/fileinfo/",{
        headers: httpHeaders,
        params: httpParams,
        responseType: "json"});
  }

  // Mail -- Shared
  public sendmail(mailReciever:any, mailMessage:any, mailSubject:any, mailRond:any, attachmentfile:any, path:any) {
    let token = localStorage.getItem('token');

    if (token != null)
    {
      const headers = new HttpHeaders({'Accept':'application/json','Authorization':token});
      let httpParams = new HttpParams()
        .set("mailReciever", mailReciever)
        .set("mailSubject", mailSubject)
        .set("mailMessage", mailMessage)
        .set("mailRond", mailRond)
        .set("attachmentfile",attachmentfile)
        .set("path", path);
      this.http
        .get<{}>(BackendUrl + "/sendmail/", {
          headers: headers,
          params: httpParams,
          responseType: "json",
        })
        .subscribe((values) => {});
      }
  }

  // ---- Listeners ----
  // Needed for the Subjects to work
  public getUserModelListener() {
    return this.userModelUpdated.asObservable();
  }

  public orgModelListener() {
    return this.orgModelUpdated.asObservable();
  }

  public orgModelListenerControlPanel() {
    return this.orgModelUpdatedControlPanel.asObservable();
  }

  public orgInfoListener() {
    return this.orgInfoUpdated.asObservable();
  }

  public usersListener() {
    return this.usersUpdated.asObservable();
  }

  public usersListener2() {
    return this.usersUpdated2.asObservable();
  }

  public safetyRoundListener() {
    return this.safetyRoundModelUpdated_table.asObservable();
  }

  public incidentListener() {
    return this.incidentModelUpdated_table.asObservable();
  }

  public workplaceRoundListener() {
    return this.workplaceRoundModelUpdated_table.asObservable();
  }

  public workplaceItemModelUpdatedEditListener() {
    return this.workplaceItemModelUpdated_edit.asObservable();
  }

  public workplaceIdModelUpdatedEditListener() {
    return this.workplaceIdModelUpdated_edit.asObservable();
  }

  public actionplanListener() {
    return this.actionPlanUpdated_table.asObservable();
  }

  public organisationListener() {
    return this.organisationsUpdated.asObservable();
  }

  public getManagementsystemListener() {
    return this.managementSystemModelUpdated.asObservable();
  }

  public getInformationListener() {
    return this.informationModelUpdated.asObservable();
  }

  public getEventListListener() {
    return this.eventListUpdated.asObservable();
  }

  public getCategoryListListener() {
    return this.categoryListUpdated.asObservable();
  }

  public tokenUpdateListener() {
    return this.tokenUpdate.asObservable();
  }

  public getmainorgListener() {
    return this.mainorgUpdated.asObservable();
  }

  public subscriptionsListener() {
    return this.subscriptionsUpdated.asObservable();
  }

  public subscriptionValuesListener() {
    return this.subscriptionValuesUpdated.asObservable();
  }

  public fileInfoListener() {
    return this.fileInfoUpdated.asObservable();
  }

  public getStatisticalDataListener() {
    return this.getStatisticalDataUpdated.asObservable();
  }

  public safetyRoundModelUpdatedEditListener() {
    return this.safetyRoundModelUpdated_edit.asObservable();
  }

  public safetyItemModelUpdatedEditListener() {
    return this.safetyItemModelUpdated_edit.asObservable();
  }

  public roundModelTableListener() {
    return this.roundModel_table.asObservable();
  }

  public roundWorkplaceModelTableListener() {
    return this.roundWorkplaceModel_table.asObservable();
  }

  public incidentItemModelUpdatedEditListener() {
    return this.incidentItemModelUpdated_edit.asObservable();
  }

  public incidentRoundModelUpdatedEditListener() {
    return this.incidentRoundModelUpdated_edit.asObservable();
  }

  // Moving PDF part to another service
}


