// Takes care of the filtering, for now I just filter on Title and doneDate
// if it is safetyrounds and the fieldname if it is anything else.
import { Pipe, PipeTransform, Injectable } from '@angular/core';
@Pipe({
  name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
  transform(items: any[], field: string, value: string): any[] {
    if (!items) {
      return [];
    }
    if (!field || !value) {
      return items;
    }
    if(field=='safetyround'||field=='tools'){
      var h=new Array('Title','Supervisor','doneDate');
    }
    //if(field=='navigation'){
    //  var h=new Array('name');
    //}
    if(field=='tools'){
      var h=new Array('roundResponsible2','roundDoneDate');
    }
    if(field=='dashboard'){
      var h=new Array('roundDoneDate','roundMeasure');
    }
    if(field=='incident'){
      var h=new Array('Date','Title');
    }
    if(field=='users'){
      var h=new Array('username','email');
    }
    if(field=='safetyround'||field=='users'||field=='incident'||field=='tools'||field=='dashboard'){
      return items.filter(singleItem =>
        singleItem[h[0]].toLowerCase().includes(value.toLowerCase())||
        singleItem[h[1]].toLowerCase().includes(value.toLowerCase()))
      }else{
      return items.filter(singleItem =>
        singleItem[field].toLowerCase().includes(value.toLowerCase())
      )
    }
  }
}
