import { Component, OnInit } from '@angular/core';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { ActivatedRoute, Router } from '@angular/router';
import { take } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-incident-edit',
  templateUrl: './incident-edit.component.html',
  styleUrls: ['./incident-edit.component.css']
})
export class IncidentEditComponent implements OnInit {

  BackendUrl = environment.BackendUrl;
  UploadUrl = environment.UploadUrl;
  URL = this.BackendUrl + "/file";

  selectedFile: any = [];
  incidentid: any;
  userId: any;
  orgId: any;
  localOrgId: any;
  orgmodel: any;
  incidentitem: any;
  filetypeinfo: any;
  fileNames: any = [];
  srcImageName: any;
  subscriptions: any = {};
  is_admin: any;
  is_super_user: any;
  incidentValues: any = [];
  displayAlert: boolean;
  incidentPlace: any;
  incidentTitle: any;
  incidentDescription: any;
  incidentCause: any;
  incidentReporter: any;
  incidentDate: any;
  eventList: any;
  categoryList: any;
  files: any = [];
  item: any = [];
  filenames: any = [];
  file: any;
  filesExisting: any;
  uploadFileDone: boolean = false;
  userInfo: any;
  itemArray: any;
  doNotShowSubDepartment: any;
  oldid: any;
  incidentOrgId: any;

  constructor(private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private route: ActivatedRoute,
    private uploadService: FileUploadService,
    private translate: TranslateService) { }

  ngAfterViewInit(): void {
    $("#incidentResponsibleChose").dropdown();
    $("#depnameChose").dropdown();
    $("#incidenttypechose").dropdown();
    $("#injurytypechose").dropdown();
  }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.incidentid = params['id'];
    });

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
    this.displayAlert=false;

    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.orgId = this.authService.getOrgid();

    this.dataService.getEventList(this.orgId);
    this.dataService.getEventListListener().subscribe((eventList:[]) => {
      this.eventList = eventList;
    });

    this.dataService.getCategoryList(this.orgId);
    this.dataService.getCategoryListListener().subscribe((categoryList:[]) => {
      this.categoryList = categoryList;
    });

    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService
      .orgModelListener()
      .pipe(take(1))
      .subscribe((orgmodel: any) => {
        this.orgmodel = orgmodel;
        if (this.doNotShowSubDepartment == true) {
          this.itemArray = null;
        } else {
          this.itemArray = this.displayTreeItem(this.localOrgId);
        }
    });
    
    var file = "";
    this.filesExisting = 0;
    this.dataService.getIncidentItembyId(this.incidentid);
    this.dataService
      .incidentItemModelUpdatedEditListener()
      .pipe(take(1))
      .subscribe((incident_item: any) => {
        this.incidentitem = incident_item.incidentitemvalue[0];
        if ((this.incidentitem.File != null) && (this.incidentitem.File != ''))
        {
          for (var f = 0;f < this.incidentitem.File.split(",").length;f++) {
            if (this.incidentitem.File != "") {
              file += "|" + this.incidentitem.File.split(",")[f];
              this.filesExisting += 1;
            }
          }
          this.getImage(file,'');

          if (this.incidentitem.File.length > 1)
          {
            this.files = this.incidentitem.File.split(",");
          }

          this.incidentitem.File = this.incidentitem.File;
        }
    });

    this.subscriptions = this.dataService.getSubscriptions(
      this.authService.getLocalOrgid()
    );
    this.dataService.subscriptionsListener().subscribe((subscriptions) => {
      this.subscriptions = subscriptions[0];
    });

    this.dataService.getUsers(this.authService.getOrgid(), this.userId);
    this.dataService.usersListener().subscribe((users) => {
      this.userInfo = users;
    });

    this.msgService.sendMessage(null);
  }

  // A function for the filetype
  getImage(file:any,entrance:any) {
    this.dataService
      .fileInfo(environment.catalogue, file)
      .subscribe((response) => {
        this.dataService.fileInfoUpdated.next(response);
      });
    this.dataService
      .fileInfoListener()
      .pipe(take(1))
      .subscribe((response) => {

      if (typeof response != "undefined") {
          this.filetypeinfo=response;
          for (var i = 0; i < this.filetypeinfo.length; i++)
          {
            this.fileNames.push(this.filetypeinfo[i].File);
          }
      }
    });
  }

  displayTreeItem(id:any) {
    if (this.orgmodel) {
      if (this.oldid != id) {
        this.oldid = id;
        var count = Object.keys(this.orgmodel).length;
        this.itemArray = new Array();
        for (let nthItem = 0; nthItem < count; nthItem++) {
          if (
            this.orgmodel[nthItem].Parent == id ||
            (this.itemArray &&
              this.itemArray.indexOf(+this.orgmodel[nthItem].Parent) > -1)
          ) {
            this.itemArray.push(this.orgmodel[nthItem].Id);
          }
        }
      } else {
        this.oldid = null;
        this.itemArray = [];
      }
      return this.itemArray;
    }
  }

  removeImage(file: any, index: any) {
    this.file = "";

    this.filetypeinfo.splice(index, 1);
    this.fileNames.splice(index, 1);

    for (var i = 0; i < this.fileNames.length; i++)
    {
      if (i != 0 || (i == 0 && this.file != "")) {
        this.file += ",";
      }
      this.file += this.fileNames[i];
    }

    this.incidentitem.File = this.file;
  }

  deleteItem() {
    var tmpString = this.translate.instant('DO_YOU_WANT_TO_REMOVE')
    var deleteQuestion = confirm(tmpString);
    if (deleteQuestion == true) {
      this.dataService.deleteIncident(this.incidentitem.Id);
    } 
  }

  setIncidentType(type:any) {
    this.incidentitem.typeofaction = type.name;
  }

  setCategoryType(type:any) {
    this.incidentitem.category = type.name;
  }

  setRoundResponsible(userInfo:any) {
    this.incidentitem.Reporter = userInfo.username;
  }

  setDepartment(department: any) {
    this.incidentitem.Department = department.Name + "|" + department.Id;
  }

  imageUpload(event:any) {
    this.uploadFileDone = true,
    this.uploadService.upload(event.target.files[0], "");
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {
      if (filename == null)
      {
        Swal.fire("Error", "Error");
      }
      else
      {
        this.files.push(filename);
        setTimeout(() => {
          this.uploadFileDone = false;

          this.selectedFile.push(event.target.files[0]);
    
          if (this.files) {
            this.filenames.push(event.target.files[0].name);
          } else {
            this.filenames = event.target.files[0].name;
          }
          this.item.push(1);
        }, 1000);
      }
    });
  }

  updateItem() {
    if(!this.filesExisting){this.filesExisting=0;}
    this.file = this.incidentitem.File;
    if (this.file != null)
    {
      for (var i = 0; i < this.selectedFile.length; i++) {
        if (this.selectedFile[i]) {
          if (i != 0 || (i == 0 && this.file != "")) {
            this.file += ",";
          }
          this.file += this.files[i + this.filesExisting];
        }
      }

      this.incidentitem.File = this.file;
    }

    this.dataService.updateIncident(this.incidentitem);
  }

  goBack() {
    this.routing.navigate(['/incidentitem/'+this.incidentid]);
  }
}
