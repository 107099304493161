import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from           '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { SortPipe } from './shared/pipes/sort.pipe';
import { FilterPipe} from './shared/pipes/filter.pipe';
import { FileUploadModule } from "ng2-file-upload";
import { DataTablesModule } from 'angular-datatables';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ManagementSystemComponent } from './management-system/management-system.component';
import { ModalsSelectOrganisationComponent } from './modals/modals-select-organisation/modals-select-organisation.component';
import { SafetyRoundComponent } from './safetyround/safetyround.component';
import { ModalAddFileComponent } from './modals/modal-add-file/modal-add-file.component';
import { SafetyroundAddComponent } from './safetyround/safetyround-add/safetyround-add.component';
import { SafetyroundEditComponent } from './safetyround/safetyround-edit/safetyround-edit.component';
import { SafetyroundItemComponent } from './safetyround/safetyround-item/safetyround-item.component';
import { ModalSendEmailComponent } from './modals/modal-send-email/modal-send-email.component';
import { SafetyroundissueItemComponent } from './safetyround/safetyroundissue/safetyroundissue-item/safetyroundissue-item.component';
import { SafetyroundissueEditComponent } from './safetyround/safetyroundissue/safetyroundissue-edit/safetyroundissue-edit.component';
import { SafetyroundissueEditFiveComponent } from './safetyround/safetyroundissue/safetyroundissue-edit-five/safetyroundissue-edit-five.component';
import { SafetyroundissueAddComponent } from './safetyround/safetyroundissue/safetyroundissue-add/safetyroundissue-add.component';
import { WorkplaceComponent } from './workplace/workplace.component';
import { WorkplaceAddComponent } from './workplace/workplace-add/workplace-add.component';
import { WorkplaceItemComponent } from './workplace/workplace-item/workplace-item.component';
import { ModalSendEmailWorkplaceComponent } from './modals/modal-send-email-workplace/modal-send-email-workplace.component';
import { WorkplaceEditComponent } from './workplace/workplace-edit/workplace-edit.component';
import { WorkplaceissueAddComponent } from './workplace/workplaceissue/workplaceissue-add/workplaceissue-add.component';
import { WorkplaceissueEditComponent } from './workplace/workplaceissue/workplaceissue-edit/workplaceissue-edit.component';
import { WorkplaceissueItemComponent } from './workplace/workplaceissue/workplaceissue-item/workplaceissue-item.component';
import { IncidentComponent } from './incident/incident.component';
import { IncidentAddComponent } from './incident/incident-add/incident-add.component';
import { IncidentItemComponent } from './incident/incident-item/incident-item.component';
import { ModalSendEmailIncidentComponent } from './modals/modal-send-email-incident/modal-send-email-incident.component';
import { IncidentissueAddComponent } from './incident/incidentissue/incidentissue-add/incidentissue-add.component';
import { IncidentissueItemComponent } from './incident/incidentissue/incidentissue-item/incidentissue-item.component';
import { IncidentissueEditComponent } from './incident/incidentissue/incidentissue-edit/incidentissue-edit.component';
import { IncidentissueEditFiveComponent } from './incident/incidentissue/incidentissue-edit-five/incidentissue-edit-five.component';
import { ActionPlanComponent } from './action-plan/action-plan.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { OrganisationsComponent } from './control-panel/organisations/organisations.component';
import { NewsComponent } from './control-panel/news/news.component';
import { OrgItemsComponent } from './control-panel/organisations/org-items/org-items.component';
import { OrgAddComponent } from './control-panel/organisations/org-add/org-add.component';
import { UserAddComponent } from './control-panel/organisations/user-add/user-add.component';
import { UserEditComponent } from './control-panel/organisations/user-edit/user-edit.component';
import { AddNewsComponent } from './control-panel/news/add-news/add-news.component';
import { EditNewsComponent } from './control-panel/news/edit-news/edit-news.component';
import { HelpComponent } from './settings/help/help.component';
import { SettingsComponent } from './settings/settings.component';
import { ModalEditDepartmentComponent } from './modals/modal-edit-department/modal-edit-department.component';
import { ModalAddDepartmentComponent } from './modals/modal-add-department/modal-add-department.component';
import { IncidentEditComponent } from './incident/incident-edit/incident-edit.component';
import { AddUserComponent } from './settings/add-user/add-user.component';
import { EditUserComponent } from './settings/edit-user/edit-user.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    SignupComponent,
    DashboardComponent,
    FooterComponent,
    NavigationComponent,
    FilterPipe,
    SortPipe,
    ManagementSystemComponent,
    ModalsSelectOrganisationComponent,
    SafetyRoundComponent,
    ModalAddFileComponent,
    SafetyroundAddComponent,
    SafetyroundEditComponent,
    SafetyroundItemComponent,
    ModalSendEmailComponent,
    SafetyroundissueItemComponent,
    SafetyroundissueEditComponent,
    SafetyroundissueEditFiveComponent,
    SafetyroundissueAddComponent,
    WorkplaceComponent,
    WorkplaceAddComponent,
    WorkplaceItemComponent,
    ModalSendEmailWorkplaceComponent,
    WorkplaceEditComponent,
    WorkplaceissueAddComponent,
    WorkplaceissueEditComponent,
    WorkplaceissueItemComponent,
    IncidentComponent,
    IncidentAddComponent,
    IncidentItemComponent,
    ModalSendEmailIncidentComponent,
    IncidentissueAddComponent,
    IncidentissueItemComponent,
    IncidentissueEditComponent,
    IncidentissueEditFiveComponent,
    ActionPlanComponent,
    ControlPanelComponent,
    OrganisationsComponent,
    NewsComponent,
    OrgItemsComponent,
    OrgAddComponent,
    UserAddComponent,
    UserEditComponent,
    AddNewsComponent,
    EditNewsComponent,
    HelpComponent,
    SettingsComponent,
    ModalEditDepartmentComponent,
    ModalAddDepartmentComponent,
    IncidentEditComponent,
    AddUserComponent,
    EditUserComponent
  ],
  imports: [
    BrowserModule,
    FileUploadModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    DataTablesModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'sv',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    TranslateModule
  ],
  providers:[DatePipe],
  bootstrap: [AppComponent],
  schemas:
        [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
