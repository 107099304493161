import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuthData } from '../auth-data.model';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  name:any = "";
  pwd:any = "";
  organisation: number=0;
  level: number=0;
  private organisationsValueSub:any = Subscription;
  organisationValues:any = [];
  error:string[]=[];
  message:string="";
  constructor(private authService:AuthService,
    private dataService: DataService) { }

  ngOnInit() {
    this.dataService.getRootOrganisationsValues();
    this.organisationsValueSub = this.dataService
      .organisationListener()
      .pipe(take(1))
      .subscribe((organisationValues: any) => {
        this.organisationValues = organisationValues;
      });
  }

  addNewUser(form: NgForm){
    this.message="";
    this.error.length=0;
    if(!form.valid||form.value.organisation==null||form.value.organisation==0){
      if(form.value.name==null){
        this.error.push("Ange ett Användarnamn");
      }
      if(form.value.pwd==null){
        this.error.push("Ange ett Lösenord");
      }
      if(form.value.organisation==null||form.value.organisation==0){
        this.error.push("Ange ett Företag");
      }
      return;
    }
    let authData:AuthData=(
      {
      'username':form.form.value.name,
      'password':form.form.value.pwd,
      'organisation':form.form.value.organisation,
      'userlevel':form.form.value.level
    });
    this.authService.createUser(authData);
    this.message="Användare tillagd";
    //form.reset();
    this.name="";
    this.pwd="";
    this.organisation=0;
    this.level=0;
  }

  login(form: NgForm){
    if(form.invalid){
      return;
    }
    this.authService.login(form.form.value.name, form.form.value.pwd);
  }
}
