import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthData } from './auth-data.model';
import { environment } from 'src/environments/environment';

const BackendUrl = environment.BackendUrl;

@Injectable({ providedIn: 'root' })
export class AuthService {
  private authStatusListener = new Subject<boolean>();
  private token: any = "";
  private userId: any = "";
  private userName: any = "";
  private orgid: any = "";
  private localOrgid: any = "";
  private isAuthenticated=false;

  constructor(private http: HttpClient) { }

  getToken() {
    return this.token;
  }

  getUserId() {
    this.userId=localStorage.getItem('userId');
    return this.userId;
  }

  getUsername() {
    this.userName=localStorage.getItem('userName');
    return this.userName;
  }

  getOrgid() {
    this.orgid=localStorage.getItem('orgid');
    return this.orgid;
  }

  getLocalOrgid() {
    this.localOrgid=localStorage.getItem('localorgid');
    return this.localOrgid;
  }
  
  getAuth() {
    return this.isAuthenticated;
  }

  getAuthServiceListener() {
    return this.authStatusListener.asObservable();
  }

  getIsAuth() {
    let time: any = 0;
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    time = localStorage.getItem('tokenTime');
    const time_current = Date.now();
    if (time != null)
      time = time - Date.now();

    if(token && userId && time > 0){
      return true;
    }
    return false;
  }

  getSuperUser() {
    var is_super_user=localStorage.getItem('is_super_user');
    // Needed because localstorage uses string
    if(is_super_user=='true'){return true;}
    else{return false;}
  }

  getAdmin() {
    var is_admin=localStorage.getItem('is_admin');
    // Needed because localstorage uses string
    if(is_admin=='true'){return true;}
    else{return false;}
  }

  getShowOrHideSubdepartment(){
    var ShowOrHideSubdepartment=localStorage.getItem('ShowOrHideSubdepartment');
      if(ShowOrHideSubdepartment=='true'){return true;}
      else{return false;}
  }

  setLocalOrgid(id:any) {
    localStorage.setItem('localorgid',id);
  }

  setOrgid(orgid:any) {
    localStorage.setItem('orgid', orgid);
  }

  setToken(token:any) {
    localStorage.setItem('token', token);
  }

  setShowOrHideSubdepartment(value:any){
    localStorage.setItem('ShowOrHideSubdepartment', value);
  }

  setTokenTime() {
    //var date=Date.now()+60*60*24*1000;
    var date=Date.now()+14*24*60*60*1000;
    localStorage.setItem('tokenTime',date.toString());
  }

  setUsername(username:any) {
    localStorage.setItem('userName', username);
  }

  setAdmin(is_admin:any) {
    localStorage.setItem('is_admin', is_admin);
  }

  setSuperUser(is_super_user:any) {
    localStorage.setItem('is_super_user', is_super_user);
  }

  setUserId(userId:any) {
    localStorage.setItem('userId', userId);
  }

    createUser(authData:AuthData) {
    this.http
      .post<{authData:AuthData}>(BackendUrl+"/signup/", authData)
      .subscribe(() => {

      }, error => {

      });
  }

  login(username:any, password:any) {
    this.http
      .post<{ token: string, userId: string,orgid: string, is_admin:boolean, is_super_user: boolean, department:any }>(BackendUrl + "/login/", { username: username, password: password })
      .subscribe(response => {
        const token = response.token;
        this.token = token;
        if (token) {
          this.userId = response.userId;
          this.orgid=response.orgid;
          this.isAuthenticated = true;
          this.saveAuthData(token,this.userId,username,this.orgid, response.is_admin, response.is_super_user, response.department);
          this.authStatusListener.next(true);
          window.location.href = '/';
        }
      }, error => {
        this.authStatusListener.next(false);
      });
  }

  loginToken(token:any) {
    this.http
      .post<{ token: string, userId: string,orgid: string, is_admin:boolean, is_super_user: boolean, department:any }>(BackendUrl + "/loginToken/", { token })
      .subscribe(response => {
        const token = response.token;
        this.token = token;
        if (token) {
          this.userId = response.userId;
          this.orgid=response.orgid;
          this.isAuthenticated = true;
          this.saveAuthData(token,this.userId,token,this.orgid, response.is_admin, response.is_super_user, response.department);
          this.authStatusListener.next(true);
          window.location.href = '/';
        }
      }, error => {
        this.authStatusListener.next(false);
      });
  }

  logout() {
    this.token = null;
    this.userId = null;
    this.authStatusListener.next(false);
    this.isAuthenticated = false;
    this.clearAuthData();
    window.location.href = '/';
  }

  private saveAuthData(token: string, userId: string, username:string, orgid: string, is_admin:any, is_super_user:any, deparment:any) {
    this.setToken(token);
    this.setTokenTime();
    this.setUserId(userId);
    this.setUsername(username);
    this.setAdmin(is_admin);
    this.setSuperUser(is_super_user);
    this.setOrgid(orgid);
    if(deparment!=""){
      this.setLocalOrgid(deparment);
    }else{
      this.setLocalOrgid(orgid);
    }
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('orgid');
  }
}
