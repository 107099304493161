<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <div class="header-text">
                            <h1>{{'workplace'|translate}}</h1>
                        </div>
                    </div>
                    <div class="computer only three wide right aligned column" style="padding: 40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <button class="ui white button">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <form class="ui form" (submit)="add_workplace(postForm)" #postForm="ngForm">
                    <div class="field">
                        <label>{{'responsible'|translate}}</label>
                        <select [(ngModel)]="workplaceRoundResponsible" name="workplaceRoundResponsible">
                            <option disabled selected hidden>{{workplaceRoundResponsible}}</option>   
                            <option *ngFor="let userInfoitem of userInfo;let i=index" [ngValue]="userInfoitem.username">{{userInfoitem.username}}</option>
                        </select>

                        <label>{{'date'| translate }}</label>
                        <input type="date" name="rondDatum" [value]="workplaceRoundDate" [(ngModel)]="workplaceRoundDate" />
                        
                        <label>{{'department'|translate}}</label>
                        <select [(ngModel)]="depname" name="depname" >
                            <option disabled selected hidden>{{depname}}</option>   
                            <option *ngFor="let orgmodelitem of orgmodel;let i=index" [ngValue]="orgmodelitem.Name">{{orgmodelitem.Name}}</option>
                        </select>

                        <label>{{ 'participants' | translate }}</label>
                        <input id="workplaceRoundParticipants" name="workplaceRoundParticipants" [(ngModel)]="workplaceRoundParticipants" required/>

                        <label>{{ 'header' | translate }}</label>
                        <input id="workplaceRoundHeader" name="workplaceRoundHeader" [(ngModel)]="workplaceRoundHeader" required/><br><br>

                        <label>{{ 'description' | translate }}</label>
                        <textarea id="workplaceRoundDescription" name="workplaceRoundDescription" rows="3" [(ngModel)]="workplaceRoundDescription"></textarea><br><br>

                        <input class="ui button" type="file" (change)="imageUpload($event)" placeholder="Upload file" accept="application/msword,
                            application/vnd.ms-excel,
                            application/vnd.ms-powerpoint,
                            text/plain,
                            application/pdf,
                            image/*,
                            video/*"/> 

                        <div [hidden]="!uploadFileDone" class="ui segment">
                            <div class="ui active loader"></div>
                            <p></p>
                        </div><br><br><br>
                        
                        <div class="ui grid item" *ngFor="let file of filenames; let i=index" style="width:100%;margin-left:20px;padding:0px">
                            <div class="row" style="margin:0px;padding:0px">
                                <div class="eight wide column" style="margin:0px;padding:0px">
                                    <i class="ui icon file alternate outline"></i>{{file}}
                                </div>
                                <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                    <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                </div>
                            </div>
                        </div><br>
                        <hr>
                        <button [hidden]="uploadFileDone" style="margin:10px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="saveItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                    </div>
                  </form>
            </div>
        </div>
    </div>
</div>
