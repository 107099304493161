import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modals-select-organisation',
  templateUrl: './modals-select-organisation.component.html',
  styleUrls: ['./modals-select-organisation.component.css']
})
export class ModalsSelectOrganisationComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() mainOrg:any;

  searchText: any;
  subscriptions:any;

  constructor(public activeModal: NgbActiveModal,
              private authService: AuthService, 
              private msgService: MessageService,
              private router: Router,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.searchText = "";
  }

  changethisorganisation(id:any) {
    this.authService.setOrgid(id);
    this.authService.setLocalOrgid(id);
    this.msgService.sendMessageId(id);
    let url = this.router.url;
    this.redirectToUrl(id,url);
    this.passEntry.emit(id);
    this.activeModal.close(ModalsSelectOrganisationComponent);
  }

  redirectTo(url:any) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  redirectToUrl(id:any, url:any) {
    var dashboard = false;
    if(url=="/managementsystem"||url=="/workplace"){
      this.subscriptions=this.dataService.getSubscriptions(id);
      this.dataService.subscriptionsListener()
      .subscribe((subscriptions) => {
        this.subscriptions=subscriptions[0];
        if(url=="/managementsystem"&&this.subscriptions.subscription_managementsystem==0){
          dashboard=true;
        }
        if(url=="/workplace"&&this.subscriptions.subscription_workplace==0){
          dashboard=true;
        }
      });
    }else{
      if (this.router.url == "/") {
        url = url + "dashboard";
      } else if (url.indexOf("safetyround") != -1) {
        url = "safetyround";
      } else {
        url = url;
      }
    }
    if(url=="/managementsystem"||url=="/workplace"){
      // setting up some delay to wait for the subscriptionslistener.
      setTimeout(() => {
        if(dashboard==true){
          alert("Denna organisation/avdelning har inte tillgång till modulen.");
          url="/dashboard";
        }
        this.redirectTo(url);
      }, 500);
    }else{
      this.redirectTo(url);
    }
  }
}
