import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "../../auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take,concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSendEmailIncidentComponent } from 'src/app/modals/modal-send-email-incident/modal-send-email-incident.component';
import { PDFService } from "src/app/shared/services/pdf.service";
import { TranslateService } from '@ngx-translate/core';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-incident-item',
  templateUrl: './incident-item.component.html',
  styleUrls: ['./incident-item.component.css']
})
export class IncidentItemComponent implements OnInit {

  BackendUrl = environment.BackendUrl;
  UploadUrl = environment.UploadUrl;
  URL = this.BackendUrl + "/file";

  incidentid: any;
  userId: any;
  orgId: any;
  localOrgId: any;
  orgmodel: any;
  incidentitem: any;
  filetypeinfo: any;
  fileNames: any = [];
  srcImageName: any;
  subscriptions: any = {};
  is_admin: any;
  is_super_user: any;
  incidentValues: any = [];
  displayAlert: boolean;
  incidentPlace: any;
  incidentTitle: any;
  incidentDescription: any;
  incidentCause: any;
  incidentReporter: any;
  incidentDate: any;

  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private modalService: NgbModal,
    private pdfService: PDFService,
    private translate: TranslateService,
    private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $("#selectIncidentItem").dropdown();
  }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.incidentid = params['id'];
    });

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
    this.displayAlert=false;

    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.orgId = this.authService.getOrgid();

    var file = "";
    this.dataService.getIncidentItembyId(this.incidentid);
    this.dataService
      .incidentItemModelUpdatedEditListener()
      .pipe(take(1))
      .subscribe((incident_item: any) => {
        this.incidentitem = incident_item.incidentitemvalue[0];
        if (this.incidentitem.File != null)
        {
          for (var f = 0;f < this.incidentitem.File.split(",").length;f++) {
            if (this.incidentitem.File != "") {
              file += "|" + this.incidentitem.File.split(",")[f];
            }
          }
          this.getImage(file,'');
        }
    });

    this.dataService.getIncidentRoundValuesbyId(this.incidentid);
    this.dataService
      .incidentRoundModelUpdatedEditListener()
      .pipe(take(1))
      .subscribe((incident_round: any) => {
        this.incidentValues = incident_round.incidentroundvalue;
    });

    this.subscriptions = this.dataService.getSubscriptions(
      this.authService.getLocalOrgid()
    );
    this.dataService.subscriptionsListener().subscribe((subscriptions) => {
      this.subscriptions = subscriptions[0];
    });

    this.msgService.sendMessage(null);
  }

  // A function for the filetype
  getImage(file:any,entrance:any) {
    this.dataService
      .fileInfo(environment.catalogue, file)
      .subscribe((response) => {
        this.dataService.fileInfoUpdated.next(response);
      });
    this.dataService
      .fileInfoListener()
      .pipe(take(1))
      .subscribe((response) => {

      if (typeof response != "undefined") {
          this.filetypeinfo=response;
          for (var i = 0; i < this.filetypeinfo.length; i++)
          {
            this.fileNames.push(this.filetypeinfo[i].File);
          }
      }
    });
  }

  openDocumentInNewWindow(fileId:any) {
    this.uploadService.getFileAsTrustedResourceUrl(fileId);
    this.uploadService.readFileDataListener().pipe(take(1)).subscribe({
      next: content => {

        // var popup = window.open('')
        // popup.location.href = pdfContent;
        //this.santizedFile = content;
        let opened=window.open(content,"_blank");
          if(!opened){
            window.location.href=content;
          }
          return false;
      },
      error: error => {
      }
    })
  }

  selectIssue(id: any) {
    this.routing.navigate(['incidentitem/' + this.incidentitem.Id + '/incidentissueitem/' + id]);
  }

  editIssue(id: any) {
    this.routing.navigate(['incidentitem/' + this.incidentitem.Id + '/incidentissueedit/' + id]);
  }

  goBack() {
    this.routing.navigate(['/incident']);
  }

  addincidentitem() {
    this.routing.navigate(['incidentitem/' + this.incidentitem.Id + '/addincidentissue']);
  }

  sendmail() {
    const modalRef = this.modalService.open(ModalSendEmailIncidentComponent);
    modalRef.componentInstance.incidentitem = this.incidentitem;
    modalRef.componentInstance.incidentValues = this.incidentValues;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe(() => {
      window.location.reload();
    })
  }

  download() {
    this.displayAlert=true;
    this.pdfService.createPDF('incident',this.incidentitem, this.incidentitem.Id, this.incidentValues, false);
    setTimeout(() => { this.displayAlert = false }, 5000);
  }

  editItem() {
    this.routing.navigate(['/editincident', this.incidentitem.Id]);
  }

  riskvalues(item:any){
    if (!item.incidentSeverity || !item.incidentPossibility || !item.incidentFrequency) {
      return "fa fa-info-circle";
    }
    if(item.incidentSeverity==1 || item.incidentSeverity==2 || item.incidentSeverity==3 || item.incidentSeverity==4 || item.incidentSeverity==5){
      if(item.incidentFrequency==1 || item.incidentFrequency==2 || item.incidentFrequency==3 || item.incidentFrequency==4 || item.incidentFrequency==5){
        return "numberSquareGreenSmall";
      }
    }
    if(item.incidentSeverity==6 || item.incidentSeverity==7 || item.incidentSeverity==8 || item.incidentSeverity==9 || item.incidentSeverity==10){
      if(item.incidentFrequency==6 || item.incidentFrequency==7 || item.incidentFrequency==8 || item.incidentFrequency==9 || item.incidentFrequency==10){
        return "numberSquareRedSmall";
      }
    }
    return "numberSquareYellowSmall";
  }

  riskvaluesNumber(item:any) {
    if (!item.incidentSeverity || !item.incidentPossibility || !item.incidentFrequency) {
      return "";
    }
    if(item.incidentSeverity==1 || item.incidentSeverity==2 || item.incidentSeverity==3 || item.incidentSeverity==4 || item.incidentSeverity==5){
      if(item.incidentFrequency==1 || item.incidentFrequency==2 || item.incidentFrequency==3 || item.incidentFrequency==4 || item.incidentFrequency==5){
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 1;
        }else{
          return 2;
        }
      }else{
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 2;
        }else{
          return 3;
        }
      }
    }else{
      if(item.incidentFrequency==1 || item.incidentFrequency==2 || item.incidentFrequency==3 || item.incidentFrequency==4 || item.incidentFrequency==5){
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 3;
        }else{
          return 4;
        }
      }else{
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 4;
        }else{
          return 5;
        }
      }
    }
  }

  splitimageName(image:any, entrance:any) {
    if (typeof this.filetypeinfo == "undefined" || this.filetypeinfo == null) {
      return "none";
    } else {
      for (var n = 0; n < this.filetypeinfo.length; n++) {
        if (this.filetypeinfo[n].File == image) {
          return this.filetypeinfo[n].thisFileName.slice(0,40);
        }
      }
    }
  }
}
