<div class="ui stackable grid">
  <div class="row">
    <div class="sixteen wide column">
      <div class="ui card">
        <div class="header-text">
          <h1>{{'safetyrounds'|translate}}
          <button class="ui right floated positive button" *ngIf="is_admin||is_super_user" style="margin-left:20px;margin-bottom:0" (click)="addItem()"><i class="ui icon add"></i>{{'ADD_SAFETYROUND' | translate}}</button></h1>
        </div>
        <div class="ui grid" style="margin:0px">
          <div class="row" style="padding-bottom:0px">
            <div class="eight wide column">
              <div class="ui search" style="margin:20px;width:100%">
                <div class="ui icon input" style="width:100%">
                  <input class="prompt" type="text" type="text" name="searchText" id="searchText" placeholder="{{'searchall'|translate}}" [(ngModel)]="searchText" (keyup)="filterTableValue()">
                  <i class="search icon"></i>
                </div>
                <div class="results"></div>
              </div>
            </div>
            <div class="eight wide column" style="padding:30px">
              <div class="ui toggle checkbox" >
                <input type="checkbox" name="public" [checked]="doNotShowSubDepartment!=true" (click)="changeShowSubdepartment()">
                <label>{{'showsubdivisions'|translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <table datatable [dtOptions]="dtOptions" class='ui hover table' id='safetytable' style="padding-left:20px;cursor:pointer" *ngIf="safetyRoundValues_table2&&safetyRoundValues_table2.length!=0">
          <thead>
            <tr>
              <th (click)="sort('doneDate','')">{{'date'|translate}}</th>
              <th>{{'responsible'|translate}}</th>
              <th>{{'department'|translate}}</th>
              <th width="40%">{{'header'|translate}}</th>
              <th>{{'ROUNDS_DONE'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let safetyRoundValue of safetyRoundValues_table2 | filter : 'safetyround' : searchText;let i=index " (click)="viewItem(safetyRoundValue.Id)">
              <td *ngIf="safetyRoundValue">{{safetyRoundValue.doneDate}}</td>
              <td *ngIf="safetyRoundValue.Supervisor&&safetyRoundValue.Supervisor.length>=20" data-toggle="tooltip" title="{{safetyRoundValue.Supervisor}}">{{wordbreak(safetyRoundValue.Supervisor)}}</td>
              <td *ngIf="safetyRoundValue.Supervisor&&safetyRoundValue.Supervisor.length<20">{{safetyRoundValue.Supervisor}}</td>
              <td>{{safetyRoundValue.Department}}</td>
              <td *ngIf="safetyRoundValue.Title.length>=100" data-toggle="tooltip" title="{{safetyRoundValue.Title}}">{{wordbreak(safetyRoundValue.Title)}}...</td>
              <td *ngIf="safetyRoundValue.Title.length<100">{{safetyRoundValue.Title}}</td>
              <td>
                <div *ngIf="showRoundInfo(safetyRoundValue.Id) as variable">
                  <button class="ui negative button" *ngIf="variable.B==true">{{variable.A}}</button>
                  <button class="ui positive button" *ngIf="variable.B==false">{{variable.A}}</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

