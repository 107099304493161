<div class="modal-header">
    <h4 class="modal-title">{{'addfile'|translate}}</h4>
</div>
<div class="modal-body">
    <div class="ui fluid icon input">
        <input type="text" name="description"style="width: 90%;" [(ngModel)]="filedescription" value="filedescription" placeholder="{{'description' | translate}}"/>
    </div>
    <input class="ui button" type="file" (change)="fileUpload($event)" placeholder="Upload file" id="upload-file" style="margin-top: 10px;margin-bottom: 10px" />
    <button class="ui positive button" (click)="upload()"><i class="ui icon upload"></i>{{'UPLOAD' | translate}}</button>

    <div class="modal-footer">
        <button type="button" class="ui button" (click)="activeModal.close('Close click')" >{{"CLOSE" | translate}}</button>
    </div>
</div>
