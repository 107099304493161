import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { Orgmodel } from '../../shared/model_view/orgmodel';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { SafetyRoundModel, riskValueModel } from "src/app/shared/model_view/safetymodel";
import { take } from "rxjs/operators";
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-safetyround-add',
  templateUrl: './safetyround-add.component.html',
  styleUrls: ['./safetyround-add.component.css']
})
export class SafetyroundAddComponent implements OnInit {

  safetyRoundResponsible: string;
  userInfo: any;
  safetyRoundDate: string;
  localOrgId: any;
  userId: any;
  orgId: string;
  depname: any;
  orgmodel: any;
  doNotShowSubDepartment: boolean;
  itemArray: any;
  oldid: any;
  usermodel: any;
  safetyRoundParticipants: string;
  safetyRoundHeader: string;
  safetyRoundDescription: string;
  selectedFile: any = [];
  files: any = [];
  item:any = [];
  is_admin: any;
  is_super_user: any;
  filenames: any = [];
  uploadFileDone: boolean = false;
  file: any;
  safetyRoundValues: SafetyRoundModel[] = new Array();
  randomNumber: any;
  depnameId: any;
  selectSafetyroundCategory: any;
  roundValues: any = new Array();

  constructor(private dataService: DataService,
              private authService: AuthService,
              private msgService: MessageService,
              private routing: Router,
              private translate: TranslateService,
              private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $("#safetyRoundResponsibleChose").dropdown();
    $("#depnameChose").dropdown();
  }

  ngOnInit(): void {
    this.safetyRoundDate = new Date().toISOString().slice(0, 10);
    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.orgId = this.authService.getOrgid();
    this.safetyRoundResponsible = "";
    this.safetyRoundParticipants = "";
    this.safetyRoundHeader = "";
    this.safetyRoundDescription = "";

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    if (this.localOrgId) {
      this.dataService.getUserInfo(this.localOrgId);
      this.dataService
        .getUserModelListener()
        .subscribe((usermodel: any) => {
          if (usermodel[0].name) {
            this.usermodel = usermodel[0].name;
            this.msgService.sendMessage(this.usermodel);
          }
      });
    }

    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService
      .orgModelListener()
      .pipe(take(1))
      .subscribe((orgmodel: Orgmodel) => {
        this.orgmodel = orgmodel;
        if (this.doNotShowSubDepartment == true) {
          this.itemArray = null;
        } else {
          this.itemArray = this.displayTreeItem(this.localOrgId);
        }

        if (this.orgmodel) {
          var count=this.orgmodel.length;
          for (var n = 0; n < count; n++) {
            if (this.orgmodel[n].Name == this.usermodel) {
              this.depname = this.orgmodel[n].Name;
              this.depnameId = this.orgmodel[n].Id;
            }
          }
        }
    });

    this.dataService.getUsers(this.authService.getOrgid(), this.userId);
    this.dataService.usersListener().subscribe((users) => {
      this.userInfo = users;
    });
  }

  imageUpload(event:any) {
    this.uploadFileDone = true,
    this.uploadService.upload(event.target.files[0], "");
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {
      if (filename == null)
      {
        Swal.fire("Error", "Error");
      }
      else
      {
        this.files.push(filename);
        setTimeout(() => {
          this.uploadFileDone = false;

          this.selectedFile.push(event.target.files[0]);
    
          if (this.files) {
            this.filenames.push(event.target.files[0].name);
          } else {
            this.filenames = event.target.files[0].name;
          }
          this.item.push(1);
        }, 1000);
      }
    });
  }

  goBack() {
    this.routing.navigate(['/safetyround']);
  }

  displayTreeItem(id:any) {
    if (this.orgmodel) {
      if (this.oldid != id) {
        this.oldid = id;
        var count = Object.keys(this.orgmodel).length;
        this.itemArray = new Array();
        for (let nthItem = 0; nthItem < count; nthItem++) {
          if (
            this.orgmodel[nthItem].Parent == id ||
            (this.itemArray &&
              this.itemArray.indexOf(+this.orgmodel[nthItem].Parent) > -1)
          ) {
            this.itemArray.push(this.orgmodel[nthItem].Id);
          }
        }
      } else {
        this.oldid = null;
        this.itemArray = [];
      }
      return this.itemArray;
    }
  }

  add_safetyround(form: NgForm){
    //form.reset();
  }

  setRoundResponsible(userInfo:any) {
    this.safetyRoundResponsible = userInfo.first_name + " " + userInfo.last_name;
    if (this.safetyRoundResponsible == "" || this.safetyRoundResponsible == null || this.safetyRoundResponsible == "null null")
    {
      this.safetyRoundResponsible = userInfo.username;
    }
  }

  setDepartment(department: any) {
    this.depname = department.Name + "|" + department.Id;
    this.depnameId = null;
  }

  removeImage(file: any, index: any) {
    this.selectedFile.splice(index, 1);
    this.filenames.splice(index, 1);
  }

  saveItem() {

    if (!this.safetyRoundResponsible ||!this.safetyRoundParticipants ||!this.safetyRoundHeader) {
      if (!this.safetyRoundResponsible) {
        Swal.fire(this.translate.instant("INFORMATION_MISSING"), this.translate.instant("INFORMATION_MISSING_RESPONSIBLE"), "error");
      }

      if (!this.safetyRoundParticipants) {
        Swal.fire(this.translate.instant("INFORMATION_MISSING"), this.translate.instant("INFORMATION_MISSING_PARTICIPANTS"), "error");
      }

      if (!this.safetyRoundHeader) {
        Swal.fire(this.translate.instant("INFORMATION_MISSING"), this.translate.instant("INFORMATION_MISSING_HEADING"), "error");
      }
      return;
    }

    this.file = "";
    for (var i = 0; i < this.selectedFile.length; i++) {
      if (this.selectedFile[i]) {
        if (i != 0 || (i == 0 && this.file != "")) {
          this.file += ",";
        }
        this.file += this.files[i];
      }
    }

    Math.random;
    this.randomNumber = "R-" + Math.floor(Math.random() * 100);

    this.selectSafetyroundCategory = "Arbetsmiljö";

    this.safetyRoundValues = [];

    if (this.depnameId != null)
    {
      this.depname = this.depname + "|" +  this.depnameId;
    }

    this.safetyRoundValues.push({
      Id: this.randomNumber,
      doneDate: this.safetyRoundDate,
      Supervisor: this.safetyRoundResponsible,
      Participants: this.safetyRoundParticipants,
      Department: this.depname,
      Title: this.safetyRoundHeader,
      Text: this.safetyRoundDescription,
      File: this.file,
      Danger: 4,
      category: this.selectSafetyroundCategory
    });

    let user = +this.authService.getUserId();
    let org = +this.authService.getOrgid();
    this.dataService.addSafetyRounds(
      this.safetyRoundValues,
      this.roundValues,
      user,
      this.localOrgId,
      org
    );
  }
}
