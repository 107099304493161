<div class="contents">
    <h1>{{'newuser'|translate}}</h1>
    <hr/>
    <div class="row">
        <form (submit)="addNewUser(postForm)" #postForm="ngForm">
            <div class="form-group col-sm-2">
                <input type="text" id="name" name="name" [ngModel]="name" required #thisname="ngModel" placeholder="{{'username'|translate}}" />
            </div>
            <div class="form-group col-sm-2">
                <input type="password" id="pwd" name="pwd" [ngModel]="pwd" required #thispwd="ngModel" placeholder="{{'password'|translate}}" />
            </div>
            <div class="form-group col-sm-2">
              <select id="organisation" name="organisation" [(ngModel)]="organisation" required #thisorganisation="ngModel" style="width: 210px !important;" >
                <option [ngValue]="0" disabled>{{'company'|translate}}</option>
                <option *ngFor="let organisationValuesItem of organisationValues"
                  [ngValue]="organisationValuesItem.id">{{ organisationValuesItem.name }}
                 </option>
              </select>
            </div>
              <div class="form-group col-sm-2">
              <select id="level" name="level" [(ngModel)]="level" #thislevel="ngModel" style="width: 210px !important;">
                <option [ngValue]="0" disabled>{{'userlevel'|translate}}</option>
                <option [ngValue]="1">{{'user'|translate}}</option>
                <option [ngValue]="2">{{'superuser'|translate}}</option>
                <option [ngValue]="3">{{'admin'|translate}}</option>
              </select>
            </div>
          <div class="text-danger" *ngIf="error&&error.length">
            <ul>
              <div *ngFor="let erroritem of error;">
                <li>{{erroritem}}</li>
              </div>
            </ul>
          </div>
          <hr/>
            <div class="col-sm-2">
              <i class="fa fa-floppy-o fa-2x" (click)="addNewUser(postForm)"></i>
                <!--<button class="btn btn-primary" type="button" (click)="addNewUser(postForm)">{{'save'|translate}}</button>-->
            </div>



        </form>
    </div>
    <br/>
    <div class="col-sm-4">
      <span class="text-info">{{message}}</span>
    </div>
</div>

