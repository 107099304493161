import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  constructor(private authService: AuthService,
    private router:Router,
    private dataService: DataService,
    private msgService: MessageService,) { }

  ngOnInit(): void {
    this.msgService.sendMessage(null);
  }
}
