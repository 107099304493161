<div class="ui stackable grid">
    <div class="row">
        <div class="eleven wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="twelve wide column">
                        <i style="color:green;margin:0px;margin-left:40px;margin-bottom:5px;cursor:pointer" class="ui large icon edit" (click)="editIssue()"></i>
                        <label class="first-header-label" style="margin:0px;margin-top:20px;">{{'safetyrounditem'|translate}}</label>
                    </div>
                    <div class="computer only four wide right aligned column" style="padding: 40px;cursor:pointer" >
                        <span style="opacity:0.8;margin-left:10px" (click)="goBack()"><button class="ui white button">{{"back" | translate}}</button></span>
                    </div>
                    <div class="mobile only one wide column" style="padding:10px;padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <div *ngIf="safetyRoundValue" class="rectangle-green-small">
                    <div class="ui computer only grid row">
                        <div class="fourteen wide column">
                            <div *ngIf="roundDone">
                                <i class="check square outline icon" style="color:white;margin-bottom: 20px"></i>
                                <label style="color: white">{{'finished'|translate}} {{safetyRoundValue.roundDoneRealDate | date:"yyyy-MM-dd"}}</label>
                                <i *ngIf="roundActive" class="check square outline icon" style="margin-left:20px;color:white;margin-bottom: 20px"></i>
                                <label *ngIf="roundActive" style="color: white">{{'active'|translate}} {{safetyRoundValue.roundDoneActiveDate | date:"yyyy-MM-dd"}}</label>
                            </div>
                            <div *ngIf="!roundDone">
                                <i class="clock icon" style="color:white;margin-bottom: 20px"></i>
                                <label style="color: white">{{'ongoing'|translate}}</label>
                            </div>
                            
                        </div>
                        <div class="one wide right aligned column">
                            <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1">
                                <div *ngIf="safetyRoundValue" class="{{riskvaluesModal(safetyRoundValue.roundSeverity, safetyRoundValue.roundPossibility, safetyRoundValue.roundFrequency,'panel')}}">
                                    {{riskvaluesModalNumber(safetyRoundValue.roundSeverity, safetyRoundValue.roundPossibility, safetyRoundValue.roundFrequency)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ui mobile only grid row">
                        <div class="twelve wide column">
                            <div *ngIf="roundDone">
                                <i class="check square outline icon" style="color:white;margin-bottom: 0px"></i>
                                <label style="color: white">{{'finished'|translate}} {{safetyRoundValue.roundDoneRealDate | date:"yyyy-MM-dd"}}</label>
                                <i *ngIf="roundActive" class="check square outline icon" style="margin-left:20px;color:white;margin-bottom: 20px"></i>
                                <label *ngIf="roundActive" style="color: white">{{'active'|translate}} {{safetyRoundValue.roundDoneActiveDate | date:"yyyy-MM-dd"}}</label>
                            </div>
                            <div *ngIf="!roundDone">
                                <i class="clock icon" style="color:white;margin-bottom: 0px"></i>
                                <label style="color: white">{{'ongoing'|translate}}</label>
                            </div>
                        </div>
                        <div class="four wide column">
                            <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1">
                                <div *ngIf="safetyRoundValue" class="{{riskvaluesModal(safetyRoundValue.roundSeverity, safetyRoundValue.roundPossibility, safetyRoundValue.roundFrequency,'panel')}}">
                                    {{riskvaluesModalNumber(safetyRoundValue.roundSeverity, safetyRoundValue.roundPossibility, safetyRoundValue.roundFrequency)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class="header-label">{{'responsible'|translate}}</label><br>
                        <label class="text-label">{{safetyRoundValue.roundResponsible2}}</label>
                    </div>
                    <div>
                        <label class="info-label">{{'place'|translate}}</label><br>
                        <label class="text-label">{{safetyRoundValue.roundLocation}}</label>
                    </div>
                    <div>
                        <label class="info-label">{{'shortageorcomment'|translate}}</label><br>
                        <label class="text-label">{{safetyRoundValue.roundRisk}}</label>
                    </div>
                    <div class="ui grid row">
                        <div class="ten wide column">
                            <label class="info-label">{{'measures'|translate}}</label><br>
                            <label class="text-label">{{safetyRoundValue.roundMeasure}}</label>
                        </div>
                        <div class="six wide column">
                            <div class="with-border">
                                <label class="info-label">{{'attachments'|translate}}</label>
                                <div *ngFor="let fileName of fileNames;let i=index;">
                                    <a (click)="openDocumentInNewWindow(filetypeinfo[i])" *ngIf="fileName" style="color:black">
                                        <i title="{{splitimageName(fileName,'')}}" class="ui icon file pdf outline" style="font-size:14px;color:black"></i><small>{{splitimageName(fileName,'')}}</small>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui grid row" style="margin: 20px">
                    <div class="twelve wide column"></div>
                    <div class="four wide right aligned column">
                        <button class="ui negative button" *ngIf="(is_admin||is_super_user)" (click)="removeIssue()"><i class="ui icon trash"></i>{{'REMOVE' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="five wide column">
            <div *ngIf="subscriptions&&subscriptions.subscription_rootCauseAnalysis" class="rectangle-green" style="margin-top:15px;">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <label class="five-info-label">{{'five_why'|translate}}</label>
                    </div>
                    <div class="one wide right aligned column" style="margin-top: 15px; cursor:pointer; color:white">
                        <i *ngIf="(is_admin||is_super_user)" class="ui large icon edit" (click)="editRisk()"></i>
                    </div>
                </div>
                <div *ngIf="safetyRoundValue&&safetyRoundValue.rootCauseAnalysisSolution!=''&&safetyRoundValue.rootCauseAnalysisSolution!=null" class="five-line">
                    <div class="ellipse" style="margin-top:20px">1</div>
                    <label class="ellipse-text" style="margin-top:30px">{{safetyRoundValue.rootCauseAnalysisWhy1}}</label>
                    <div class="ellipse" style="margin-top:40px">2</div>
                    <label class="ellipse-text" style="margin-top:50px">{{safetyRoundValue.rootCauseAnalysisWhy2}}</label>
                    <div class="ellipse" style="margin-top:40px">3</div>
                    <label class="ellipse-text" style="margin-top:50px">{{safetyRoundValue.rootCauseAnalysisWhy3}}</label>
                    <div class="ellipse" style="margin-top:40px">4</div>
                    <label class="ellipse-text" style="margin-top:50px">{{safetyRoundValue.rootCauseAnalysisWhy4}}</label>
                    <div class="ellipse" style="margin-top:40px">5</div>
                    <label class="ellipse-text" style="margin-top:50px">{{safetyRoundValue.rootCauseAnalysisWhy5}}</label>
                    <div class="ellipse-done" style="margin-top:40px"><i class="icon check" style="color:white"></i></div>
                    <label class="ellipse-text-header" style="margin-top:50px">{{'solution'|translate}}</label>
                    <label class="ellipse-text" style="margin-top:0px">{{safetyRoundValue.rootCauseAnalysisSolution}}</label>
                </div>
            </div>
        </div>
    </div>
</div>
