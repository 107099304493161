import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "../../../auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take, concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-workplaceissue-item',
  templateUrl: './workplaceissue-item.component.html',
  styleUrls: ['./workplaceissue-item.component.css']
})
export class WorkplaceissueItemComponent implements OnInit {

  UploadUrl = environment.UploadUrl;
  
  orgId: any;
  localOrgId: any;
  is_admin: any;
  is_super_user: any;
  subscriptions: any;
  workplaceValue: any;
  workplaceissueid: any;
  roundDone: any = false;
  file: any = "";
  filetypeinfo: any;
  fileNames: any = [];

  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private translate: TranslateService,
    private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $("#selectWorkplaceIssueItem").dropdown();
  }

  ngOnInit(): void {
    this.orgId = this.authService.getOrgid();
    this.localOrgId = this.authService.getLocalOrgid();
    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    this.subscriptions=this.dataService.getSubscriptions(this.localOrgId);
    this.dataService.subscriptionsListener()
    .subscribe((subscriptions) => {
      this.subscriptions=subscriptions[0];
    });

    this.route.params.pipe(take(1)).subscribe(params => {
      this.workplaceissueid = params['id'];

      this.dataService.getWorkplaceRoundById(this.workplaceissueid);
      this.dataService
        .roundWorkplaceModelTableListener()
        .pipe(take(1))
        .subscribe((roundItem: any) => {
          this.workplaceValue = roundItem.roundvalue;

          if (this.workplaceValue.roundDone == "Ja")
          {
            this.roundDone = true;
          }

          for (var f = 0; f < this.workplaceValue.Files.split(",").length; f++) {
            if (this.workplaceValue.Files != "" && this.workplaceValue.Files != undefined) {
              this.file += "|" + this.workplaceValue.Files.split(",")[f];
            }
          }
          this.getImage(this.file,'');
      });
    });

    this.msgService.sendMessage(null);
  }

  openDocumentInNewWindow(fileId:any) {
    this.uploadService.getFileAsTrustedResourceUrl(fileId);
    this.uploadService.readFileDataListener().pipe(take(1)).subscribe({
      next: content => {

        // var popup = window.open('')
        // popup.location.href = pdfContent;
        //this.santizedFile = content;
        let opened=window.open(content,"_blank");
          if(!opened){
            window.location.href=content;
          }
      },
      error: error => {
      }
    })
  }

  goBack() {
    this.routing.navigate(['workplaceitem/' + this.workplaceValue.Id]);
  }

  editIssue() {
    this.routing.navigate(['workplaceitem/' + this.workplaceValue.Id + '/workplaceissueedit', this.workplaceissueid]);
  }

  removeIssue() {
    var tmpString = this.translate.instant('DO_YOU_WANT_TO_REMOVE')
    var deleteQuestion = confirm(tmpString);
    if (deleteQuestion == true) {
      this.dataService.deleteWorkplaceRound(this.workplaceissueid, this.workplaceValue.Id)
    } 
  }

  getImage(file:any,entrance:any) {
    this.dataService
      .fileInfo(environment.catalogue, file)
      .subscribe((response) => {
        this.dataService.fileInfoUpdated.next(response);
      });
    this.dataService
      .fileInfoListener()
      .pipe(take(1))
      .subscribe((response) => {

      if (typeof response != "undefined") {
          this.filetypeinfo=response;
          for (var i = 0; i < this.filetypeinfo.length; i++)
          {
            this.fileNames.push(this.filetypeinfo[i].File);
          }
      }
    });
  }

  splitimageName(image:any, entrance:any) {
    if (typeof this.filetypeinfo == "undefined" || this.filetypeinfo == null) {
      return "none";
    } else {
      for (var n = 0; n < this.filetypeinfo.length; n++) {
        if (this.filetypeinfo[n].File == image) {
          return this.filetypeinfo[n].thisFileName;
        }
      }
    }
  }

  riskvaluesModal(sev:any, poss:any, freq:any, entrance:any) {
    if (!sev || !poss || !freq) {
      if(entrance=='panel'){
        return "";
      }else{
        return "numberSquareGrey";
      }
    }
    if(sev==1 || sev==2 || sev==3 || sev==4 || sev==5){
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        return "numberSquareGreen";
      }
    }
    if(sev > 5){
      if(freq > 4){
        return "numberSquareRed";
      }
    }
    return "numberSquareYellow";
  }

  riskvaluesModalNumber(sev:any, poss:any, freq:any) {
    if (!sev || !poss || !freq) {
      return "";
    }
    if(sev==1 || sev==2 || sev==3 || sev==4 || sev==5){
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 1;
        }else{
          return 2;
        }
      }else{
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 2;
        }else{
          return 3;
        }
      }
    }else{
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 3;
        }else{
          return 4;
        }
      }else{
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 4;
        }else{
          return 5;
        }
      }
    }
  }
}
