<div class="ui stackable grid">
    <div class="row">
        <div class="eight wide column">
            <div class="ui grid rectangle-green">
                <div class="row">
                    <div class="fourteen wide column big-text" (click)="goToOrganisations()">
                        {{"organisations" | translate}}
                    </div>
                </div>
            </div>
        </div>
        <div class="eight wide column">
            <div class="ui grid rectangle-orange">
                <div class="row">
                    <div class="fourteen wide column big-text" (click)="goToNews()">
                        {{"news" | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
