<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card">
                <div class="ui top attached tabular menu" (active)="0">
                    <a class="item" [class.active] = "activeTab==0" index="0" data-tab="zero" (click)="setActiveTab(0)" >{{'departments' | translate}}</a>
                    <a class="item" [class.active] = "activeTab==1" index="1" data-tab="one" (click)="setActiveTab(1)" >{{'CATEGORIES' | translate}}</a>
                    <a class="item" [class.active] = "activeTab==2" index="2" data-tab="two" (click)="setActiveTab(2)" >{{'USERS' | translate}}</a>
                </div>
                <div class="sixteen wide column">
                    <div class="ui bottom attached tab segment" [class.active] = "activeTab==0" data-tab="zero">
                        <ul>
                            <div *ngFor="let orgmodelitem of orgmodelDep;">
                                <div [ngSwitch]="orgmodelitem.Level">
                                    <li *ngSwitchCase="0" draggable="true" style="margin-left: 0vw;width: 90%;"
                                        class="list-group-item"  (dragstart)="onDragStart($event,orgmodelitem)"  (drop)="onDrop($event,orgmodelitem)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)" ><strong>{{orgmodelitem.Name}}</strong>
                                        <i class="ui icon plus" style="float:right;padding-right: 3.1vw;"
                                            (click)="addDepartment(orgmodelitem.Place,orgmodelitem.Level,orgmodelitem.Id);">
                                        </i>
                                    </li>
                                    <li *ngSwitchCase="1"  draggable="true" style="margin-left: 1vw;width: 90%;"
                                        class="list-group-item" (dragstart)="onDragStart($event,orgmodelitem)" (drop)="onDrop($event,orgmodelitem)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)">{{orgmodelitem.Name}}
                                    <i class="ui red icon trash" style="float:right;padding-right: 2px;"
                                        (click)="removeDepartment(orgmodelitem.Id);"></i>
                                    <i class="ui icon blue pencil" style="float:right;padding-right: 2px;margin-right:.5vw;"
                                        (click)="editDepartment(orgmodelitem.Id,orgmodelitem.Name)"></i>
                                    <i class="ui icon green plus" style="float:right;padding-right: 2px;margin-right:.5vw;"
                                        (click)="addDepartment(orgmodelitem.Place,orgmodelitem.Level,orgmodelitem.Id);"></i>
                                    </li>
                                    <li *ngSwitchCase="2" style="margin-left: 2vw;width: 89%;"
                                    class="list-group-item" draggable="true" (dragstart)="onDragStart($event,orgmodelitem)" (drop)="onDrop($event,orgmodelitem)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)">{{orgmodelitem.Name}}
                                    <i class="ui red icon trash" style="float:right;padding-right: 2px;"
                                        (click)="removeDepartment(orgmodelitem.Id);"></i>
                                    <i class="ui icon blue pencil" style="float:right;padding-right: 2px;margin-right:.5vw;"
                                        (click)="editDepartment(orgmodelitem.Id,orgmodelitem.Name)"></i>
                                    <i class="ui icon green plus" style="float:right;padding-right: 2px;margin-right:.5vw;"
                                        (click)="addDepartment(orgmodelitem.Place,orgmodelitem.Level,orgmodelitem.Id);"></i>
                                    </li>
                                    <li *ngSwitchCase="3" style="margin-left: 3vw;width: 88%;"
                                    class="list-group-item"  draggable="true" (dragstart)="onDragStart($event,orgmodelitem)" (drop)="onDrop($event,orgmodelitem)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)">{{orgmodelitem.Name}}
                                    <i class="ui red icon trash" style="float:right;padding-right: 2px;"
                                        (click)="removeDepartment(orgmodelitem.Id);"></i>
                                    <i class="ui icon blue pencil" style="float:right;padding-right: 2px;margin-right:.5vw;"
                                        (click)="editDepartment(orgmodelitem.Id,orgmodelitem.Name)"></i>
                                    <i class="ui icon green plus" style="float:right;padding-right: 2px;margin-right:.5vw;"
                                        (click)="addDepartment(orgmodelitem.Place,orgmodelitem.Level,orgmodelitem.Id);"></i>
                                    </li>
                                    <li *ngSwitchCase="4" style="margin-left: 4vw;width: 88%;"
                                    class="list-group-item"  draggable="true" (dragstart)="onDragStart($event,orgmodelitem)" (drop)="onDrop($event,orgmodelitem)" (dragover)="allowDrop($event)" (dragleave)="removeStyle($event)">{{orgmodelitem.Name}}
                                    <i class="ui red icon trash" style="float:right;padding-right: 2px;border: 1px solid red;"
                                        (click)="removeDepartment(orgmodelitem.Id);"></i>
                                    <i class="ui icon blue pencil" style="float:right;padding-right: 2px;margin-right:.5vw;"
                                        (click)="editDepartment(orgmodelitem.Id,orgmodelitem.Name)"></i>
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </div>
                    <div class="ui bottom attached tab segment" [class.active] = "activeTab==1" data-tab="one">
                        <div class="ui top attached tabular menu" (active)="0">
                            <a class="item" [class.active] = "activeCategoryTab==0" index="0" data-tab="zero-cat" (click)="setActiveCategoryTab(0)" >{{'categorinametypeofinjury' | translate}}</a>
                            <a class="item" [class.active] = "activeCategoryTab==1" index="1" data-tab="one-cat" (click)="setActiveCategoryTab(1)" >{{'categorinametypeofincident' | translate}}</a>
                        </div>
                        <div class="sixteen wide column">
                            <div class="ui bottom attached tab segment" [class.active] = "activeCategoryTab==0" data-tab="zero-cat">
                                <table class="col-sm-10">
                                    <ng-container *ngFor="let categoryListItem of categoryList;let last=last;let index=index">
                                        <tr *ngIf="categoryListItem.uid==orgId">
                                        <td><input
                                          class="form-control"
                                          #j
                                          id="category-name-{{index}}"
                                          type="text"
                                          value="{{categoryListItem.name}}"
                                          /></td>
                                          <td><i class="fa fa-floppy-o form-control" style="float:right;padding-right: 2px;cursor:pointer;color:green" (click)="changeNameItem('category',categoryListItem, j)">
                                            {{'save'|translate}}</i></td>
                                          <td><i class="fa fa-trash form-control" style="float:right;padding-right: 2px;cursor:pointer;color:red" (click)="removeItem('category',categoryListItem)"> {{'remove'|translate}}</i></td>
                                        </tr>
                                        <tr *ngIf="categoryListItem.uid==null">
                                          <td><input
                                            class="form-control"
                                            #j
                                            type="text"
                                            disabled
                                            value="{{categoryListItem.name}}"
                                            /></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        <tr *ngIf="last">
                                          <td><input
                                            type="text"
                                            class="form-control"
                                            [(ngModel)]="thisNameCategory"
                                            placeholder="{{'newtypeofinjury'|translate}}"
                                            [value]="thisNameCategory" /></td>
                                            <td><i class="fa fa-plus form-control" style="float:right;padding-right: 2px;cursor:pointer;color:blue" (click)="addItem('category',thisNameCategory)">{{'  '}}{{'add'|translate}}</i></td>
                                            <td></td>
                                        </tr>
                                    </ng-container>
                                    <div [hidden]="categoryList">
                                        <tr>
                                            <td><input
                                                type="text"
                                                class="form-control"
                                                [(ngModel)]="thisNameCategory"
                                                placeholder="{{'newtypeofinjury'|translate}}"
                                                [value]="thisNameCategory" />
                                            </td>
                                            <td><i class="fa fa-plus form-control" style="cursor:pointer;color:blue;height:auto" (click)="addItem('category',thisNameCategory)">{{'  '}}{{'add'|translate}}</i></td>
                                            <td></td>
                                        </tr>
                                    </div>
                                </table>{{message}}
                            </div>
                            <div class="ui bottom attached tab segment" [class.active] = "activeCategoryTab==1" data-tab="one-cat">
                                <table class="col-sm-10">
                                    <ng-container *ngFor="let eventListItem of eventList;let last=last;let index=index">
                                        <tr *ngIf="eventListItem.uid==orgId">
                                        <td><input
                                          class="form-control"
                                          #i
                                          type="text"
                                          id="event-name-{{index}}"
                                          value="{{eventListItem.name}}"
                                          /></td>
                                          <td><i class="fa fa-floppy-o form-control" style="float:right;padding-right: 2px;cursor:pointer;color:green" (click)="changeNameItem('event',eventListItem, i)"> Ändra</i></td>
                                          <td><i class="fa fa-trash form-control" style="float:right;padding-right: 2px;cursor:pointer;color:red" (click)="removeItem('event',eventListItem)"> Ta bort</i></td>
                                        </tr>
                                        <tr *ngIf="eventListItem.uid==null">
                                          <td><input
                                            class="form-control"
                                            #i
                                            type="text"
                                            disabled
                                            value="{{eventListItem.name}}"
                                            /></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        <tr *ngIf="last">
                                          <td><input
                                            type="text"
                                            class="form-control"
                                            [(ngModel)]="thisName"
                                            placeholder="{{'newtypeofincident'|translate}}"
                                            [value]="thisName" /></td>
                                            <td><i class="fa fa-plus form-control" style="float:right;padding-right: 2px;cursor:pointer;color:blue" (click)="addItem('event',thisName)">{{'  '}}{{'add'|translate}}</i></td>
                                            <td></td>
                                          </tr>
                                          </ng-container>
                                          <div [hidden]="eventList">
                                            <tr>
                                                <td><input
                                                  type="text"
                                                  class="form-control"
                                                  [(ngModel)]="thisName"
                                                  placeholder="{{'newtypeofincident'|translate}}"
                                                  [value]="thisName" /></td>
                                                  <td><i class="fa fa-plus form-control" style="cursor:pointer;color:blue;height:auto" (click)="addItem('event',thisName)">{{'  '}}{{'add'|translate}}</i></td>
                                                  <td></td>
                                            </tr>
                                        </div>
                                    </table>
                            </div>
                        </div>
                    </div>
                    <div class="ui bottom attached tab segment" [class.active] = "activeTab==2" data-tab="two">
                        <div>
                            <button class="ui right floated positive button" *ngIf="is_admin||is_super_user" style="margin-top:0px;margin-bottom:20px" (click)="addUser()"><i class="ui icon add"></i>{{'ADD_USER' | translate}}</button>
                            <div class="input-group" style="margin-bottom:20px">
                                <div class="input-group-prepend" >
                                    <span class="input-group-text" id="basic-text1"><i class="fa fa-search" aria-hidden="true"></i></span>
                                </div>
                                <input class="form-control my-0 py-1" type="text" name="searchText" placeholder="{{'searchall'|translate}}" aria-label="Sök alla..." [(ngModel)]="searchText">
                            </div>
                            <table class="ui selectable table">
                                <thead>
                                    <tr>
                                        <th>{{'name'|translate}}</th>
                                        <th>{{'email'|translate}}</th>
                                        <th>{{'eligibility'|translate}}</th>
                                        <th>{{'CREATED_AT'|translate}}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of users | filter : 'name' : searchText;let i=index" style="cursor:pointer">
                                        <td (click)="editUser(user.id)" >{{user.username}}</td>
                                        <td (click)="editUser(user.id)" >{{user.email}}</td>
                                        <td (click)="editUser(user.id)" class="thirdcolumn" *ngIf="user.is_super_user=='1'">{{'superuser'|translate}}</td>
                                        <td (click)="editUser(user.id)" class="fourthcolumn" *ngIf="user.is_admin=='1'">{{'admin'|translate}}</td>
                                        <td (click)="editUser(user.id)" class="fifthcolumn" *ngIf="user.is_admin!='1'&&user.is_super_user!='1'">{{'user'|translate}}</td>
                                        <td (click)="editUser(user.id)" >{{user.created | date:"yyyy-MM-dd:HH:MM"}}</td>
                                        <td><i class="ui icon red trash" (click)="removeUser(user.id)"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
