<div *ngIf="is_super_user">
  <a style="cursor:pointer" (click)="andraorganisation()">
    <i class="ui icon exchange" style="margin-left:20px;margin-right:5px;margin-top:20px;cursor:pointer" ></i>{{'CHANGE_ORGANISATION' | translate}}
  </a>
  <hr/>
</div>
<div style="margin-top: 20px; margin-left:20px;border-left:1px">
  <h5 *ngIf="orgId" class="treeitem" style="margin-bottom:5px;margin-left:0px" (click)="addMessage(orgId)" [class.active]="localOrgId==orgId">{{orgName}}</h5>
  <div class="first-line">
    <div *ngFor="let orgmodels of orgmodel">
      <div class="treeitem" [class.active]="localOrgId==orgmodels.Id" (click)="addMessage(orgmodels.Id)" *ngIf="orgmodels.Level>0">
        <strong *ngIf="orgmodels.Level==1">{{orgmodels.Name}}</strong>
      </div>
      <div class="first-line" style="margin-left:10px" *ngIf="orgmodels.Level>1">
        <div class="treeitem" [class.active]="localOrgId==orgmodels.Id" (click)="addMessage(orgmodels.Id)" *ngIf="orgmodels.Level==2">
          {{orgmodels.Name}}
        </div>
        <div class="first-line" style="margin-left:15px" *ngIf="orgmodels.Level>2">
          <div class="treeitem" [class.active]="localOrgId==orgmodels.Id" (click)="addMessage(orgmodels.Id)" *ngIf="orgmodels.Level==3">
            {{orgmodels.Name}}
          </div>
          <div class="first-line" style="margin-left:20px" *ngIf="orgmodels.Level>3">
            <div class="treeitem" [class.active]="localOrgId==orgmodels.Id" (click)="addMessage(orgmodels.Id)" *ngIf="orgmodels.Level==4">
              {{orgmodels.Name}}
            </div>
            <div class="first-line" style="margin-left:25px" *ngIf="orgmodels.Level>4">
              <div class="treeitem" [class.active]="localOrgId==orgmodels.Id" (click)="addMessage(orgmodels.Id)" *ngIf="orgmodels.Level==5">
                {{orgmodels.Name}}
              </div>
              <div class="first-line" style="margin-left:25px" *ngIf="orgmodels.Level>5">
                <div class="treeitem" [class.active]="localOrgId==orgmodels.Id" (click)="addMessage(orgmodels.Id)" *ngIf="orgmodels.Level==6">
                  {{orgmodels.Name}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

