import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "../../auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take,concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSendEmailWorkplaceComponent } from 'src/app/modals/modal-send-email-workplace/modal-send-email-workplace.component';
import { PDFService } from "src/app/shared/services/pdf.service";
import { TranslateService } from '@ngx-translate/core';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-workplace-item',
  templateUrl: './workplace-item.component.html',
  styleUrls: ['./workplace-item.component.css']
})
export class WorkplaceItemComponent implements OnInit {

  BackendUrl = environment.BackendUrl;
  UploadUrl = environment.UploadUrl;
  URL = this.BackendUrl + "/file";

  workplaceid: any;
  workplaceitem: any;
  is_admin: any;
  is_super_user: any;
  localOrgId: any;
  userId: any;
  orgId: any;
  filetypeinfo: any;
  fileNames: any = [];
  srcImageName: any;
  workplaceRoundValues: any = [];
  displayAlert: boolean;
  subscriptions: any = {};
  orgmodel: any;
  doNotShowSubDepartment: any; 
  itemArray: any;
  headerid: any;
  oldid: any;
  workplaceRoundValues_table: any;
  workplaceRoundValues_table2: any = [];

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private modalService: NgbModal,
    private pdfService: PDFService,
    private translate: TranslateService,
    private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $("#selectWorkplaceItem").dropdown();
  }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.workplaceid = params['id'];
    });

    this.headerid = this.route.snapshot.queryParamMap.get("id");

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
    this.displayAlert = false;

    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.orgId = this.authService.getOrgid();
    this.doNotShowSubDepartment = this.authService.getShowOrHideSubdepartment();

    var file = "";
    this.dataService.getWorkplaceItembyId(this.workplaceid);
    this.dataService
      .workplaceItemModelUpdatedEditListener()
      .pipe(take(1))
      .subscribe((workplace_item: any) => {
        this.workplaceitem = workplace_item.workplaceitemvalue[0];
        if (this.workplaceitem.File != null)
        {
          for (var f = 0;f < this.workplaceitem.File.split(",").length;f++) {
            if (this.workplaceitem.File != "") {
              file += "|" + this.workplaceitem.File.split(",")[f];
            }
          }
          this.getImage(file,'');
        }
    });

    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService
      .orgModelListener()
      .pipe(take(1))
      .subscribe((orgmodel: any) => {
        this.orgmodel = orgmodel;
        if (this.doNotShowSubDepartment == true) {
          this.itemArray = null;
        } else {
          this.itemArray = this.displayTreeItem(this.localOrgId);
        }
        this.getWorkplaceRoundValues(this.headerid, this.itemArray);
      });

    this.subscriptions = this.dataService.getSubscriptions(
      this.authService.getLocalOrgid()
    );
    this.dataService.subscriptionsListener().subscribe((subscriptions) => {
      this.subscriptions = subscriptions[0];
    });

    this.msgService.sendMessage(null);
  }

  getWorkplaceRoundValues(thisid: any, numbers2: any) {
    this.dataService.getWorkplaceValuesbyId(this.workplaceid);

    this.dataService
      .workplaceIdModelUpdatedEditListener()
      .pipe(take(1))
      .subscribe((workplaceRoundValues_table: any) => {
          // On Next
          this.workplaceRoundValues_table = workplaceRoundValues_table;
          var tableindexvalues = new Array();
          // För att inte få med dubletter
          var tlength = this.workplaceRoundValues_table.length | 0;
          for (var tableindex = 0; tableindex < tlength; tableindex++) {
            if (
              tableindexvalues.indexOf(
                this.workplaceRoundValues_table[tableindex].Id
              ) == -1
            ) {
              tableindexvalues.push(
                this.workplaceRoundValues_table[tableindex].Id
              );
              this.workplaceRoundValues_table2.push(
                this.workplaceRoundValues_table[tableindex]
              );
            }
          }
        }
      );
  }

  displayTreeItem(id:any) {
    if (this.orgmodel) {
      if (this.oldid != id) {
        this.oldid = id;
        var count = Object.keys(this.orgmodel).length;
        this.itemArray = new Array();
        for (let nthItem = 0; nthItem < count; nthItem++) {
          if (
            this.orgmodel[nthItem].Parent == id ||
            (this.itemArray &&
              this.itemArray.indexOf(+this.orgmodel[nthItem].Parent) > -1)
          ) {
            this.itemArray.push(this.orgmodel[nthItem].Id);
          }
        }
      } else {
        this.oldid = null;
        this.itemArray = [];
      }
      return this.itemArray;
    }
  }

  // A function for the filetype
  getImage(file:any,entrance:any) {
    this.dataService
      .fileInfo(environment.catalogue, file)
      .subscribe((response) => {
        this.dataService.fileInfoUpdated.next(response);
      });
    this.dataService
      .fileInfoListener()
      .pipe(take(1))
      .subscribe((response) => {

      if (typeof response != "undefined") {
          this.filetypeinfo=response;
          for (var i = 0; i < this.filetypeinfo.length; i++)
          {
            this.fileNames.push(this.filetypeinfo[i].File);
          }
      }
    });
  }

  goBack() {
    this.routing.navigate(['/workplace']);
  }

  selectIssue(id: any) {
    this.routing.navigate(['workplaceitem/' + this.workplaceitem.Id + '/workplaceissueitem/' + id]);
  }

  editIssue(id: any) {
    this.routing.navigate(['workplaceitem/' + this.workplaceitem.Id + '/workplaceissueedit/' + id]);
  }

  sendmail() {
    const modalRef = this.modalService.open(ModalSendEmailWorkplaceComponent);
    modalRef.componentInstance.workplaceitem = this.workplaceitem;
    modalRef.componentInstance.workplaceValues = this.workplaceRoundValues_table;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe(() => {
      window.location.reload();
    })
  }

  download() {
    this.displayAlert=true;
    this.pdfService.createPDF('workplace',this.workplaceitem, this.workplaceitem.Id, this.workplaceRoundValues_table, false);
    setTimeout(() => { this.displayAlert = false }, 5000);
  }

  editItem() {
    this.routing.navigate(['/editworkplace', this.workplaceitem.Id]);
  }

  addworkplaceitem() {
    this.routing.navigate(['workplaceitem/' + this.workplaceitem.Id + '/addworkplaceissue']);
  }

  openDocumentInNewWindow(fileId:any) {
    this.uploadService.getFileAsTrustedResourceUrl(fileId);
    this.uploadService.readFileDataListener().pipe(take(1)).subscribe({
      next: content => {

        // var popup = window.open('')
        // popup.location.href = pdfContent;
        //this.santizedFile = content;
        let opened=window.open(content,"_blank");
          if(!opened){
            window.location.href=content;
          }
      },
      error: error => {
      }
    })
  }

  riskvalues(item:any) {
    if (!item.roundSeverity || !item.roundPossibility || !item.roundFrequency) {
      return "fa fa-info-circle";
    }
    if(item.roundSeverity==1 || item.roundSeverity==2 || item.roundSeverity==3 || item.roundSeverity==4 || item.roundSeverity==5){
      if(item.roundFrequency==1 || item.roundFrequency==2 || item.roundFrequency==3 || item.roundFrequency==4 || item.roundFrequency==5){
        return "numberSquareGreenSmall";
      }
    }
    if(item.roundSeverity==6 || item.roundSeverity==7 || item.roundSeverity==8 || item.roundSeverity==9 || item.roundSeverity==10){
      if(item.roundFrequency==6 || item.roundFrequency==7 || item.roundFrequency==8 || item.roundFrequency==9 || item.roundFrequency==10){
        return "numberSquareRedSmall";
      }
    }
    return "numberSquareYellowSmall";
  }

  riskvaluesNumber(item:any) {
    if (!item.roundSeverity || !item.roundPossibility || !item.roundFrequency) {
      return "";
    }
    if(item.roundSeverity==1 || item.roundSeverity==2 || item.roundSeverity==3 || item.roundSeverity==4 || item.roundSeverity==5){
      if(item.roundFrequency==1 || item.roundFrequency==2 || item.roundFrequency==3 || item.roundFrequency==4 || item.roundFrequency==5){
        if(item.roundPossibility==1 || item.roundPossibility==2 || item.roundPossibility==3 || item.roundPossibility==4 || item.roundPossibility==5){
          return 1;
        }else{
          return 2;
        }
      }else{
        if(item.roundPossibility==1 || item.roundPossibility==2 || item.roundPossibility==3 || item.roundPossibility==4 || item.roundPossibility==5){
          return 2;
        }else{
          return 3;
        }
      }
    }else{
      if(item.roundFrequency==1 || item.roundFrequency==2 || item.roundFrequency==3 || item.roundFrequency==4 || item.roundFrequency==5){
        if(item.roundPossibility==1 || item.roundPossibility==2 || item.roundPossibility==3 || item.roundPossibility==4 || item.roundPossibility==5){
          return 3;
        }else{
          return 4;
        }
      }else{
        if(item.roundPossibility==1 || item.roundPossibility==2 || item.roundPossibility==3 || item.roundPossibility==4 || item.roundPossibility==5){
          return 4;
        }else{
          return 5;
        }
      }
    }
  }

  splitimageName(image:any, entrance:any) {
    if (typeof this.filetypeinfo == "undefined" || this.filetypeinfo == null) {
      return "none";
    } else {
      for (var n = 0; n < this.filetypeinfo.length; n++) {
        if (this.filetypeinfo[n].File == image) {
          return this.filetypeinfo[n].thisFileName.slice(0,40);
        }
      }
    }
  }
}
