import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { Usermodel } from 'src/app/shared/model_view/usermodel';
import { Managementsystemmodel } from 'src/app/shared/model_view/managementsystemmodel';
import { MessageService } from 'src/app/shared/services/message.service';
import { DataService } from 'src/app/shared/services/data.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { take } from "rxjs/operators";
import { Orgmodel } from 'src/app/shared/model_view/orgmodel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddFileComponent } from 'src/app/modals/modal-add-file/modal-add-file.component';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './management-system.component.html',
  styleUrls: ['./management-system.component.css']
})
export class ManagementSystemComponent implements OnInit {
  usermodel:any;
  UploadUrlMan = environment.UploadUrlMan;
  private userModelSub: Subscription;
  userId:string;
  subscription:any;
  filedescription:string;
  showTab1:boolean=true;
  showTab2:boolean=false;
  dep:string="led";
  organisation:any;
  orgmodel: any;
  localOrgId:any;
  is_admin:boolean;
  is_super_user:boolean;
  managementSystemModel:any;
  private managementSystemModelSub: Subscription;
  orgId:any;
  subscriptions:any;

  constructor(private msgService: MessageService,
    private authService:AuthService,
    private dataService:DataService,
    private modalService:NgbModal,
    private uploadService: FileUploadService) { }

  ngOnInit() {
    this.is_admin=this.authService.getAdmin();
    this.is_super_user=this.authService.getSuperUser();
    this.organisation=this.authService.getOrgid();
    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.dataService.getUserInfo(this.localOrgId);
    this.userModelSub = this.dataService.getUserModelListener()
      .subscribe((usermodel:any) => {
        this.usermodel = usermodel[0].name;
        this.msgService.sendMessage(this.usermodel);
      });

    this.orgId = this.authService.getOrgid();
    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService.orgModelListener()
    .pipe(take(1))
      .subscribe((orgmodel: Orgmodel) => {
        this.orgmodel = orgmodel;
      }
      );

      this.dataService.getFilesManagement(this.dep, this.localOrgId);
      this.managementSystemModelSub = this.dataService.getManagementsystemListener()
      .subscribe((managementsystemmodel:Managementsystemmodel[]) => {
        this.managementSystemModel  = managementsystemmodel;
        if ((this.managementSystemModel != null) && (this.managementSystemModel != undefined))
        {
          for(let i = 0; i < this.managementSystemModel.length; i++)
          {
            if ((this.managementSystemModel[i].orgId != this.localOrgId) && (this.managementSystemModel[i].orgId != null))
            {
              this.managementSystemModel.splice(i, 1);
            }
          }
        }
      });

      this.subscriptions=this.dataService.getSubscriptions(this.authService.getLocalOrgid());
      this.dataService.subscriptionsListener()
      .subscribe((subscriptions) => {
        this.subscriptions=subscriptions[0];
      });
  }

  removeItem(thisobject:any,i:any){
    if(confirm("Är du säker på att du vill ta bort denna ?")){
      this.managementSystemModel.splice(i,1);
      this.dataService.removeRowManagementTable(thisobject.id);
    }else{
      return;
    }
  }

  openDocumentInNewWindow(fileId:any) {
    this.uploadService.getFileIdAsTrustedResourceUrl(fileId);
    this.uploadService.readFileDataListener().pipe(take(1)).subscribe({
      next: content => {

        // var popup = window.open('')
        // popup.location.href = pdfContent;
        //this.santizedFile = content;
        let opened=window.open(content,"_blank");
          if(!opened){
            window.location.href=content;
          }
          return false;
      },
      error: error => {
      }
    })
  }

  changeTab(flik:boolean){
    if (flik == true) {
      this.dep = "led";
      this.dataService.getFilesManagement(this.dep, this.localOrgId);
      this.showTab1 = true;
      this.showTab2 = false;
    } else {
      this.dep = "egna";
      this.dataService.getFilesManagement(this.dep, this.localOrgId);
      this.showTab1 = false;
      this.showTab2 = true;
    }

    this.managementSystemModelSub = this.dataService.getManagementsystemListener()
    .subscribe((managementsystemmodel:Managementsystemmodel[]) => {
      this.managementSystemModel  = managementsystemmodel;
    });
  }

  add_document() {
    const modalRef = this.modalService.open(ModalAddFileComponent);
    modalRef.componentInstance.dep = this.dep;
    modalRef.componentInstance.department_id = this.localOrgId;
    modalRef.componentInstance.organisation_id = this.organisation;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe(() => {
      window.location.reload();
    })
  }
}
