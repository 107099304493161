<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div *ngIf="workplaceValue" class="ui card">
                <div class="computer only ui grid secondary pointing menu" style="padding:20px;width:100%">
                    <a class="item" data-target="#" (click)="changeTab(true)" [class.active]="showTab1==true">{{'workplaceitem' | translate}}</a>
                    <a *ngIf="is_admin||is_super_user" class="item" data-target="#" (click)="changeTab(false)" [class.active]="showTab2==true">{{'riskevaluation' | translate}}</a>
                    <span class="back-edit-text" (click)="goBack()"><button class="ui white button">{{"back" | translate}}</button></span>
                </div>
                <div class="mobile only ui grid secondary pointing menu" style="padding:20px;width:100%">
                    <a class="item" data-target="#" style="font-size:16px" (click)="changeTab(true)" [class.active]="showTab1==true">{{'workplaceitem' | translate}}</a>
                    <a *ngIf="is_admin||is_super_user" style="font-size:16px" class="item" data-target="#" (click)="changeTab(false)" [class.active]="showTab2==true">{{'riskevaluation' | translate}}</a>
                    <i class="ui icon left arrow" style="cursor:pointer;margin-top:15px" (click)="goBack()"></i>
                </div>
                <div *ngIf="showTab1==true" style="padding:20px;padding-top:0px">
                    <form class="ui form" #postForm="ngForm">
                        <div class="field">
                            <label>{{'sourcesofriskandrisks'|translate}}</label>
                            <input id="roundRisk" name="roundRisk" [(ngModel)]="workplaceValue.roundRisk"/>
                        </div>
                        <div class="field">
                            <label>{{'measures'|translate}}</label>
                            <input id="roundMeasure" name="roundMeasure" [(ngModel)]="workplaceValue.roundMeasure"/>
                        </div>
                        <div class="field">
                            <div class="field">
                                <label>{{'responsible'|translate}}</label>
                                <select [(ngModel)]="workplaceValue.roundResponsible2" name="roundResponsible2" required>
                                    <option disabled selected hidden>{{workplaceValue.roundResponsible2}}</option>   
                                    <option *ngFor="let userInfoitem of userInfo;let i=index" [ngValue]="userInfoitem.username">{{userInfoitem.username}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="field">
                            <label>{{'planned_done_date'| translate }}</label>
                            <input type="date" name="roundDoneDate" [value]="workplaceValue.roundDoneDate" [(ngModel)]="workplaceValue.roundDoneDate" placeholder="workplaceValue.roundDoneDate"/>
                        </div>
                        <div class="field">
                            <div class="ui checkbox" (click)="setDoneDate()">
                                <input type="checkbox" id="roundDone" name="roundDone" [value]="roundDone" [(ngModel)]="roundDone">
                                <label>{{'done'|translate}}</label>
                            </div><br>
                        </div>
                        <div class="field">
                            <input class="ui button" type="file" (change)="imageUpload($event)" placeholder="Upload file" accept="application/msword,
                                application/vnd.ms-excel,
                                application/vnd.ms-powerpoint,
                                text/plain,
                                application/pdf,
                                image/*,
                                video/*"/> 
                                
                            <div [hidden]="!uploadFileDone" class="ui segment">
                                <div class="ui active loader"></div>
                                <p></p>
                            </div><br><br><br>
                        
                            <div class="ui grid item" *ngFor="let file of fileNames; let i=index" style="width:100%;margin-left:20px;padding:0px">
                                <div class="row" *ngIf="filetypeinfo&&filetypeinfo[i]" style="margin:0px;padding:0px">
                                    <div class="eight wide column" style="margin:0px;padding:0px">
                                        <i class="ui icon file alternate outline"></i>{{filetypeinfo[i].thisFileName}}
                                    </div>
                                    <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                        <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="ui grid item" *ngFor="let file of selectedFile; let i=index" style="width:100%;margin-left:20px;padding:0px">
                                <div class="row" style="margin:0px;padding:0px">
                                    <div class="eight wide column" style="margin:0px;padding:0px">
                                        <i class="ui icon file alternate outline"></i>{{file.name}}
                                    </div>
                                    <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                        <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                    </div>
                                </div>
                            </div><br>
                        </div>
                        <div class="field">
                            <div class="ui modal">
                                <div class="ui range" id="smooth"></div>
                            </div>
                        </div>
                        <hr>
                        <div class="field">
                            <button [disabled]="uploadFileDone" style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                        </div>
                    </form>
                </div>
                <div *ngIf="(showTab2 == true)&&(is_admin || is_super_user)" style="padding:20px;padding-top:0px">
                    <div class="ui grid row">
                        <div class="fifteen wide column"></div>
                        <div class="one wide column">
                            <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1">
                                <div class="{{riskvaluesModal(workplaceValue.roundSeverity, workplaceValue.roundPossibility, workplaceValue.roundFrequency,'panel')}}">
                                    {{riskvaluesModalNumber(workplaceValue.roundSeverity, workplaceValue.roundPossibility, workplaceValue.roundFrequency)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <form class="ui form" #postForm="ngForm">
                        <div class="field">
                            <label for="riskSeverity">{{'Severity'|translate}}<br/>{{severityValues(workplaceValue.roundSeverity)}}</label>
                            <input type="range"
                                    class="form-control"
                                    id="riskSeverity"
                                    min="1"
                                    max="10"
                                    step="1"
                                    list="ticks"
                                    name="riskSeverity"
                                    [value]="workplaceValue.roundSeverity"
                                    [(ngModel)]="workplaceValue.roundSeverity" />
                        </div>
                        <div class="field">
                            <textarea id="riskSeverityComment"
                                    name="riskSeverityComment"
                                    style="width: 100%;"
                                    rows="2"
                                    [value]="workplaceValue.roundSeverityComment"
                                    [(ngModel)]="workplaceValue.roundSeverityComment">
                            </textarea>
                        </div>
                        <div class="field">
                            <label for="riskFrequency">{{'Frequency'|translate}}<br/>{{frequencyValues(workplaceValue.roundFrequency)}}</label>
                            <input type="range" class="form-control" id="riskFrequency" name="riskFrequency" min="1" max="10" step="1"
                                list="ticks" [value]="workplaceValue.roundFrequency" [(ngModel)]="workplaceValue.roundFrequency" />
                        </div>
                        <div class="field">
                            <textarea id="riskFrequencyComment"
                                    name="riskFrequencyComment"
                                    style="width: 100%;"
                                    rows="2"
                                    [value]="workplaceValue.roundFrequencyComment"
                                    [(ngModel)]="workplaceValue.roundFrequencyComment"></textarea>
                        </div>
                        <div class="field">
                            <label for="riskPossibility">{{'Possibility'|translate}}<br />{{possibilityValues(workplaceValue.roundPossibility)}}</label>
                            <input type="range" class="form-control" id="riskPossibility" name="riskPossibility" min="1" max="10"
                                step="1" list="ticks" [value]="workplaceValue.roundPossibility" [(ngModel)]="workplaceValue.roundPossibility" />
                            </div>
                        <div class="field">
                            <textarea id="riskPossibilityComment"
                                    name="riskPossibilityComment"
                                    style="width: 100%;"
                                    rows="2"
                                    [value]="workplaceValue.roundPossibilityComment"
                                    [(ngModel)]="workplaceValue.roundPossibilityComment"></textarea>
                        </div>
                        <div class="field">
                            <label for="roundRiskComment">{{'commenthazard'|translate}}:</label>
                            <textarea id="roundRiskComment" rows="2" name="roundRiskComment" [value]="workplaceValue.roundRiskComment"
                                [(ngModel)]="workplaceValue.roundRiskComment">
                            </textarea>
                        </div>
                        <div class="field">           
                            <button style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="updateItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
