<div class="ui stackable grid">
    <div class="row">
      <div class="sixteen wide column">
        <div class="ui card">
          <div class="header-text">
            <h1>{{'workplaces'|translate}}
            <button class="ui right floated positive button" *ngIf="(is_admin||is_super_user)" style="margin-left:20px;margin-bottom:0" (click)="addItem()"><i class="ui icon add"></i>{{'ADD_WORKPLACE' | translate}}</button></h1>
          </div>
          <div class="ui stackable grid" style="margin:0px">
            <div class="row" style="padding-bottom:0px">
              <div class="eight wide column">
                <div class="ui search" style="margin:20px;width:96%;margin-left:4%;">
                  <div class="ui icon input" style="width:100%">
                    <input class="prompt" type="text" type="text" name="searchText" id="searchText" placeholder="{{'searchall'|translate}}" [(ngModel)]="searchText" (keyup)="filterTableValue()">
                    <i class="search icon"></i>
                  </div>
                  <div class="results"></div>
                </div>
              </div>
              <div class="eight wide column" style="padding:30px">
                <div class="ui toggle checkbox" >
                  <input type="checkbox" name="public" [checked]="doNotShowSubDepartment!=true" (click)="changeShowSubdepartment()">
                  <label>{{'showsubdivisions'|translate}}</label>
                </div>
              </div>
            </div>
          </div>
          <table *ngIf="workplaceRoundValues_table2&&workplaceRoundValues_table2.length!=0" datatable [dtOptions]="dtOptions" class='ui hover table' id='safetytable' style="padding-left:20px;cursor:pointer">
            <thead>
              <tr>
                <th>{{'date'|translate}}</th>
                <th>{{'responsible'|translate}}</th>
                <th>{{'department'|translate}}</th>
                <th width="40%">{{'header'|translate}}</th>
                <th>{{'ROUNDS_DONE'|translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let workplaceRoundValue of workplaceRoundValues_table2 | filter : 'safetyround' : searchText;let i=index " (click)="viewItem(workplaceRoundValue.Id)">
                <td *ngIf="workplaceRoundValue">{{workplaceRoundValue.doneDate}}</td>
                <td *ngIf="workplaceRoundValue.Supervisor&&workplaceRoundValue.Supervisor.length>=20" data-toggle="tooltip" title="{{workplaceRoundValue.Supervisor}}">{{wordbreak(workplaceRoundValue.Supervisor)}}</td>
                <td *ngIf="workplaceRoundValue.Supervisor&&workplaceRoundValue.Supervisor.length<20">{{workplaceRoundValue.Supervisor}}</td>
                <td>{{workplaceRoundValue.Department}}</td>
                <td *ngIf="workplaceRoundValue.Title.length>=100" data-toggle="tooltip" title="{{workplaceRoundValue.Title}}">{{wordbreak(workplaceRoundValue.Title)}}...</td>
                <td *ngIf="workplaceRoundValue.Title.length<100">{{workplaceRoundValue.Title}}</td>
                <td>
                  <div *ngIf="showRoundInfo(workplaceRoundValue.Id) as variable">
                    <button class="ui negative button" *ngIf="variable.B==true">{{variable.A}}</button>
                    <button class="ui positive button" *ngIf="variable.B==false">{{variable.A}}</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
