import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.css']
})
export class AddNewsComponent implements OnInit {

  newInformation: any;
  showValue: any = false;
  is_admin: any;
  is_super_user: any;
  information =  {
    Id: 0,
    Date: new Date,
    Show: 0,
    Title: "",
    Text: "",
    Userid: null
  }

  constructor(private authService: AuthService,
    private router:Router,
    private dataService: DataService,
    private msgService: MessageService,) { }

  ngOnInit(): void {
    this.newInformation = false;

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    this.msgService.sendMessage(null);
  }

  informationAdd(){
    this.dataService.addInformation(this.information);
  }

  goBack() {
    this.router.navigate(['/controlpanel/news']);
  }

  checkbox(){
    if(!this.showValue){
      this.showValue = true;
      this.information.Show = 1;
    }
    else{
      this.showValue=!this.showValue;
      this.information.Show = 0;
    }
  }
}
