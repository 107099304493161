<div *ngIf="isAuthenticated" id="samtecapp">
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1">
  <div *ngIf="isAuthenticated">
    <app-header></app-header>
    <div class="ui fluid stackable container" id="samtecapp2">
      <div class="ui sidebar vertical menu" style="width:260px;">
        <app-navigation></app-navigation>
      </div>
      <div class="pusher" style="background-color:black;margin:0px;">
        <button class="ui greybutton button" tooltips tooltip-smart="true" style="border-radius: 0 !important" title="{{'ORGANISATION_TREE' | translate}}" (click)="toggleNavBar()">
          <div *ngIf="!toggle"><i class="ui icon angle double right"></i></div>
          <div *ngIf="toggle"><i class="ui icon angle double left"></i></div>
        </button>
        <div class="ui stackable grid" style="margin:0;width:100%">
          <div [ngClass]="toggle == true ? 'thirteen wide column' : 'sixteen wide column'">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
<div *ngIf="!isAuthenticated">
  <router-outlet></router-outlet>
</div>
