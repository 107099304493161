import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { Orgmodel } from '../../shared/model_view/orgmodel';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { SafetyRoundModel, riskValueModel } from "src/app/shared/model_view/safetymodel";
import { take } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-workplace-edit',
  templateUrl: './workplace-edit.component.html',
  styleUrls: ['./workplace-edit.component.css']
})
export class WorkplaceEditComponent implements OnInit {

  workplaceRoundResponsible: string;
  userId: any;
  userInfo: any;
  workplaceRoundDate: string;
  depname: any;
  workplaceid: any;
  workplaceitem: any;
  filetypeinfo: any;
  fileNames: any = [];
  workplaceRoundParticipants: string;
  workplaceRoundHeader: string;
  workplaceRoundDescription: string;
  orgmodel: any;
  selectedFile: any = [];
  files: any = [];
  filenames: any = [];
  file: any;
  item:any = [];
  error: any;
  filesExisting: any;
  localOrgId: any;
  orgId: any;
  uploadFileDone: boolean = false;
  is_admin: any;
  is_super_user: any;
  itemArray: any;
  doNotShowSubDepartment: boolean = false;
  oldid: any;
  usermodel: any;

  constructor(private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $("#workplaceRoundResponsibleChose").dropdown();
    $("#depnameChose").dropdown();
  }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.workplaceid = params['id'];
    });

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.orgId = this.authService.getOrgid();

    if (this.localOrgId) {
      this.dataService.getUserInfo(this.localOrgId);
      this.dataService
        .getUserModelListener()
        .subscribe((usermodel: any) => {
          if (usermodel[0].name) {
            this.usermodel = usermodel[0].name;
          }
      });
    }

    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService
      .orgModelListener()
      .pipe(take(1))
      .subscribe((orgmodel: Orgmodel) => {
        this.orgmodel = orgmodel;
        if (this.doNotShowSubDepartment == true) {
          this.itemArray = null;
        } else {
          this.itemArray = this.displayTreeItem(this.localOrgId);
        }

        if (this.orgmodel) {
          var count=this.orgmodel.length;
          for (var n = 0; n < count; n++) {
            if (this.orgmodel[n].Name == this.usermodel) {
              this.depname = this.orgmodel[n].Name;
            }
          }
        }
    });

    var file = "";
    this.dataService.getWorkplaceItembyId(this.workplaceid);
    this.dataService
      .workplaceItemModelUpdatedEditListener()
      .pipe(take(1))
      .subscribe((workplace_item: any) => {
        this.workplaceitem = workplace_item.workplaceitemvalue[0];
        if (this.workplaceitem.File != null)
        {
          for (var f = 0;f < this.workplaceitem.File.split(",").length;f++) {
            if (this.workplaceitem.File != "") {
              file += "|" + this.workplaceitem.File.split(",")[f];
            }
          }
          this.getImage(file,'');
          this.filesExisting = 0;
          if (this.workplaceitem.File.length > 1)
          {
            this.files = this.workplaceitem.File.split(",");
            this.filesExisting += 1;
          }
        }

        this.workplaceRoundResponsible=this.workplaceitem.Supervisor;
        this.workplaceRoundDate = this.workplaceitem.doneDate;
        this.depname = this.workplaceitem.Department;
        this.workplaceRoundParticipants = this.workplaceitem.Participants;
        this.workplaceRoundHeader = this.workplaceitem.Title;
        this.workplaceRoundDescription = this.workplaceitem.Text;
    });

    this.dataService.getUsers(this.authService.getOrgid(), this.userId);
    this.dataService.usersListener().subscribe((users) => {
      this.userInfo = users;
    });
  }

  displayTreeItem(id:any) {
    if (this.orgmodel) {
      if (this.oldid != id) {
        this.oldid = id;
        var count = Object.keys(this.orgmodel).length;
        this.itemArray = new Array();
        for (let nthItem = 0; nthItem < count; nthItem++) {
          if (
            this.orgmodel[nthItem].Parent == id ||
            (this.itemArray &&
              this.itemArray.indexOf(+this.orgmodel[nthItem].Parent) > -1)
          ) {
            this.itemArray.push(this.orgmodel[nthItem].Id);
          }
        }
      } else {
        this.oldid = null;
        this.itemArray = [];
      }
      return this.itemArray;
    }
  }

  setRoundResponsible(userInfo:any) {
    this.workplaceRoundResponsible = userInfo.first_name + " " + userInfo.last_name + " " + userInfo.email;
  }  

  setDepartment(department: any) {
    this.depname = department.Name + "|" + department.Id;
    for (let i = 0; i < this.itemArray.length; i++)
    {
      if (this.itemArray[i] == this.depname.Id)
      {
        this.localOrgId = this.itemArray[i];
      }
    }
  }

  // A function for the filetype
  getImage(file:any,entrance:any) {
    this.dataService
      .fileInfo(environment.catalogue, file)
      .subscribe((response) => {
        this.dataService.fileInfoUpdated.next(response);
      });
    this.dataService
      .fileInfoListener()
      .pipe(take(1))
      .subscribe((response) => {

      if (typeof response != "undefined") {
          this.filetypeinfo=response;
          for (var i = 0; i < this.filetypeinfo.length; i++)
          {
            this.fileNames.push(this.filetypeinfo[i].File);
          }
      }
    });
  }

  deleteItem() {
    var tmpString = this.translate.instant('DO_YOU_WANT_TO_REMOVE')
    var deleteQuestion = confirm(tmpString);
    if (deleteQuestion == true) {
      this.dataService.deleteWorkplace(this.workplaceitem.Id);
    } 
  }

  imageUpload(event:any) {
    this.uploadFileDone = true,
    this.uploadService.upload(event.target.files[0], "");
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {
      if (filename == null)
      {
        Swal.fire("Error", "Error");
      }
      else
      {
        this.files.push(filename);
        setTimeout(() => {
          this.uploadFileDone = false;

          this.selectedFile.push(event.target.files[0]);
    
          if (this.files) {
            this.filenames.push(event.target.files[0].name);
          } else {
            this.filenames = event.target.files[0].name;
          }
          this.item.push(1);
        }, 1000);
      }
    });
  }

  removeImage(file: any, index: any) {
    this.file = "";

    this.filetypeinfo.splice(index, 1);
    this.fileNames.splice(index, 1);

    var x = this.files.splice(index, 1);

    if (this.files.length + 1 == 1)
    {
      this.workplaceitem.File = this.workplaceitem.File.replace(
        x,
        ""
      );
    }

    if (this.files.length + 1 > 1)
    {
      if (index == 0)
      {
        this.workplaceitem.File = this.workplaceitem.File.replace(
          x + ",",
          ""
        );
      }
      else
      {
        this.workplaceitem.File = this.workplaceitem.File.replace(
          "," + x,
          ""
        );
      }
    }
  }

  goBack() {
    this.routing.navigate(['workplaceitem/', this.workplaceid]);
  }

  updateItem() {

    if (!this.workplaceRoundResponsible ||!this.workplaceRoundParticipants ||!this.workplaceRoundHeader) {
      if (!this.workplaceRoundResponsible) {
        this.error.push("Fyll i ansvarig");
      }
      if (!this.workplaceRoundParticipants) {
        this.error.push("Fyll i deltagare");
      }
      if (!this.workplaceRoundHeader) {
        this.error.push("Fyll i rubrik");
      }
      return;
    }

    if(!this.filesExisting){this.filesExisting=0;}
    this.file = this.workplaceitem.File;
    if (this.file == null)
    {
      this.file = "";
    }
    
    if(!this.filesExisting){this.filesExisting=0;}
    this.file = this.workplaceitem.File;
    for (var i = 0; i < this.selectedFile.length; i++) {
      if (this.selectedFile[i]) {
        if (i != 0 || (i == 0 && this.file != "")) {
          this.file += ",";
        }
        this.file += this.files[i + this.filesExisting];
      }
    }

    this.workplaceitem.Supervisor = this.workplaceRoundResponsible;
    this.workplaceitem.Participants = this.workplaceRoundParticipants;
    this.workplaceitem.Text = this.workplaceRoundDescription;
    this.workplaceitem.Title = this.workplaceRoundHeader;
    this.workplaceitem.doneDate = this.workplaceRoundDate.substr(0, 10);
    this.workplaceitem.File = this.file;
    this.workplaceitem.Department = this.depname;
    this.workplaceitem.orgId = this.localOrgId;
    this.dataService.updateWorkplace(this.workplaceitem);
  }
}
