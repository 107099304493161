import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take,concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  username: any;
  companyModel: any;
  epost: any;
  firstname: any;
  lastname: any;
  phone: any;
  depname: any;
  token: any;
  static_token: any;
  userlevel: any;
  password: any;
  orgmodel: any;
  is_admin: any;
  is_super_user: any;
  orgId: any;
  userInfo: any;
  users: any;
  usermodel: any;
  userid: any;
  static_token_text: any;

  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {

    this.route.params.pipe(take(1)).subscribe(params => {
      this.userid = params['id'];

      this.dataService.getUserById(this.userid);
      this.dataService.usersListener2()
      .subscribe((usermodel:any) => {
        this.usermodel = usermodel[0];
        this.orgId = this.usermodel.organisation_id;

        if(this.usermodel.is_admin==1){
          this.userlevel='2';
        }else if(this.usermodel.is_super_user==1){
          this.userlevel='1';
        }else{
          this.userlevel='0';
        }

        this.usermodel.password = "";

        if (environment.tokentext == "test")
        {
          this.static_token_text = 'http://test.samtec.se/login?access_token=' + this.usermodel.static_token;
        }
        else
        {
          this.static_token_text = 'http://portal.samtec.se/login?access_token=' + this.usermodel.static_token;
        }

        this.depname = this.usermodel.department;

        this.is_admin=this.authService.getAdmin();
        this.is_super_user=this.authService.getSuperUser();

        this.dataService.getOrganisationSchemaControlPanel(this.orgId);
        this.dataService.orgModelListenerControlPanel()
        .pipe(take(1))
        .subscribe((orgmodel: any) => {
          this.orgmodel = orgmodel;
        });
      });
    });

    this.msgService.sendMessage(null);
  }

  generateToken(){
    this.usermodel.static_token = uuidv4();
    if (environment.tokentext == "test")
    {
      this.static_token_text = 'http://test.samtec.se/login?access_token=' + this.usermodel.static_token;
    }
    else
    {
      this.static_token_text = 'http://portal.samtec.se/login?access_token=' + this.usermodel.static_token;
    }
  }

  updateItem() {
    if(this.userlevel==='2'){
      this.usermodel.is_admin=1;
      this.usermodel.is_super_user=0;
    }else if(this.userlevel=='1'){
      this.usermodel.is_super_user=1;
      this.usermodel.is_admin=0;
    }else{
      this.usermodel.is_super_user=0;
      this.usermodel.is_admin=0;
    }

    this.usermodel.department = this.depname;

    this.dataService.updateUserInfo(this.usermodel, this.usermodel.id, this.orgId, 0)
  }

  goBack() {
    this.routing.navigate(['/controlpanel/organisations']);
  }
}
