import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from '../auth/auth.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from '../shared/services/message.service';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.css']
})
export class ControlPanelComponent implements OnInit {

  constructor(private authService: AuthService,
    private router:Router,
    private dataService: DataService,
    private msgService: MessageService,) { }

  ngOnInit(): void {
    this.msgService.sendMessage(null);
  }

  goToNews() {
    this.router.navigate(['/controlpanel/news']);
  }

  goToOrganisations() {
    this.router.navigate(['/controlpanel/organisations']);
  }
}
