<div class="ui stackable grid" style="height:800px">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card" style="min-height:550px">
                <div class="ui grid row">
                    <div class="twelve wide column">
                        <i style="color:green;margin:0px;margin-left:40px;margin-bottom:5px;cursor:pointer" class="ui large icon edit" (click)="editIssue()"></i>
                        <label class="first-header-label" style="margin:0px;margin-top:20px">{{'workplace'|translate}}</label>
                    </div>
                    <div class="computer only four wide right aligned column" style="padding: 40px;cursor:pointer" >
                        <button class="ui white button"  (click)="goBack()">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding:10px;padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <div *ngIf="workplaceValue" class="rectangle-green-small">
                    <div class="computer only ui grid row">
                        <div class="fourteen wide column">
                            <div *ngIf="roundDone">
                                <i class="check square outline icon" style="color:white;margin-bottom: 20px"></i>
                                <label style="color: white">{{'finished'|translate}} {{workplaceValue.roundDoneRealDate | date:"yyyy-MM-dd"}}</label>
                            </div>
                            <div *ngIf="!roundDone">
                                <i class="clock icon" style="color:white;margin-bottom: 20px"></i>
                                <label style="color: white">{{'ongoing'|translate}}</label>
                            </div>
                        </div>
                        <div class="two wide right aligned column">
                            <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1">
                                <div *ngIf="workplaceValue" class="{{riskvaluesModal(workplaceValue.roundSeverity, workplaceValue.roundPossibility, workplaceValue.roundFrequency,'panel')}}">
                                    {{riskvaluesModalNumber(workplaceValue.roundSeverity, workplaceValue.roundPossibility, workplaceValue.roundFrequency)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mobile only ui grid row">
                        <div class="twelve wide column">
                            <div *ngIf="roundDone">
                                <i class="check square outline icon" style="color:white;margin-bottom: 20px"></i>
                                <label style="color: white">{{'finished'|translate}} {{workplaceValue.roundDoneRealDate | date:"yyyy-MM-dd"}}</label>
                            </div>
                            <div *ngIf="!roundDone">
                                <i class="clock icon" style="color:white;margin-bottom: 20px"></i>
                                <label style="color: white">{{'ongoing'|translate}}</label>
                            </div>
                        </div>
                        <div class="four wide right aligned column">
                            <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1">
                                <div *ngIf="workplaceValue" class="{{riskvaluesModal(workplaceValue.roundSeverity, workplaceValue.roundPossibility, workplaceValue.roundFrequency,'panel')}}">
                                    {{riskvaluesModalNumber(workplaceValue.roundSeverity, workplaceValue.roundPossibility, workplaceValue.roundFrequency)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class="header-label">{{'planned_done_date'|translate}}</label><br>
                        <label class="text-label">{{workplaceValue.roundDoneDate | date:"yyy-MM-dd"}}</label>
                    </div>
                    <div>
                        <label class="info-label">{{'responsible'|translate}}</label><br>
                        <label class="text-label">{{workplaceValue.roundResponsible2}}</label>
                    </div>
                    <div>
                        <label class="info-label">{{'shortageorcomment'|translate}}</label><br>
                        <label class="text-label">{{workplaceValue.roundRisk}}</label>
                    </div>
                    <div class="ui grid row">
                        <div class="ten wide column">
                            <label class="info-label">{{'measures'|translate}}</label><br>
                            <label class="text-label">{{workplaceValue.roundMeasure}}</label>
                        </div>
                        <div class="six wide column">
                            <div class="with-border">
                                <label class="info-label">{{'attachments'|translate}}</label>
                                <div *ngFor="let fileName of fileNames;let i=index;">
                                    <a (click)="openDocumentInNewWindow(filetypeinfo[i])" *ngIf="fileName" style="color:black;cursor:pointer">
                                        <i title="{{splitimageName(fileName,'')}}" class="ui icon file pdf outline" style="font-size:14px;color:black"></i><small>{{splitimageName(fileName,'')}}</small>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui grid row" style="margin: 20px">
                    <div class="twelve wide column"></div>
                    <div class="four wide right aligned column">
                        <button class="ui negative button" *ngIf="(is_admin||is_super_user)" (click)="removeIssue()"><i class="ui icon trash"></i>{{'REMOVE' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
