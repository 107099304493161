import { Component, OnInit} from '@angular/core';
import { DataService } from '../shared/services/data.service';
import { Usermodel } from '../shared/model_view/usermodel';
import { MessageService } from '../shared/services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { Orgmodel } from '../shared/model_view/orgmodel';
import { SortService } from 'src/app/shared/services/sort.service';
import { ModalsSelectOrganisationComponent } from 'src/app/modals/modals-select-organisation/modals-select-organisation.component';
import { take, timeout } from 'rxjs/operators';

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"],
})
export class NavigationComponent implements OnInit {
  msg: string = "";
  usermodel: Usermodel;
  usermodel2: any;
  orgmodel: any;
  tmporgmodel: Orgmodel;
  tmparrayname: Array<string>;
  dataIsClicked = false;
  subscription:any;
  usermodelname: string;
  token:any;
  isclicked: number;
  parentid: number;
  orgId: string;
  orgName:string;
  private orgSchema: any;
  mainOrg = new Array();
  localOrgId: any;
  localOrgName:string;
  is_admin: boolean;
  is_super_user: boolean;
  itemArray:any = [];
  oldid: any;
  subscriptions:any;

  constructor(
    private dataService: DataService,
    private msgService: MessageService,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private sortService: SortService
  ) {}

  ngOnInit() {
    this.dataService.getToken();
    this.token = this.dataService.tokenUpdateListener().subscribe((token) => {
      this.token = token;
    });

    this.dataService.getMainOrg();
    this.dataService.getmainorgListener().subscribe((mainOrg) => {
      if (mainOrg == null)
      {
        this.authService.logout();
      }
      this.mainOrg = mainOrg;
    });

    this.subscription = this.msgService
      .accessMessage()
      .pipe(take(1))
      .subscribe((usermodel: any) => {
        this.usermodel = usermodel;
        this.orgId = this.authService.getOrgid();
        this.dataService.getOrganisationSchema(this.orgId);
        this.orgSchema = this.dataService
          .orgModelListener()
          .subscribe((orgmodel: Orgmodel) => {
            this.orgmodel = orgmodel;
            this.orgmodel = this.sortService.sortOrgModel(this.orgmodel);
            this.localOrgId = this.authService.getLocalOrgid();
            this.orgId = this.authService.getOrgid();
            this.getName(this.localOrgId,this.orgId);
          });
      });
    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
  }

  clearData() {
    this.dataIsClicked = false;
  }

  andraorganisation() {
    const modalRef = this.modalService.open(ModalsSelectOrganisationComponent);
    modalRef.componentInstance.mainOrg = this.mainOrg;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe(() => {
      window.location.reload();
    })
  }

  addMessage(id: string) {
    this.authService.setLocalOrgid(id);
    this.msgService.sendMessageId(id);
    var url = this.router.url;
    window.location.reload();
    //this.redirectToUrl(id,url);
  }

  redirectTo(url:any) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  redirectToUrl(id:any, url:any) {
    var dashboard = false;
    if(url=="/managementsystem"||url=="/workplace"){
      this.subscriptions=this.dataService.getSubscriptions(id);
      this.dataService.subscriptionsListener()
      .subscribe((subscriptions) => {
        this.subscriptions=subscriptions[0];
        if(url=="/managementsystem"&&this.subscriptions.subscription_managementsystem==0){
          dashboard=true;
        }
        if(url=="/workplace"&&this.subscriptions.subscription_workplace==0){
          dashboard=true;
        }
      });
    }else{
      if (this.router.url == "/") {
        url = url + "dashboard";
      } else if (url.indexOf("safetyround") != -1) {
        url = "safetyround";
      } else {
        url = url;
      }
    }
    if(url=="/managementsystem"||url=="/workplace"){
      // setting up some delay to wait for the subscriptionslistener.
      setTimeout(() => {
        if(dashboard==true){
          alert("Denna organisation/avdelning har inte tillgång till modulen.");
          url="/dashboard";
        }
        this.redirectTo(url);
      }, 500);
    }else{
      this.redirectTo(url);
    }
  }

  getName(localOrgId:any,orgId:any){
    let count = Object.keys(this.orgmodel).length;
    for(let n=0;n<count;n++){
      if(this.orgmodel[n].Id==localOrgId){
        this.localOrgName=this.orgmodel[n].Name;
      }
      if(this.orgmodel[n].Id==orgId){
        this.orgName=this.orgmodel[n].Name;
      }
    }
  }
}

