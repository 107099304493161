import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManagementSystemComponent } from './management-system/management-system.component';
import { SafetyRoundComponent } from './safetyround/safetyround.component';
import { SafetyroundAddComponent } from './safetyround/safetyround-add/safetyround-add.component';
import { SafetyroundEditComponent } from './safetyround/safetyround-edit/safetyround-edit.component';
import { SafetyroundItemComponent } from './safetyround/safetyround-item/safetyround-item.component';
import { SafetyroundissueItemComponent } from './safetyround/safetyroundissue/safetyroundissue-item/safetyroundissue-item.component';
import { SafetyroundissueEditComponent } from './safetyround/safetyroundissue/safetyroundissue-edit/safetyroundissue-edit.component';
import { SafetyroundissueEditFiveComponent } from './safetyround/safetyroundissue/safetyroundissue-edit-five/safetyroundissue-edit-five.component';
import { SafetyroundissueAddComponent } from './safetyround/safetyroundissue/safetyroundissue-add/safetyroundissue-add.component';
import { WorkplaceComponent } from './workplace/workplace.component';
import { WorkplaceAddComponent } from './workplace/workplace-add/workplace-add.component';
import { WorkplaceItemComponent } from './workplace/workplace-item/workplace-item.component';
import { WorkplaceEditComponent } from './workplace/workplace-edit/workplace-edit.component';
import { WorkplaceissueAddComponent } from './workplace/workplaceissue/workplaceissue-add/workplaceissue-add.component';
import { WorkplaceissueEditComponent } from './workplace/workplaceissue/workplaceissue-edit/workplaceissue-edit.component';
import { WorkplaceissueItemComponent } from './workplace/workplaceissue/workplaceissue-item/workplaceissue-item.component';
import { IncidentComponent } from './incident/incident.component';
import { IncidentAddComponent } from './incident/incident-add/incident-add.component';
import { IncidentEditComponent } from './incident/incident-edit/incident-edit.component';
import { IncidentItemComponent } from './incident/incident-item/incident-item.component';
import { IncidentissueAddComponent } from './incident/incidentissue/incidentissue-add/incidentissue-add.component';
import { IncidentissueItemComponent } from './incident/incidentissue/incidentissue-item/incidentissue-item.component';
import { IncidentissueEditComponent } from './incident/incidentissue/incidentissue-edit/incidentissue-edit.component';
import { IncidentissueEditFiveComponent } from './incident/incidentissue/incidentissue-edit-five/incidentissue-edit-five.component';
import { ActionPlanComponent } from './action-plan/action-plan.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { OrganisationsComponent } from './control-panel/organisations/organisations.component';
import { NewsComponent } from './control-panel/news/news.component';
import { OrgItemsComponent } from './control-panel/organisations/org-items/org-items.component';
import { OrgAddComponent } from './control-panel/organisations/org-add/org-add.component';
import { UserAddComponent } from './control-panel/organisations/user-add/user-add.component';
import { UserEditComponent } from './control-panel/organisations/user-edit/user-edit.component';
import { AddNewsComponent } from './control-panel/news/add-news/add-news.component';
import { EditNewsComponent } from './control-panel/news/edit-news/edit-news.component';
import { HelpComponent } from './settings/help/help.component';
import { SettingsComponent } from './settings/settings.component';
import { AddUserComponent } from './settings/add-user/add-user.component';
import { EditUserComponent } from './settings/edit-user/edit-user.component';

const routes: Routes = [
  { path: 'login', component:LoginComponent },
  { path: 'signup', component:SignupComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'managementsystem', component: ManagementSystemComponent, canActivate: [AuthGuard] },
  { path: 'safetyround', component: SafetyRoundComponent, canActivate: [AuthGuard] },
  { path: 'addsafetyround', component: SafetyroundAddComponent, canActivate: [AuthGuard] },
  { path: 'editsafetyround/:id', component: SafetyroundEditComponent, canActivate: [AuthGuard] },
  { path: 'safetyrounditem/:id', component: SafetyroundItemComponent, canActivate: [AuthGuard] },
  { path: 'safetyrounditem/:id/addsafetyroundissue', component: SafetyroundissueAddComponent, canActivate: [AuthGuard] },
  { path: 'safetyrounditem/:id/safetyroundissueitem/:id', component: SafetyroundissueItemComponent, canActivate: [AuthGuard] },
  { path: 'safetyrounditem/:id/safetyroundissueedit/:id', component: SafetyroundissueEditComponent, canActivate: [AuthGuard] },
  { path: 'safetyrounditem/:id/safetyroundissueeditfive/:id', component: SafetyroundissueEditFiveComponent, canActivate: [AuthGuard] },
  { path: 'workplace', component: WorkplaceComponent, canActivate: [AuthGuard] },
  { path: 'addworkplace', component: WorkplaceAddComponent, canActivate: [AuthGuard] },
  { path: 'workplaceitem/:id', component: WorkplaceItemComponent, canActivate: [AuthGuard] },
  { path: 'editworkplace/:id', component: WorkplaceEditComponent, canActivate: [AuthGuard] },
  { path: 'workplaceitem/:id/addworkplaceissue', component: WorkplaceissueAddComponent, canActivate: [AuthGuard] },
  { path: 'workplaceitem/:id/workplaceissueitem/:id', component: WorkplaceissueItemComponent, canActivate: [AuthGuard] },
  { path: 'workplaceitem/:id/workplaceissueedit/:id', component: WorkplaceissueEditComponent, canActivate: [AuthGuard] },
  { path: 'incident', component: IncidentComponent, canActivate: [AuthGuard] },
  { path: 'addincident', component: IncidentAddComponent, canActivate: [AuthGuard] },
  { path: 'editincident/:id', component: IncidentEditComponent, canActivate: [AuthGuard] },
  { path: 'incidentitem/:id', component: IncidentItemComponent, canActivate: [AuthGuard] },
  { path: 'incidentitem/:id/addincidentissue', component: IncidentissueAddComponent, canActivate: [AuthGuard] },
  { path: 'incidentitem/:id/incidentissueitem/:id', component: IncidentissueItemComponent, canActivate: [AuthGuard] },
  { path: 'incidentitem/:id/incidentissueedit/:id', component: IncidentissueEditComponent, canActivate: [AuthGuard] },
  { path: 'incidentitem/:id/incidentissueeditfive/:id', component: IncidentissueEditFiveComponent, canActivate: [AuthGuard] },
  { path: 'actionplan', component: ActionPlanComponent, canActivate: [AuthGuard] },
  { path: 'controlpanel', component: ControlPanelComponent, canActivate: [AuthGuard] },
  { path: 'controlpanel/organisations', component: OrganisationsComponent, canActivate: [AuthGuard] },
  { path: 'controlpanel/addorganisation', component: OrgAddComponent, canActivate: [AuthGuard] },
  { path: 'controlpanel/organisations/:id/adduser', component: UserAddComponent, canActivate: [AuthGuard] },
  { path: 'controlpanel/organisations/:id/edituser/:id', component: UserEditComponent, canActivate: [AuthGuard] },
  { path: 'controlpanel/organisations/:id', component: OrgItemsComponent, canActivate: [AuthGuard] },
  { path: 'controlpanel/news', component: NewsComponent, canActivate: [AuthGuard] }, 
  { path: 'controlpanel/addnews', component: AddNewsComponent, canActivate: [AuthGuard] }, 
  { path: 'controlpanel/editnews/:id', component: EditNewsComponent, canActivate: [AuthGuard] }, 
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] }, 
  { path: 'settings/help', component: HelpComponent, canActivate: [AuthGuard] }, 
  { path: 'settings/adduser', component: AddUserComponent, canActivate: [AuthGuard] }, 
  { path: 'settings/edituser/:id', component: EditUserComponent, canActivate: [AuthGuard] }, 
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports:    [RouterModule.forRoot(routes, { useHash: true })],
  exports:    [RouterModule],
  providers:  [AuthGuard]
})
export class AppRoutingModule { }
