import { Injectable } from "@angular/core";
import { jsPDF } from "jspdf";
import { DataService } from 'src/app/shared/services/data.service';

@Injectable({ providedIn: "root" })

export class PDFService {



  constructor(private dataService: DataService) { }

// A function that makes a report of a round
  // entrance is from where, safetyround or incident
  // at the moment
  // round is which round it is ( a json object consisting of all possible rounds )
  // roundId is the individual roundId
  // j is a number value to keep the rows ordered
  // j is more fixed, offset more individual
  public createPDF(entrance:any, round:any, roundId:any, safetyrounds:any, attachToEmail:any) {
    var jrows=0;
    var n = 0;
    var offset = 0;
    var sida = 1;
    // For the safetyround part
    // (probably could mix the two but this is more readable)
    if (entrance == "safetyround") {
      let objectToPDF: any = new Array();
      objectToPDF.push(round);
        
      var doc = new jsPDF();
      doc.setFontSize(10);
      var img = new Image();
      img.src = "assets/samtec-logo-primar.png";
      img.onload = function () {
        doc.addImage(img, "png", 20, 10, 50, 10);
      };
      img.crossOrigin = "";
      doc.addImage(img, "png", 20, 10, 50, 10);

      // Övergripande
      var j = 0;
      doc.addImage(img, "png", 20, 10, 50, 10);
      j = j + 10;
      doc.setFont("bold");
      doc.text("Rapport", 20, 25 + j);
      doc.text("Skyddsrond", 120, 25 + j);
      doc.setFont("normal");
      j = j + 5;
      doc.line(20, 25 + j, 200, 25 + j);
      j = j + 10;
      doc.setFont("bold");
      doc.text("Kategori:", 20, 25 + j);
      doc.setFont("normal");

      if(objectToPDF[0].category!=null){
        doc.text(objectToPDF[0].category.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Ansvarig:", 20, 25 + j);
      doc.setFont("normal");
      if(objectToPDF[0].Supervisor!=null){
        doc.text(objectToPDF[0].Supervisor.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Datum:", 20, 25 + j);
      doc.setFont("normal");
      if( objectToPDF[0].doneDate!=null){
        doc.text(objectToPDF[0].doneDate.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Avdelning:", 20, 25 + j);
      doc.setFont("normal");
      if( objectToPDF[0].Department!=null){
        doc.text(objectToPDF[0].Department.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Deltagare:", 20, 25 + j);
      doc.setFont("normal");
      if( objectToPDF[0].Participants!=null){
        doc.text(objectToPDF[0].Participants.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      var xi = 1;
      doc.setFont("bold");
      doc.text("Rubrik:", 20, 30 + j);
      doc.setFont("normal");
      if (objectToPDF[0].Title.length < 80) {
        doc.text(objectToPDF[0].Title.replace(/(\r\n|\n|\r)/gm, " ").toString(), 70, 30 + j);
        j = j + 5;
      } else {
        let words=objectToPDF[0].Title.split(' ');
        let g=0;
        let wordline="";
        j = j + 5;
        while(g < words.length){
           wordline=wordline+' '+words[g];
           g++;
           if(wordline.length>100){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              20,
              30 + j +jrows
            );
            wordline="";
            jrows = jrows + 5;
           }
           if(g==words.length){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              20,
              30 + j + jrows
            );
           }
        }
        j = j + jrows;
      }
      jrows=0;
      j=j+5;
      doc.setFont("bold");
      doc.text("Text:", 20, 25 + j);
      doc.setFont("normal");
      if (objectToPDF[0].Text.length < 80) {
        doc.text(objectToPDF[0].Text.replace(/(\r\n|\n|\r)/gm, " ").toString(),
          70,
          25 + j
        );
        j = j + 5;
      } else {
        let words=objectToPDF[0].Text.split(' ');
        let g=0;
        let wordline="";
        j = j + 5;
        while(g < words.length){
           wordline=wordline+' '+words[g];
           g++;
           if(wordline.length>100){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              20,
              25 + j +jrows
            );
            wordline="";
            jrows = jrows + 5;
           }
           if(g==words.length){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              20,
              25 + j + jrows
            );
           }
        }
        j = j + jrows;
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Rondpunkter", 120, 30 + j);
      doc.setFont("normal");
      doc.line(20, 35 + j, 200, 35 + j);
      j = j + 10;
      if (roundId != null) {
        for (let i = 0; i < safetyrounds.length; i++) {
          // 50 is a usual size for the round
          if ((n+offset+j+50) > 300) {
            doc.text("Sida " + sida++, 180, 290);
            doc.addPage("1", "p");
            var img = new Image();
            img.src = "assets/samtec-logo-primar.png";
            img.onload = function () {
              doc.addImage(img, "png", 20, 10, 50, 10);
            };
            img.crossOrigin = "";
            offset = 0;
            n = 0;
            j = 0;
            doc.addImage(img, "png", 20, 10, 50, 10);
            j = j + 10;
            doc.setFont("bold");
            doc.text("Rapport", 20, 25 + j);
            doc.text("Skyddsronder", 120, 25 + j);
            doc.text("Rondpunkter", 120, 35 + j);
            doc.setFont("normal");
            j = j + 15;
            doc.line(20, 22 + j, 200, 22 + j);
            j = j + 5;
          } else {
            offset = n;
          }
          doc.setFont("bold");
          doc.text("Plats", 20, 30 + j + offset);
          doc.text("Ansvarig", 70, 30 + j + offset);
          doc.text("Datum", 120, 30 + j + offset);
          doc.text("Utfört", 150, 30 + j + offset);
          doc.text("Åtgärd effektiv", 170, 30 + j + offset);
          doc.setFont("normal");
          n = n + 5;
          if(safetyrounds[i].roundLocation!=null){
            doc.text(safetyrounds[i].roundLocation.toString(), 20, 35 + j + offset);
          }else{
            doc.text('', 20, 35 + j + offset);
          }

          if(safetyrounds[i].roundResponsible2!=null){
            doc.text(safetyrounds[i].roundResponsible2.toString(), 70, 35 + j + offset);
          }else{
            doc.text('', 70, 35 + j + offset);
          }

          if(safetyrounds[i].roundDoneDate!=null){
            doc.text(safetyrounds[i].roundDoneDate.toString(), 120, 35 + j + offset);
          }else{
            doc.text('', 120, 35 + j + offset);
          }

          if(safetyrounds[i].roundDone!=null){
            doc.text(safetyrounds[i].roundDone.toString(), 150, 35 + j + offset);
          }else{
            doc.text('', 150, 35 + j + offset);
          }

          if(safetyrounds[i].roundActive!=null){
            doc.text(safetyrounds[i].roundActive.toString(), 170, 35 + j + offset);
          }else{
            doc.text('', 170, 35 + j + offset);
          }

          n = n + 10;
          var riskvalues=this.riskvalues(safetyrounds[i].roundSeverity,safetyrounds[i].roundFrequency,safetyrounds[i].roundPossibility);
          doc.setFont("bold");
          doc.text("Brist:", 20, 45 + j + offset);
          doc.text("Riskvärde:", 160, 45 + j + offset);

          if(riskvalues!=null){
            doc.text(riskvalues.toString(), 180, 45 + j + offset);
          }else{
            doc.text('', 180, 45 + j + offset);
          }
          doc.setFont("normal");
          jrows = 0;
          if (safetyrounds[i].roundRisk.length < 100) {
            doc.text(safetyrounds[i].roundRisk.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              50,
              45 + j + offset
            );
            n = n + 5;
          } else {
            let words=safetyrounds[i].roundRisk.split(' ');
        let g=0;
        let wordline="";
        while(g < words.length){
           wordline=wordline+' '+words[g];
           g++;
           if(wordline.length>100){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              20,
              50 + j +jrows+offset
            );
            wordline="";
            jrows = jrows + 5;
           }
           if(g==words.length){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              20,
              50 + j + jrows+offset
            );
            j=j+5;
           }
        }
            j = j + jrows;
            n = n + jrows;
          }
          doc.setFont("bold");
          doc.text("Åtgärdsförslag:", 20, 50 + j + offset);
          doc.setFont("normal");
          jrows = 0;
          if (safetyrounds[i].roundMeasure.length < 100) {
            doc.text(safetyrounds[i].roundMeasure.replace(/(\r\n|\n|\r)/gm, " ").toString(), 50, 50 + j + offset);
            n = n + 5;
            doc.line(20, 55 + j + offset, 200, 55 + j + offset);
            n = n + 5;
          } else {
            let words=safetyrounds[i].roundMeasure.split(' ');
            let g=0;
            let wordline="";
        while(g < words.length){
           wordline=wordline+' '+words[g];
           g++;
           if(wordline.length>100){
              doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20, 55 + j +jrows+offset);
              wordline="";
              jrows = jrows + 5;
           }
           if(g==words.length){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20, 55 + j + jrows+offset);
           }
        }
        j = j + jrows;
            n = n + jrows;
            doc.line(20, 55 + j + jrows + offset, 200, 55 + j + jrows + offset);
            n = n + 10;
          }
        }
      }
      doc.text("Sida " + sida++, 180, 290);
      if(attachToEmail==true){

        // Appropiately difficult to write filename by accident
        var blob = doc.output('blob');
        return blob;
      }else{
        doc.save("Rapport_Skyddsrond.pdf");
      }
    }
    if (entrance == "incident") {
      let objectToPDF: any = new Array();
      let roundMax = 0;
      objectToPDF.push(round);
      let iMax = objectToPDF.length;
      var doc = new jsPDF();
      doc.setFontSize(10);
      var img = new Image();
      img.src = "assets/samtec-logo-primar.png";
      img.onload = function () {
        doc.addImage(img, "png", 20, 10, 50, 10);
      };
      img.crossOrigin = "";
      doc.addImage(img, "png", 20, 10, 50, 10);
      j = 0;n = 0;
      doc.addImage(img, "png", 20, 10, 50, 10);
      j = j + 10;
      doc.setFont("bold");
      doc.text("Rapport", 20, 25 + j);
      doc.text("Incidenter", 120, 25 + j);
      doc.setFont("normal");
      j = j + 5;
      doc.line(20, 25 + j, 200, 25 + j);
      j = j + 10;
      doc.setFont("bold");
      doc.text("Typ av incident:", 20, 25 + j);
      doc.setFont("normal");
      if(objectToPDF[0].typeofaction!=null){
        doc.text(objectToPDF[0].typeofaction.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Ansvarig:", 20, 25 + j);
      doc.text("Typ av skada:", 120, 20 + j);
      doc.setFont("normal");
      if(objectToPDF[0].Reporter!=null){
        doc.text(objectToPDF[0].Reporter.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      if(objectToPDF[0].category!=null){
        doc.text(objectToPDF[0].category.toString(), 170, 20 + j);
      }else{
        doc.text('', 170, 20 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Datum:", 20, 25 + j);
      doc.setFont("normal");
      if(objectToPDF[0].Date!=null){
        doc.text(objectToPDF[0].Date.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Avdelning:", 20, 25 + j);
      doc.setFont("normal");
      if(objectToPDF[0].Department!=null){
        doc.text(objectToPDF[0].Department.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Plats:", 20, 25 + j);
      doc.setFont("normal");
      if(objectToPDF[0].Place!=null){
        doc.text(objectToPDF[0].Place.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Rubrik:", 20, 25 + j);
      doc.setFont("normal");
      if (objectToPDF[0].Title.length < 80) {
        doc.text(objectToPDF[0].Title.replace(/(\r\n|\n|\r)/gm, " ").toString(), 70, 25 + j);
        j = j + 5;
      } else {
        j = j+5;
        let words=objectToPDF[0].Title.split(' ');
        let g=0;
        let wordline="";
        while(g < words.length){
           wordline=wordline+' '+words[g];
           g++;
           if(wordline.length>100){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20, 25 + j +jrows);
            wordline="";
            jrows = jrows + 5;
           }
           if(g==words.length){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20, 25 + j + jrows
            );
           }
        }
        j = j + jrows + 5;
      }
      doc.setFont("bold");
      doc.text("Beskrivning:", 20, 25 + j);
      doc.setFont("normal");
      if (objectToPDF[0].Description.length < 80) {
        doc.text(objectToPDF[0].Description.replace(/(\r\n|\n|\r)/gm, " ").toString(), 70, 25 + j);
        j = j + 5;
      } else {
        j = j+5;
        let words=objectToPDF[0].Description.split(' ');
        let g=0;
        let wordline="";
        while(g < words.length){
           wordline=wordline+' '+words[g];
           g++;
           if(wordline.length>100){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20,20 + j +jrows);
            wordline="";
            jrows = jrows + 5;
           }
           if(g==words.length){
              doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20, 20 + j + jrows);
           }
        }
        j = j + jrows;
      }
      doc.setFont("bold");
      doc.text("Orsak:", 20, 25 + j + n);
      doc.setFont("normal");
      if (objectToPDF[0].Cause!=null) {
        if (objectToPDF[0].Cause.length < 80) {
          doc.text(objectToPDF[0].Cause.replace(/(\r\n|\n|\r)/gm, " ").toString(), 70, 25 + j);
          j = j + 5;
        } else {
          j = j+5;
          let words=objectToPDF[0].Cause.split(' ');
          let g=0;
          let wordline="";
          while(g < words.length){
            wordline=wordline+' '+words[g];
            g++;
            if(wordline.length>100){
              doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20, 25 + j +jrows);
              wordline="";
              jrows = jrows + 5;
            }
            if(g==words.length){
              doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20, 25 + j + jrows);
            }
          }
          j = j + jrows;
        }
      }
      doc.setFont("bold");
      doc.text("Åtgärdspunkter", 120, 30 + j);
      doc.setFont("normal");
      doc.line(20, 32 + j, 200, 32 + j);
      j = j + 7;
      // individual values for each round
      var n = 0;
      // startvalue for each round
      var offset = 0;
      // Every page
      var sida = 1;
      if (objectToPDF[0].Id != null) {
        for (let i = 0; i < safetyrounds.length; i++) {
          if ((n+offset+50) > 300) {
            doc.text("Sida " + sida++, 180, 290);
            doc.addPage("1", "p");
            var img = new Image();
            img.src = "assets/samtec-logo-primar.png";
            img.onload = function () {
              doc.addImage(img, "png", 20, 10, 50, 10);
            };
            img.crossOrigin = "";
            offset = 0;
            n = 0;
            j = 0;
            doc.addImage(img, "png", 20, 10, 50, 10);
            j = j + 10;
            doc.setFont("bold");
            doc.text("Rapport", 20, 25 + j);
            doc.text("Incidenter", 120, 25 + j);
            doc.text("Åtgärdspunkter", 120, 35 + j);
            doc.setFont("normal");
            j = j + 15;
            doc.line(20, 22 + j, 200, 22 + j);
            j = j + 5;
          } else {
            offset = n;
          }
          doc.setFont("bold");
          var riskvalues=this.riskvalues(safetyrounds[i].incidentSeverity, safetyrounds[i].incidentFrequency, safetyrounds[i].incidentPossibility);
          doc.text("Riskvärde", 20, 30 + j + offset);
          doc.text("Ansvarig", 70, 30 + j + offset);
          doc.text("Utfört datum", 120, 30 + j + offset);
          doc.text("Utfört", 150, 30 + j + offset);
          doc.text("Åtgärd effektiv", 170, 30 + j + offset);
          doc.setFont("normal");
          n = n + 5;
          if(riskvalues!=null){
            doc.text(riskvalues.toString(), 20, 35 + j + offset);
          }else{
            doc.text('', 20, 35 + j + offset);
          }
          if(safetyrounds[i].roundResponsible2!=null){
            doc.text(safetyrounds[i].roundResponsible2.toString(), 70, 35 + j + offset);
          }else{
            doc.text('', 70, 35 + j + offset);
          }
          if(safetyrounds[i].roundDoneDate!=null){
            doc.text(safetyrounds[i].roundDoneDate.toString(), 120, 35 + j + offset);
          }else{
            doc.text('', 120, 35 + j + offset);
          }
          if(safetyrounds[i].roundDone!=null){
            doc.text(safetyrounds[i].roundDone.toString(), 150, 35 + j + offset);
          }else{
            doc.text('', 150, 35 + j + offset);
          }
          if(safetyrounds[i].roundActive!=null){
            doc.text(safetyrounds[i].roundActive.toString(), 170, 35 + j + offset);
          }else{
            doc.text('', 170, 35 + j + offset);
          }
          n = n + 10;
          doc.setFont("bold");
          doc.text("Riskkälla:", 20, 45 + j + offset);
          doc.setFont("normal");
          if(safetyrounds[i].roundRisk!=null){
            doc.text(safetyrounds[i].roundRisk.toString(), 50, 45 + j + offset);
          }else{
            doc.text('', 50, 45 + j + offset);
          }
          n = n + 5;
          doc.setFont("bold");
          doc.text("Åtgärd:", 20, 50 + j + offset);
          doc.setFont("normal");
          jrows = 0;
          if (safetyrounds[i].roundMeasure.length < 100) {
            doc.text(safetyrounds[i].roundMeasure.replace(/(\r\n|\n|\r)/gm, " ").toString(), 50, 50 + j + offset);
            n = n + 5;
            doc.line(20, 55 + j + offset, 200, 55 + j + offset);
            n = n + 5;
          } else {
            let words=safetyrounds[i].roundMeasure.split(' ');
            let g=0;
            let wordline="";
            while(g < words.length){
               wordline=wordline+' '+words[g];
               g++;
               if(wordline.length>100){
                  doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20,55 + j + offset + jrows);
                  wordline="";
                  jrows = jrows + 5;
               }
               if(g==words.length){
                  doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20, 55 + j + offset + jrows);
               }
            }
            n = n + jrows;
            doc.line(20, 60 + j + jrows + offset, 200, 60 + j + jrows + offset);
            n = n + 15;
          }
        }
      }
      doc.text("Sida " + sida++, 180, 290);
      if(attachToEmail==true){
        var blob = doc.output('blob');
        return blob;
      }else{
        doc.save("Rapport_Incident.pdf");
      }
    }
    if (entrance == "workplace") {
      let objectToPDF: any = new Array();
      objectToPDF.push(round);

      var doc = new jsPDF();
      doc.setFontSize(10);
      var img = new Image();
      img.src = "assets/samtec-logo-primar.png";
      img.onload = function () {
        doc.addImage(img, "png", 20, 10, 50, 10);
      };
      img.crossOrigin = "";
      doc.addImage(img, "png", 20, 10, 50, 10);

      // Övergripande
      var j = 0;
      doc.addImage(img, "png", 20, 10, 50, 10);
      j = j + 10;
      doc.setFont("bold");
      doc.text("Rapport", 20, 25 + j);
      doc.text("Arbetsplats",120, 25 + j);
      doc.setFont("normal");
      j = j + 5;
      doc.line(20, 25 + j, 200, 25 + j);
      j = j + 10;
      j = j + 5;
      doc.setFont("bold");
      doc.text("Ansvarig:", 20, 25 + j);
      doc.setFont("normal");
      if(objectToPDF[0].Supervisor!=null){
        doc.text(objectToPDF[0].Supervisor.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Datum:", 20, 25 + j);
      doc.setFont("normal");
      if( objectToPDF[0].doneDate!=null){
        doc.text(objectToPDF[0].doneDate.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Avdelning:", 20, 25 + j);
      doc.setFont("normal");
      if( objectToPDF[0].Department!=null){
        doc.text(objectToPDF[0].Department.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Deltagare:", 20, 25 + j);
      doc.setFont("normal");
      if( objectToPDF[0].Participants!=null){
        doc.text(objectToPDF[0].Participants.toString(), 70, 25 + j);
      }else{
        doc.text('', 70, 25 + j);
      }
      j = j + 5;
      var xi = 1;
      doc.setFont("bold");
      doc.text("Rubrik:", 20, 30 + j);
      doc.setFont("normal");
      if (objectToPDF[0].Title.length < 80) {
        doc.text(objectToPDF[0].Title.replace(/(\r\n|\n|\r)/gm, " ").toString(), 70, 30 + j);
        j = j + 5;
      } else {
        let words=objectToPDF[0].Title.split(' ');
        let g=0;
        let wordline="";
        j = j + 5;
        while(g < words.length){
           wordline=wordline+' '+words[g];
           g++;
           if(wordline.length>100){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              20,
              30 + j +jrows
            );
            wordline="";
            jrows = jrows + 5;
           }
           if(g==words.length){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              20,
              30 + j + jrows
            );
           }
        }
        j = j + jrows;
      }
      jrows=0;
      j=j+5;
      doc.setFont("bold");
      doc.text("Beskrivning:", 20, 25 + j);
      doc.setFont("normal");
      if (objectToPDF[0].Text.length < 80) {
        doc.text(objectToPDF[0].Text.replace(/(\r\n|\n|\r)/gm, " ").toString(), 
          70,
          25 + j,
        );
        j = j + 5;
      } else {
        let words=objectToPDF[0].Text.split(' ');
        let g=0;
        let wordline="";
        j = j + 5;
        while(g < words.length){
           wordline=wordline+' '+words[g];
           g++;
           if(wordline.length>100){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              20,
              25 + j +jrows
            );
            wordline="";
            jrows = jrows + 5;
           }
           if(g==words.length){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(),
              20,
              25 + j + jrows
            );
           }
        }
        j = j + jrows;
      }
      j = j + 5;
      doc.setFont("bold");
      doc.text("Riskkällor", 120, 30 + j);
      doc.setFont("normal");
      doc.line(20, 35 + j, 200, 35 + j);
      j = j + 10;
      if (safetyrounds != null) {
        for (let i = 0; i < safetyrounds.length; i++) {
          // 50 is a usual size for the round
          if ((n+offset+j+50) > 300) {
            doc.text("Sida " + sida++, 180, 290);
            doc.addPage("1", "p");
            var img = new Image();
            img.src = "assets/samtec-logo-primar.png";
            img.onload = function () {
              doc.addImage(img, "png", 20, 10, 50, 10);
            };
            img.crossOrigin = "";
            offset = 0;
            n = 0;
            j = 0;
            doc.addImage(img, "png", 20, 10, 50, 10);
            j = j + 10;
            doc.setFont("bold");
            doc.text("Rapport", 20, 25 + j);
            doc.text("Arbetsplats", 120, 25 + j);
            doc.text("Riskkällor", 120, 35 + j);
            doc.setFont("normal");
            j = j + 15;
            doc.line(20, 22 + j, 200, 22 + j);
            j = j + 5;
          } else {
            offset = n;
          }
          doc.setFont("bold");
          doc.text("Riskkällor och risker", 20, 30 + j + offset);
          doc.text("Ansvarig", 70, 30 + j + offset);
          doc.text("Datum", 160, 30 + j + offset);
          doc.text("Utfört", 180, 30 + j + offset);
          // doc.text(190, 30 + j + offset, "Aktiv");
          doc.setFont("normal");
          n = n + 5;
          if(safetyrounds[i].roundLocation!=null){
            doc.text(safetyrounds[i].roundRisk.toString(), 20, 35 + j + offset);
          }else{
            doc.text('', 20, 35 + j + offset);
          }

          if(safetyrounds[i].roundResponsible2!=null){
            doc.text(safetyrounds[i].roundResponsible2.toString(), 70, 35 + j + offset);
          }else{
            doc.text('', 70, 35 + j + offset);
          }

          if(safetyrounds[i].roundDoneDate!=null){
            doc.text(safetyrounds[i].roundDoneDate.toString(), 160, 35 + j + offset);
          }else{
            doc.text('', 160, 35 + j + offset);
          }

          if(safetyrounds[i].roundDone!=null){
            doc.text(safetyrounds[i].roundDone.toString(), 180, 35 + j + offset);
          }else{
            doc.text('', 180, 35 + j + offset);
          }

          /*if(safetyrounds[i].roundActive!=null){
            doc.text(190, 35 + j + offset, safetyrounds[i].roundActive.toString());
          }else{
            doc.text(190, 35 + j + offset,'');
          }*/

          n = n + 10;
          var riskvalues=this.riskvalues(safetyrounds[i].roundSeverity,safetyrounds[i].roundFrequency,safetyrounds[i].roundPossibility);
          doc.setFont("bold");
          // doc.text(20, 45 + j + offset, "Brist:");
          doc.text("Riskvärde:", 160, 45 + j + offset);

          if(riskvalues!=null){
            doc.text(riskvalues.toString(), 180, 45 + j + offset);
          }else{
            doc.text('', 180, 45 + j + offset);
          }
          doc.setFont("normal");
          jrows = 0;
          if (safetyrounds[i].roundRisk.length < 100) {
            /*doc.text(
              50,
              45 + j + offset,
              safetyrounds[i].roundRisk.replace(/(\r\n|\n|\r)/gm, " ").toString()
            );*/
            n = n + 5;
          } else {
            let words=safetyrounds[i].roundRisk.split(' ');
        let g=0;
        let wordline="";
        while(g < words.length){
           wordline=wordline+' '+words[g];
           g++;
           if(wordline.length>100){
            /*doc.text(
              20,
              50 + j +jrows+offset,wordline.replace(/(\r\n|\n|\r)/gm, " ").toString()
            );*/
            wordline="";
            jrows = jrows + 5;
           }
           if(g==words.length){
            /*doc.text(
              20,
              50 + j + jrows+offset,wordline.replace(/(\r\n|\n|\r)/gm, " ").toString()
            );*/
            j=j+5;
           }
        }
            j = j + jrows;
            n = n + jrows;
          }
          doc.setFont("bold");
          doc.text("Åtgärdsförslag:", 20, 50 + j + offset);
          doc.setFont("normal");
          jrows = 0;
          if (safetyrounds[i].roundMeasure&&safetyrounds[i].roundMeasure.length < 100) {
            doc.text(safetyrounds[i].roundMeasure.replace(/(\r\n|\n|\r)/gm, " ").toString(), 50, 50 + j + offset);
            n = n + 5;
            doc.line(20, 55 + j + offset, 200, 55 + j + offset);
            n = n + 5;
          } else {
            let words=safetyrounds[i].roundMeasure.split(' ');
            let g=0;
            let wordline="";
        while(g < words.length){
           wordline=wordline+' '+words[g];
           g++;
           if(wordline.length>100){
              doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20, 55 + j +jrows+offset);
              wordline="";
              jrows = jrows + 5;
           }
           if(g==words.length){
            doc.text(wordline.replace(/(\r\n|\n|\r)/gm, " ").toString(), 20, 55 + j + jrows+offset);
           }
        }
        j = j + jrows;
            n = n + jrows;
            doc.line(20, 55 + j + jrows + offset, 200, 55 + j + jrows + offset);
            n = n + 10;
          }
          }
        }
      doc.text("Sida " + sida++, 180, 290);
      if(attachToEmail==true){

        // Appropiately difficult to write filename by accident
        var blob = doc.output('blob');
        return blob;
      }else{
        doc.save("Rapport_Arbetsplats.pdf");
      }
    }

    return null;
  }

  riskvalues(sev:any, poss:any, freq:any) {
    if (!sev || !poss || !freq) {
      // return "fa fa-info-circle";
      return "";
    }
    if(sev==1 || sev==2 || sev==3 || sev==4 || sev==5){
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 1;
        }else{
          return 2;
        }
      }else{
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 2;
        }else{
          return 3;
        }
      }
    }else{
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 3;
        }else{
          return 4;
        }
      }else{
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 4;
        }else{
          return 5;
        }
      }
    }
  }

}


