<div class="modal-header">
    <h4 class="modal-title">{{'mail'|translate}}:</h4>
</div>
<div class="modal-body">
    <form class="ui form" #postForm="ngForm">
        <label>{{ 'receiver' | translate }}</label>
        <input id="mailReceiver" name="mailReceiver" [(ngModel)]="mailReceiver" required/><br><br>
        <label>{{ 'subject' | translate }}</label>
        <input class="ui input" id="mailReceiver" name="mailReceiver" [(ngModel)]="mailSubject" required/><br><br>
        <label>{{ 'message' | translate }}</label>
        <textarea class="form-control" id="mailMessage" name="mailMessage" [value]="mailMessage" [(ngModel)]="mailMessage" placeholder="{{'message'|translate}}">
        </textarea><br>
    </form>

    <div class="modal-footer">
        <button class="ui positive button" (click)="mailModalSend()"><i class="ui icon send"></i>{{"SEND" | translate }}</button>
        <button type="button" class="ui button" (click)="activeModal.close('Close click')" >{{"CLOSE" | translate}}</button>
    </div>
</div>

