<div class="ui stackable grid">
    <div class="row">
      <div class="sixteen wide column">
        <div class="ui card">
          <div class="header-text">
            <h1>{{'incidents'|translate}}
            <button class="ui right floated positive button" *ngIf="is_admin||is_super_user" style="margin-left:20px;margin-bottom:0" (click)="addItem()"><i class="ui icon add"></i>{{'ADD_INCIDENT' | translate}}</button></h1>
          </div>
          <div class="ui grid" style="margin:0px">
            <div class="row" style="padding-bottom:0px">
              <div class="eight wide column">
                <div class="ui search" style="margin:20px;width:100%">
                  <div class="ui icon input" style="width:100%">
                    <input class="prompt" type="text" type="text" name="searchText" id="searchText" placeholder="{{'searchall'|translate}}" [(ngModel)]="searchText" (keyup)="filterTableValue()">
                    <i class="search icon"></i>
                  </div>
                  <div class="results"></div>
                </div>
              </div>
              <div class="eight wide column" style="padding:30px">
                <div class="ui toggle checkbox" >
                  <input type="checkbox" name="public" [checked]="doNotShowSubDepartment!=true" (click)="changeShowSubdepartment()">
                  <label>{{'showsubdivisions'|translate}}</label>
                </div>
              </div>
            </div>
          </div>
          <table datatable [dtOptions]="dtOptions" class='ui hover table' id='incidenttable' style="padding-left:20px;cursor:pointer" *ngIf="incidentValues_table2&&incidentValues_table2.length!=0">
            <thead>
              <tr>
                <th>{{'date'|translate}}</th>
                <th>{{'type'|translate}}</th>
                <th>{{'responsible'|translate}}</th>
                <th>{{'department'|translate}}</th>
                <th>{{'place'|translate}}</th>
                <th>{{'header'|translate}}</th>
                <th>{{'ROUNDS_DONE'|translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let incidentValue of incidentValues_table2 | filter : 'safetyround' : searchText;let i=index " (click)="viewItem(incidentValue.Id)">
                <td *ngIf="incidentValue">{{incidentValue.Date}}</td>
                <td *ngIf="incidentValue">{{incidentValue.typeofaction}}</td>
                <td *ngIf="incidentValue.Reporter.length<20">{{incidentValue.Reporter}}</td>
                <td *ngIf="incidentValue.Reporter.length>=20" data-toggle="tooltip" title="{{incidentValue.Reporter}}">{{wordbreak(incidentValue.Reporter)}}</td>
                <td *ngIf="incidentValue">{{incidentValue.Department}}</td>
                <td *ngIf="incidentValue">{{incidentValue.Place}}</td>
                <td *ngIf="incidentValue">{{incidentValue.Title}}</td>
                <td>
                  <div *ngIf="showRoundInfo(incidentValue.Id) as variable">
                    <button class="ui negative button" *ngIf="variable.B==true">{{variable.A}}</button>
                    <button class="ui positive button" *ngIf="variable.B==false">{{variable.A}}</button>
                  </div>
                </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
