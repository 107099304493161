import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../shared/services/data.service';
import { MessageService } from '../shared/services/message.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Usermodel } from '../shared/model_view/usermodel';
import { take } from 'rxjs/operators';
import { Orgmodel } from '../shared/model_view/orgmodel';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';
import { DataTableSetup } from 'src/app/shared/services/dataTableSetup';
declare var $: any;

@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.css']
})
export class ActionPlanComponent implements OnInit {

  private handlingsplansub: Subscription;
  dtOptions: any = {};
  actionplan_table: any = new Array();
  searchText: any;
  table:any;
  sortOrderAscOrDesc = true;
  msgListGet = false;

  constructor(
    private dataService: DataService,
    private msgService: MessageService,
    private authService: AuthService,
    private router: Router,
    private dataTableSetup: DataTableSetup,
  ) {}

  ngOnInit(): void {
    this.dtOptions =this.dataTableSetup.dataTableInit('actionplan');
    this.authService.setShowOrHideSubdepartment(false);
    this.dataService.getActionPlan("");
    // this.spinner=true;
    this.handlingsplansub = this.dataService
      .actionplanListener()
      .pipe(take(1))
      .subscribe((actionplan_table: any) => {
        this.actionplan_table = actionplan_table;
        this.msgListGet = true;
        // För att inte få med dubletter
      });

    this.msgService.sendMessage(null);
  }

  filterTableValue2(){
    var thisvalue=$("#searchText").val();
    if(thisvalue.toString().length>0||thisvalue.toString()==''){
      this.table = $('#thistable').DataTable({retrieve: true});
      this.table.search(thisvalue.toString()).draw();
    }
  }

  filterTableValue(){
    var thisvalue=$("#selectbox option:selected").val();
    switch(thisvalue){
      case '1': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('').draw();
        break;
      }
      case '2': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('Pågående').draw();
        break;
      }
      case '3': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('Klar för uppföljning').draw();
        break;
      }
      case '4': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('Åtgärd effektiv').draw();
        break;
      }
      default:{
        // Nothing happens
      }
    }
  }

  filterTypeTableValue(){
    var thisvalue=$("#selectboxtype option:selected").val();
    switch(thisvalue){
      case '1': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('').draw();
        break;
      }
      case '2': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('Skyddsrond').draw();
        break;
      }
      case '3': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('Incident').draw();
        break;
      }
      case '4': {
        this.table = $('#thistable').DataTable({retrieve: true});
        this.table.search('Arbetsplats').draw();
        break;
      }
      default:{
        thisvalue='';
        // Nothing happens
      }
    }
  }

  sort(column:any, lower:any) {
    // The logic is in this pipe see under shared/pipes.
    let sortPipe = new SortPipe();
    if (this.sortOrderAscOrDesc == true) {
      this.actionplan_table = sortPipe.transform(
        this.actionplan_table,
        false,
        column,
        lower
      );
      this.sortOrderAscOrDesc = false;
    } else if (this.sortOrderAscOrDesc == false) {
      this.actionplan_table = sortPipe.transform(
        this.actionplan_table,
        true,
        column,
        lower
      );
      this.sortOrderAscOrDesc = true;
    }
  }

  riskvaluesNumber(item:any) {
    if (!item.incidentSeverity || !item.incidentPossibility || !item.incidentFrequency) {
      // return "fa fa-info-circle";
      return "";
    }
    if(item.incidentSeverity==1 || item.incidentSeverity==2 || item.incidentSeverity==3 || item.incidentSeverity==4 || item.incidentSeverity==5){
      if(item.incidentFrequency==1 || item.incidentFrequency==2 || item.incidentFrequency==3 || item.incidentFrequency==4 || item.incidentFrequency==5){
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 1;
        }else{
          return 2;
        }
      }else{
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 2;
        }else{
          return 3;
        }
      }
    }else{
      if(item.incidentFrequency==1 || item.incidentFrequency==2 || item.incidentFrequency==3 || item.incidentFrequency==4 || item.incidentFrequency==5){
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 3;
        }else{
          return 4;
        }
      }else{
        if(item.incidentPossibility==1 || item.incidentPossibility==2 || item.incidentPossibility==3 || item.incidentPossibility==4 || item.incidentPossibility==5){
          return 4;
        }else{
          return 5;
        }
      }
    }
  }

  addMessage(id: number) {
    this.msgService.changeHeaderBackground(id);
  }

  visit(id:any, type:any, roundid:any) {
    if (type == "Skyddsrond") {
      this.addMessage(2);
      this.router.navigate(["/safetyrounditem/" + id + "/safetyroundissueitem/" + roundid]);
    }
    if (type == "Incident") {
      this.addMessage(3);
      this.router.navigate(["/incidentitem/" + id + "/incidentissueitem/" + roundid]);
    }
    if (type == "Arbetsplats") {
      this.addMessage(6);
      this.router.navigate(["/workplaceitem/" + id + "/workplaceissueitem/" + roundid]);
    }
  }
}
