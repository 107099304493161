<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="sixteen wide column header-text">
                        {{'HANDLING_PLAN'|translate}}
                    </div>
                </div>
                <div class="ui grid row" style="margin:10px">
                    <div class="seven wide column">
                        <div class="ui search">
                            <div class="ui icon input" style="width:100%">
                                <input class="prompt" type="text" type="text" name="searchText" id="searchText" placeholder="{{'searchall'|translate}}" [(ngModel)]="searchText" (keyup)="filterTableValue2()">
                                <i class="search icon"></i>
                            </div>
                        </div>
                    </div>
                    <div class="four wide column">
                        <div class="ui icon input" style="width:100%">
                            <select class="browser-default custom-select" (change)="filterTableValue()" id="selectbox" placeholder="status">
                                <option value="1">{{'all'|translate}}</option>
                            <option value="2">{{'ongoing'|translate}}</option>
                            <option value="3">{{'ready_for_follow_up'|translate}}</option>
                            <option value="4">{{'active'|translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="four wide column">
                        <div class="ui icon input" style="width:100%">
                            <select class="browser-default custom-select" (change)="filterTypeTableValue()" id="selectboxtype" placeholder="type">
                                <option value="1">{{'all'|translate}} ({{'type'|translate}})</option>
                                <option value="2">{{'safetyround'|translate}}</option>
                                <option value="3">{{'incident'|translate}}</option>
                                <option value="4">{{'workplace'|translate}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <table datatable [dtOptions]="dtOptions" class='ui stackable table' style="padding:20px" id='thistable' *ngIf="actionplan_table&&actionplan_table.length!=0">
                    <thead>
                        <tr>
                            <th>{{'type' | translate}}</th>
                            <th (click)="sort('roundDoneDate','')">{{'date'|translate}}</th>
                            <th (click)="sort('roundResponsible2','')">{{'responsible'|translate}}</th>
                            <th (click)="sort('roundMeasure','')">{{'TASK'|translate}}</th>
                            <th>{{'status'|translate}}</th>
                            <th>{{'hazard'|translate}}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="visitrow" *ngFor="let actionplan_table_item of actionplan_table | filter : 'dashboard' : searchText;let i=index">
                            <td class="visitcolumn">{{actionplan_table_item.incidentroundtype}}</td>
                            <td>{{actionplan_table_item.roundDoneDate}}</td>
                            <td>{{actionplan_table_item.roundResponsible2}}</td>
                            <td>{{actionplan_table_item.roundMeasure}}</td>
                            <td *ngIf="actionplan_table_item.roundDone==null||actionplan_table_item.roundActive==null">{{'ongoing'|translate}}</td>
                            <td *ngIf="actionplan_table_item.roundDone=='Nej'">{{'ongoing'|translate}}</td>
                            <td *ngIf="actionplan_table_item.roundDone=='Ja'&&actionplan_table_item.roundActive=='Nej'">{{'ready_for_follow_up'|translate}}</td>
                            <td *ngIf="actionplan_table_item.roundDone=='Ja'&&actionplan_table_item.roundActive=='Ja'">{{'active'|translate}}</td>
                            <td *ngIf="!actionplan_table_item.roundDone">-</td>
                            <td>
                                <div *ngIf="!riskvaluesNumber(actionplan_table_item)" class="ui label lightgrey">-</div>
                                <div *ngIf="riskvaluesNumber(actionplan_table_item)&&riskvaluesNumber(actionplan_table_item)==1" class="ui label green">{{riskvaluesNumber(actionplan_table_item)}}</div>
                                <div *ngIf="riskvaluesNumber(actionplan_table_item)&&riskvaluesNumber(actionplan_table_item)==2" class="ui label green">{{riskvaluesNumber(actionplan_table_item)}}</div>
                                <div *ngIf="riskvaluesNumber(actionplan_table_item)&&riskvaluesNumber(actionplan_table_item)==3" class="ui label yellow">{{riskvaluesNumber(actionplan_table_item)}}</div>
                            </td>
                            <td>
                                <div class="ui positive button" (click)="visit(actionplan_table_item.incidentId,actionplan_table_item.incidentroundtype,actionplan_table_item.roundId)" style="border-radius: 29px;">{{'MANAGE' | translate}}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
