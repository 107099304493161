import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take,concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFService } from "src/app/shared/services/pdf.service";
import { TranslateService } from '@ngx-translate/core';
import { SortService } from 'src/app/shared/services/sort.service';
import { ModalEditDepartmentComponent } from 'src/app/modals/modal-edit-department/modal-edit-department.component';
import { ModalAddDepartmentComponent } from 'src/app/modals/modal-add-department/modal-add-department.component';
declare var $: any;
import * as _ from "lodash";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  activeTab: any;
  is_admin: any;
  is_super_user: any;
  orgmodelDep: any;
  orgId: any;
  categoryList: any;
  subscriptions: any;
  filtersLoaded: Promise<boolean>;
  itemDragEnd: any;
  itemDragStart: any;
  message: any;
  errorDepartment: any;
  activeCategoryTab: any;
  treeChanged: boolean = false;
  thisNameCategory: any;
  eventList: any;
  thisName: any;
  userInfo: any;
  users: any;
  searchText: any;

  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private sortService: SortService,
    private modalService: NgbModal,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.activeTab = localStorage.getItem('activeTab');
    if (this.activeTab == null)
    {
      this.activeTab = 0;
    }

    this.activeCategoryTab = localStorage.getItem('activeCategoryTab');
    if (this.activeCategoryTab == null)
    {
      this.activeCategoryTab = 0;
    }

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
    this.orgId = this.authService.getOrgid();

    this.dataService.getEventList(this.orgId);
    this.dataService.getEventListListener().subscribe((eventList:[])=>{
      this.eventList=eventList;
    });

    this.dataService.getCategoryList(this.orgId);
    this.dataService.getCategoryListListener().subscribe((categoryList:[])=>{
      this.categoryList = categoryList;
    });

    this.dataService.getSubscriptions(this.authService.getLocalOrgid());
    this.dataService.subscriptionsListener()
    .subscribe((subscriptions) => {
      this.subscriptions = subscriptions[0];
    });

    this.userInfo=this.dataService.getUsers(this.orgId, 0);
    this.dataService.usersListener()
    .subscribe((users: any) => {
      this.users=users;
    })

    this.dataService.getOrganisationSchema(this.orgId);
    this.dataService.orgModelListener()
      .pipe(take(1))
      .subscribe((orgmodel: any) => {
        this.orgmodelDep = orgmodel;
        this.orgmodelDep = this.sortService.sortOrgModel(this.orgmodelDep);
        this.filtersLoaded = Promise.resolve(true);
      }
    );

    this.msgService.sendMessage(null);
  }

  addUser() {
    this.routing.navigate(['/settings/adduser']);
  }

  editUser(id:any) {
    this.routing.navigate(['/settings/edituser/' + id]);
  }

  removeUser(id: any) {
    const confirmed = window.confirm(this.translate.instant('REMOVE_USER_Q'));
    if (confirmed) {
      // Logic to remove the user
      this.dataService.deleteSettingsUser(id, this.orgId);
    }
  }

  goBack() {
    this.routing.navigate(['/controlpanel/organisations']);
  }

  setActiveTab(tabnumber:any) {
    this.activeTab = tabnumber;
    localStorage.setItem('activeTab', tabnumber);
  }

  setActiveCategoryTab(tabnumber:any) {
    this.activeCategoryTab = tabnumber;
    localStorage.setItem('activeCategoryTab', tabnumber);
  }

  removeItem(entrance:any, item:any){
    if(entrance=='event'){
      this.dataService.changeEvent('delete',item);
    }else{
      this.dataService.changeCategory('delete',item);
    }
    let text = this.translate.instant('REMOVED');
    confirm(text);
    window.location.reload();
  }

  changeNameItem(entrance:any, item:any, postInput:any){
    item=({'id':item.id,'name':postInput.value});
    if(entrance=='event'){
      this.dataService.changeEvent('change',item);
    }else{
      this.dataService.changeCategory('change',item);
    }
  }

  addItem(entrance:any, name:any){
    if(entrance=='event'){
      this.dataService.changeEvent('add',name);
    }else{
      this.dataService.changeCategory('add',name);
    }
  }

  onDragStart(e:any, item: any){
    this.itemDragStart = item;
  }

  onDrop(e:any, item: any){
    e.preventDefault();
    $(e.target).css('background-color','#FFF');
    this.message="";
    if(this.itemDragStart.Parent==-1){
      return;
    }

    // get Items id
    let listOfIds:any = [];
    let startingPoint = this.itemDragStart.Id;
    let draglevel = this.itemDragStart.Level;
    this.errorDepartment = false;
    this.itemDragEnd = item;

    listOfIds = this.sortService.getItemParents(startingPoint, draglevel, this.orgmodelDep);
    let temporgmodelAvd = _.cloneDeep(this.orgmodelDep);
    if (listOfIds.indexOf(this.itemDragStart.Id) >- 1
        && listOfIds.indexOf(this.itemDragEnd.Id) >- 1
        && (+this.itemDragEnd.Level>+this.itemDragStart.Level)){
          this.errorDepartment = true;
        } // This means that I try to move a parent  object to a child

    if (this.errorDepartment!=true){
      if (listOfIds.length < 1){  } // do nothing
      else if(listOfIds.length == 1){
        _.forEach(temporgmodelAvd, (value, index, key) => {
          if (listOfIds.indexOf(value.Id) >- 1){
            value.Level = (+this.itemDragEnd.Level + 1).toString();
            value.Place = this.itemDragEnd.Place;
            value.Parent = this.itemDragEnd.Id;
            if (value.Level > 4){
              this.errorDepartment = true;
            }
          }
        });
      }
      else if(listOfIds.length>1){
        let sibling = true; // The item is either sibling or child to the first node, if its the same Level its a sibling
        let startLevel = +this.itemDragEnd.Level;
        _.forEach(temporgmodelAvd, (value, index, key) => {
          if(listOfIds.indexOf(value.Id) >- 1){
            if(startLevel == value.Level){
              sibling = true;
            } else {
              sibling = false; // ie child
            }
            if(sibling){
              value.Parent = (+this.itemDragEnd.Id).toString();
              let endLevel =+ this.itemDragEnd.Level;
              value.Level = (+value.Level + endLevel).toString();
              value.Place = this.itemDragEnd.Place;
            }
            if(!sibling){
              if(value.Id == this.itemDragStart.Id){
                value.Parent = this.itemDragEnd.Id
              }
              let endLevel = temporgmodelAvd.find(({ Id }:any) => Id === value.Parent).Level;
              endLevel++;
              value.Level = (endLevel).toString();
              value.Place = this.itemDragEnd.Place;
            }

            if(value.Level > 4){
              this.errorDepartment = true;
            }
          }
        });
      }
    }


    if(this.errorDepartment==true){
      this.message="Detta går inte";
    } else {
      this.orgmodelDep = _.cloneDeep(temporgmodelAvd);
      this.orgmodelDep=this.sortService.sortOrgModel(this.orgmodelDep);
      this.dataService.updateDepartments(this.orgmodelDep,'setting');
    }
  }

  allowDrop(e:any){
    e.preventDefault();
    if(this.itemDragStart.Parent==-1){
      return;
    }
    $(e.target).css('background-color','grey');
  }

  removeStyle(e:any){
    e.preventDefault();
    if(this.itemDragStart.Parent==-1){
      return;
    }
    $(e.target).css('background-color','#FFF');
  }

  editDepartment(id:any, name:any){
    const modalRef = this.modalService.open(ModalEditDepartmentComponent);
      modalRef.componentInstance.AvdName = name;
      modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((AvdName:any) => {
        for (var i = 0; i < this.orgmodelDep.length; i++)
        {
          if (this.orgmodelDep[i].Id == id)
          {
            this.orgmodelDep[i].Name = AvdName;
            this.dataService.updateDepartment(this.orgmodelDep[i]);
          }
        }
      })
  }

  addDepartment(Place:any, Level:any, Id:any){
    let departmentModel=({
      Id: null,
      Name: "",
      Place: Place,
      Level: String(Number(Level)+1),
      Parent: Id,
      main_organisation:this.authService.getOrgid()
    });
    const modalRef = this.modalService.open(ModalAddDepartmentComponent);
    modalRef.componentInstance.department = departmentModel;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe((department:any) => {
      departmentModel.Name = department.Name;
      this.dataService.insertDepartment(departmentModel);
      //window.location.reload();
    })
  }

  removeDepartment(id: any){
    if (!id || id == this.orgId){
      return;
    }

    let object = this.orgmodelDep.find(({ Id }:any) => Id == id);
    let listOfIds = this.sortService.getItemParents(object.Id, object.Level, this.orgmodelDep);
    var tmpString = this.translate.instant('DO_YOU_WANT_TO_REMOVE')
    var deleteQuestion = confirm(tmpString);
    if (deleteQuestion == true){
      for(let n=0;n<this.orgmodelDep.length;n++){
        if(this.orgmodelDep[n].Id==id){
          this.orgmodelDep.splice(n,1);
          this.dataService.removeDepartment(id);
          break;
        }
      }
    }
  }
}
