<div class="ui grid">
  <div class="twelve wide computer only column">
      <div class="login-background" style="height: 1300px"></div>
  </div>
  <div class="four wide computer only column" style="background-color: black;">
      <div class="loginlogo"></div>
      <form class="ui form" [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="field">
          <label>{{ 'USERNAME' | translate }}</label>
          <input formControlName="username" id="username" required/>
        </div>
        <div class="field">
          <label>{{ 'PASSWORD' | translate }}</label>
          <input type="password" formControlName="password" id="password" required/>
        </div>
        <button *ngIf="!token" class="ui positive button" type="submit" id="login">{{ 'LOGIN' | translate }}</button>
        <span class="badge badge-pill badge-danger">{{message}}</span>
      </form>
  </div>
  <div class="sixteen wide mobile only column" style="background-color: black;height:1300px">
      <div class="loginlogo"></div>
      <form class="ui form" [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="field">
          <label>{{ 'USERNAME' | translate }}</label>
          <input formControlName="username" id="usernamemobile" required>
        </div>
        <div class="field">
          <label style="margin-top:15px" >{{ 'PASSWORD' | translate }}</label>
          <input type="password" formControlName="password" id="passwordmobile" required/>
        </div>
        <button *ngIf="!token" class="ui button" type="submit" id="loginmobile">{{ 'LOGIN' | translate }}</button>
        <br/>
        <span class="badge badge-pill badge-danger">{{message}}</span>
      </form>
  </div>
</div>
