<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card">
                <div class="computer only ui grid secondary pointing menu" style="padding:20px;width:100%">
                    <a class="item" data-target="#" (click)="changeTab(true)" [class.active]="showTab1==true">{{'safetyrounditem' | translate}}</a>
                    <a *ngIf="is_admin||is_super_user" class="item" data-target="#" (click)="changeTab(false)" [class.active]="showTab2==true">{{'riskevaluation' | translate}}</a>
                    <span class="back-edit-text" (click)="goBack()">{{"back"|translate}}</span>
                </div>
                <div class="mobile only ui grid secondary pointing menu" style="padding:20px;width:100%">
                    <a class="item" data-target="#" (click)="changeTab(true)" [class.active]="showTab1==true">{{'safetyrounditem' | translate}}</a>
                    <a *ngIf="is_admin||is_super_user" class="item" data-target="#" (click)="changeTab(false)" [class.active]="showTab2==true">{{'riskevaluation' | translate}}</a>
                    <i class="ui icon left arrow" style="cursor:pointer;margin-top:15px" (click)="goBack()"></i>
                </div>
                <div *ngIf="showTab1==true" style="padding:20px;padding-top:0px">
                    <form class="ui form" #postForm="ngForm">
                        <div class="field">
                            <label>{{'place'|translate}}</label>
                            <input id="roundLocation" name="roundLocation" [(ngModel)]="safetyRoundValue.roundLocation"/>
                        </div>
                        <div class="field">
                            <label>{{'shortageorcomment'|translate}}</label>
                            <input id="roundRisk" name="roundRisk" [(ngModel)]="safetyRoundValue.roundRisk"/>
                        </div>
                        <div class="field">
                            <label>{{'proposedmeasures'|translate}}</label>
                            <textarea id="measures" name="measures" rows="3" [(ngModel)]="safetyRoundValue.roundMeasure"></textarea><br><br>
                        </div>
                        <div class="field">
                            <label>{{'responsible'|translate}}</label>
                            <div class="ui selection dropdown" id="roundResponsibleChose">
                                <input type="hidden" name="safetyRoundValue.roundResponsible2">
                                <i class="dropdown icon"></i>
                                <div class="default text" style="color:black">{{safetyRoundValue.roundResponsible2}}</div>
                                <div class="menu">
                                <div *ngFor="let userInfoitem of userInfo;let i=index" (click)="setRoundResponsible(userInfoitem)" class="item" data-value="userInfoitem">{{userInfoitem.username}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label>{{'date'| translate }}</label>
                            <input type="date" name="roundDoneDate" [value]="safetyRoundValue.roundDoneDate" [(ngModel)]="safetyRoundValue.roundDoneDate" placeholder="safetyRoundValue.roundDoneDate"/>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" id="roundDone" name="roundDone" [value]="roundDone" [(ngModel)]="roundDone">
                                <label>{{'done'|translate}}</label>
                            </div><br>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" id="roundActive" name="roundActive" [value]="roundActive" [(ngModel)]="roundActive">
                                <label>{{'active'|translate}}</label>
                            </div><br>
                        </div>
                        <div class="field">
                            <input class="ui button" type="file" (change)="imageUpload($event)" placeholder="Upload file" accept="application/msword,
                                application/vnd.ms-excel,
                                application/vnd.ms-powerpoint,
                                text/plain,
                                application/pdf,
                                image/*,
                                video/*"/> 
                            
                            <div [hidden]="!uploadFileDone" class="ui segment">
                                <div class="ui active loader"></div>
                                <p></p>
                            </div><br><br><br>
                            
                            <div class="ui grid item" *ngFor="let file of fileNames; let i=index" style="width:100%;margin-left:20px;padding:0px">
                                <div class="row" *ngIf="filetypeinfo&&filetypeinfo[i]" style="margin:0px;padding:0px">
                                    <div class="eight wide column" style="margin:0px;padding:0px">
                                        <i class="ui icon file alternate outline"></i>{{filetypeinfo[i].thisFileName}}
                                    </div>
                                    <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                        <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="ui grid item" *ngFor="let file of selectedFile; let i=index" style="width:100%;margin-left:20px;padding:0px">
                                <div class="row" style="margin:0px;padding:0px">
                                    <div class="eight wide column" style="margin:0px;padding:0px">
                                        <i class="ui icon file alternate outline"></i>{{file.name}}
                                    </div>
                                    <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                        <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                    </div>
                                </div>
                            </div><br>
                        </div>
                        <div class="field">
                            <div class="ui modal">
                                <div class="ui range" id="smooth"></div>
                            </div>
                        </div>
                        <hr>
                        <div class="field">
                            <button [hidden]="uploadFileDone" style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="addItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                        </div>
                    </form>
                </div>
                <div *ngIf="showTab2==true" style="padding:20px;padding-top:0px">
                    <div class="ui grid row">
                        <div class="fifteen wide column"></div>
                        <div class="one wide column">
                            <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1">
                                <div class="{{riskvaluesModal(safetyRoundValue.roundSeverity, safetyRoundValue.roundPossibility, safetyRoundValue.roundFrequency,'panel')}}">
                                    {{riskvaluesModalNumber(safetyRoundValue.roundSeverity, safetyRoundValue.roundPossibility, safetyRoundValue.roundFrequency)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <form class="ui form" #postForm="ngForm">
                        <div class="field">
                            <label for="riskSeverity">{{'Severity'|translate}}<br/>{{severityValues(safetyRoundValue.roundSeverity)}}</label>
                            <input type="range"
                                    class="form-control"
                                    id="riskSeverity"
                                    min="1"
                                    max="10"
                                    step="1"
                                    list="ticks"
                                    name="riskSeverity"
                                    [value]="safetyRoundValue.roundSeverity"
                                    [(ngModel)]="safetyRoundValue.roundSeverity" />
                        </div>
                        <div class="field">
                            <textarea id="riskSeverityComment"
                                    name="riskSeverityComment"
                                    style="width: 100%;"
                                    rows="2"
                                    [value]="safetyRoundValue.roundSeverityComment"
                                    [(ngModel)]="safetyRoundValue.roundSeverityComment">
                            </textarea>
                        </div>
                        <div class="field">
                            <label for="riskFrequency">{{'Frequency'|translate}}<br/>{{frequencyValues(safetyRoundValue.roundFrequency)}}</label>
                            <input type="range" class="form-control" id="riskFrequency" name="riskFrequency" min="1" max="10" step="1"
                                list="ticks" [value]="safetyRoundValue.roundFrequency" [(ngModel)]="safetyRoundValue.roundFrequency" />
                        </div>
                        <div class="field">
                            <textarea id="riskFrequencyComment"
                                    name="riskFrequencyComment"
                                    style="width: 100%;"
                                    rows="2"
                                    [value]="safetyRoundValue.roundFrequencyComment"
                                    [(ngModel)]="safetyRoundValue.roundFrequencyComment"></textarea>
                        </div>
                        <div class="field">
                            <label for="riskPossibility">{{'Possibility'|translate}}<br />{{possibilityValues(safetyRoundValue.roundPossibility)}}</label>
                            <input type="range" class="form-control" id="riskPossibility" name="riskPossibility" min="1" max="10"
                                step="1" list="ticks" [value]="safetyRoundValue.roundPossibility" [(ngModel)]="safetyRoundValue.roundPossibility" />
                            </div>
                        <div class="field">
                            <textarea id="riskPossibilityComment"
                                    name="riskPossibilityComment"
                                    style="width: 100%;"
                                    rows="2"
                                    [value]="safetyRoundValue.roundPossibilityComment"
                                    [(ngModel)]="safetyRoundValue.roundPossibilityComment"></textarea>
                        </div>
                        <div class="field">
                            <label for="roundRiskComment">{{'commenthazard'|translate}}:</label>
                            <textarea id="roundRiskComment" rows="2" name="roundRiskComment" [value]="safetyRoundValue.roundTotalComment"
                                [(ngModel)]="safetyRoundValue.roundTotalComment">
                            </textarea>
                        </div>
                        <hr>
                        <div class="field">           
                            <button style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="addItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
