import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class DataTableSetup {
  is_admin:boolean;
  is_super_user:boolean;
  usedlanguage: any;
  constructor(private authService: AuthService, private translate: TranslateService) {  }

  dataTableInit(entrance:any) {
    var x = {};
    this.is_admin=this.authService.getAdmin();
    this.is_super_user=this.authService.getSuperUser();
    this.usedlanguage = localStorage.getItem('usedLanguage');

    if(entrance=='tools'){
      // Visible buttons or not
      if(this.is_admin||this.is_super_user){
        var showButtons="<'row'<'col-sm-4'B><'col-sm-6'>>";
      }else{
        var showButtons="";
      }

      if (this.usedlanguage == 'sv')
      {
        x = {
          pagingType: 'full_numbers',
          pageLength: 25,
          lengthMenu: [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "Alla"] ],
          processing: true,
          order: [[1, 'desc']],
          dom:"<'row'<'col-sm-12'>>" +
          showButtons +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-2'i><'col-sm-1'><'col-sm-4'p><'col-sm-1'><'col-sm-2'l>>",
          //dom: '<B>lfrtip',
          //"dom": '<"top"f>Brt<"bottom"ipl><"clear">',
          language:{"sEmptyTable": "Ingen data hittades",
          "sInfo": "Visar _START_ till _END_ av totalt _TOTAL_ ",
          "sInfoEmpty": "Visar 0 till 0 av totalt 0 ",
          "sInfoFiltered": "(filtrerat från totalt _MAX_)",
          "sLoadingRecords": "Laddar...",
          "sSearch": "Filtrera:",
          "sLengthMenu": "Visa _MENU_ rader",
          "sZeroRecords": "Inga poster hittades",
          "oPaginate": {
              "sFirst": "Första",
              "sLast": "Sista",
              "sNext": "Nästa",
              "sPrevious": "Föregående"}
          },buttons: [{
            extend: 'excel',
            text: 'Ladda ner Excelutskrift',
          },{
            extend: 'print',
            text: 'Skriv ut',
          }]
        };
      }
      else
      {
        x = {
          pagingType: 'full_numbers',
          pageLength: 25,
          lengthMenu: [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "All"] ],
          processing: true,
          order: [[1, 'desc']],
          dom:"<'row'<'col-sm-12'>>" +
          showButtons +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-2'i><'col-sm-1'><'col-sm-4'p><'col-sm-1'><'col-sm-2'l>>",
          //dom: '<B>lfrtip',
          //"dom": '<"top"f>Brt<"bottom"ipl><"clear">',
          language:{"sEmptyTable": "No data was found",
          "sInfo": "Shows _START_ to _END_ from total _TOTAL_ ",
          "sInfoEmpty": "Shows 0 to 0 from total 0 ",
          "sInfoFiltered": "(filtered from total _MAX_)",
          "sLoadingRecords": "Loading...",
          "sSearch": "Filter:",
          "sLengthMenu": "Show _MENU_ rows",
          "sZeroRecords": "No posts were found",
          "oPaginate": {
              "sFirst": "First",
              "sLast": "Last",
              "sNext": "Next",
              "sPrevious": "Previous"}
          },buttons: [{
            extend: 'excel',
            text: 'Download excel file',
          },{
            extend: 'print',
            text: 'Print',
          }]
        };
      }

      return x;
    }
    if(entrance=='dashboard'){
      // Visible buttons or not

      if (this.usedlanguage == 'sv')
      {
        x = {
          pagingType: 'full_numbers',
          pageLength: 25,
          lengthMenu: [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "Alla"] ],
          processing: true,
          order: [[0, 'desc']],
          dom:"<'row'<'col-sm-12'>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-2'i><'col-sm-1'><'col-sm-6'p><'col-sm-2'l>>",
          //dom: '<B>lfrtip',
          //"dom": '<"top"f>Brt<"bottom"ipl><"clear">',
          language:{"sEmptyTable": "Ingen data hittades",
          "sInfo": "Visar _START_ till _END_ av totalt _TOTAL_ ",
          "sInfoEmpty": "Visar 0 till 0 av totalt 0 ",
          "sInfoFiltered": "(filtrerat från totalt _MAX_)",
          "sLoadingRecords": "Laddar...",
          "sSearch": "Filtrera:",
          "sLengthMenu": "_MENU_",
          "sZeroRecords": "Inga poster hittades",
          "oPaginate": {
              "sFirst": "Första",
              "sLast": "Sista",
              "sNext": "Nästa",
              "sPrevious": "Föregående"}
          },buttons: [{
            extend: '',
            text: '',
          },{
            extend: '',
            text: '',
          }]
        };
      }
      else
      {
        x = {
          pagingType: 'full_numbers',
          pageLength: 25,
          lengthMenu: [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "All"] ],
          processing: true,
          order: [[0, 'desc']],
          dom:"<'row'<'col-sm-12'>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-2'i><'col-sm-1'><'col-sm-4'p><'col-sm-1'><'col-sm-2'l>>",
          //dom: '<B>lfrtip',
          //"dom": '<"top"f>Brt<"bottom"ipl><"clear">',
          language:{"sEmptyTable": "No data was found",
          "sInfo": "Shows _START_ to _END_ from total _TOTAL_ ",
          "sInfoEmpty": "Shows 0 to 0 from total 0 ",
          "sInfoFiltered": "(filtered from total _MAX_)",
          "sLoadingRecords": "Loading...",
          "sSearch": "Filter:",
          "sLengthMenu": "Show _MENU_ rows",
          "sZeroRecords": "No post were found",
          "oPaginate": {
              "sFirst": "First",
              "sLast": "Last",
              "sNext": "Next",
              "sPrevious": "Previous"}
          },buttons: [{
            extend: '',
            text: '',
          },{
            extend: '',
            text: '',
          }]
        };
      }
      return x;
    }
    if(entrance=='actionplan'){

      let excelTitle = "";

      // Visible buttons or not
      if(this.is_admin||this.is_super_user){
        var showButtons="<'row'<'col-sm-4'B><'col-sm-6'>>";
      }else{
        var showButtons="";
      }
      if (this.usedlanguage == 'sv')
      {
        if (entrance=='workplace')
        {
          excelTitle = "Arbetsplats";
        }
        else if (entrance=='actionplan')
        {
          excelTitle = "Handlingsplan";
        }
        else if (entrance=='incident')
        {
          excelTitle = "Incident";
        }
        else if (entrance=='safetyround')
        {
          excelTitle = "Skyddsrond";
        }

        x = {
          pagingType: 'full_numbers',
          pageLength: 10,
          lengthMenu: [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "Alla"] ],
          processing: true,
          order: [[1, 'desc']],
          dom:"<'row'<'col-sm-12'>>" +
          showButtons +
          "<'row'<'col-sm-12'tr>>"+
          "<'row'<'col-sm-2'i><'col-sm-1'><'col-sm-4'p><'col-sm-1'><'col-sm-2'l>>",
          language:{"sEmptyTable": "Ingen data hittades",
          "sInfo": "Visar _START_ till _END_ av totalt _TOTAL_",
          "sInfoEmpty": "Visar 0 till 0 av totalt 0",
          "sInfoFiltered": "(filtrerat från totalt _MAX_)",
          "sLoadingRecords": "Laddar...",
          "sSearch": "Filtrera:",
          "sLengthMenu": "Visa _MENU_ rader",
          "sZeroRecords": "Inga poster hittades",
          "oPaginate": {
              "sFirst": "Första",
              "sLast": "Sista",
              "sNext": "Nästa",
              "sPrevious": "Föregående"}
          },
          buttons: {
            dom: {
              button: {
                tag: 'button',
                className: ''
              }
            },
            buttons: [{
                  extend: 'excel',
                  text: 'Ladda ner Excelutskrift',
                  className: 'ui button',
                  filename: excelTitle,
                  title: excelTitle
              },{
                extend: 'print',
                  text: 'Skriv ut',
                  className: 'ui button',
                  filename: excelTitle,
                  title: excelTitle
              }]
          }
        };
      }
      else
      {
        if (entrance=='workplace')
        {
          excelTitle = "Workplace";
        }
        else if (entrance=='actionplan')
        {
          excelTitle = "Actionplan";
        }
        else if (entrance=='incident')
        {
          excelTitle = "Incident";
        }
        else if (entrance=='safetyround')
        {
          excelTitle = "Safetyround";
        }

        x = {
          pagingType: 'full_numbers',
          pageLength: 10,
          lengthMenu: [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "All"] ],
          processing: true,
          order: [[1, 'desc']],
          dom:"<'row'<'col-sm-12'>>" +
          showButtons +
          "<'row'<'col-sm-12'tr>>"+
          "<'row'<'col-sm-2'i><'col-sm-1'><'col-sm-4'p><'col-sm-1'><'col-sm-2'l>>",
          language:{"sEmptyTable": "Ingen data hittades",
          "sInfo": "Shows _START_ to _END_ from total _TOTAL_ ",
          "sInfoEmpty": "Shows 0 to 0 from total 0 ",
          "sInfoFiltered": "(filtered from total _MAX_)",
          "sLoadingRecords": "Loading...",
          "sSearch": "Filter:",
          "sLengthMenu": "Show _MENU_ rows",
          "sZeroRecords": "No post were found",
          "oPaginate": {
              "sFirst": "First",
              "sLast": "Last",
              "sNext": "Next",
              "sPrevious": "Previous"}
          },
          buttons: {
            dom: {
              button: {
                tag: 'button',
                className: ''
              }
            },
            buttons: [{
                  extend: 'excel',
                  text: 'Download excel file',
                  className: 'ui button',
                  filename: excelTitle,
                  title: excelTitle
              },{
                extend: 'print',
                  text: 'Print',
                  className: 'ui button',
                  filename: excelTitle,
                  title: excelTitle
              }]
          }
        };
      }
      return x;
    }

    if((entrance=='incident') || (entrance=='safetyround') || (entrance=='workplace')){

      let excelTitle = "";

      // Visible buttons or not
      if(this.is_admin||this.is_super_user){
        var showButtons="<'row'<'col-sm-4'B><'col-sm-6'>>";
      }else{
        var showButtons="";
      }
      if (this.usedlanguage == 'sv')
      {
        if (entrance=='workplace')
        {
          excelTitle = "Arbetsplats";
        }
        else if (entrance=='actionplan')
        {
          excelTitle = "Handlingsplan";
        }
        else if (entrance=='incident')
        {
          excelTitle = "Incident";
        }
        else if (entrance=='safetyround')
        {
          excelTitle = "Skyddsrond";
        }

        x = {
          pagingType: 'full_numbers',
          pageLength: 10,
          lengthMenu: [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "Alla"] ],
          processing: true,
          order: [[0, 'desc']],
          dom:"<'row'<'col-sm-12'>>" +
          showButtons +
          "<'row'<'col-sm-12'tr>>"+
          "<'row'<'col-sm-2'i><'col-sm-1'><'col-sm-4'p><'col-sm-1'><'col-sm-2'l>>",
          language:{"sEmptyTable": "Ingen data hittades",
          "sInfo": "Visar _START_ till _END_ av totalt _TOTAL_",
          "sInfoEmpty": "Visar 0 till 0 av totalt 0",
          "sInfoFiltered": "(filtrerat från totalt _MAX_)",
          "sLoadingRecords": "Laddar...",
          "sSearch": "Filtrera:",
          "sLengthMenu": "Visa _MENU_ rader",
          "sZeroRecords": "Inga poster hittades",
          "oPaginate": {
              "sFirst": "Första",
              "sLast": "Sista",
              "sNext": "Nästa",
              "sPrevious": "Föregående"}
          },
          buttons: {
            dom: {
              button: {
                tag: 'button',
                className: ''
              }
            },
            buttons: [{
                  extend: 'excel',
                  text: 'Ladda ner Excelutskrift',
                  className: 'ui button',
                  filename: excelTitle,
                  title: excelTitle
              },{
                extend: 'print',
                  text: 'Skriv ut',
                  className: 'ui button',
                  filename: excelTitle,
                  title: excelTitle
              }]
          }
        };
      }
      else
      {
        if (entrance=='workplace')
        {
          excelTitle = "Workplace";
        }
        else if (entrance=='actionplan')
        {
          excelTitle = "Actionplan";
        }
        else if (entrance=='incident')
        {
          excelTitle = "Incident";
        }
        else if (entrance=='safetyround')
        {
          excelTitle = "Safetyround";
        }

        x = {
          pagingType: 'full_numbers',
          pageLength: 10,
          lengthMenu: [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "All"] ],
          processing: true,
          order: [[0, 'desc']],
          dom:"<'row'<'col-sm-12'>>" +
          showButtons +
          "<'row'<'col-sm-12'tr>>"+
          "<'row'<'col-sm-2'i><'col-sm-1'><'col-sm-4'p><'col-sm-1'><'col-sm-2'l>>",
          language:{"sEmptyTable": "Ingen data hittades",
          "sInfo": "Shows _START_ to _END_ from total _TOTAL_ ",
          "sInfoEmpty": "Shows 0 to 0 from total 0 ",
          "sInfoFiltered": "(filtered from total _MAX_)",
          "sLoadingRecords": "Loading...",
          "sSearch": "Filter:",
          "sLengthMenu": "Show _MENU_ rows",
          "sZeroRecords": "No post were found",
          "oPaginate": {
              "sFirst": "First",
              "sLast": "Last",
              "sNext": "Next",
              "sPrevious": "Previous"}
          },
          buttons: {
            dom: {
              button: {
                tag: 'button',
                className: ''
              }
            },
            buttons: [{
                  extend: 'excel',
                  text: 'Download excel file',
                  className: 'ui button',
                  filename: excelTitle,
                  title: excelTitle
              },{
                extend: 'print',
                  text: 'Print',
                  className: 'ui button',
                  filename: excelTitle,
                  title: excelTitle
              }]
          }
        };
      }
      return x;
    }
    return x;
  }
}
