<div class="ui stackable grid">
    <div class="row">
        <div class="sixteen wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <div class="header-text">
                            <h1>{{'incident'|translate}}</h1>
                        </div>
                    </div>
                    <div class="computer only three wide right aligned column" style="padding: 40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <button class="ui white button">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <form class="ui form" (submit)="add_incident(postForm)" #postForm="ngForm">
                    <div class="field">
                        <label>{{'typeofincident'|translate}}</label>
                        <div class="ui selection dropdown" id="incidenttypechose">
                            <input type="hidden" name="selectIncidentType">
                            <i class="dropdown icon"></i>
                            <div class="default text">{{selectIncidentType}}</div>
                            <div class="menu">
                            <div *ngFor="let eventListItem of eventList;let i=index" (click)="setIncidentType(eventListItem)" class="item" data-value="eventListItem">{{eventListItem.name}}</div>
                            </div>
                        </div><br><br>

                        <label>{{'typeofinjury'|translate}}</label>
                        <div class="ui selection dropdown" id="injurytypechose">
                            <input type="hidden" name="selectIncidentCategory">
                            <i class="dropdown icon"></i>
                            <div class="default text">{{selectIncidentCategory}}</div>
                            <div class="menu">
                            <div *ngFor="let categoryListItem of categoryList;let i=index" (click)="setCategoryType(categoryListItem)" class="item" data-value="categoryListItem">{{categoryListItem.name}}</div>
                            </div>
                        </div><br><br>

                        <div class="field">
                            <label>{{'responsible'|translate}}</label>
                            <div class="ui selection dropdown" id="incidentResponsibleChose">
                                <input type="hidden" name="incidentReporter">
                                <i class="dropdown icon"></i>
                                <div class="default text" style="color:black">{{incidentReporter}}</div>
                                <div class="menu">
                                <div *ngFor="let userInfoitem of userInfo;let i=index" (click)="setRoundResponsible(userInfoitem)" class="item" data-value="userInfoitem">{{userInfoitem.username}}</div>
                                </div>
                            </div>
                        </div>

                        <label>{{'incidentdate'| translate }}</label>
                        <input type="date" name="incidentDate" [value]="incidentDate" [(ngModel)]="incidentDate" /><br><br>
                        
                        <label>{{'department'|translate}}</label>
                        <div class="ui selection dropdown" id="depnameChose">
                            <input type="hidden" name="depname">
                            <i class="dropdown icon"></i>
                            <div class="default text">{{depname}}</div>
                            <div class="menu">
                            <div *ngFor="let orgmodelitem of orgmodel;let i=index" class="item" (click)="setDepartment(orgmodelitem)" data-value="orgmodelitem">{{orgmodelitem.Name}}</div>
                            </div>
                        </div><br><br>

                        <label>{{ 'incidentplace' | translate }}</label>
                        <input id="incidentPlace" name="incidentPlace" [(ngModel)]="incidentPlace"><br><br>

                        <label>{{ 'header' | translate }}</label>
                        <input id="incidentTitle" name="incidentTitle" [(ngModel)]="incidentTitle"><br><br>

                        <label>{{ 'description' | translate }}</label>
                        <textarea id="incidentDescription" name="incidentDescription" rows="3" [(ngModel)]="incidentDescription"></textarea><br><br>

                        <label>{{ 'Cause' | translate }}</label>
                        <input id="incidentCause" name="incidentCause" [(ngModel)]="incidentCause"><br><br>

                        <input class="ui button" type="file" (change)="imageUpload($event)" placeholder="Upload file" accept="application/msword,
                            application/vnd.ms-excel,
                            application/vnd.ms-powerpoint,
                            text/plain,
                            application/pdf,
                            image/*,
                            video/*"/>

                        <div [hidden]="!uploadFileDone" class="ui segment">
                            <div class="ui active loader"></div>
                            <p></p>
                        </div><br><br><br>

                        <div class="ui grid item" *ngFor="let file of filenames; let i=index" style="width:100%;margin-left:20px;padding:0px">
                            <div class="row" style="margin:0px;padding:0px">
                                <div class="eight wide column" style="margin:0px;padding:0px">
                                    <i class="ui icon file alternate outline"></i>{{file}}
                                </div>
                                <div class="eight wide column" style="margin:0px;padding:0px;cursor:pointer">
                                    <p style="color:red" (click)="removeImage(file[i], i)">{{'REMOVE' | translate | uppercase}}</p>
                                </div>
                            </div>
                        </div><br>
            
                        <hr>
                        <button [hidden]="uploadFileDone" style="margin:0px;margin-top:15px; margin-bottom:15px" class="ui positive button" (click)="saveItem()" type="submit" id="login"><i class="ui save icon"></i>{{ 'save' | translate }}</button>
                    </div>
                  </form>
            </div>
        </div>
    </div>
</div>
