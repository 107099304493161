import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take,concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSendEmailIncidentComponent } from 'src/app/modals/modal-send-email-incident/modal-send-email-incident.component';
import { PDFService } from "src/app/shared/services/pdf.service";
declare var $: any;

@Component({
  selector: 'app-org-items',
  templateUrl: './org-items.component.html',
  styleUrls: ['./org-items.component.css']
})
export class OrgItemsComponent implements OnInit {

  mainOrg: any;
  searchText: any;
  table:any;
  sortOrderAscOrDesc = true;
  msgListGet = false;
  orgInfo: any = {};
  itemsSubscribted: any = [];
  userInfo: any;
  users: any = [];
  chosenOrgId: any;
  activeTab: any = 0;
  checkedAll:boolean;
  checkBoxItems = new Array();
  checkBoxItems2 = new Array();
  subscriptionValues: any;
  items2:any = [];
  selectAll2=false;
  userId: any;
  localOrgId: any;
  userModelSub: any;
  usermodel: any;
  subscription: any;
  is_admin: any;
  is_super_user: any;

  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private modalService: NgbModal,
    private pdfService: PDFService) { }

  ngOnInit(): void {
    this.activeTab = 0;

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    this.route.params.pipe(take(1)).subscribe(params => {
      this.chosenOrgId = params['id'];

      this.dataService.getOrgInfo(this.chosenOrgId);
      this.dataService.orgInfoListener()
      .subscribe((orgInfo: any) => {
        if(orgInfo.length){
          this.orgInfo = orgInfo[0];
          var n;
          for (const [key, value] of Object.entries(this.orgInfo)) {
            if(key.includes('subscription_')){
              if(this.itemsSubscribted.includes(key+":"+value)){
              }else if(this.itemsSubscribted.includes(key)){
              }else{
                this.itemsSubscribted.push(key+":"+value);
              }
            }
          }

          this.userInfo=this.dataService.getUsers(this.chosenOrgId, 0);
          this.dataService.usersListener()
          .subscribe((users: any) => {
            this.users=users;
          })

          this.dataService.getSubscriptionValues();
          this.subscriptionValues=this.dataService.subscriptionValuesListener().subscribe((subscriptionValues)=>{
          this.subscriptionValues=subscriptionValues;
          for(let i=0;i<this.subscriptionValues.subscriptions.length;i++){
            this.items2.push(this.subscriptionValues.subscriptions[i].name);
            this.checkBoxItems.push(this.subscriptionValues.subscriptions[i].field+':0:'+this.subscriptionValues.subscriptions[i].name);
            this.checkBoxItems2.push(this.subscriptionValues.subscriptions[i].field);
            }
          });
        }
      });
    });

    this.msgService.sendMessage(null);
  }

  setActiveTab(tabnumber:any) {
    this.activeTab = tabnumber;
  }

  addUser() {
    this.routing.navigate(['/controlpanel/organisations/' + this.chosenOrgId + '/adduser']);
  }

  editUser(id:any) {
    this.routing.navigate(['/controlpanel/organisations/' + this.chosenOrgId + '/edituser/' + id]);
  }

  goBack() {
    this.routing.navigate(['/controlpanel/organisations']);
  }

  removeUser(id: any) {
    this.dataService.deleteUser(id, this.chosenOrgId);
  }

  updateItem() {
    var qwertObject=({
      'orginfoId':this.orgInfo.Id,
      'orginfoName':this.orgInfo.Name,
      'orginfoOrg_nr':this.orgInfo.Org_nr,
      'orginfoAddress_1':this.orgInfo.Address_1,
      'orginfoAddress_2':this.orgInfo.Address_2,
      'orginfoVisitAddress':this.orgInfo.visitAddress,
      'orginfoZip':this.orgInfo.Zip,
      'orginfoCity':this.orgInfo.City,
      'orginfoCountry':this.orgInfo.Country,
      'orginfoWww':this.orgInfo.Www,
      'orginfoEmail':this.orgInfo.Email,
      'orginfoPhone':this.orgInfo.Phone,
      'orginfoContact_person':this.orgInfo.Contact_person
    });
    this.dataService.updateUser(qwertObject, this.itemsSubscribted, this.orgInfo.Id);
  }

  selectAll(){
    this.selectAll2=!this.selectAll2;
  }

  changed(evt:any) {
    for(let k=0;k<this.itemsSubscribted.length;k++){
      if(this.itemsSubscribted[k].split(':')[0]==evt.target.name){
        if(evt.target.checked==true){
          this.itemsSubscribted[k]=this.itemsSubscribted[k].split(':')[0]+':1';
        }else{
          this.itemsSubscribted[k]=this.itemsSubscribted[k].split(':')[0]+':0';
        }
      }
    }
  }

  // A simple helper function to find the right name
  // This works but probably could be better.
  thisname(nametofind:any){
    for(let n=0;n<this.subscriptionValues.subscriptions.length;n++){
      if(this.subscriptionValues.subscriptions[n].field==nametofind){
        return this.subscriptionValues.subscriptions[n].name;
      }
    }
  }

  checkAll(){
    var checkAllTo='';
    this.checkedAll=!this.checkedAll;
    this.checkedAll==true?checkAllTo='1':checkAllTo='0';
    for(var k=0;k<this.itemsSubscribted.length;k++){
      this.itemsSubscribted[k]=this.itemsSubscribted[k].split(':')[0]+':'+checkAllTo;
    }
  }
}
