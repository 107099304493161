import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "../../auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take,concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSendEmailComponent } from 'src/app/modals/modal-send-email/modal-send-email.component';
import { PDFService } from "src/app/shared/services/pdf.service";
import { TranslateService } from '@ngx-translate/core';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
declare var $: any;

@Component({
  selector: 'app-safetyround-item',
  templateUrl: './safetyround-item.component.html',
  styleUrls: ['./safetyround-item.component.css']
})
export class SafetyroundItemComponent implements OnInit {

  BackendUrl = environment.BackendUrl;
  UploadUrl = environment.UploadUrl;
  URL = this.BackendUrl + "/file";

  safetyid: any;
  safetyRoundResponsible: string;
  userId: any;
  orgId: any;
  localOrgId: any;
  orgmodel: any;
  safetyitem: any;
  filetypeinfo: any;
  fileNames: any = [];
  srcImageName: any;
  subscriptions: any = {};
  is_admin: any;
  is_super_user: any;
  safetyRoundValues: any = [];
  displayAlert: boolean;
  santizedFile: any;

  constructor(private route: ActivatedRoute,
              private dataService: DataService,
              private authService: AuthService,
              private msgService: MessageService,
              private routing: Router,
              private modalService: NgbModal,
              private pdfService: PDFService,
              private translate: TranslateService,
              private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $("#selectSafetyItem").dropdown();
  }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.safetyid = params['id'];
    });

    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();
    this.displayAlert=false;

    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.orgId = this.authService.getOrgid();

    var file = "";
    this.dataService.getSafetyItembyId(this.safetyid);
    this.dataService
      .safetyItemModelUpdatedEditListener()
      .pipe(take(1))
      .subscribe((safety_item: any) => {
        this.safetyitem = safety_item.safetyitemvalue[0];
        for (var f = 0;f < this.safetyitem.File.split(",").length;f++) {
          if (this.safetyitem.File != "") {
            file += "|" + this.safetyitem.File.split(",")[f];
          }
        }
        this.getImage(file,'');
    });

    this.dataService.getSafetyRoundValuesbyId(this.safetyid);
    this.dataService
      .safetyRoundModelUpdatedEditListener()
      .pipe(take(1))
      .subscribe((safety_round: any) => {
        this.safetyRoundValues = safety_round.safetyroundvalue;
    });

    this.subscriptions = this.dataService.getSubscriptions(
      this.authService.getLocalOrgid()
    );
    this.dataService.subscriptionsListener().subscribe((subscriptions) => {
      this.subscriptions = subscriptions[0];
    });

    this.msgService.sendMessage(null);
  }

  goBack() {
    this.routing.navigate(['/safetyround']);
  }

  selectIssue(id: any) {
    this.routing.navigate(['safetyrounditem/' + this.safetyitem.Id + '/safetyroundissueitem/' + id]);
  }

  editIssue(id: any) {
    this.routing.navigate(['safetyrounditem/' + this.safetyitem.Id + '/safetyroundissueedit/' + id]);
  }

  sendmail() {
    const modalRef = this.modalService.open(ModalSendEmailComponent);
    modalRef.componentInstance.safetyitem = this.safetyitem;
    modalRef.componentInstance.safetyRoundValues = this.safetyRoundValues;
    modalRef.componentInstance.passEntry.pipe(take(1)).subscribe(() => {
      window.location.reload();
    })
  }

  download() {
    this.displayAlert=true;
    this.pdfService.createPDF('safetyround',this.safetyitem, this.safetyitem.Id, this.safetyRoundValues, false);
    setTimeout(() => { this.displayAlert = false }, 5000);
  }

  editItem() {
    this.routing.navigate(['/editsafetyround', this.safetyitem.Id]);
  }

  addsafetyrounditem() {
    this.routing.navigate(['safetyrounditem/' + this.safetyitem.Id + '/addsafetyroundissue']);
  }

  // A function for the filetype
  getImage(file:any,entrance:any) {
    this.dataService
      .fileInfo(environment.catalogue, file)
      .subscribe((response) => {
        this.dataService.fileInfoUpdated.next(response);
      });
    this.dataService
      .fileInfoListener()
      .pipe(take(1))
      .subscribe((response) => {

      if (typeof response != "undefined") {
          this.filetypeinfo=response;
          for (var i = 0; i < this.filetypeinfo.length; i++)
          {
            this.fileNames.push(this.filetypeinfo[i].File);
          }
      }
    });
  }

  splitimageName(image:any, entrance:any) {
    if (typeof this.filetypeinfo == "undefined" || this.filetypeinfo == null) {
      return "none";
    } else {
      for (var n = 0; n < this.filetypeinfo.length; n++) {
        if (this.filetypeinfo[n].File == image) {
          return this.filetypeinfo[n].thisFileName.slice(0,40);
        }
      }
    }
  }

  showImage2(folder:any, file:any, entrance:any) {
    this.srcImageName=this.splitimageName(file,entrance);
  }

  riskvalues(item:any) {
    if (!item.roundSeverity || !item.roundPossibility || !item.roundFrequency) {
      return "fa fa-info-circle";
    }
    if(item.roundSeverity==1 || item.roundSeverity==2 || item.roundSeverity==3 || item.roundSeverity==4 || item.roundSeverity==5){
      if(item.roundFrequency==1 || item.roundFrequency==2 || item.roundFrequency==3 || item.roundFrequency==4 || item.roundFrequency==5){
        return "numberSquareGreenSmall";
      }
    }
    if(item.roundSeverity==6 || item.roundSeverity==7 || item.roundSeverity==8 || item.roundSeverity==9 || item.roundSeverity==10){
      if(item.roundFrequency==6 || item.roundFrequency==7 || item.roundFrequency==8 || item.roundFrequency==9 || item.roundFrequency==10){
        return "numberSquareRedSmall";
      }
    }
    return "numberSquareYellowSmall";
  }

  riskvaluesNumber(item:any) {
    if (!item.roundSeverity || !item.roundPossibility || !item.roundFrequency) {
      return "";
    }
    if(item.roundSeverity==1 || item.roundSeverity==2 || item.roundSeverity==3 || item.roundSeverity==4 || item.roundSeverity==5){
      if(item.roundFrequency==1 || item.roundFrequency==2 || item.roundFrequency==3 || item.roundFrequency==4 || item.roundFrequency==5){
        if(item.roundPossibility==1 || item.roundPossibility==2 || item.roundPossibility==3 || item.roundPossibility==4 || item.roundPossibility==5){
          return 1;
        }else{
          return 2;
        }
      }else{
        if(item.roundPossibility==1 || item.roundPossibility==2 || item.roundPossibility==3 || item.roundPossibility==4 || item.roundPossibility==5){
          return 2;
        }else{
          return 3;
        }
      }
    }else{
      if(item.roundFrequency==1 || item.roundFrequency==2 || item.roundFrequency==3 || item.roundFrequency==4 || item.roundFrequency==5){
        if(item.roundPossibility==1 || item.roundPossibility==2 || item.roundPossibility==3 || item.roundPossibility==4 || item.roundPossibility==5){
          return 3;
        }else{
          return 4;
        }
      }else{
        if(item.roundPossibility==1 || item.roundPossibility==2 || item.roundPossibility==3 || item.roundPossibility==4 || item.roundPossibility==5){
          return 4;
        }else{
          return 5;
        }
      }
    }
  }

  openDocumentInNewWindow(fileId:any) {
    this.uploadService.getFileAsTrustedResourceUrl(fileId);
    this.uploadService.readFileDataListener().pipe(take(1)).subscribe({
      next: content => {

        // var popup = window.open('')
        // popup.location.href = pdfContent;
        //this.santizedFile = content;
        let opened=window.open(content,"_blank");
          if(!opened){
            window.location.href=content;
          }
          return false;
      },
      error: error => {
      }
    })
  }

  rondRemove(i: number) {
    if (confirm("Ta bort denna ?")) {
      for (
        var tableIndex = 0;
        tableIndex < this.safetyRoundValues.length;
        tableIndex++
      ) {
        if (this.safetyRoundValues[tableIndex].roundId == i) {
          this.safetyRoundValues[tableIndex].roundId = null;
          if (
            this.safetyRoundValues[tableIndex].Files != "" &&
            this.safetyRoundValues[tableIndex].Files != null
          ) {
            // Remove existing image belonging to this round
          }
          this.dataService.deleteRound(
            i,
            this.safetyRoundValues[tableIndex].Id
          );
          // This should only be done once
          break;
        }
      }
    }
  }
}
