<div class="modal-header">
    <h4 class="modal-title">{{'ADD_DEPARTMENT'|translate}}</h4>
</div>
<div class="modal-body">

    <form class="ui form" #postForm="ngForm">
        <label>{{ 'department' | translate }}</label>
        <input id="AvdName" name="AvdName" value="{{department.Name}}" [(ngModel)]="department.Name" ><br><br>
    </form>
    
    <!-- Modal footer -->
    <div class="modal-footer">
        <button class="ui positive button" (click)="saveDepartment()"><i class="ui icon save"></i>{{'save' | translate}}</button>
        <button type="button" class="ui button" (click)="activeModal.close('Close click')" id="ModalEditDepartmentComponent">{{"CLOSE" | translate}}</button>
    </div>
</div>
