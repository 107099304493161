<div class="ui secondary pointing menu">
        <a class="item" (click)="navigateHome()" id="tourStep30">
                <div class="headerlogo"></div>
        </a>
        <div class="right menu">
                <img [hidden]="defaultLanguage=='sv'" alt="Sweden" style="margin-top:25px;margin-right:10px;cursor:pointer;height:20px" 
                        src="https://catamphetamine.gitlab.io//country-flag-icons/3x2/SE.svg" (click)="changeLanguage('en')"/>
                <img [hidden]="defaultLanguage=='en'" alt="Uniged Kingdom" style="margin-top:25px;margin-right:10px;cursor:pointer;height:20px" 
                        src="https://catamphetamine.gitlab.io//country-flag-icons/3x2/GB.svg" (click)="changeLanguage('sv')"/>   

                <div class="cdocItem" *ngIf="subscriptions&&subscriptions.subscription_managementsystem==1">
                        <a class="item" [class.active]="activeHeader==1" (click)="navigateProduct()">{{'managementsystem'|translate}}</a>
                </div>
                <div class="cdocItem" *ngIf="subscriptions&&subscriptions.subscription_workplace==1">
                        <a class="item" [class.active]="activeHeader==6" (click)="navigateWorkPlace()">{{'workplaces'|translate}}</a>
                </div>
                <div class="cdocItem">
                        <a class="item" [class.active]="activeHeader==2" (click)="navigateSafetyRound()">{{'safetyrounds'|translate}}</a>
                </div>
                <div class="cdocItem">
                        <a class="item" [class.active]="activeHeader==3" (click)="navigateIncident()">{{'incidents'|translate}}</a>
                </div>
                <div class="cdocItem" *ngIf="is_super_user">
                        <a class="item" [class.active]="activeHeader==5" (click)="navigateControlPanel()">{{'thecontrolpanel'|translate}}</a>
                </div>
                <div *ngIf="is_admin||is_super_user">
                        <div class="ui dropdown" id='selectChemical'>
                                <div class="cdocItem">
                                        <a [class.active]="activeHeader==4" class="item">{{"tools" | translate}}<i class="dropdown icon" style="margin-left:0px;color:white"></i></a>
                                </div>
                                <div class="menu">
                                        <div (click)="navigateTools1()" class="item"><a data-target="#" id="actionplan">{{"actionplan" | translate}}</a></div>
                                        <div *ngIf="token&&subscriptions&&subscriptions.subscription_chemicaldocumentation==1" class="item"><a href="http://portal.cdoc.se/login?access_token={{token}}" target="_blank">Kemikaliehantering</a></div>
                                        <div *ngIf="subscriptions&&subscriptions.subscription_lawpoint==1" class="item"><a href="http://www.lagpunkten.se" target="_blank">Lagpunkten</a></div>
                                </div>
                        </div>
                </div>
                <div>
                        <div class="ui dropdown" id='select'>
                            <div class="item">
                                <a class="item" [class.active]="activeHeader==6"><i class="ui user circle big icon" area-hidden="true"></i></a>
                            </div>
                            <div class="menu">
                                <div class="item" (click)="navigateHelp()"><a data-target="#">{{'help'|translate}}</a></div>
                                <div class="item" (click)="navigateSettings()" *ngIf="is_admin||is_super_user"><a data-target="#">{{'settings'|translate}}</a></div>
                                <div class="item" (click)="headerModalOpen()"><a>{{'contact'|translate}}</a></div>
                                <div class="item" (click)="logout()"><a *ngIf="isAuthenticated" >{{'logout'|translate}}</a></div>
                            </div>
                        </div>
                </div>
        </div>
        <div class="right menu" id='selectMobile' style="margin-right:0;padding-right:0">
                <div class="ui dropdown" id="menumobile">
                        <div class="item">
                                <a class="item"><i class="ui icon large bars" area-hidden="true"></i></a>
                        </div>
                        <div class="ui vertical menu">
                                <div class="item" *ngIf="subscriptions&&subscriptions.subscription_managementsystem==1" (click)="navigateProduct()"><a data-target="#">{{"managementsystem" | translate}}</a></div>
                                <div class="item" *ngIf="subscriptions&&subscriptions.subscription_workplace==1" (click)="navigateWorkPlace()"><a data-target="#">{{"workplaces" | translate}}</a></div>
                                <div class="item" (click)="navigateSafetyRound()"><a data-target="#">{{"safetyrounds" | translate}}</a></div>
                                <div class="item" (click)="navigateIncident()"><a data-target="#">{{"incidents" | translate}}</a></div>
                                <div class="item" (click)="navigateControlPanel()"><a data-target="#">{{"thecontrolpanel" | translate}}</a></div>
                                <div class="item" (click)="navigateTools1()"><a data-target="#">{{"actionplan" | translate}}</a></div>
                                <div class="item" *ngIf="token&&subscriptions&&subscriptions.subscription_chemicaldocumentation==1"><a data-target="http://portal.cdoc.se/login?access_token={{token}}">Kemikaliehantering</a></div>
                                <div class="item" *ngIf="token&&subscriptions&&subscriptions.subscription_lawpoint==1"><a href="http://www.lagpunkten.se" target="_blank">Lagpunkten</a></div>
                                <div class="item" (click)="navigateHelp()"><a data-target="#">{{'help'|translate}}</a></div>
                                <div class="item" (click)="navigateSettings()" *ngIf="is_admin||is_super_user"><a data-target="#">{{'settings'|translate}}</a></div>
                                <div class="item" (click)="headerModalOpen()"><a>{{'contact'|translate}}</a></div>
                                <div class="item" (click)="logout()"><a *ngIf="isAuthenticated" >{{'logout'|translate}}</a></div>
                        </div>
                </div>
        </div>
</div>
<div class="modal" id="showMailModalbox" [style.display]="showHeaderModalbox ? 'block' : 'none'">
        <div class="modal-dialog">
                <div class="modal-content">

                        <!-- Modal Header -->
                        <div class="modal-header">
                                <h4 class="modal-title">{{'contact'|translate}}</h4>
                                <button type="button" class="close" data-dismiss="modal"  (click)="headerModalClose()">&times;</button>
                        </div>
                        <div class="modal-body">
                                <div class="form-group row">
                                        <label for="mailFrom" class="col-sm-12 col-form-label">{{'name'|translate}}:</label>
                                        <div class="col-sm-12">
                                                <input type="text"
                                                        required
                                                        class="form-control"
                                                        id="mailRond"
                                                        name="mailRond"
                                                        [value]="mailHeaderRond"
                                                        [(ngModel)]="mailHeaderRond">
                                        </div>
                                </div>
                                <div class="form-group row">
                                        <label for="mailHeaderSubject" class="col-sm-12 col-form-label">{{'email'|translate}}:</label>
                                        <div class="col-sm-12">
                                                <input type="text"
                                                        required
                                                        class="form-control"
                                                        id="mailFrom"
                                                        name="mailFrom"
                                                        [value]="mailHeaderFrom"
                                                        [(ngModel)]="mailHeaderFrom"
                                                        placeholder="Från">
                                        </div>
                                </div>
                                <div class="form-group row">
                                        <label for="mailHeaderSubject" class="col-sm-12 col-form-label">{{'subject'|translate}}:</label>
                                        <div class="col-sm-12">
                                                <input type="text"
                                                        required
                                                        class="form-control"
                                                        id="mailHeaderSubject"
                                                        name="mailHeaderSubject"
                                                        [value]="mailHeaderSubject"
                                                        [(ngModel)]="mailHeaderSubject"
                                                        placeholder="{{'subject'|translate}}">
                                        </div>
                                </div>
                                <div class="form-group row">
                                        <label for="mailMessage" class="col-sm-12 col-form-label">{{'message'|translate}}:</label>
                                        <div class="col-sm-12">
                                                <textarea
                                                        class="form-control"
                                                        id="mailMessage"
                                                        name="mailMessage"
                                                        [value]="mailHeaderMessage"
                                                        [(ngModel)]="mailHeaderMessage"
                                                        placeholder="{{'message'|translate}}">
                                                </textarea>
                                        </div>
                                </div>
                        </div>
                        <!-- Modal footer -->
                        <div class="modal-footer">
                                <div class="col-sm-12" align="center">
                                        <i data-dismiss="modal" class="fa fa-close fa-2x" style="color:black !important;cursor:pointer" (click)="headerModalClose()"></i>
                                        <i data-dismiss="modal" (click)="headerModalSend()" style="color:black !important;cursor:pointer;margin-left:5px" class="fa fa-floppy-o fa-2x"></i>
                                </div>
                        </div>
                </div>
        </div>
</div>
