/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash-es';

@Pipe({ name: 'sortBy' })
export class SortPipe implements PipeTransform {
  transform(value: any[], order: boolean = false, column: string = '', lower: string): any[] {
    // column='Department';
    if (!value || order === false) { return value; } // no array
    if (value.length <= 1) { return value; } // array with only one item
    if (!column || column === '') {
      if(!order){return value.sort()}
      else{return value.sort().reverse();}
    } // sort 1d array

    if(lower=="lower"){
      if (!order)
      {
        return orderBy(value, [user=> user[column].toLowerCase()], ['desc']);
      }
      else
      {
        return orderBy(value, [user=> user[column].toLowerCase()], ['asc']);
      }
    }else{
      if (!order)
      {
        return orderBy(value, [column], ['desc']);
      }
      else
      {
        return orderBy(value, [column], ['asc']);
      }
    }
  }
}
