<div class="ui stackable grid">
    <div class="row">
        <div class="eleven wide column">
            <div class="ui card">
                <div class="ui grid row">
                    <div class="thirteen wide column">
                        <div class="header-text">
                            <h1>{{'incident'|translate}}</h1>
                        </div>
                    </div>
                    <div class="computer only three wide right aligned column" style="padding: 40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <button class="ui white button">{{"back" | translate}}</button>
                    </div>
                    <div class="mobile only one wide column" style="padding-top:40px;cursor:pointer;opacity:0.8" (click)="goBack()">
                        <i class="ui icon left long arrow"></i>
                    </div>
                </div>
                <div class="ui grid row">
                    <div class="twelve wide column">
                        <h3>{{'riskevaluationandmeasures' | translate}}</h3>
                    </div>
                    <div class="computer only four wide left aligned column">
                        <button class="ui positive button" *ngIf="(is_admin||is_super_user)" (click)="addincidentitem()"><i class="ui icon plus"></i>{{"add_point"|translate}}</button>
                    </div>
                    <div class="mobile only one wide left aligned column">
                        <i class="ui icon large green plus" *ngIf="(is_admin||is_super_user)" (click)="addincidentitem()"></i>
                    </div>
                </div>
                <div *ngIf="incidentitem&&incidentValues&&incidentValues.length == 0">
                    <p style="margin-left:20px;font-style:italic">{{"no_point_in_list"|translate}}</p>
                </div>
                <div class="second-line" style="margin:20px" *ngIf="incidentitem&&incidentValues&&incidentValues.length > 0">
                    <div class="ui divided items" *ngFor="let rondVarde of incidentValues;let i=index;let first=first;" style="margin:20px; width:700px">
                        <div class="item">
                            <div class="content">
                                <i class="icon green large edit" (click)="editIssue(rondVarde.roundId)" style="cursor:pointer"></i>
                                <a class="header" *ngIf="rondVarde.roundResponsible2!=''" (click)="selectIssue(rondVarde.roundId)">{{rondVarde.roundDoneDate}} ({{rondVarde.roundResponsible2}})</a>
                                <a class="header" *ngIf="rondVarde.roundResponsible2==''" (click)="selectIssue(rondVarde.roundId)">{{rondVarde.roundDoneDate}}</a>
                                <div class="meta">
                                    <span class="cinema">{{rondVarde.roundMeasure}}</span>
                                </div>
                                <div class="description">
                                    <p>{{rondVarde.roundRisk}}</p>
                                </div>
                                <div class="extra">
                                    <div *ngIf="rondVarde.roundDone=='Nej'" class="ui label"><i class="ui icon clock outline"></i>{{'ongoing'|translate}}</div>
                                    <div *ngIf="rondVarde.roundDone=='Ja'" class="ui label green"><i class="ui icon check"></i>{{'finished'|translate}} {{rondVarde.roundDoneRealDate | date:"yyyy-MM-dd"}}</div>
                                    <div *ngIf="rondVarde.roundActive=='Ja'" class="ui label blue"><i class="ui icon check"></i>{{'active'|translate}} {{rondVarde.roundDoneActiveDate | date:"yyyy-MM-dd"}}</div>
                                    <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1&&riskvaluesNumber(rondVarde)==1" class="ui label green">{{riskvaluesNumber(rondVarde)}}</div>
                                    <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1&&riskvaluesNumber(rondVarde)==2" class="ui label green">{{riskvaluesNumber(rondVarde)}}</div>
                                    <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1&&riskvaluesNumber(rondVarde)==3" class="ui label yellow">{{riskvaluesNumber(rondVarde)}}</div>
                                    <div *ngIf="subscriptions&&subscriptions.subscription_riskevaluation==1&&riskvaluesNumber(rondVarde)==4" class="ui label red">{{riskvaluesNumber(rondVarde)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="five wide column">
            <div class="rectangle-green">
                <div class="ui grid row">
                    <div class="twelve wide column">
                        <div *ngIf="incidentitem" class="green-header">
                            {{ 'title' | translate }}
                        </div>
                        <div *ngIf="incidentitem" class="green-text">
                            {{incidentitem.Title}}
                        </div>
                    </div>
                    <div class="one wide right aligned column" style="margin-top: 20px; cursor:pointer; color:white" (click)="editItem()">
                        <i class="ui large icon edit"></i>
                    </div>
                    <div class="one wide right aligned column" style="margin-top: 20px; cursor:pointer; color:white">
                        <div class="ui dropdown" id='selectIncidentItem'>
                            <i class="ui icon large ellipsis vertical" style="margin-bottom:10px" ></i>
                            <div class="menu">
                                <div (click)="sendmail()" class="item"><a data-target="#"><i class="ui icon mail"></i> {{"incident" | translate}}</a></div>
                                <div (click)="download()" class="item"><a data-target="#"><i class="ui icon download"></i> {{"incident" | translate}}</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="incidentitem" class="green-header">
                    {{ 'description' | translate }}
                </div>
                <div *ngIf="incidentitem" class="green-text">
                    {{incidentitem.Text}}
                </div>
                <div *ngIf="incidentitem" class="green-header">
                    {{'typeofincident'|translate}}
                </div>
                <div *ngIf="incidentitem" class="green-text">
                    {{incidentitem.typeofaction}}
                </div>
                <div *ngIf="incidentitem" class="green-header">
                    {{'typeofinjury'|translate}}
                </div>
                <div *ngIf="incidentitem" class="green-text">
                    {{incidentitem.category}}
                </div>
                <div *ngIf="incidentitem" class="green-header">
                    {{'responsible'|translate}}
                </div>
                <div *ngIf="incidentitem" class="green-text">
                    {{incidentitem.Reporter}}
                </div>
                <div *ngIf="incidentitem" class="green-header">
                    {{'date'| translate }}
                </div>
                <div *ngIf="incidentitem" class="green-text">
                    {{incidentitem.Date}}
                </div>
                <div *ngIf="incidentitem" class="green-header">
                    {{ 'place' | translate }}
                </div>
                <div *ngIf="incidentitem" class="green-text">
                    {{incidentitem.Place}}
                </div>
                <div *ngIf="incidentitem" class="green-header">
                    {{'ATTACHED_FILES'|translate}}
                </div>
                <div *ngFor="let fileName of fileNames;let i=index;" class="green-text">
                    <a (click)="openDocumentInNewWindow(filetypeinfo[i])" *ngIf="fileName" style="color:white;cursor:pointer">
                        <i title="{{splitimageName(fileName,'')}}" class="ui icon pdf file" style="font-size:14px;color:white"></i><small>{{splitimageName(fileName,'')}}</small>
                    </a>
                </div>
                <div *ngIf="incidentitem" class="green-text" style="color:#9CD487;margin-top:20px;margin-right:10px">
                    <i class="icon info circle"></i>
                    {{'incidentinfotext' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
