import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "../../../auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take, concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-incidentissue-edit',
  templateUrl: './incidentissue-edit.component.html',
  styleUrls: ['./incidentissue-edit.component.css']
})
export class IncidentissueEditComponent implements OnInit {

  UploadUrl = environment.UploadUrl;

  incidentissueid: any;
  orgId: any;
  localOrgId: any;
  incidentRoundValue: any;
  fileNames: any = [];
  file: any = "";
  filetypeinfo: any;
  subscriptions: any;
  is_admin: any;
  is_super_user: any;
  userInfo: any;
  selectedFile: any = [];
  files: any = [];
  filenames: any = [];
  item: any = [];
  userId: any;
  roundDone: any = false;
  roundActive: any = false;
  filesExisting: any;
  incidentRoundValues: any = [];
  uploadFileDone: boolean = false;
  showTab1: boolean = true;
  showTab2: boolean = false;

  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private translate: TranslateService,
    private uploadService: FileUploadService) { }

  ngAfterViewInit(): void {
    $('.ui.dropdown').dropdown();
  }

  ngOnInit(): void {
    this.orgId = this.authService.getOrgid();
    this.localOrgId = this.authService.getLocalOrgid();
    this.userId = this.authService.getUserId();
    this.is_admin = this.authService.getAdmin();
    this.is_super_user = this.authService.getSuperUser();

    this.route.params.pipe(take(1)).subscribe(params => {
      this.incidentissueid = params['id'];

      this.dataService.getIncidentRoundValuebyId(this.incidentissueid);
      this.dataService
        .incidentRoundModelUpdatedEditListener()
        .pipe(take(1))
        .subscribe((roundItem: any) => {
          this.incidentRoundValue = roundItem.incidentroundvalue[0];

          if (this.incidentRoundValue.roundDone == "Ja")
          {
            this.roundDone = true;
          }

          if (this.incidentRoundValue.roundActive == "Ja")
          {
            this.roundActive = true;
          }

          if (this.incidentRoundValue.Files != null)
          {
            for (var f = 0; f < this.incidentRoundValue.Files.split(",").length; f++) {
              if (this.incidentRoundValue.Files != "" && this.incidentRoundValue.Files != undefined) {
                this.file += "|" + this.incidentRoundValue.Files.split(",")[f];
              }
            }

            this.getImage(this.file,'');

            this.filesExisting = 0;
            if (this.incidentRoundValue.Files.length > 1)
            {
              this.files = this.incidentRoundValue.Files.split(",");
              this.filesExisting += 1;
            }
          }
      });
    });

    this.subscriptions=this.dataService.getSubscriptions(this.localOrgId);
    this.dataService.subscriptionsListener()
    .subscribe((subscriptions) => {
      this.subscriptions=subscriptions[0];
    });

    this.dataService.getUsers(this.authService.getOrgid(), this.userId);
    this.dataService.usersListener().subscribe((users) => {
      this.userInfo = users;
    });

    this.msgService.sendMessage(null);
  }

  goBack() {
    this.routing.navigate(['incidentitem/' + this.incidentRoundValue.incidentId + '/incidentissueitem/' + this.incidentissueid]);
  }

  setRoundResponsible(userInfo:any) {
    this.incidentRoundValue.roundResponsible2 = userInfo.first_name + " " + userInfo.last_name + " " + userInfo.email;
  }

  imageUpload(event:any) {
    this.uploadFileDone = true,
    this.uploadService.upload(event.target.files[0], "");
    this.uploadService.getFileDataListener().pipe(take(1)).subscribe((filename:any) => {
      if (filename == null)
      {
        Swal.fire("Error", "Error");
      }
      else
      {
        this.files.push(filename);
        setTimeout(() => {
          this.uploadFileDone = false;

          this.selectedFile.push(event.target.files[0]);
    
          if (this.files) {
            this.filenames.push(event.target.files[0].name);
          } else {
            this.filenames = event.target.files[0].name;
          }
          this.item.push(1);
        }, 1000);
      }
    });
  }

  getImage(file:any,entrance:any) {
    this.dataService
      .fileInfo(environment.catalogue, file)
      .subscribe((response) => {
        this.dataService.fileInfoUpdated.next(response);
      });
    this.dataService
      .fileInfoListener()
      .pipe(take(1))
      .subscribe((response) => {

      if (typeof response != "undefined") {
          this.filetypeinfo=response;
          for (var i = 0; i < this.filetypeinfo.length; i++)
          {
            this.fileNames.push(this.filetypeinfo[i].File);
          }
      }
    });
  }

  removeImage(file: any, index: any) {
    this.filetypeinfo.splice(index, 1);
    this.fileNames.splice(index, 1);

    var x = this.files.splice(index, 1);

    if (this.files.length + 1 == 1)
    {
      this.incidentRoundValue.Files = this.incidentRoundValue.Files.replace(
        x,
        ""
      );
    }

    if (this.files.length + 1 > 1)
    {
      if (index == 0)
      {
        this.incidentRoundValue.Files = this.incidentRoundValue.Files.replace(
          x + ",",
          ""
        );
      }
      else
      {
        this.incidentRoundValue.Files = this.incidentRoundValue.Files.replace(
          "," + x,
          ""
        );
        }
    }
  }

  changeTab(flik:boolean){
    if (flik == true) {
      this.showTab1 = true;
      this.showTab2 = false;
    } else {
      this.showTab1 = false;
      this.showTab2 = true;
    }
  }

  updateItem() {
    let user = this.authService.getUserId();

    this.incidentRoundValue.roundDone = "Nej"
    if (this.roundDone == true)
    {
      this.incidentRoundValue.roundDone = "Ja";
    }

    this.incidentRoundValue.roundActive = "Nej";
    if (this.roundActive == true)
    {
      this.incidentRoundValue.roundActive = "Ja";
    }

    if(!this.filesExisting){this.filesExisting=0;}
    this.file = "";
    if (this.incidentRoundValue.Files == null)
    { 
      this.file = "";
    }
    else
    {
      this.file = this.incidentRoundValue.Files;
    }
    
    for (var i = 0; i < this.selectedFile.length; i++) {
      if (this.selectedFile[i]) {
        if (i != 0 || (i == 0 && this.file != "")) {
          this.file += ",";
        }
        this.file += this.files[i + this.filesExisting];
      }
    }

    if (this.selectedFile.length > 0)
    {
      this.incidentRoundValue.Files = this.file;
    }

    this.dataService.updateIncidentRound(this.incidentRoundValue, user);
  }

  severityValues(sev:any) {
    if (sev == 1 || sev == 2 || sev == 3 || sev == 4 || sev == 5) {
      // return "lätt";
      return  this.translate.instant('Severity_low');
    }else{
      // return "svår";
      return  this.translate.instant('Severity_high');
    }
  }

  frequencyValues(freq:any) {
    if (freq == 1 || freq == 2 || freq == 3 || freq == 4 || freq == 5) {
      // return "sällan till mindre ofta och/eller kort exponeringstid";
      return  this.translate.instant('Frequency_low');
    }else{
      // return "ofta till kontinuerlig och/eller lång exponeringstid";
      return  this.translate.instant('Frequency_high');
    }
  }

  possibilityValues(poss:any) {
    if (poss == 1 || poss == 2 || poss == 3 || poss == 4 || poss == 5) {
      return  this.translate.instant('Possibility_low');
      //return "möjligt under vissa omständigheter";
    }else{
      return  this.translate.instant('Possibility_high');
      //return "knappast möjligt";
    }
  }

  riskvaluesModalNumber(sev:any, poss:any, freq:any) {
    if (!sev || !poss || !freq) {
      // return "fa fa-info-circle";
      return "";
    }
    if(sev==1 || sev==2 || sev==3 || sev==4 || sev==5){
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 1;
        }else{
          return 2;
        }
      }else{
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 2;
        }else{
          return 3;
        }
      }
    }else{
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 3;
        }else{
          return 4;
        }
      }else{
        if(poss==1 || poss==2 || poss==3 || poss==4 || poss==5){
          return 4;
        }else{
          return 5;
        }
      }
    }
  }

  riskvaluesModal(sev:any, poss:any, freq:any, entrance:any) {
    if (!sev || !poss || !freq) {
      if(entrance=='panel'){
        return "";
      }else{
        return "numberSquareGrey";
      }
    }
    if(sev==1 || sev==2 || sev==3 || sev==4 || sev==5){
      if(freq==1 || freq==2 || freq==3 || freq==4 || freq==5){
        return "numberSquareGreen";
      }
    }
    if(sev > 5){
      if(freq > 4){
        return "numberSquareRed";
      }
    }
    return "numberSquareYellow";
  }
}
